import {normalize, schema, denormalize} from 'normalizr';
import productCountry from "./normalizeProductsCountries";
import productLanguage from "./normalizeProductsLanguages";
import productAttributeValue from "./normalizeProductsAttributesValues";
import assetLanguage from "./normalizeAssetsLanguages";
import assetCountry from "./normalizeAssetsCountries";
import section from "./normalizeSections";
import typeFile from "./normalizeTypesFiles";

const asset = new schema.Entity('assets', {
    asset_language: [assetLanguage],
    asset_country: [assetCountry],
    section: section,
    type_file: typeFile,
}, {idAttribute: "id"});
const product = new schema.Entity('products', {
    product_country: [productCountry],
    product_language: [productLanguage],
    product_attribute_value: [productAttributeValue],
    asset: [asset],
}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {products: [product]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = product;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = product;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default product;
