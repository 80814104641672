import {
    INVALIDATE_TYPESFILESSECTIONS,
    ERROR_TYPESFILESSECTIONS,
    RECEIVE_TYPESFILESSECTIONS,
    REQUEST_TYPESFILESSECTIONS,
    RESET_TYPESFILESSECTIONS,
    ERROR_TYPEFILESECTION,
    RECEIVE_TYPEFILESECTION,
    REQUEST_TYPEFILESECTION,
    UPDATE_TYPEFILESECTION,
    REQUEST_UPDATE_TYPEFILESECTION,
    SUCCESS_UPDATE_TYPEFILESECTION,
    ERROR_UPDATE_TYPEFILESECTION,
    RESET_UPDATE_TYPEFILESECTION,
    REQUEST_UPDATE_TYPESFILESSECTIONS,
    SUCCESS_UPDATE_TYPESFILESSECTIONS,
    ERROR_UPDATE_TYPESFILESSECTIONS,
    RESET_UPDATE_TYPESFILESSECTIONS,
    CREATE_TYPEFILESECTION,
    ERROR_CREATE_TYPEFILESECTION,
    REQUEST_CREATE_TYPEFILESECTION,
    RESET_CREATE_TYPEFILESECTION,
    SUCCESS_CREATE_TYPEFILESECTION,
    REQUEST_CREATE_TYPESFILESSECTIONS,
    SUCCESS_CREATE_TYPESFILESSECTIONS,
    ERROR_CREATE_TYPESFILESSECTIONS,
    RESET_CREATE_TYPESFILESSECTIONS,
    DELETE_TYPEFILESECTION,
    DELETE_CREATE_TYPEFILESECTION,
    DELETE_UPDATE_TYPEFILESECTION,
    REQUEST_DELETE_TYPEFILESECTION,
    SUCCESS_DELETE_TYPEFILESECTION,
    ERROR_DELETE_TYPEFILESECTION,
    RESET_DELETE_TYPEFILESECTION,
    REQUEST_PRINT_TYPEFILESECTION,
    SUCCESS_PRINT_TYPEFILESECTION,
    ERROR_PRINT_TYPEFILESECTION,
    RESET_PRINT_TYPEFILESECTION,
    RECEIVE_FILE_TYPEFILESECTION
} from '../actions/TypeFileSectionActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';
import {
    RECEIVE_SECTION,
    RECEIVE_SECTIONS,
    SUCCESS_DELETE_SECTION,
    SUCCESS_CREATE_SECTION,
    SUCCESS_UPDATE_SECTION,
    SUCCESS_UPDATE_SECTIONS

} from '../actions/SectionActions';
import {
    RECEIVE_PAGE,
    RECEIVE_PAGES,
    SUCCESS_DELETE_PAGE,
    SUCCESS_CREATE_PAGE,
    SUCCESS_UPDATE_PAGE,
    SUCCESS_UPDATE_PAGES

} from '../actions/PageActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        typesFilesSections: {},
        files: {},
    }
}

function typesFilesSectionsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                typesFilesSections: {}
            });
        case RECEIVE_TYPESFILESSECTIONS:
            let dato = action.typesFilesSections.entities.typesFilesSections;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                typesFilesSections: merge({}, state.typesFilesSections, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_TYPEFILESECTION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_TYPEFILESECTION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_TYPEFILESECTION:
            let datoTypeFileSection = action.typeFileSection.entities.typesFilesSections;
            return Object.assign({}, state, {
                typesFilesSections: merge({}, state.typesFilesSections, datoTypeFileSection),
                isFetching: false,
            });
        case RECEIVE_FILE_TYPEFILESECTION:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_TYPEFILESECTION:
            let datoTypeFileSectionEliminado = action.typeFileSection.entities.typesFilesSections;
            return Object.assign({}, state, {
                typesFilesSections: mergeWith(clone(datoTypeFileSectionEliminado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TYPEFILESECTION:
            let datoTypeFileSectionCreado = action.typeFileSection.entities.typesFilesSections;
            return Object.assign({}, state, {
                typesFilesSections: mergeWith(clone(datoTypeFileSectionCreado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TYPESFILESSECTIONS:
            let datosTypeFileSectionCreado = action.typesFilesSections.entities.typesFilesSections;
                return Object.assign({}, state, {
                    typesFilesSections: mergeWith(clone(datosTypeFileSectionCreado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_TYPEFILESECTION:
            let datoTypeFileSectionActualizado = action.typeFileSection.entities.typesFilesSections;
            return Object.assign({}, state, {
                typesFilesSections: mergeWith(clone(datoTypeFileSectionActualizado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_TYPESFILESSECTIONS:
            let datosTypeFileSectionActualizado = action.typesFilesSections.entities.typesFilesSections;
                return Object.assign({}, state, {
                    typesFilesSections: mergeWith(clone(datosTypeFileSectionActualizado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.typesFilesSections ? action.assets.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datoassetEliminado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datoassetCreado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datoassetActualizado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.typesFilesSections ? action.assets.entities.typesFilesSections : {} ;
        return Object.assign({}, state, {
            typesFilesSections: mergeWith(clone(datosassetActualizado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
        });
//SECTION
case RECEIVE_SECTION:
    let section = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, section),
    });
case RECEIVE_SECTIONS:
    let sections = action.sections.entities && action.sections.entities.typesFilesSections ? action.sections.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, sections),
    });
case SUCCESS_DELETE_SECTION:
    let datosectionEliminado = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datosectionEliminado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_SECTION:
    let datosectionCreado = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datosectionCreado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_SECTION:
    let datosectionActualizado = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datosectionActualizado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_SECTIONS:
    let datossectionActualizado = action.sections.entities && action.sections.entities.typesFilesSections ? action.sections.entities.typesFilesSections : {} ;
        return Object.assign({}, state, {
            typesFilesSections: mergeWith(clone(datossectionActualizado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
        });
//PAGE
case RECEIVE_PAGE:
    let page = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, page),
    });
case RECEIVE_PAGES:
    let pages = action.pages.entities && action.pages.entities.typesFilesSections ? action.pages.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: merge({}, state.typesFilesSections, pages),
    });
case SUCCESS_DELETE_PAGE:
    let datopageEliminado = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datopageEliminado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PAGE:
    let datopageCreado = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datopageCreado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PAGE:
    let datopageActualizado = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : {};
    return Object.assign({}, state, {
        typesFilesSections: mergeWith(clone(datopageActualizado), pickBy(state.typesFilesSections, function(typeFileSection) {return typeFileSection.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PAGES:
    let datospageActualizado = action.pages.entities && action.pages.entities.typesFilesSections ? action.pages.entities.typesFilesSections : {} ;
        return Object.assign({}, state, {
            typesFilesSections: mergeWith(clone(datospageActualizado), state.typesFilesSections, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                typesFilesSections: {}
            });
        default:
            return state
    }
}


function allTypesFilesSections(state = [], action) {
    switch (action.type) {
        case RECEIVE_TYPESFILESSECTIONS:
            return action.typesFilesSections.result && action.typesFilesSections.result.typesFilesSections ? union(action.typesFilesSections.result.typesFilesSections, state) : (action.typesFilesSections.result ? action.typesFilesSections.result : state) ;
        case RECEIVE_TYPEFILESECTION:
                return action.typeFileSection.result ? union([action.typeFileSection.result], state) : state;
        
        case SUCCESS_CREATE_TYPEFILESECTION:
                   let datoTypeFileSectionSCreate = action.typeFileSection.entities.typesFilesSections;
                   let idNuevoSCreate = null;
                   if (Object.values(datoTypeFileSectionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoTypeFileSectionSCreate)[0] && Object.values(datoTypeFileSectionSCreate)[0].id ? Object.values(datoTypeFileSectionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_TYPESFILESSECTIONS:
                   let typesFilesSectionsCreate = action.typesFilesSections.entities && action.typesFilesSections.entities.typesFilesSections ? action.typesFilesSections.entities.typesFilesSections : null;
                   return typesFilesSectionsCreate ?
                       union(state, Object.values(typesFilesSectionsCreate).map((typesFilesSections) => {
                           return typesFilesSections.id
                       })) : state;
        case RESET_TYPESFILESSECTIONS:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.typesFilesSections ? action.assets.entities.typesFilesSections : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.typesFilesSections ? action.asset.entities.typesFilesSections : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.typesFilesSections ? action.assets.entities.typesFilesSections : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;case RECEIVE_SECTION:
    let section = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : null ;
    return section ?
        union(state, Object.values(section).map((section) => {
            return section.id
        })) : state;
case RECEIVE_SECTIONS:
    let sections = action.sections.entities && action.sections.entities.typesFilesSections ? action.sections.entities.typesFilesSections : null;
    return sections ?
        union(state, Object.values(sections).map((sections) => {
            return sections.id
        })) : state;

case SUCCESS_DELETE_SECTION:
    let sectionDelete = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : null ;
        return sectionDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(sectionDelete).map((section) => {
                return section.id
            })) : state;
case SUCCESS_CREATE_SECTION:
   let sectionCreate = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : null ;
       return sectionCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(sectionCreate).map((section) => {
               return section.id
           })) : state;
case SUCCESS_UPDATE_SECTION:
    let sectionUpdate = action.section.entities && action.section.entities.typesFilesSections ? action.section.entities.typesFilesSections : null ;
        return sectionUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(sectionUpdate).map((section) => {
                return section.id
            })) : state;
 case SUCCESS_UPDATE_SECTIONS:
     let sectionsUpdate = action.sections.entities && action.sections.entities.typesFilesSections ? action.sections.entities.typesFilesSections : null;
        return sectionsUpdate ?
            union(state, Object.values(sectionsUpdate).map((sections) => {
                return sections.id
            })) : state;case RECEIVE_PAGE:
    let page = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : null ;
    return page ?
        union(state, Object.values(page).map((page) => {
            return page.id
        })) : state;
case RECEIVE_PAGES:
    let pages = action.pages.entities && action.pages.entities.typesFilesSections ? action.pages.entities.typesFilesSections : null;
    return pages ?
        union(state, Object.values(pages).map((pages) => {
            return pages.id
        })) : state;

case SUCCESS_DELETE_PAGE:
    let pageDelete = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : null ;
        return pageDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageDelete).map((page) => {
                return page.id
            })) : state;
case SUCCESS_CREATE_PAGE:
   let pageCreate = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : null ;
       return pageCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageCreate).map((page) => {
               return page.id
           })) : state;
case SUCCESS_UPDATE_PAGE:
    let pageUpdate = action.page.entities && action.page.entities.typesFilesSections ? action.page.entities.typesFilesSections : null ;
        return pageUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageUpdate).map((page) => {
                return page.id
            })) : state;
 case SUCCESS_UPDATE_PAGES:
     let pagesUpdate = action.pages.entities && action.pages.entities.typesFilesSections ? action.pages.entities.typesFilesSections : null;
        return pagesUpdate ?
            union(state, Object.values(pagesUpdate).map((pages) => {
                return pages.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalTypesFilesSections(state = null, action) {
    switch (action.type) {
        case RECEIVE_TYPESFILESSECTIONS:
            return action.typesFilesSections && action.typesFilesSections.result.total ? action.typesFilesSections.result.total : 0;
        case RESET_TYPESFILESSECTIONS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_TYPEFILESECTION:
            let dato = action.typeFileSection.entities.typesFilesSections;
            let typeFileSection = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: typeFileSection ? typeFileSection : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_TYPEFILESECTION:
            let idsUpdate = [];
            Object.values(action.typeFileSection).map((typeFileSectionUpdate) => {
                if (typeFileSectionUpdate && typeFileSectionUpdate.id)
                    idsUpdate.push(typeFileSectionUpdate.id);
            });
            return merge({}, state, {
                activo: action.typeFileSection,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_TYPEFILESECTION:
            let datoTypeFileSectionActualizado = {};
            if (Object.values(action.typeFileSection.entities.typesFilesSections).length > 0)
                datoTypeFileSectionActualizado = Object.values(action.typeFileSection.entities.typesFilesSections)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoTypeFileSectionActualizado
            });
        case ERROR_UPDATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_TYPESFILESSECTIONS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_TYPESFILESSECTIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_TYPESFILESSECTIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });//SECTION
//TODO ver si esta bien
 case SUCCESS_CREATE_SECTION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_SECTION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_SECTION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_SECTIONS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });//PAGE
//TODO ver si esta bien
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_TYPEFILESECTION:
            let datoTypeFileSectionDelete = action.typeFileSection;
            let idsDelete = [];
           Object.values(action.typeFileSection).map((typeFileSectionDelete) => {
               if (typeFileSectionDelete && typeFileSectionDelete.id)
                   idsDelete.push(typeFileSectionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoTypeFileSectionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_TYPEFILESECTION:
                       let datoTypeFileSectionDeleteUpdate = action.typeFileSection;
                       let idsDeleteUpdate = [];
                      Object.values(action.typeFileSection).map((typeFileSectionDelete) => {
                          if (typeFileSectionDelete && typeFileSectionDelete.id)
                              idsDeleteUpdate.push(typeFileSectionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoTypeFileSectionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_TYPEFILESECTION:
                    let datoTypeFileSectionDeleted = {};
                    if (Object.values(action.typeFileSection.entities.typesFilesSections).length > 0)
                        datoTypeFileSectionDeleted = Object.values(action.typeFileSection.entities.typesFilesSections)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoTypeFileSectionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_TYPEFILESECTION:
             let idsCreate = [];
             Object.values(action.typeFileSection).map((typeFileSectionCreate) => {
                 if (typeFileSectionCreate && typeFileSectionCreate.id)
                     idsCreate.push(typeFileSectionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.typeFileSection,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_TYPEFILESECTION:
            let datoTypeFileSectionNuevo = {};
            if (Object.values(action.typeFileSection.entities.typesFilesSections).length > 0)
                datoTypeFileSectionNuevo = Object.values(action.typeFileSection.entities.typesFilesSections)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoTypeFileSectionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_TYPESFILESSECTIONS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//SECTION
 case SUCCESS_CREATE_SECTION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_SECTION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_SECTION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_SECTIONS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//PAGE
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_TYPEFILESECTION:
           let datoTypeFileSectionDelete = action.typeFileSection;
           let idsDelete = [];
           Object.values(action.typeFileSection).map((typeFileSectionDelete) => {
               if (typeFileSectionDelete && typeFileSectionDelete.id)
                   idsDelete.push(typeFileSectionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoTypeFileSectionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_TYPEFILESECTION:
                  let datoTypeFileSectionDeleteCreate = action.typeFileSection;
                  let idsDeleteCreate = [];
                  Object.values(action.typeFileSection).map((typeFileSectionDelete) => {
                      if (typeFileSectionDelete && typeFileSectionDelete.id)
                          idsDeleteCreate.push(typeFileSectionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoTypeFileSectionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_TYPEFILESECTION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.typeFileSection,
                error: null,
            });
        case REQUEST_DELETE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_TYPEFILESECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });//SECTION
 case SUCCESS_CREATE_SECTION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_SECTION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_SECTION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_SECTIONS:
        return Object.assign({}, state, {
            eliminado: {},
        });//PAGE
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_TYPEFILESECTION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_TYPEFILESECTION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_TYPEFILESECTION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const typesFilesSections = combineReducers({
    byId: typesFilesSectionsById,
    allIds: allTypesFilesSections,
    update: update,
    create: create,
    totalTypesFilesSections: totalTypesFilesSections,
    delete: deleter,
    print: print
});

export default typesFilesSections;