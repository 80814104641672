import c from '../constants/Constants';
import $ from 'jquery';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var assetsLanguages = {

        getAll(filtros) {
            var esc = encodeURIComponent;
            var query = "";
            if (filtros)
                query = Object.keys(filtros)
                    .map(k => esc(k) + '=' + esc(filtros[k]))
                    .join('&');

            let defaultOptions = {
                url: '',
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json;charset=UTF-8",
                    'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                },
                // cache: false,
                dataType: 'json',
            };
            if (!!localStorage.token) {
                defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
            }
            return fetch(c.BASE_URL + '/assetsLanguages/?' + query, defaultOptions);
        },
        getOne(idAssetLanguage) {
            let defaultOptions = {
                url: '',
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json;charset=UTF-8",
                    'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                },
                // cache: false,
                dataType: 'json',
            };
            if (!!localStorage.token) {
                defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
            }
            return fetch(c.BASE_URL + '/assetsLanguages/' + idAssetLanguage, defaultOptions);
        },
        getFile(idAssetLanguage, filtros) {
            var esc = encodeURIComponent;
            var query = "";
            if (filtros)
                query = Object.keys(filtros)
                    .map(k => esc(k) + '=' + esc(filtros[k]))
                    .join('&');
            let defaultOptions = {
                url: '',
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json;charset=UTF-8",
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                    'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,

                }
            };
            if (!!localStorage.token) {
                defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
            }
            return fetch(c.BASE_URL + '/assetsLanguages/' + idAssetLanguage + '/file/?' + query, defaultOptions);
        },
        saveCreate(assetLanguage) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(assetLanguage)
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }

        return fetch(c.BASE_URL + '/assetsLanguages/', defaultOptions);
    },
        saveUpdate(assetLanguage) {
    let defaultOptions = {
        url: '',
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json;charset=UTF-8",
            'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
        },
        // cache: false,
        dataType: 'json',
        body: JSON.stringify(assetLanguage)
    };
    if (!!localStorage.token) {
        defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
    }

    return fetch(c.BASE_URL + '/assetsLanguages/' + assetLanguage.id, defaultOptions);
},
          saveUpdateAssetsLanguages(activos) {
                 let defaultOptions = {
                     url: '',
                     method: 'PUT',
                     mode: 'cors',
                     headers: {
                         'Access-Control-Allow-Origin': '*',
                         "Content-Type": "application/json;charset=UTF-8",
                         "Authorization": "Bearer " + localStorage.token,
                         'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                     },
                     // cache: false,
                     dataType: 'json',
                     body: JSON.stringify(activos)
                 };
            if (!!localStorage.token) {
               defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
            }
                 return fetch(c.BASE_URL + '/assetsLanguages/all', defaultOptions);
             },
             saveDelete(assetLanguage) {
                     let defaultOptions = {
                         url: '',
                         method: 'DELETE',
                         mode: 'cors',
                         headers: {
                             'Access-Control-Allow-Origin': '*',
                             "Content-Type": "application/json;charset=UTF-8",
                             "Authorization": "Bearer " + localStorage.token,
                             'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                         },
                         // cache: false,
                         dataType: 'json',
                     };
            if (!!localStorage.token) {
                defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
            }
                     return fetch(c.BASE_URL + '/assetsLanguages/' + assetLanguage.id, defaultOptions);
                 },
         saveCreateAssetsLanguages(nuevos) {
              let defaultOptions = {
                  url: '',
                  method: 'POST',
                  mode: 'cors',
                  headers: {
                      'Access-Control-Allow-Origin': '*',
                      "Content-Type": "application/json;charset=UTF-8",
                      "Authorization": "Bearer " + localStorage.token,
                      'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                  },
                  // cache: false,
                  dataType: 'json',
                  body: JSON.stringify(nuevos)
              };
             if (!!localStorage.token) {
                defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
             }
                  return fetch(c.BASE_URL + '/assetsLanguages/all', defaultOptions);
              },
         printAssetLanguage(idAssetLanguage) {
                 let defaultOptions = {
                     url: '',
                     method: 'GET',
                     mode: 'cors',
                     headers: {
                         'Access-Control-Allow-Origin': '*',
                         "Content-Type": "multipart/form-data",
                         "Authorization": "Bearer " + localStorage.token,
                         'idCuenta': window.location.pathname.split('/')[3],'idHerramienta': 6,
                     },
                     cache: "no-cache"
                 };
                 return fetch(c.BASE_URL + '/assetsLanguages/' + idAssetLanguage + "/edit/", defaultOptions);
             },
    };

export default assetsLanguages;