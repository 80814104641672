import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
//Components
import UsersList from "./UsersList";
import CRUDUser from "./CRUDUser";
import permissionReset from "../../reset/permissionReset";
import {fetchPermissionsIfNeeded, invalidatePermissions} from "../../actions/PermissionActions";

export default function Users() {

    const dispatch = useDispatch();
    //Effects
    useEffect(() => {
        dispatch(invalidatePermissions());
        dispatch(fetchPermissionsIfNeeded({show: "Mis permisos", module: "users"}));
        return function cleanup() {
            permissionReset.resetAll(dispatch);
        }
    }, []);

    return (
        <Switch>
            <Route path="/users/new" component={CRUDUser}/>
            <Route path="/users/:idUser" component={CRUDUser}/>
            <Route path="/users" component={UsersList}/>
        </Switch>

    );
}

