import {
    INVALIDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    ERROR_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    REQUEST_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    ERROR_PRODUCTATTRIBUTEVALUELANGUAGE,
    RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_PRODUCTATTRIBUTEVALUELANGUAGE,
    UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    ERROR_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    RESET_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    ERROR_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    RESET_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    ERROR_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    RESET_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    ERROR_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    RESET_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    DELETE_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    DELETE_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    ERROR_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    RESET_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    REQUEST_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    SUCCESS_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    ERROR_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    RESET_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    RECEIVE_FILE_PRODUCTATTRIBUTEVALUELANGUAGE
} from '../actions/ProductAttributeValueLanguageActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productsAttributesValuesLanguages: {},
        files: {},
    }
}

function productsAttributesValuesLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productsAttributesValuesLanguages: {}
            });
        case RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            let dato = action.productsAttributesValuesLanguages.entities.productsAttributesValuesLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productsAttributesValuesLanguages: merge({}, state.productsAttributesValuesLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguage = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
            return Object.assign({}, state, {
                productsAttributesValuesLanguages: merge({}, state.productsAttributesValuesLanguages, datoProductAttributeValueLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageEliminado = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
            return Object.assign({}, state, {
                productsAttributesValuesLanguages: mergeWith(clone(datoProductAttributeValueLanguageEliminado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageCreado = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
            return Object.assign({}, state, {
                productsAttributesValuesLanguages: mergeWith(clone(datoProductAttributeValueLanguageCreado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            let datosProductAttributeValueLanguageCreado = action.productsAttributesValuesLanguages.entities.productsAttributesValuesLanguages;
                return Object.assign({}, state, {
                    productsAttributesValuesLanguages: mergeWith(clone(datosProductAttributeValueLanguageCreado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageActualizado = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
            return Object.assign({}, state, {
                productsAttributesValuesLanguages: mergeWith(clone(datoProductAttributeValueLanguageActualizado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            let datosProductAttributeValueLanguageActualizado = action.productsAttributesValuesLanguages.entities.productsAttributesValuesLanguages;
                return Object.assign({}, state, {
                    productsAttributesValuesLanguages: mergeWith(clone(datosProductAttributeValueLanguageActualizado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : {};
    return Object.assign({}, state, {
        productsAttributesValuesLanguages: merge({}, state.productsAttributesValuesLanguages, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsAttributesValuesLanguages ? action.products.entities.productsAttributesValuesLanguages : {};
    return Object.assign({}, state, {
        productsAttributesValuesLanguages: merge({}, state.productsAttributesValuesLanguages, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : {};
    return Object.assign({}, state, {
        productsAttributesValuesLanguages: mergeWith(clone(datoproductEliminado), pickBy(state.productsAttributesValuesLanguages, function(productAttributeValueLanguage) {return productAttributeValueLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : {};
    return Object.assign({}, state, {
        productsAttributesValuesLanguages: mergeWith(clone(datoproductCreado), pickBy(state.productsAttributesValuesLanguages, function(productAttributeValueLanguage) {return productAttributeValueLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : {};
    return Object.assign({}, state, {
        productsAttributesValuesLanguages: mergeWith(clone(datoproductActualizado), pickBy(state.productsAttributesValuesLanguages, function(productAttributeValueLanguage) {return productAttributeValueLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.productsAttributesValuesLanguages ? action.products.entities.productsAttributesValuesLanguages : {} ;
        return Object.assign({}, state, {
            productsAttributesValuesLanguages: mergeWith(clone(datosproductActualizado), state.productsAttributesValuesLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productsAttributesValuesLanguages: {}
            });
        default:
            return state
    }
}


function allProductsAttributesValuesLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return action.productsAttributesValuesLanguages.result && action.productsAttributesValuesLanguages.result.productsAttributesValuesLanguages ? union(action.productsAttributesValuesLanguages.result.productsAttributesValuesLanguages, state) : (action.productsAttributesValuesLanguages.result ? action.productsAttributesValuesLanguages.result : state) ;
        case RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE:
                return action.productAttributeValueLanguage.result ? union([action.productAttributeValueLanguage.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
                   let datoProductAttributeValueLanguageSCreate = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductAttributeValueLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductAttributeValueLanguageSCreate)[0] && Object.values(datoProductAttributeValueLanguageSCreate)[0].id ? Object.values(datoProductAttributeValueLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
                   let productsAttributesValuesLanguagesCreate = action.productsAttributesValuesLanguages.entities && action.productsAttributesValuesLanguages.entities.productsAttributesValuesLanguages ? action.productsAttributesValuesLanguages.entities.productsAttributesValuesLanguages : null;
                   return productsAttributesValuesLanguagesCreate ?
                       union(state, Object.values(productsAttributesValuesLanguagesCreate).map((productsAttributesValuesLanguages) => {
                           return productsAttributesValuesLanguages.id
                       })) : state;
        case RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsAttributesValuesLanguages ? action.products.entities.productsAttributesValuesLanguages : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.productsAttributesValuesLanguages ? action.product.entities.productsAttributesValuesLanguages : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.productsAttributesValuesLanguages ? action.products.entities.productsAttributesValuesLanguages : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductsAttributesValuesLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return action.productsAttributesValuesLanguages && action.productsAttributesValuesLanguages.result.total ? action.productsAttributesValuesLanguages.result.total : 0;
        case RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let dato = action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages;
            let productAttributeValueLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productAttributeValueLanguage ? productAttributeValueLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let idsUpdate = [];
            Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageUpdate) => {
                if (productAttributeValueLanguageUpdate && productAttributeValueLanguageUpdate.id)
                    idsUpdate.push(productAttributeValueLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.productAttributeValueLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageActualizado = {};
            if (Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages).length > 0)
                datoProductAttributeValueLanguageActualizado = Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductAttributeValueLanguageActualizado
            });
        case ERROR_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageDelete = action.productAttributeValueLanguage;
            let idsDelete = [];
           Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageDelete) => {
               if (productAttributeValueLanguageDelete && productAttributeValueLanguageDelete.id)
                   idsDelete.push(productAttributeValueLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductAttributeValueLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE:
                       let datoProductAttributeValueLanguageDeleteUpdate = action.productAttributeValueLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageDelete) => {
                          if (productAttributeValueLanguageDelete && productAttributeValueLanguageDelete.id)
                              idsDeleteUpdate.push(productAttributeValueLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductAttributeValueLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
                    let datoProductAttributeValueLanguageDeleted = {};
                    if (Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages).length > 0)
                        datoProductAttributeValueLanguageDeleted = Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductAttributeValueLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
             let idsCreate = [];
             Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageCreate) => {
                 if (productAttributeValueLanguageCreate && productAttributeValueLanguageCreate.id)
                     idsCreate.push(productAttributeValueLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productAttributeValueLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            let datoProductAttributeValueLanguageNuevo = {};
            if (Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages).length > 0)
                datoProductAttributeValueLanguageNuevo = Object.values(action.productAttributeValueLanguage.entities.productsAttributesValuesLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductAttributeValueLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
           let datoProductAttributeValueLanguageDelete = action.productAttributeValueLanguage;
           let idsDelete = [];
           Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageDelete) => {
               if (productAttributeValueLanguageDelete && productAttributeValueLanguageDelete.id)
                   idsDelete.push(productAttributeValueLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductAttributeValueLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE:
                  let datoProductAttributeValueLanguageDeleteCreate = action.productAttributeValueLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.productAttributeValueLanguage).map((productAttributeValueLanguageDelete) => {
                      if (productAttributeValueLanguageDelete && productAttributeValueLanguageDelete.id)
                          idsDeleteCreate.push(productAttributeValueLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductAttributeValueLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productAttributeValueLanguage,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productsAttributesValuesLanguages = combineReducers({
    byId: productsAttributesValuesLanguagesById,
    allIds: allProductsAttributesValuesLanguages,
    update: update,
    create: create,
    totalProductsAttributesValuesLanguages: totalProductsAttributesValuesLanguages,
    delete: deleter,
    print: print
});

export default productsAttributesValuesLanguages;