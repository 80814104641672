import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link, useParams} from 'react-router-dom';
import clone from "lodash/clone";
//Components
import {
    Box,
    Breadcrumbs,
    Chip,
    Collapse,
    Container,
    FormControlLabel,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import ContainerBtn from "../../library/Buttons/ContainerBtn";
import BtnSave from "../../library/Buttons/BtnSave";
import BtnCancel from "../../library/Buttons/BtnCancel";
import Grid from "@material-ui/core/Grid";
import PaperBody from "../../library/CustomPaper/PaperBody";
import ListSubheader from '@material-ui/core/ListSubheader';
//Img
import DoneIcon from '@material-ui/icons/Done';
//Hooks
import {useValidador} from "../../utils/validador";
import {useDispatch, useSelector} from "react-redux";
//Actions
import {
    createProduct,
    fetchProduct,
    saveCreateProduct,
    saveUpdateProduct,
    updateProduct
} from "../../actions/ProductActions";
import {
    createProductLanguage,
    deleteProductLanguage,
    updateProductLanguage
} from "../../actions/ProductLanguageActions";
import {fetchLanguagesIfNeeded} from "../../actions/LanguageActions";
import {createProductCountry, deleteProductCountry} from "../../actions/ProductCountryActions";
//reset
import productReset from "../../reset/productReset";
import languageReset from "../../reset/languageReset";
import ProductLanguage from "./ProductLanguage";
import EmbeddedAssetsList from "../Assets/Embedded/EmbeddedAssetsList";
import ImageFileProduct from "./ImageFileProduct";
import countryReset from "../../reset/countryReset";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ProductCategory from "./ProductCategory";
import {fetchCategoriesIfNeeded} from "../../actions/CategoryActions";
import categoryReset from "../../reset/categoryReset";
import productAttributeValueReset from "../../reset/productAttributeValueReset";
import assetReset from "../../reset/assetReset";
import attributeReset from "../../reset/attributeReset";
import attributeGroupReset from "../../reset/attributeGroupReset";
import attributeLanguageReset from "../../reset/attributeLanguageReset";
import productAttributeValueLanguageReset from "../../reset/productAttributeValueLanguageReset";
import AssociatedProductsList from "./AssociatedProductsList";
import EmbeddedCountriesList from "../Countries/Embedded/EmbeddedCountriesList";
import usePermisos from "../Layout/CustomHooks/usePermisos";


const useStyles = makeStyles(theme => ({
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "flex-start",
            flexWrap: "nowrap",
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function CRUDProduct(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let {idProduct, idSection} = useParams();
    const mounted = useRef();
    const msjCountries = useRef();
    let permisos = usePermisos("products");

    //Store
    const products = useSelector(state => state.products);
    const product = idProduct ? products.update.activo : products.create.nuevo;
    const productsLanguages = useSelector(state => state.productsLanguages);
    const productsCountries = useSelector(state => state.productsCountries);
    const languages = useSelector(state => state.languages);
    const attributes = useSelector(state => state.attributes);
    const attributesGroups = useSelector(state => state.attributesGroups);
    const productsAttributesValues = useSelector(state => state.productsAttributesValues);
    const productsAttributesValuesLanguages = useSelector(state => state.productsAttributesValuesLanguages);
    const attributesLanguages = useSelector(state => state.attributesLanguages);
    const assets = useSelector(state => state.assets);
    const assetsLanguages = useSelector(state => state.assetsLanguages);
    let languageDef = null;
    languages.allIds.some((idLanguage) => {
        let language = languages.byId.languages[idLanguage];
        if (language.codLanguage === 'ES') {
            languageDef = language;
            return true;
        }
        return false;
    })

    //Asset main image
    let mainAsset = null;
    let fileMainAsset = null;
    assets.allIds.some((idAsset) => {
        let asset = assets.byId.assets[idAsset];
        let assetLanguage = null;
        asset.asset_language.some((idAssetLanguage) => {
            let _assetLanguage = assetsLanguages.byId.assetsLanguages[idAssetLanguage];
            if (!assetLanguage || _assetLanguage.codLanguage === 'ES') {
                assetLanguage = _assetLanguage;
                return true;
            }
            return false;
        })
        if (asset.mainImage === 1) {
            let file = assetLanguage ? assetsLanguages.byId.files[assetLanguage.filename] : null;
            mainAsset = asset;
            fileMainAsset = file;
            return true;
        }
        return false;
    })

    //State

    const [files, setFiles] = useState([]);
    const [refreshCheckboxSelectAllCountries, setRefreshCheckboxSelectAllCountries] = useState(0);
    const [previewFile, setPreviewFile] = React.useState(null);
    const [previewOpen, setPreviewOpen] = React.useState(false);

    const guardandoLoading = idProduct ? products.update.isUpdating : products.create.isCreating;
    const disabled = (idProduct ? products.update.isUpdating : products.create.isCreating) || products.byId.isFetching || !permisos.get("update") || !permisos.get("create");
    const error = idProduct ? products.update.error : products.create.error;

    //Hooks
    let reglas = {};
    reglas = {
        nameProduct: ["required"],
        genericName: ["required"],
        codProduct: ["required"],
    };
    const [validador, validar] = useValidador(reglas);


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchLanguagesIfNeeded({}));
        dispatch(fetchCategoriesIfNeeded({idParentCategory: null, with: ['childrenCategories']}));
        if (idProduct)
            dispatch(fetchProduct(idProduct));

        return function cleanup() {
            productReset.resetAll(dispatch);
            languageReset.resetAll(dispatch);
            countryReset.resetAll(dispatch);
            categoryReset.resetAll(dispatch);
            productAttributeValueReset.resetAll(dispatch);
            productAttributeValueLanguageReset.resetAll(dispatch);
            attributeReset.resetAll(dispatch);
            attributeGroupReset.resetAll(dispatch);
            attributeLanguageReset.resetAll(dispatch);
            assetReset.resetAll(dispatch);
        }
    }, []);


    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!products.create.isCreating && !products.create.error) {
                productReset.resetAll(dispatch);
                productAttributeValueReset.resetAll(dispatch);
                productAttributeValueLanguageReset.resetAll(dispatch);
                attributeReset.resetAll(dispatch);
                attributeGroupReset.resetAll(dispatch);
                attributeLanguageReset.resetAll(dispatch);
                assetReset.resetAll(dispatch);
                setFiles([]);
                window.scrollTo(0, 0);
            }
        }
    }, [products.create.isCreating]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!products.update.isUpdating && !products.update.error) {
                setFiles([]);
                // fetchFiles();
            }
        }
    }, [products.update.isUpdating]);

    // const fetchFiles = () => {
    //     let countFiles = 0;
    //     if (product.product_language)
    //         product.product_language.forEach((idProductLanguage) => {
    //             let productLanguage = productsLanguages.byId.productsLanguages[idProductLanguage];
    //             if (productLanguage && productLanguage.filenameDesktop)
    //                 countFiles++;
    //             if (productLanguage && productLanguage.filenameMobile)
    //                 countFiles++;
    //         })
    //     if (typeFile && (typeFile.codType === 'BANNER' || typeFile.codType === 'IMAGE' || typeFile.codType === 'PDF' || typeFile.codType === 'CSV' || typeFile.codType === 'OFFICE') && product.product_language && (productsLanguages.byId.files.size !== countFiles)) {
    //         product.product_language.forEach((idDocumentoAtencion, i) => {
    //             if (typeFile.codType === 'BANNER') {
    //                 dispatch(fetchFileProductLanguage(idDocumentoAtencion, {type: "mobile"}));
    //                 dispatch(fetchFileProductLanguage(idDocumentoAtencion, {type: "desktop"}));
    //             } else {
    //                 dispatch(fetchFileProductLanguage(idDocumentoAtencion, {type: "file"}));
    //             }
    //         })
    //     }
    // }


    //Product
    const handleChangeProduct = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeProduct(cambio);
    };


    const changeProduct = (cambio) => {
        if (idProduct)
            dispatch(updateProduct(cambio));
        else
            dispatch(createProduct(cambio));
    };

    const handleChangeStatusProduct = () => {
        let cambio = {};
        cambio.status = !product.status;
        changeProduct(cambio);
    }

    //Product language
    const handleChangeProductLanguage = (e, idProductLanguage, idLanguage) => {
        let id = idProductLanguage ? idProductLanguage : Date.now() + Math.random() + "-";
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeProductLanguage(cambio, id, idLanguage);
    }

    const changeProductLanguage = (cambio, idProductLanguage, idLanguage) => {
        //Verificar si ya esta siendo actualizado
        let productLanguage = idProductLanguage && productsLanguages.update.activos.indexOf(idProductLanguage) === -1 && productsLanguages.create.nuevos.indexOf(idProductLanguage) === -1 ? productsLanguages.byId.productsLanguages[idProductLanguage] : null;
        if (productLanguage) {
            let actualizar = productLanguage ? clone(productLanguage) : null;
            if (actualizar) {
                let cambioProductLanguage = {};
                cambioProductLanguage[actualizar.id] = actualizar;
                if (idProductLanguage.toString().indexOf("-") === -1)
                    dispatch(updateProductLanguage(cambioProductLanguage));
                else
                    dispatch(createProductLanguage(cambioProductLanguage));
            }
        }
        cambio.id = idProductLanguage;
        cambio.idProduct = idProduct;
        cambio.idLanguage = idLanguage;
        let cambioProductLanguage = {};
        cambioProductLanguage[idProductLanguage] = cambio;
        if (idProductLanguage.toString().indexOf("-") === -1)
            dispatch(updateProductLanguage(cambioProductLanguage));
        else
            dispatch(createProductLanguage(cambioProductLanguage));
    };

    const deleteProductLanguageFile = (productLanguage) => {
        let cambioUbicacion = {};
        cambioUbicacion[productLanguage.id] = clone(productLanguage);
        dispatch(deleteProductLanguage(cambioUbicacion));
    };

    const downloadFileProductLanguage = (file) => {
        let fileSubido = file;
        let reader = new FileReader();
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        reader.onloadend = function () {
            a.href = reader.result;
            a.download = file.name;
            a.click();
        };
        if (fileSubido) {
            reader.readAsDataURL(fileSubido);
        }
    };

    const previewFileProductLanguage = (file) => {
        let fileSubido = file;
        let reader = new FileReader();
        reader.onloadend = function () {
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(reader.result) + "'></iframe>")
        };
        if (fileSubido) {
            reader.readAsDataURL(fileSubido);
        }
    };
    const previewImageVideoProductLanguage = (file) => {
        setPreviewFile(file);
        setPreviewOpen(true);
    };

    //Lista países
    const handleChangeProductCountry = (idCountry, selectAll) => {
        let id = idCountry + "-";
        let esProductCountry = false;
        if (product.product_country)
            esProductCountry = product.product_country.some((idProductCountry) => {
                let productCountry = productsCountries.byId.productsCountries[idProductCountry];
                if (productsCountries.update.activos.indexOf(idProductCountry) !== -1)
                    productCountry = productsCountries.update.activo[idProductCountry];
                if (productsCountries.create.nuevos.indexOf(idProductCountry) !== -1)
                    productCountry = productsCountries.create.nuevo[idProductCountry];
                if (productCountry.idCountry === idCountry)
                    id = idProductCountry;
                return productCountry.idCountry === idCountry
            });
        let cambio = {};
        cambio[id] = {idProduct: idProduct, idCountry: idCountry, id: id};
        if (esProductCountry) {
            dispatch(deleteProductCountry(cambio));
        } else
            dispatch(createProductCountry(cambio));
    };

    const isUbicacionSelected = (idCountry) => {
        let isProductCountry = false;
        if (product.product_country)
            isProductCountry = product.product_country.some((idProductCountry) => {
                let productCountry = productsCountries.byId.productsCountries[idProductCountry];
                if (productsCountries.update.activos.indexOf(idProductCountry) !== -1)
                    productCountry = productsCountries.update.activo[idProductCountry];
                if (productsCountries.create.nuevos.indexOf(idProductCountry) !== -1)
                    productCountry = productsCountries.create.nuevo[idProductCountry];
                if (productCountry)
                    return productCountry.idCountry === idCountry
                else return false;
            });
        return isProductCountry;
    }

    //Submit
    const onSubmit = (e) => {
        e.preventDefault();
        if (validador.isValid()) {
            if (idProduct)
                dispatch(saveUpdateProduct(files));
            else
                dispatch(saveCreateProduct(files));
        }
    };

    const deleteFiles = () => {
        let arrayFiles = clone(files);
        files.forEach((file, index) => {
            let onUse = product.product_language.some((idProductLanguage) => {
                let productLanguage = productsLanguages.byId.productsLanguages[idProductLanguage];
                if (productsLanguages.update.activos.indexOf(idProductLanguage) !== -1)
                    productLanguage = productsLanguages.update.activo[idProductLanguage];
                if (productsLanguages.create.nuevos.indexOf(idProductLanguage) !== -1)
                    productLanguage = productsLanguages.create.nuevo[idProductLanguage];
                if (productLanguage && (productLanguage.filename === file.name || productLanguage.filenameMobile === file.name || productLanguage.filenameDesktop === file.name)) {
                    return true;
                }
                return false;
            })
            if (!onUse)
                arrayFiles.splice(index, 1);
        })
        return arrayFiles;
    }


    return (
        <Container maxWidth={"xg"}>
            {/*<PreviewImageVideo file={previewFile} setPreviewOpen={setPreviewOpen} previewOpen={previewOpen}/>*/}
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
                <Link color="inherit" to="/products">
                    Productos
                </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
                {!idProduct ? "Nuevo producto" : "Modificar producto"}

            </Typography>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2}>

                    <Grid item md={6} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    Datos del producto
                                </Typography>
                            </PaperHeader>
                            <PaperBody spacing={2}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <ImageFileProduct files={files} setFiles={setFiles} changeProduct={changeProduct}
                                                      file={fileMainAsset}/>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12} style={{marginBottom: "15px"}}>
                                    <Grid item md={12} sm={12} xs={12} style={{marginBottom: "15px"}}>
                                        <TextField
                                            id="genericName"
                                            placeholder="Nombre genérico"
                                            variant="outlined"
                                            label={"Nombre genérico"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                // disabled: disabled
                                                type: "text"
                                            }}
                                            disabled={disabled}
                                            fullWidth
                                            value={product && product.genericName ? product.genericName : ""}
                                            onChange={handleChangeProduct}
                                            onBlur={validar}
                                            error={!validador.isValidById("genericName")}
                                            helperText={validador.getHelperTextById("genericName")}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} style={{marginBottom: "15px"}}>
                                        <TextField
                                            id="codProduct"
                                            placeholder="Código"
                                            variant="outlined"
                                            label={"Código"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                // disabled: disabled
                                                type: "text"
                                            }}
                                            disabled={disabled}
                                            fullWidth
                                            value={product && product.codProduct ? product.codProduct : ""}
                                            onChange={handleChangeProduct}
                                            onBlur={validar}
                                            error={!validador.isValidById("codProduct")}
                                            helperText={validador.getHelperTextById("codProduct")}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} style={{marginBottom: "15px"}}>
                                        <Box display={"flex"} justifyContent={"flex-end"}>
                                            {
                                                product.status === 1 &&
                                                <Chip
                                                    icon={<DoneIcon/>}
                                                    label="Activo"
                                                    color="primary"
                                                    variant="outlined"
                                                    style={{marginBottom: "20px"}}
                                                />
                                            }
                                            {
                                                product.status === 0 &&
                                                <Chip
                                                    icon={<DoneIcon/>}
                                                    label="Inactivo"
                                                    color="secondary"
                                                    variant="outlined"
                                                    style={{marginBottom: "20px"}}
                                                />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        id="externalId"
                                        placeholder="Código externo"
                                        variant="outlined"
                                        label={"Código externo"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            // disabled: disabled
                                            type: "text",
                                            min: 1,
                                        }}
                                        disabled={true}
                                        fullWidth
                                        value={product ? product.externalId : ""}
                                        onChange={(e) => handleChangeProduct(e)}
                                        // onKeyDown={(e) => handleKeyDown(e)}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}
                                    style={{visibility: !idProduct ? "hidden" : "inherit", marginTop: "14px"}}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={product && !!product.status}
                                                onChange={() => handleChangeStatusProduct()}
                                                disabled={disabled}
                                                name="estado"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />}
                                        label="Estado"
                                    />
                                </Grid>
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    ¿En qué países se visualizará este producto?
                                </Typography>
                            </PaperHeader>
                            <EmbeddedCountriesList
                                onSelect={handleChangeProductCountry}
                                isSelected={isUbicacionSelected}
                                refresh={refreshCheckboxSelectAllCountries}
                                disabled={disabled}
                                // title="¿En qué países se visualizará este contenido?"
                                msjCountries={msjCountries}/>
                        </CustomPaper>
                    </Grid>
                </Grid>
                <CustomPaper>
                    <PaperHeader>
                        <Typography variant={"overline"} component={"span"}>
                            Idiomas
                        </Typography>
                    </PaperHeader>
                    <PaperBody>
                        <Grid item md={12} xs={12} sm={12}>
                            {
                                languages.allIds && languages.allIds.map((idLanguage, i) => {
                                    let language = languages.byId.languages[idLanguage];
                                    let product = idProduct ? products.update.activo : products.create.nuevo;
                                    let productLanguageFound = null;
                                    if (product && product.product_language)
                                        product.product_language.some((idProductLanguage) => {
                                            let productLanguage = productsLanguages.byId.productsLanguages[idProductLanguage];
                                            if (productsLanguages.update.activos.indexOf(idProductLanguage) !== -1)
                                                productLanguage = productsLanguages.update.activo[idProductLanguage];
                                            if (productsLanguages.create.nuevos.indexOf(idProductLanguage) !== -1)
                                                productLanguage = productsLanguages.create.nuevo[idProductLanguage];
                                            if (productLanguage && productLanguage.idLanguage === idLanguage && (productLanguage.status === undefined || productLanguage.status === 1)) {
                                                productLanguageFound = productLanguage;
                                                return true;
                                            }
                                            return false;
                                        })
                                    return <ProductLanguage language={language} productLanguage={productLanguageFound}
                                                            changeProductLanguage={changeProductLanguage}
                                                            handleChangeProductLanguage={handleChangeProductLanguage}/>;

                                })
                            }
                        </Grid>
                    </PaperBody>
                </CustomPaper>
                <Grid container spacing={2}>

                    <Grid item md={6} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    Atributos
                                </Typography>
                            </PaperHeader>
                            <PaperBody>
                                <Grid item md={12} xs={12} sm={12}>
                                    {
                                        attributesGroups.allIds.map((idAttributeGroup) => {
                                            let attributeGroup = attributesGroups.byId.attributesGroups[idAttributeGroup];
                                            let Attributes = productsAttributesValues.allIds.map((idProductAttributeValue) => {
                                                let productAttributeValue = productsAttributesValues.byId.productsAttributesValues[idProductAttributeValue];
                                                let attribute = productAttributeValue ? attributes.byId.attributes[productAttributeValue.idAttribute] : null;

                                                let ProductsAttributesValuesLanguages = productAttributeValue.product_attribute_value_language.map((idProductAttributeValue) => {
                                                    let productAttributeValueLanguage = productsAttributesValuesLanguages.byId.productsAttributesValuesLanguages[idProductAttributeValue];
                                                    let language = productAttributeValueLanguage ? languages.byId.languages[productAttributeValueLanguage.idLanguage] : null;
                                                    let attributeLanguage = null;
                                                    attribute.attribute_language.some((idAttributeLanguage) => {
                                                        let _attributeLanguage = attributesLanguages.byId.attributesLanguages[idAttributeLanguage];
                                                        if (_attributeLanguage && language && _attributeLanguage.idLanguage == language.id) {
                                                            attributeLanguage = _attributeLanguage;
                                                            return true;
                                                        }
                                                        return false;
                                                    })
                                                    if (productAttributeValueLanguage)
                                                        return (
                                                            <ListItem button className={classes.nested}>
                                                                <ListItemIcon>
                                                                    {language ? language.unicodeIcon : ""}
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={(attributeLanguage ? attributeLanguage.nameAttribute + ": " : "") + productAttributeValueLanguage.value}/>
                                                            </ListItem>
                                                        )
                                                })

                                                if (attribute && attribute.idAttributeGroup === idAttributeGroup)
                                                    return (
                                                        <>
                                                            <ListItem button>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        {attribute && attribute.nameAttribute ? attribute.nameAttribute.charAt(0) : null}
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={attribute && attribute.nameAttribute ? attribute.nameAttribute + ": " + productAttributeValue.value : ""}/>
                                                            </ListItem>
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding dense={true}>
                                                                    {ProductsAttributesValuesLanguages}
                                                                </List>
                                                            </Collapse>
                                                        </>
                                                    )
                                            })
                                            return (
                                                <List
                                                    dense={true}
                                                    component="nav"
                                                    aria-labelledby="nested-list-subheader"
                                                    subheader={
                                                        <ListSubheader component="div" id="nested-list-subheader">
                                                            {attributeGroup ? attributeGroup.nameAttributeGroup : ""}
                                                        </ListSubheader>
                                                    }
                                                    className={classes.root}
                                                >{Attributes}
                                                </List>
                                            );
                                        })
                                    }
                                    {
                                        attributesGroups.allIds.length === 0 &&
                                            <Typography variant={"body1"}>No hay atributos registrados para este producto</Typography>
                                    }
                                </Grid>
                            </PaperBody>
                        </CustomPaper>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    Categoria
                                </Typography>
                            </PaperHeader>
                            <PaperBody>
                                <Grid item md={12} xs={12} sm={12}>
                                    <ProductCategory changeProduct={changeProduct} product={product}/>
                                </Grid>
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                </Grid>
                <ContainerBtn>
                    <BtnCancel
                        onClick={() => props.location.pathname === "/products/new" ? props.history.push("/products") : props.history.goBack()}/>
                    <BtnSave loading={guardandoLoading} disabled={disabled} error={error}/>
                </ContainerBtn>
            </form>
            <EmbeddedAssetsList id={idProduct} fk={'idProductAsset'}/>
            <AssociatedProductsList idProductAsset={idProduct}/>

        </Container>
    );
}
