//api
import categoriesApi from "../api/categoriesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeCategories";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//CATEGORIES
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const INVALIDATE_CATEGORIES = 'INVALIDATE_CATEGORIES';
export const ERROR_CATEGORIES = "ERROR_CATEGORIES";
export const RESET_CATEGORIES = "RESET_CATEGORIES";


export function invalidateCategories() {
    return {
        type: INVALIDATE_CATEGORIES
    }
}

function requestCategories() {
    return {
        type: REQUEST_CATEGORIES,
    }
}

function receiveCategories(json) {
    return {
        type: RECEIVE_CATEGORIES,
    categories: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorCategories(error) {
    return {
        type: ERROR_CATEGORIES,
        error: error,
    }
}

export function resetCategories() {
    return {
        type: RESET_CATEGORIES
    }
}

export function fetchCategories(filtros) {
    return dispatch => {
        dispatch(requestCategories());
        return categoriesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategories(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategories(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategories(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategories(state) {
    const categories = state.categories.byId;
    if (!categories) {
        return true
    } else if (categories.isFetching) {
        return false
    } else {
        return categories.didInvalidate;
    }
}

export function fetchCategoriesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCategories(getState())) {
            return dispatch(fetchCategories(filtros))
        }
    }
}


//MODEL
export const REQUEST_CATEGORY = 'REQUEST_CATEGORY';
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY';
export const INVALIDATE_CATEGORY = 'INVALIDATE_CATEGORY';
export const ERROR_CATEGORY = "ERROR_CATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";


export function invalidateCategory() {
    return {
        type: INVALIDATE_CATEGORY
    }
}

function requestCategory() {
    return {
        type: REQUEST_CATEGORY,
    }
}

export function receiveCategory(json) {
    return {
        type: RECEIVE_CATEGORY,
    category: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorCategory(error) {
    return {
        type: ERROR_CATEGORY,
        error: error,
    }
}

export function fetchCategory(idCategory) {
    return dispatch => {
        dispatch(requestCategory());
        return categoriesApi.getOne(idCategory)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategory(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategory(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategory(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_CATEGORY = 'RECEIVE_FILE_CATEGORY';

function receiveFileCategory(file) {
    return {
        type: RECEIVE_FILE_CATEGORY,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileCategory(idCategory, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return categoriesApi.getFile(idCategory, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileCategory(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategory(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategory(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REQUEST_UPDATE_CATEGORY = "REQUEST_UPDATE_CATEGORY";
export const SUCCESS_UPDATE_CATEGORY = "SUCCESS_UPDATE_CATEGORY";
export const ERROR_UPDATE_CATEGORY = "ERROR_UPDATE_CATEGORY";
export const RESET_UPDATE_CATEGORY = "RESET_UPDATE_CATEGORY";
export const DELETE_UPDATE_CATEGORY = "DELETE_UPDATE_CATEGORY";

function requestUpdateCategory() {
    return {
        type: REQUEST_UPDATE_CATEGORY,
    }
}

function receiveUpdateCategory(category) {
    return {
        type: SUCCESS_UPDATE_CATEGORY,
        receivedAt: Date.now(),
        category: normalizeDato(category)
    }
}

function errorUpdateCategory(error) {
    return {
        type: ERROR_UPDATE_CATEGORY,
        error: error,
    }
}

export function resetUpdateCategory() {
    return {
        type: RESET_UPDATE_CATEGORY,
    }
}

export function updateCategory(category) {
    return {
        type: UPDATE_CATEGORY,
        category
    }
}

export function saveUpdateCategory(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategory());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let category = denormalizeDato(getState().categories.update.activo, store);

        return categoriesApi.saveUpdate(category, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveUpdateCategory(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateCategory(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateCategories(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateCategories(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateCategory(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                    dispatch(receiveUpdateCategory(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateCategory(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateCategory(category) {
    return {
        type: DELETE_UPDATE_CATEGORY,
        category
    }
}

//UPDATE CATEGORIES
export const REQUEST_UPDATE_CATEGORIES = "REQUEST_UPDATE_CATEGORIES";
export const SUCCESS_UPDATE_CATEGORIES = "SUCCESS_UPDATE_CATEGORIES";
export const ERROR_UPDATE_CATEGORIES = "ERROR_UPDATE_CATEGORIES";
export const RESET_UPDATE_CATEGORIES = "RESET_UPDATE_CATEGORIES";

function requestUpdateCategories() {
    return {
        type: REQUEST_UPDATE_CATEGORIES,
}
}

function receiveUpdateCategories(categories) {
    return {
        type: SUCCESS_UPDATE_CATEGORIES,
    receivedAt: Date.now(),
        categories: normalizeDatos(categories)
}
}

function errorUpdateCategories(error) {
    return {
        type: ERROR_UPDATE_CATEGORIES,
    error: error,
}
}

export function resetUpdateCategories() {
    return {
        type: RESET_UPDATE_CATEGORIES,
}
}

export function saveUpdateCategories() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategories());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categories = getState().categories.update.activos.map((idCategory) => {
            return denormalizeDato(getState().categories.update.activo[idCategory], store);
        });

        return categoriesApi.saveUpdateCategories(categories)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCategories(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCategories(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateCategories(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateCategories(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateCategories(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateCategories(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA CATEGORY
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const REQUEST_CREATE_CATEGORY = "REQUEST_CREATE_CATEGORY";
export const SUCCESS_CREATE_CATEGORY = "SUCCESS_CREATE_CATEGORY";
export const ERROR_CREATE_CATEGORY = "ERROR_CREATE_CATEGORY";
export const RESET_CREATE_CATEGORY = "RESET_CREATE_CATEGORY";
export const DELETE_CREATE_CATEGORY = "DELETE_CREATE_CATEGORY";


//ALTA CATEGORY
function requestCreateCategory() {
    return {
        type: REQUEST_CREATE_CATEGORY,
    }
}

function receiveCreateCategory(category) {
    return {
        type: SUCCESS_CREATE_CATEGORY,
        receivedAt: Date.now(),
        category: normalizeDato(category)
    }
}

export function errorCreateCategory(error) {
    return {
        type: ERROR_CREATE_CATEGORY,
        error: error,
    }
}

export function resetCreateCategory() {
    return {
        type: RESET_CREATE_CATEGORY
    }
}

export function createCategory(category) {
    return {
        type: CREATE_CATEGORY,
        category
    }
}

export function deleteCreateCategory(category) {
    return {
        type: DELETE_CREATE_CATEGORY,
        category
    }
}

export function saveCreateCategory(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateCategory());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let category = denormalizeDato(getState().categories.create.nuevo, store);

        return categoriesApi.saveCreate(category, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateCategory(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateCategory(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateCategories(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateCategories(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateCategory(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateCategory(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateCategory(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE CATEGORIES
export const REQUEST_CREATE_CATEGORIES = "REQUEST_CREATE_CATEGORIES";
export const SUCCESS_CREATE_CATEGORIES = "SUCCESS_CREATE_CATEGORIES";
export const ERROR_CREATE_CATEGORIES = "ERROR_CREATE_CATEGORIES";
export const RESET_CREATE_CATEGORIES = "RESET_CREATE_CATEGORIES";

function requestCreateCategories() {
    return {
        type: REQUEST_CREATE_CATEGORIES,
}
}

function receiveCreateCategories(categories) {
    return {
        type: SUCCESS_CREATE_CATEGORIES,
    receivedAt: Date.now(),
        categories: normalizeDatos(categories)
}
}

function errorCreateCategories(error) {
    return {
        type: ERROR_CREATE_CATEGORIES,
    error: error,
}
}

export function resetCreateCategories() {
    return {
        type: RESET_CREATE_CATEGORIES,
}
}

export function saveCreateCategories() {
    return (dispatch, getState) => {
        dispatch(requestCreateCategories());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categories = getState().categories.create.nuevos.map((idCategory) => {
            return denormalizeDato(getState().categories.create.nuevo[idCategory], store);
        });

        return categoriesApi.saveCreateCategories(categories)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCategories(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCategories(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateCategories(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateCategories(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateCategories(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateCategories(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE CATEGORY
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const REQUEST_DELETE_CATEGORY = "REQUEST_DELETE_CATEGORY";
export const SUCCESS_DELETE_CATEGORY = "SUCCESS_DELETE_CATEGORY";
export const ERROR_DELETE_CATEGORY = "ERROR_DELETE_CATEGORY";
export const RESET_DELETE_CATEGORY = "RESET_DELETE_CATEGORY";

function requestDeleteCategory() {
    return {
        type: REQUEST_DELETE_CATEGORY,
    }
}

function receiveDeleteCategory(category) {
    return {
        type: SUCCESS_DELETE_CATEGORY,
        receivedAt: Date.now(),
        category: normalizeDato(category)
    }
}

function errorDeleteCategory(error) {
    return {
        type: ERROR_DELETE_CATEGORY,
        error: error,
    }
}

export function resetDeleteCategory(error) {
    return {
        type: RESET_DELETE_CATEGORY,
        error: error,
    }
}

export function deleteCategory(category) {
    return {
        type: DELETE_CATEGORY,
        category
    }
}

export function saveDeleteCategory(category) {
    return dispatch => {
        dispatch(requestDeleteCategory());
        return categoriesApi.saveDelete(category)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteCategory());
                            dispatch(receiveDeleteCategory(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteCategory(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteCategory(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteCategory(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteCategory(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteCategory(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT CATEGORY
export const REQUEST_PRINT_CATEGORY = "REQUEST_PRINT_CATEGORY";
export const SUCCESS_PRINT_CATEGORY = "SUCCESS_PRINT_CATEGORY";
export const ERROR_PRINT_CATEGORY = "ERROR_PRINT_CATEGORY";
export const RESET_PRINT_CATEGORY = "RESET_PRINT_CATEGORY";

function requestPrintCategory() {
    return {
        type: REQUEST_PRINT_CATEGORY,
    }
}

function receivePrintCategory(turnos) {
    return {
        type: SUCCESS_PRINT_CATEGORY,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintCategory(error) {
    return {
        type: ERROR_PRINT_CATEGORY,
        error: error,
    }
}

export function resetPrintCategory() {
    return {
        type: RESET_PRINT_CATEGORY,
    }
}

export function printCategory(idCategory) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintCategory());
        return categoriesApi.printCategory(idCategory)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintCategory(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintCategory(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintCategory(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintCategory(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintCategory(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}