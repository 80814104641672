import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
//Components
import ProductsList from "./ProductsList";
import CRUDProduct from "./CRUDProduct";
import permissionReset from "../../reset/permissionReset";
import {fetchPermissionsIfNeeded, invalidatePermissions} from "../../actions/PermissionActions";

export default function Products() {

    const dispatch = useDispatch();
    //Effects
    useEffect(() => {
        dispatch(invalidatePermissions());
        dispatch(fetchPermissionsIfNeeded({show: "Mis permisos", module: "products"}));
        return function cleanup() {
            permissionReset.resetAll(dispatch);
        }
    }, []);

    return (
        <Switch>
            <Route path="/products/new" component={CRUDProduct}/>
            <Route path="/products/:idProduct" component={CRUDProduct}/>
            <Route path="/products" component={ProductsList}/>
        </Switch>

    );
}

