//api
import associatedProductsApi from "../api/associatedProductsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAssociatedProducts";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ASSOCIATEDPRODUCTS
export const REQUEST_ASSOCIATEDPRODUCTS = 'REQUEST_ASSOCIATEDPRODUCTS';
export const RECEIVE_ASSOCIATEDPRODUCTS = 'RECEIVE_ASSOCIATEDPRODUCTS';
export const INVALIDATE_ASSOCIATEDPRODUCTS = 'INVALIDATE_ASSOCIATEDPRODUCTS';
export const ERROR_ASSOCIATEDPRODUCTS = "ERROR_ASSOCIATEDPRODUCTS";
export const RESET_ASSOCIATEDPRODUCTS = "RESET_ASSOCIATEDPRODUCTS";


export function invalidateAssociatedProducts() {
    return {
        type: INVALIDATE_ASSOCIATEDPRODUCTS
    }
}

function requestAssociatedProducts() {
    return {
        type: REQUEST_ASSOCIATEDPRODUCTS,
    }
}

function receiveAssociatedProducts(json) {
    return {
        type: RECEIVE_ASSOCIATEDPRODUCTS,
    associatedProducts: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAssociatedProducts(error) {
    return {
        type: ERROR_ASSOCIATEDPRODUCTS,
        error: error,
    }
}

export function resetAssociatedProducts() {
    return {
        type: RESET_ASSOCIATEDPRODUCTS
    }
}

export function fetchAssociatedProducts(filtros) {
    return dispatch => {
        dispatch(requestAssociatedProducts());
        return associatedProductsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssociatedProducts(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssociatedProducts(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssociatedProducts(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAssociatedProducts(state) {
    const associatedProducts = state.associatedProducts.byId;
    if (!associatedProducts) {
        return true
    } else if (associatedProducts.isFetching) {
        return false
    } else {
        return associatedProducts.didInvalidate;
    }
}

export function fetchAssociatedProductsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAssociatedProducts(getState())) {
            return dispatch(fetchAssociatedProducts(filtros))
        }
    }
}


//MODEL
export const REQUEST_ASSOCIATEDPRODUCT = 'REQUEST_ASSOCIATEDPRODUCT';
export const RECEIVE_ASSOCIATEDPRODUCT = 'RECEIVE_ASSOCIATEDPRODUCT';
export const INVALIDATE_ASSOCIATEDPRODUCT = 'INVALIDATE_ASSOCIATEDPRODUCT';
export const ERROR_ASSOCIATEDPRODUCT = "ERROR_ASSOCIATEDPRODUCT";
export const RESET_ASSOCIATEDPRODUCT = "RESET_ASSOCIATEDPRODUCT";


export function invalidateAssociatedProduct() {
    return {
        type: INVALIDATE_ASSOCIATEDPRODUCT
    }
}

function requestAssociatedProduct() {
    return {
        type: REQUEST_ASSOCIATEDPRODUCT,
    }
}

export function receiveAssociatedProduct(json) {
    return {
        type: RECEIVE_ASSOCIATEDPRODUCT,
    associatedProduct: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAssociatedProduct(error) {
    return {
        type: ERROR_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function fetchAssociatedProduct(idAssociatedProduct) {
    return dispatch => {
        dispatch(requestAssociatedProduct());
        return associatedProductsApi.getOne(idAssociatedProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssociatedProduct(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssociatedProduct(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ASSOCIATEDPRODUCT = 'RECEIVE_FILE_ASSOCIATEDPRODUCT';

function receiveFileAssociatedProduct(file) {
    return {
        type: RECEIVE_FILE_ASSOCIATEDPRODUCT,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAssociatedProduct(idAssociatedProduct, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return associatedProductsApi.getFile(idAssociatedProduct, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAssociatedProduct(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssociatedProduct(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ASSOCIATEDPRODUCT = 'UPDATE_ASSOCIATEDPRODUCT';
export const REQUEST_UPDATE_ASSOCIATEDPRODUCT = "REQUEST_UPDATE_ASSOCIATEDPRODUCT";
export const SUCCESS_UPDATE_ASSOCIATEDPRODUCT = "SUCCESS_UPDATE_ASSOCIATEDPRODUCT";
export const ERROR_UPDATE_ASSOCIATEDPRODUCT = "ERROR_UPDATE_ASSOCIATEDPRODUCT";
export const RESET_UPDATE_ASSOCIATEDPRODUCT = "RESET_UPDATE_ASSOCIATEDPRODUCT";
export const DELETE_UPDATE_ASSOCIATEDPRODUCT = "DELETE_UPDATE_ASSOCIATEDPRODUCT";

function requestUpdateAssociatedProduct() {
    return {
        type: REQUEST_UPDATE_ASSOCIATEDPRODUCT,
    }
}

function receiveUpdateAssociatedProduct(associatedProduct) {
    return {
        type: SUCCESS_UPDATE_ASSOCIATEDPRODUCT,
        receivedAt: Date.now(),
        associatedProduct: normalizeDato(associatedProduct)
    }
}

function errorUpdateAssociatedProduct(error) {
    return {
        type: ERROR_UPDATE_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function resetUpdateAssociatedProduct() {
    return {
        type: RESET_UPDATE_ASSOCIATEDPRODUCT,
    }
}

export function updateAssociatedProduct(associatedProduct) {
    return {
        type: UPDATE_ASSOCIATEDPRODUCT,
        associatedProduct
    }
}

export function saveUpdateAssociatedProduct() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssociatedProduct());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let associatedProduct = denormalizeDato(getState().associatedProducts.update.activo, store);

        return associatedProductsApi.saveUpdate(associatedProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssociatedProduct(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAssociatedProducts(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAssociatedProducts(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAssociatedProduct(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAssociatedProduct(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAssociatedProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAssociatedProduct(associatedProduct) {
    return {
        type: DELETE_UPDATE_ASSOCIATEDPRODUCT,
        associatedProduct
    }
}

//UPDATE ASSOCIATEDPRODUCTS
export const REQUEST_UPDATE_ASSOCIATEDPRODUCTS = "REQUEST_UPDATE_ASSOCIATEDPRODUCTS";
export const SUCCESS_UPDATE_ASSOCIATEDPRODUCTS = "SUCCESS_UPDATE_ASSOCIATEDPRODUCTS";
export const ERROR_UPDATE_ASSOCIATEDPRODUCTS = "ERROR_UPDATE_ASSOCIATEDPRODUCTS";
export const RESET_UPDATE_ASSOCIATEDPRODUCTS = "RESET_UPDATE_ASSOCIATEDPRODUCTS";

function requestUpdateAssociatedProducts() {
    return {
        type: REQUEST_UPDATE_ASSOCIATEDPRODUCTS,
}
}

function receiveUpdateAssociatedProducts(associatedProducts) {
    return {
        type: SUCCESS_UPDATE_ASSOCIATEDPRODUCTS,
    receivedAt: Date.now(),
        associatedProducts: normalizeDatos(associatedProducts)
}
}

function errorUpdateAssociatedProducts(error) {
    return {
        type: ERROR_UPDATE_ASSOCIATEDPRODUCTS,
    error: error,
}
}

export function resetUpdateAssociatedProducts() {
    return {
        type: RESET_UPDATE_ASSOCIATEDPRODUCTS,
}
}

export function saveUpdateAssociatedProducts() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssociatedProducts());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let associatedProducts = getState().associatedProducts.update.activos.map((idAssociatedProduct) => {
            return denormalizeDato(getState().associatedProducts.update.activo[idAssociatedProduct], store);
        });

        return associatedProductsApi.saveUpdateAssociatedProducts(associatedProducts)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssociatedProducts(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAssociatedProducts(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAssociatedProducts(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAssociatedProducts(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAssociatedProducts(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAssociatedProducts(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ASSOCIATEDPRODUCT
export const CREATE_ASSOCIATEDPRODUCT = 'CREATE_ASSOCIATEDPRODUCT';
export const REQUEST_CREATE_ASSOCIATEDPRODUCT = "REQUEST_CREATE_ASSOCIATEDPRODUCT";
export const SUCCESS_CREATE_ASSOCIATEDPRODUCT = "SUCCESS_CREATE_ASSOCIATEDPRODUCT";
export const ERROR_CREATE_ASSOCIATEDPRODUCT = "ERROR_CREATE_ASSOCIATEDPRODUCT";
export const RESET_CREATE_ASSOCIATEDPRODUCT = "RESET_CREATE_ASSOCIATEDPRODUCT";
export const DELETE_CREATE_ASSOCIATEDPRODUCT = "DELETE_CREATE_ASSOCIATEDPRODUCT";


//ALTA ASSOCIATEDPRODUCT
function requestCreateAssociatedProduct() {
    return {
        type: REQUEST_CREATE_ASSOCIATEDPRODUCT,
    }
}

function receiveCreateAssociatedProduct(associatedProduct) {
    return {
        type: SUCCESS_CREATE_ASSOCIATEDPRODUCT,
        receivedAt: Date.now(),
        associatedProduct: normalizeDato(associatedProduct)
    }
}

export function errorCreateAssociatedProduct(error) {
    return {
        type: ERROR_CREATE_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function resetCreateAssociatedProduct() {
    return {
        type: RESET_CREATE_ASSOCIATEDPRODUCT
    }
}

export function createAssociatedProduct(associatedProduct) {
    return {
        type: CREATE_ASSOCIATEDPRODUCT,
        associatedProduct
    }
}

export function deleteCreateAssociatedProduct(associatedProduct) {
    return {
        type: DELETE_CREATE_ASSOCIATEDPRODUCT,
        associatedProduct
    }
}

export function saveCreateAssociatedProduct() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssociatedProduct());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let associatedProduct = denormalizeDato(getState().associatedProducts.create.nuevo, store);

        return associatedProductsApi.saveCreate(associatedProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssociatedProduct(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAssociatedProducts(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAssociatedProducts(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAssociatedProduct(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAssociatedProduct(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAssociatedProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ASSOCIATEDPRODUCTS
export const REQUEST_CREATE_ASSOCIATEDPRODUCTS = "REQUEST_CREATE_ASSOCIATEDPRODUCTS";
export const SUCCESS_CREATE_ASSOCIATEDPRODUCTS = "SUCCESS_CREATE_ASSOCIATEDPRODUCTS";
export const ERROR_CREATE_ASSOCIATEDPRODUCTS = "ERROR_CREATE_ASSOCIATEDPRODUCTS";
export const RESET_CREATE_ASSOCIATEDPRODUCTS = "RESET_CREATE_ASSOCIATEDPRODUCTS";

function requestCreateAssociatedProducts() {
    return {
        type: REQUEST_CREATE_ASSOCIATEDPRODUCTS,
}
}

function receiveCreateAssociatedProducts(associatedProducts) {
    return {
        type: SUCCESS_CREATE_ASSOCIATEDPRODUCTS,
    receivedAt: Date.now(),
        associatedProducts: normalizeDatos(associatedProducts)
}
}

function errorCreateAssociatedProducts(error) {
    return {
        type: ERROR_CREATE_ASSOCIATEDPRODUCTS,
    error: error,
}
}

export function resetCreateAssociatedProducts() {
    return {
        type: RESET_CREATE_ASSOCIATEDPRODUCTS,
}
}

export function saveCreateAssociatedProducts() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssociatedProducts());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let associatedProducts = getState().associatedProducts.create.nuevos.map((idAssociatedProduct) => {
            return denormalizeDato(getState().associatedProducts.create.nuevo[idAssociatedProduct], store);
        });

        return associatedProductsApi.saveCreateAssociatedProducts(associatedProducts)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssociatedProducts(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAssociatedProducts(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAssociatedProducts(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAssociatedProducts(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAssociatedProducts(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAssociatedProducts(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ASSOCIATEDPRODUCT
export const DELETE_ASSOCIATEDPRODUCT = 'DELETE_ASSOCIATEDPRODUCT';
export const REQUEST_DELETE_ASSOCIATEDPRODUCT = "REQUEST_DELETE_ASSOCIATEDPRODUCT";
export const SUCCESS_DELETE_ASSOCIATEDPRODUCT = "SUCCESS_DELETE_ASSOCIATEDPRODUCT";
export const ERROR_DELETE_ASSOCIATEDPRODUCT = "ERROR_DELETE_ASSOCIATEDPRODUCT";
export const RESET_DELETE_ASSOCIATEDPRODUCT = "RESET_DELETE_ASSOCIATEDPRODUCT";

function requestDeleteAssociatedProduct() {
    return {
        type: REQUEST_DELETE_ASSOCIATEDPRODUCT,
    }
}

function receiveDeleteAssociatedProduct(associatedProduct) {
    return {
        type: SUCCESS_DELETE_ASSOCIATEDPRODUCT,
        receivedAt: Date.now(),
        associatedProduct: normalizeDato(associatedProduct)
    }
}

function errorDeleteAssociatedProduct(error) {
    return {
        type: ERROR_DELETE_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function resetDeleteAssociatedProduct(error) {
    return {
        type: RESET_DELETE_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function deleteAssociatedProduct(associatedProduct) {
    return {
        type: DELETE_ASSOCIATEDPRODUCT,
        associatedProduct
    }
}

export function saveDeleteAssociatedProduct(associatedProduct) {
    return dispatch => {
        dispatch(requestDeleteAssociatedProduct());
        return associatedProductsApi.saveDelete(associatedProduct)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAssociatedProduct());
                            dispatch(receiveDeleteAssociatedProduct(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAssociatedProduct(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAssociatedProduct(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAssociatedProduct(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAssociatedProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ASSOCIATEDPRODUCT
export const REQUEST_PRINT_ASSOCIATEDPRODUCT = "REQUEST_PRINT_ASSOCIATEDPRODUCT";
export const SUCCESS_PRINT_ASSOCIATEDPRODUCT = "SUCCESS_PRINT_ASSOCIATEDPRODUCT";
export const ERROR_PRINT_ASSOCIATEDPRODUCT = "ERROR_PRINT_ASSOCIATEDPRODUCT";
export const RESET_PRINT_ASSOCIATEDPRODUCT = "RESET_PRINT_ASSOCIATEDPRODUCT";

function requestPrintAssociatedProduct() {
    return {
        type: REQUEST_PRINT_ASSOCIATEDPRODUCT,
    }
}

function receivePrintAssociatedProduct(turnos) {
    return {
        type: SUCCESS_PRINT_ASSOCIATEDPRODUCT,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAssociatedProduct(error) {
    return {
        type: ERROR_PRINT_ASSOCIATEDPRODUCT,
        error: error,
    }
}

export function resetPrintAssociatedProduct() {
    return {
        type: RESET_PRINT_ASSOCIATEDPRODUCT,
    }
}

export function printAssociatedProduct(idAssociatedProduct) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAssociatedProduct());
        return associatedProductsApi.printAssociatedProduct(idAssociatedProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAssociatedProduct(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAssociatedProduct(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAssociatedProduct(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAssociatedProduct(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAssociatedProduct(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}