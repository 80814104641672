import {
    INVALIDATE_POSTSLANGUAGES,
    ERROR_POSTSLANGUAGES,
    RECEIVE_POSTSLANGUAGES,
    REQUEST_POSTSLANGUAGES,
    RESET_POSTSLANGUAGES,
    ERROR_POSTLANGUAGE,
    RECEIVE_POSTLANGUAGE,
    REQUEST_POSTLANGUAGE,
    UPDATE_POSTLANGUAGE,
    REQUEST_UPDATE_POSTLANGUAGE,
    SUCCESS_UPDATE_POSTLANGUAGE,
    ERROR_UPDATE_POSTLANGUAGE,
    RESET_UPDATE_POSTLANGUAGE,
    REQUEST_UPDATE_POSTSLANGUAGES,
    SUCCESS_UPDATE_POSTSLANGUAGES,
    ERROR_UPDATE_POSTSLANGUAGES,
    RESET_UPDATE_POSTSLANGUAGES,
    CREATE_POSTLANGUAGE,
    ERROR_CREATE_POSTLANGUAGE,
    REQUEST_CREATE_POSTLANGUAGE,
    RESET_CREATE_POSTLANGUAGE,
    SUCCESS_CREATE_POSTLANGUAGE,
    REQUEST_CREATE_POSTSLANGUAGES,
    SUCCESS_CREATE_POSTSLANGUAGES,
    ERROR_CREATE_POSTSLANGUAGES,
    RESET_CREATE_POSTSLANGUAGES,
    DELETE_POSTLANGUAGE,
    DELETE_CREATE_POSTLANGUAGE,
    DELETE_UPDATE_POSTLANGUAGE,
    REQUEST_DELETE_POSTLANGUAGE,
    SUCCESS_DELETE_POSTLANGUAGE,
    ERROR_DELETE_POSTLANGUAGE,
    RESET_DELETE_POSTLANGUAGE,
    REQUEST_PRINT_POSTLANGUAGE,
    SUCCESS_PRINT_POSTLANGUAGE,
    ERROR_PRINT_POSTLANGUAGE,
    RESET_PRINT_POSTLANGUAGE,
    RECEIVE_FILE_POSTLANGUAGE
} from '../actions/PostLanguageActions';

import {
    RECEIVE_POST,
    RECEIVE_POSTS,
    SUCCESS_DELETE_POST,
    SUCCESS_CREATE_POST,
    SUCCESS_UPDATE_POST,
    SUCCESS_UPDATE_POSTS

} from '../actions/PostActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        postsLanguages: {},
        files: {},
    }
}

function postsLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_POSTSLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                postsLanguages: {}
            });
        case RECEIVE_POSTSLANGUAGES:
            let dato = action.postsLanguages.entities.postsLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                postsLanguages: merge({}, state.postsLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_POSTLANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_POSTLANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_POSTLANGUAGE:
            let datoPostLanguage = action.postLanguage.entities.postsLanguages;
            return Object.assign({}, state, {
                postsLanguages: merge({}, state.postsLanguages, datoPostLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_POSTLANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_POSTLANGUAGE:
            let datoPostLanguageEliminado = action.postLanguage.entities.postsLanguages;
            return Object.assign({}, state, {
                postsLanguages: mergeWith(clone(datoPostLanguageEliminado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POSTLANGUAGE:
            let datoPostLanguageCreado = action.postLanguage.entities.postsLanguages;
            return Object.assign({}, state, {
                postsLanguages: mergeWith(clone(datoPostLanguageCreado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POSTSLANGUAGES:
            let datosPostLanguageCreado = action.postsLanguages.entities.postsLanguages;
                return Object.assign({}, state, {
                    postsLanguages: mergeWith(clone(datosPostLanguageCreado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_POSTLANGUAGE:
            let datoPostLanguageActualizado = action.postLanguage.entities.postsLanguages;
            return Object.assign({}, state, {
                postsLanguages: mergeWith(clone(datoPostLanguageActualizado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_POSTSLANGUAGES:
            let datosPostLanguageActualizado = action.postsLanguages.entities.postsLanguages;
                return Object.assign({}, state, {
                    postsLanguages: mergeWith(clone(datosPostLanguageActualizado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
                });

            //POST
case RECEIVE_POST:
    let post = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : {};
    return Object.assign({}, state, {
        postsLanguages: merge({}, state.postsLanguages, post),
    });
case RECEIVE_POSTS:
    let posts = action.posts.entities && action.posts.entities.postsLanguages ? action.posts.entities.postsLanguages : {};
    return Object.assign({}, state, {
        postsLanguages: merge({}, state.postsLanguages, posts),
    });
case SUCCESS_DELETE_POST:
    let datopostEliminado = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : {};
    return Object.assign({}, state, {
        postsLanguages: mergeWith(clone(datopostEliminado), pickBy(state.postsLanguages, function(postLanguage) {return postLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_POST:
    let datopostCreado = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : {};
    return Object.assign({}, state, {
        postsLanguages: mergeWith(clone(datopostCreado), pickBy(state.postsLanguages, function(postLanguage) {return postLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_POST:
    let datopostActualizado = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : {};
    return Object.assign({}, state, {
        postsLanguages: mergeWith(clone(datopostActualizado), pickBy(state.postsLanguages, function(postLanguage) {return postLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_POSTS:
    let datospostActualizado = action.posts.entities && action.posts.entities.postsLanguages ? action.posts.entities.postsLanguages : {} ;
        return Object.assign({}, state, {
            postsLanguages: mergeWith(clone(datospostActualizado), state.postsLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                postsLanguages: {}
            });
        default:
            return state
    }
}


function allPostsLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_POSTSLANGUAGES:
            return action.postsLanguages.result && action.postsLanguages.result.postsLanguages ? union(action.postsLanguages.result.postsLanguages, state) : (action.postsLanguages.result ? action.postsLanguages.result : state) ;
        case RECEIVE_POSTLANGUAGE:
                return action.postLanguage.result ? union([action.postLanguage.result], state) : state;
        
        case SUCCESS_CREATE_POSTLANGUAGE:
                   let datoPostLanguageSCreate = action.postLanguage.entities.postsLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoPostLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoPostLanguageSCreate)[0] && Object.values(datoPostLanguageSCreate)[0].id ? Object.values(datoPostLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_POSTSLANGUAGES:
                   let postsLanguagesCreate = action.postsLanguages.entities && action.postsLanguages.entities.postsLanguages ? action.postsLanguages.entities.postsLanguages : null;
                   return postsLanguagesCreate ?
                       union(state, Object.values(postsLanguagesCreate).map((postsLanguages) => {
                           return postsLanguages.id
                       })) : state;
        case RESET_POSTSLANGUAGES:
            return [];

            case RECEIVE_POST:
    let post = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : null ;
    return post ?
        union(state, Object.values(post).map((post) => {
            return post.id
        })) : state;
case RECEIVE_POSTS:
    let posts = action.posts.entities && action.posts.entities.postsLanguages ? action.posts.entities.postsLanguages : null;
    return posts ?
        union(state, Object.values(posts).map((posts) => {
            return posts.id
        })) : state;

case SUCCESS_DELETE_POST:
    let postDelete = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : null ;
        return postDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postDelete).map((post) => {
                return post.id
            })) : state;
case SUCCESS_CREATE_POST:
   let postCreate = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : null ;
       return postCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postCreate).map((post) => {
               return post.id
           })) : state;
case SUCCESS_UPDATE_POST:
    let postUpdate = action.post.entities && action.post.entities.postsLanguages ? action.post.entities.postsLanguages : null ;
        return postUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postUpdate).map((post) => {
                return post.id
            })) : state;
 case SUCCESS_UPDATE_POSTS:
     let postsUpdate = action.posts.entities && action.posts.entities.postsLanguages ? action.posts.entities.postsLanguages : null;
        return postsUpdate ?
            union(state, Object.values(postsUpdate).map((posts) => {
                return posts.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPostsLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_POSTSLANGUAGES:
            return action.postsLanguages && action.postsLanguages.result.total ? action.postsLanguages.result.total : 0;
        case RESET_POSTSLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_POSTLANGUAGE:
            let dato = action.postLanguage.entities.postsLanguages;
            let postLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: postLanguage ? postLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_POSTLANGUAGE:
            let idsUpdate = [];
            Object.values(action.postLanguage).map((postLanguageUpdate) => {
                if (postLanguageUpdate && postLanguageUpdate.id)
                    idsUpdate.push(postLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.postLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_POSTLANGUAGE:
            let datoPostLanguageActualizado = {};
            if (Object.values(action.postLanguage.entities.postsLanguages).length > 0)
                datoPostLanguageActualizado = Object.values(action.postLanguage.entities.postsLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoPostLanguageActualizado
            });
        case ERROR_UPDATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_POSTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_POSTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_POSTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //POST
//TODO ver si esta bien
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_POSTLANGUAGE:
            let datoPostLanguageDelete = action.postLanguage;
            let idsDelete = [];
           Object.values(action.postLanguage).map((postLanguageDelete) => {
               if (postLanguageDelete && postLanguageDelete.id)
                   idsDelete.push(postLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoPostLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_POSTLANGUAGE:
                       let datoPostLanguageDeleteUpdate = action.postLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.postLanguage).map((postLanguageDelete) => {
                          if (postLanguageDelete && postLanguageDelete.id)
                              idsDeleteUpdate.push(postLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoPostLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_POSTLANGUAGE:
                    let datoPostLanguageDeleted = {};
                    if (Object.values(action.postLanguage.entities.postsLanguages).length > 0)
                        datoPostLanguageDeleted = Object.values(action.postLanguage.entities.postsLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoPostLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_POSTLANGUAGE:
             let idsCreate = [];
             Object.values(action.postLanguage).map((postLanguageCreate) => {
                 if (postLanguageCreate && postLanguageCreate.id)
                     idsCreate.push(postLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.postLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_POSTLANGUAGE:
            let datoPostLanguageNuevo = {};
            if (Object.values(action.postLanguage.entities.postsLanguages).length > 0)
                datoPostLanguageNuevo = Object.values(action.postLanguage.entities.postsLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoPostLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_POSTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //POST
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_POSTLANGUAGE:
           let datoPostLanguageDelete = action.postLanguage;
           let idsDelete = [];
           Object.values(action.postLanguage).map((postLanguageDelete) => {
               if (postLanguageDelete && postLanguageDelete.id)
                   idsDelete.push(postLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoPostLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_POSTLANGUAGE:
                  let datoPostLanguageDeleteCreate = action.postLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.postLanguage).map((postLanguageDelete) => {
                      if (postLanguageDelete && postLanguageDelete.id)
                          idsDeleteCreate.push(postLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoPostLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_POSTLANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.postLanguage,
                error: null,
            });
        case REQUEST_DELETE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_POSTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //POST
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_POSTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_POSTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_POSTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const postsLanguages = combineReducers({
    byId: postsLanguagesById,
    allIds: allPostsLanguages,
    update: update,
    create: create,
    totalPostsLanguages: totalPostsLanguages,
    delete: deleter,
    print: print
});

export default postsLanguages;