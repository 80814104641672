import {resetUsers, resetCreateUser, resetUpdateUser, resetDeleteUser} from "../actions/UserActions";


var resetUser = {
    resetAll(dispatch) {
        dispatch(resetUsers());
        dispatch(resetCreateUser());
        dispatch(resetUpdateUser());
        dispatch(resetDeleteUser());
       
    },
};

export default resetUser;