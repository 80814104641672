import {normalize, schema, denormalize} from 'normalizr';

const typeFileSection = new schema.Entity('typesFilesSections', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {typesFilesSections: [typeFileSection]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = typeFileSection;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = typeFileSection;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default typeFileSection;
