import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
import clone from "lodash/clone";
//Components
import {
  Box,
  Breadcrumbs,
  Chip,
  Container,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import ContainerBtn from "../../library/Buttons/ContainerBtn";
import BtnSave from "../../library/Buttons/BtnSave";
import BtnCancel from "../../library/Buttons/BtnCancel";
import Grid from "@material-ui/core/Grid";
import PaperBody from "../../library/CustomPaper/PaperBody";
import EmbeddedCountriesList from "../Countries/Embedded/EmbeddedCountriesList";
//Img
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
//Hooks
import { useValidador } from "../../utils/validador";
import { useDispatch, useSelector } from "react-redux";
//Actions
import {
  createPost,
  fetchPost,
  saveCreatePost,
  saveUpdatePost,
  updatePost,
} from "../../actions/PostActions";
import {
  createPostLanguage,
  updatePostLanguage,
} from "../../actions/PostLanguageActions";
import { fetchLanguagesIfNeeded } from "../../actions/LanguageActions";
import {
  createPostCountry,
  deletePostCountry,
} from "../../actions/PostCountryActions";
//reset
import postReset from "../../reset/postReset";
import languageReset from "../../reset/languageReset";
import ImageFilePost from "./ImageFilePost";
import countryReset from "../../reset/countryReset";
import categoryReset from "../../reset/categoryReset";
import assetReset from "../../reset/assetReset";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import PostLanguage from "./PostLanguage";
import EmbeddedAssetsList from "../Assets/Embedded/EmbeddedAssetsList";
import { useHistory, useLocation } from "react-router";
import { fetchCountriesIfNeeded } from "../../actions/CountryActions";
import usePermisos from "../Layout/CustomHooks/usePermisos";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(2),
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      flexWrap: "nowrap",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function CRUDPost(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idPost, idSection } = useParams();
  const mounted = useRef();
  const msjCountries = useRef();
  const history = useHistory();
  let location = useLocation();
  let path = location.pathname.indexOf("jobs") === -1 ? "/posts" + "" : "/jobs";
  let permisos = usePermisos("posts");

  //Store
  const posts = useSelector((state) => state.posts);
  const post = idPost ? posts.update.activo : posts.create.nuevo;
  const postsLanguages = useSelector((state) => state.postsLanguages);
  const postsCountries = useSelector((state) => state.postsCountries);
  const languages = useSelector((state) => state.languages);

  let languageDef = null;
  languages.allIds.some((idLanguage) => {
    let language = languages.byId.languages[idLanguage];
    if (language.codLanguage === "ES") {
      languageDef = language;
      return true;
    }
    return false;
  });

  //State

  const [files, setFiles] = useState([]);
  const [
    refreshCheckboxSelectAllCountries,
    setRefreshCheckboxSelectAllCountries,
  ] = useState(0);
  const [previewFile, setPreviewFile] = React.useState(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);

  const guardandoLoading = idPost
    ? posts.update.isUpdating
    : posts.create.isCreating;
  const disabled =
    (idPost ? posts.update.isUpdating : posts.create.isCreating) ||
    posts.byId.isFetching ||
    postsLanguages.create.isCreating ||
    postsLanguages.update.isUpdating ||
    !permisos.get("update") ||
    !permisos.get("create");
  const error = idPost ? posts.update.error : posts.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    namePost: ["required"],
    type: ["required"],
    dateEvent: ["sometimes|required"],
  };
  const [validador, validar] = useValidador(reglas);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchLanguagesIfNeeded({}));
    dispatch(fetchCountriesIfNeeded({}));
    if (idPost) dispatch(fetchPost(idPost));

    return function cleanup() {
      postReset.resetAll(dispatch);
      languageReset.resetAll(dispatch);
      countryReset.resetAll(dispatch);
      categoryReset.resetAll(dispatch);
      assetReset.resetAll(dispatch);
    };
  }, [idPost]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!posts.create.isCreating && !posts.create.error) {
        if (posts.create.nuevo.id)
          history.push(path + "/" + posts.create.nuevo.id);
        postReset.resetAll(dispatch);
        assetReset.resetAll(dispatch);
        setFiles([]);
        window.scrollTo(0, 0);
      }
    }
  }, [posts.create.isCreating]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!posts.update.isUpdating && !posts.update.error) {
        setFiles([]);
        // fetchFiles();
      }
    }
  }, [posts.update.isUpdating]);

  //Post
  const handleChangePost = (e) => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changePost(cambio);
  };

  const handleChangePostDate = (date, id) => {
    let cambio = {};
    if (date && date.isValid()) {
      cambio[id] = date ? moment(date).format("YYYY-MM-DD") : null;
      changePost(cambio);
    }
  };

  const handleChangePostTime = (date, id) => {
    let cambio = {};
    if (date && date.isValid()) {
      cambio[id] = date ? moment(date).format("HH:mm") : null;
      changePost(cambio);
    }
  };

  const changePost = (cambio) => {
    if (idPost) dispatch(updatePost(cambio));
    else dispatch(createPost(cambio));
  };

  const handleChangeStatusPost = () => {
    let cambio = {};
    cambio.status = !post.status;
    changePost(cambio);
  };

  //Lista países
  const handleChangePostCountry = (idCountry, selectAll) => {
    let id = idCountry + "-";
    let esPostCountry = false;
    if (post.post_country)
      esPostCountry = post.post_country.some((idPostCountry) => {
        let postCountry = postsCountries.byId.postsCountries[idPostCountry];
        if (postsCountries.update.activos.indexOf(idPostCountry) !== -1)
          postCountry = postsCountries.update.activo[idPostCountry];
        if (postsCountries.create.nuevos.indexOf(idPostCountry) !== -1)
          postCountry = postsCountries.create.nuevo[idPostCountry];
        if (postCountry.idCountry === idCountry) id = idPostCountry;
        return postCountry.idCountry === idCountry;
      });
    let cambio = {};
    cambio[id] = { idPost: idPost, idCountry: idCountry, id: id };
    if (esPostCountry) {
      dispatch(deletePostCountry(cambio));
    } else dispatch(createPostCountry(cambio));
  };

  const isUbicacionSelected = (idCountry) => {
    let isPostCountry = false;
    if (post.post_country)
      isPostCountry = post.post_country.some((idPostCountry) => {
        let postCountry = postsCountries.byId.postsCountries[idPostCountry];
        if (postsCountries.update.activos.indexOf(idPostCountry) !== -1)
          postCountry = postsCountries.update.activo[idPostCountry];
        if (postsCountries.create.nuevos.indexOf(idPostCountry) !== -1)
          postCountry = postsCountries.create.nuevo[idPostCountry];
        if (postCountry) return postCountry.idCountry === idCountry;
        else return false;
      });
    return isPostCountry;
  };

  //Submit
  const onSubmit = (e) => {
    e.preventDefault();
    if (validador.isValid()) {
      if (idPost) dispatch(saveUpdatePost(files));
      else dispatch(saveCreatePost(files));
    }
  };
  const [selectedDate, handleDateChange] = useState(new Date());
  return (
    <Container maxWidth={"xg"}>
      <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to={path}>
          {location.pathname.indexOf("jobs") === -1 ? "Posteos" : "Empleos"}
        </Link>
      </Breadcrumbs>
      <Typography component={"h1"} variant={"h1"}>
        {location.pathname.indexOf("jobs") === -1
          ? !idPost
            ? "Nuevo posteo"
            : "Modificar posteo"
          : !idPost
          ? "Nuevo empleo"
          : "Modificar empleo"}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={"overline"} component={"span"}>
                  Datos del posteo
                </Typography>
              </PaperHeader>
              <PaperBody spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <ImageFilePost
                    files={files}
                    setFiles={setFiles}
                    changePost={changePost}
                    file={post.path}
                    disabled={disabled}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: "15px" }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: "15px" }}
                  >
                    <TextField
                      id="namePost"
                      placeholder="Nombre"
                      variant="outlined"
                      label={"Nombre *"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.namePost ? post.namePost : ""}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("namePost")}
                      helperText={validador.getHelperTextById("namePost")}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: "15px" }}
                  >
                    <TextField
                      id={"type"}
                      select
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={"Tipo posteo *"}
                      placeholder={"Seleccione tipo de posteo"}
                      value={post && post.type ? post.type : ""}
                      onChange={(e) => handleChangePost(e)}
                      InputProps={{
                        disabled: disabled,
                        id: "type",
                        name: "type",
                      }}
                      disabled={disabled}
                      variant="outlined"
                      onBlur={validador.validar}
                      error={!validador.isValidById("type")}
                      helperText={validador.getHelperTextById("type")}
                    >
                      {location.pathname.indexOf("jobs") === -1 && (
                        <MenuItem value={"Novedad"}>Novedad</MenuItem>
                      )}
                      {location.pathname.indexOf("jobs") === -1 && (
                        <MenuItem value={"Notiwiener"}>
                          Edición Notiwiener
                        </MenuItem>
                      )}
                      {location.pathname.indexOf("jobs") === -1 && (
                        <MenuItem value={"Evento"}>Evento</MenuItem>
                      )}
                      {location.pathname.indexOf("jobs") === -1 && (
                        <MenuItem value={"Adaptacion"}>Adaptación</MenuItem>
                      )}
                      {location.pathname.indexOf("jobs") !== -1 && (
                        <MenuItem value={"Empleo"}>Búsqueda laboral</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      {((!post.dateTo &&
                        moment(post.dateFrom).isSameOrBefore(moment())) ||
                        moment().isBetween(
                          moment(post.dateFrom),
                          moment(post.dateTo)
                        )) &&
                        post.status === 1 && (
                          <Chip
                            icon={<DoneIcon />}
                            label="Vigente"
                            color="primary"
                            variant="outlined"
                            style={{ marginBottom: "20px" }}
                          />
                        )}
                      {!(
                        (!post.dateTo &&
                          moment(post.dateFrom).isSameOrBefore(moment())) ||
                        moment().isBetween(
                          moment(post.dateFrom),
                          moment(post.dateTo)
                        )
                      ) && (
                        <Chip
                          icon={<CloseIcon />}
                          label="Fuera de fecha"
                          color="secondary"
                          variant="outlined"
                          style={{ marginBottom: "20px" }}
                        />
                      )}
                      {post.status === 0 && (
                        <Chip
                          icon={<CloseIcon />}
                          label="Desactivado"
                          color="secondary"
                          variant="outlined"
                          style={{ marginBottom: "20px" }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Fecha desde publicación"
                    format="DD/MM/yyyy"
                    disabled={disabled}
                    InputAdornmentProps={{ position: "end" }}
                    value={post && post.dateFrom ? post.dateFrom : null}
                    onChange={(date) => handleChangePostDate(date, "dateFrom")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Fecha hasta publicación"
                    format="DD/MM/yyyy"
                    disabled={disabled}
                    InputAdornmentProps={{ position: "end" }}
                    value={post && post.dateTo ? post.dateTo : null}
                    onChange={(date) => handleChangePostDate(date, "dateTo")}
                  />
                </Grid>
                {post.type === "Evento" && (
                  <>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardDatePicker
                        id={"dateEvent"}
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha evento"
                        format="DD/MM/yyyy"
                        disabled={disabled}
                        InputAdornmentProps={{ position: "end" }}
                        value={post && post.dateEvent ? post.dateEvent : null}
                        onChange={(date) =>
                          handleChangePostDate(date, "dateEvent")
                        }
                        onBlur={validador.validar}
                        error={!validador.isValidById("dateEvent")}
                        helperText={validador.getHelperTextById("dateEvent")}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardTimePicker
                        ampm={false}
                        id={"timeEvent"}
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora evento"
                        format="HH:mm"
                        disabled={disabled}
                        mask="__:__"
                        InputAdornmentProps={{ position: "end" }}
                        value={
                          post && post.timeEvent
                            ? moment(post.timeEvent, "HH:mm")
                            : null
                        }
                        onChange={(date) =>
                          handleChangePostTime(date, "timeEvent")
                        }
                        onBlur={validador.validar}
                        error={!validador.isValidById("timeEvent")}
                        helperText={validador.getHelperTextById("timeEvent")}
                      />
                    </Grid>
                    <TextField
                      id="location"
                      margin={"normal"}
                      placeholder="Ubicación física o virtual"
                      variant="outlined"
                      label={"Ubicación"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.location ? post.location : ""}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("location")}
                      helperText={validador.getHelperTextById("location")}
                    />
                  </>
                )}
                {post.type === "Empleo" && (
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="location"
                      margin={"normal"}
                      placeholder="Ubicación del puesto"
                      variant="outlined"
                      label={"Ubicación"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.location ? post.location : ""}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("location")}
                      helperText={validador.getHelperTextById("location")}
                    />
                    <TextField
                      id="link"
                      margin={"normal"}
                      placeholder="Link de LinkedIn"
                      variant="outlined"
                      label={"LinkedIn"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.link ? post.link : ""}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("link")}
                      helperText={validador.getHelperTextById("link")}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    visibility: !idPost ? "hidden" : "inherit",
                    marginTop: "14px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={post && !!post.status}
                        onChange={() => handleChangeStatusPost()}
                        disabled={disabled}
                        name="estado"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Estado"
                  />
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={"overline"} component={"span"}>
                  ¿En qué países se visualizará este posteo?
                </Typography>
              </PaperHeader>
              <EmbeddedCountriesList
                onSelect={handleChangePostCountry}
                isSelected={isUbicacionSelected}
                refresh={refreshCheckboxSelectAllCountries}
                disabled={disabled}
                msjCountries={msjCountries}
              />
            </CustomPaper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={"overline"} component={"span"}>
                  Autor
                </Typography>
              </PaperHeader>
              <PaperBody>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      id="nameAuthor"
                      margin={"normal"}
                      placeholder="Nombre"
                      variant="outlined"
                      label={"Nombre"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.nameAuthor ? post.nameAuthor : ""}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("nameAuthor")}
                      helperText={validador.getHelperTextById("nameAuthor")}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      id="lastnameAuthor"
                      margin={"normal"}
                      placeholder="Apellido"
                      variant="outlined"
                      label={"Apellido"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.lastnameAuthor ? post.lastnameAuthor : ""
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("lastnameAuthor")}
                      helperText={validador.getHelperTextById("lastnameAuthor")}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="contactAuthor"
                      margin={"normal"}
                      placeholder="Contacto"
                      variant="outlined"
                      label={"Contacto"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.contactAuthor ? post.contactAuthor : ""
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("contactAuthor")}
                      helperText={validador.getHelperTextById("contactAuthor")}
                    />
                    <TextField
                      id="positionAuthor"
                      margin={"normal"}
                      placeholder="Ocupación/Puesto"
                      variant="outlined"
                      label={"Ocupación/Puesto"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: "text",
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.positionAuthor ? post.positionAuthor : ""
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById("positionAuthor")}
                      helperText={validador.getHelperTextById("positionAuthor")}
                    />
                  </Grid>
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={"overline"} component={"span"}>
                  Idiomas
                </Typography>
              </PaperHeader>
              <PaperBody>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  {languages.allIds &&
                    languages.allIds.map((idLanguage, i) => {
                      let language = languages.byId.languages[idLanguage];
                      let post = idPost
                        ? posts.update.activo
                        : posts.create.nuevo;
                      let postLanguageFound = null;
                      if (post && post.post_language)
                        post.post_language.some((idPostLanguage) => {
                          let postLanguage =
                            postsLanguages.byId.postsLanguages[idPostLanguage];
                          if (
                            postsLanguages.update.activos.indexOf(
                              idPostLanguage
                            ) !== -1
                          )
                            postLanguage =
                              postsLanguages.update.activo[idPostLanguage];
                          if (
                            postsLanguages.create.nuevos.indexOf(
                              idPostLanguage
                            ) !== -1
                          )
                            postLanguage =
                              postsLanguages.create.nuevo[idPostLanguage];
                          if (
                            postLanguage &&
                            postLanguage.idLanguage === idLanguage
                          ) {
                            postLanguageFound = postLanguage;
                            return true;
                          }
                          return false;
                        });
                      return (
                        <PostLanguage
                          language={language}
                          postLanguage={postLanguageFound}
                          disabled={disabled}
                          idPost={idPost}
                          post={post}
                        />
                      );
                    })}
                </Box>
              </PaperBody>
            </CustomPaper>
          </Grid>
        </Grid>
        <ContainerBtn>
          <BtnCancel onClick={() => props.history.push(path)} />
          {/* boton de guardar */}
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
      <EmbeddedAssetsList id={idPost} fk={"idPost"} />
    </Container>
  );
}
