import {resetSections, resetCreateSection, resetUpdateSection, resetDeleteSection} from "../actions/SectionActions";
import {resetPages, resetCreatePage, resetUpdatePage, resetDeletePage} from "../actions/PageActions";import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";

var resetSection = {
    resetAll(dispatch) {
        dispatch(resetSections());
        dispatch(resetCreateSection());
        dispatch(resetUpdateSection());
        dispatch(resetDeleteSection());
       dispatch(resetPages());
dispatch(resetCreatePage());
dispatch(resetUpdatePage());
dispatch(resetDeletePage());dispatch(resetAssets());
dispatch(resetCreateAsset());
dispatch(resetUpdateAsset());
dispatch(resetDeleteAsset());
    },
};

export default resetSection;