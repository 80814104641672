import {resetLanguages, resetCreateLanguage, resetUpdateLanguage, resetDeleteLanguage} from "../actions/LanguageActions";


var resetLanguage = {
    resetAll(dispatch) {
        dispatch(resetLanguages());
        dispatch(resetCreateLanguage());
        dispatch(resetUpdateLanguage());
        dispatch(resetDeleteLanguage());
       
    },
};

export default resetLanguage;