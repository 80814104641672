//api
import logsApi from "../api/logsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeLogs";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//LOGS
export const REQUEST_LOGS = 'REQUEST_LOGS';
export const RECEIVE_LOGS = 'RECEIVE_LOGS';
export const INVALIDATE_LOGS = 'INVALIDATE_LOGS';
export const ERROR_LOGS = "ERROR_LOGS";
export const RESET_LOGS = "RESET_LOGS";


export function invalidateLogs() {
    return {
        type: INVALIDATE_LOGS
    }
}

function requestLogs() {
    return {
        type: REQUEST_LOGS,
    }
}

function receiveLogs(json) {
    return {
        type: RECEIVE_LOGS,
    logs: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorLogs(error) {
    return {
        type: ERROR_LOGS,
        error: error,
    }
}

export function resetLogs() {
    return {
        type: RESET_LOGS
    }
}

export function fetchLogs(filtros) {
    return dispatch => {
        dispatch(requestLogs());
        return logsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLogs(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLogs(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLogs(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLogs(state) {
    const logs = state.logs.byId;
    if (!logs) {
        return true
    } else if (logs.isFetching) {
        return false
    } else {
        return logs.didInvalidate;
    }
}

export function fetchLogsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchLogs(getState())) {
            return dispatch(fetchLogs(filtros))
        }
    }
}


//MODEL
export const REQUEST_LOG = 'REQUEST_LOG';
export const RECEIVE_LOG = 'RECEIVE_LOG';
export const INVALIDATE_LOG = 'INVALIDATE_LOG';
export const ERROR_LOG = "ERROR_LOG";
export const RESET_LOG = "RESET_LOG";


export function invalidateLog() {
    return {
        type: INVALIDATE_LOG
    }
}

function requestLog() {
    return {
        type: REQUEST_LOG,
    }
}

export function receiveLog(json) {
    return {
        type: RECEIVE_LOG,
    log: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorLog(error) {
    return {
        type: ERROR_LOG,
        error: error,
    }
}

export function fetchLog(idLog) {
    return dispatch => {
        dispatch(requestLog());
        return logsApi.getOne(idLog)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLog(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLog(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLog(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_LOG = 'RECEIVE_FILE_LOG';

function receiveFileLog(file) {
    return {
        type: RECEIVE_FILE_LOG,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileLog(idLog, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return logsApi.getFile(idLog, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileLog(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLog(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLog(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_LOG = 'UPDATE_LOG';
export const REQUEST_UPDATE_LOG = "REQUEST_UPDATE_LOG";
export const SUCCESS_UPDATE_LOG = "SUCCESS_UPDATE_LOG";
export const ERROR_UPDATE_LOG = "ERROR_UPDATE_LOG";
export const RESET_UPDATE_LOG = "RESET_UPDATE_LOG";
export const DELETE_UPDATE_LOG = "DELETE_UPDATE_LOG";

function requestUpdateLog() {
    return {
        type: REQUEST_UPDATE_LOG,
    }
}

function receiveUpdateLog(log) {
    return {
        type: SUCCESS_UPDATE_LOG,
        receivedAt: Date.now(),
        log: normalizeDato(log)
    }
}

function errorUpdateLog(error) {
    return {
        type: ERROR_UPDATE_LOG,
        error: error,
    }
}

export function resetUpdateLog() {
    return {
        type: RESET_UPDATE_LOG,
    }
}

export function updateLog(log) {
    return {
        type: UPDATE_LOG,
        log
    }
}

export function saveUpdateLog() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLog());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let log = denormalizeDato(getState().logs.update.activo, store);

        return logsApi.saveUpdate(log)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLog(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateLog(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateLogs(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateLogs(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateLog(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateLog(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateLog(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateLog(log) {
    return {
        type: DELETE_UPDATE_LOG,
        log
    }
}

//UPDATE LOGS
export const REQUEST_UPDATE_LOGS = "REQUEST_UPDATE_LOGS";
export const SUCCESS_UPDATE_LOGS = "SUCCESS_UPDATE_LOGS";
export const ERROR_UPDATE_LOGS = "ERROR_UPDATE_LOGS";
export const RESET_UPDATE_LOGS = "RESET_UPDATE_LOGS";

function requestUpdateLogs() {
    return {
        type: REQUEST_UPDATE_LOGS,
}
}

function receiveUpdateLogs(logs) {
    return {
        type: SUCCESS_UPDATE_LOGS,
    receivedAt: Date.now(),
        logs: normalizeDatos(logs)
}
}

function errorUpdateLogs(error) {
    return {
        type: ERROR_UPDATE_LOGS,
    error: error,
}
}

export function resetUpdateLogs() {
    return {
        type: RESET_UPDATE_LOGS,
}
}

export function saveUpdateLogs() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLogs());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let logs = getState().logs.update.activos.map((idLog) => {
            return denormalizeDato(getState().logs.update.activo[idLog], store);
        });

        return logsApi.saveUpdateLogs(logs)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLogs(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLogs(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateLogs(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateLogs(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateLogs(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateLogs(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA LOG
export const CREATE_LOG = 'CREATE_LOG';
export const REQUEST_CREATE_LOG = "REQUEST_CREATE_LOG";
export const SUCCESS_CREATE_LOG = "SUCCESS_CREATE_LOG";
export const ERROR_CREATE_LOG = "ERROR_CREATE_LOG";
export const RESET_CREATE_LOG = "RESET_CREATE_LOG";
export const DELETE_CREATE_LOG = "DELETE_CREATE_LOG";


//ALTA LOG
function requestCreateLog() {
    return {
        type: REQUEST_CREATE_LOG,
    }
}

function receiveCreateLog(log) {
    return {
        type: SUCCESS_CREATE_LOG,
        receivedAt: Date.now(),
        log: normalizeDato(log)
    }
}

export function errorCreateLog(error) {
    return {
        type: ERROR_CREATE_LOG,
        error: error,
    }
}

export function resetCreateLog() {
    return {
        type: RESET_CREATE_LOG
    }
}

export function createLog(log) {
    return {
        type: CREATE_LOG,
        log
    }
}

export function deleteCreateLog(log) {
    return {
        type: DELETE_CREATE_LOG,
        log
    }
}

export function saveCreateLog() {
    return (dispatch, getState) => {
        dispatch(requestCreateLog());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let log = denormalizeDato(getState().logs.create.nuevo, store);

        return logsApi.saveCreate(log)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLog(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateLog(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateLogs(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateLogs(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateLog(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateLog(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateLog(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE LOGS
export const REQUEST_CREATE_LOGS = "REQUEST_CREATE_LOGS";
export const SUCCESS_CREATE_LOGS = "SUCCESS_CREATE_LOGS";
export const ERROR_CREATE_LOGS = "ERROR_CREATE_LOGS";
export const RESET_CREATE_LOGS = "RESET_CREATE_LOGS";

function requestCreateLogs() {
    return {
        type: REQUEST_CREATE_LOGS,
}
}

function receiveCreateLogs(logs) {
    return {
        type: SUCCESS_CREATE_LOGS,
    receivedAt: Date.now(),
        logs: normalizeDatos(logs)
}
}

function errorCreateLogs(error) {
    return {
        type: ERROR_CREATE_LOGS,
    error: error,
}
}

export function resetCreateLogs() {
    return {
        type: RESET_CREATE_LOGS,
}
}

export function saveCreateLogs() {
    return (dispatch, getState) => {
        dispatch(requestCreateLogs());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let logs = getState().logs.create.nuevos.map((idLog) => {
            return denormalizeDato(getState().logs.create.nuevo[idLog], store);
        });

        return logsApi.saveCreateLogs(logs)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLogs(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateLogs(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateLogs(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateLogs(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateLogs(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateLogs(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE LOG
export const DELETE_LOG = 'DELETE_LOG';
export const REQUEST_DELETE_LOG = "REQUEST_DELETE_LOG";
export const SUCCESS_DELETE_LOG = "SUCCESS_DELETE_LOG";
export const ERROR_DELETE_LOG = "ERROR_DELETE_LOG";
export const RESET_DELETE_LOG = "RESET_DELETE_LOG";

function requestDeleteLog() {
    return {
        type: REQUEST_DELETE_LOG,
    }
}

function receiveDeleteLog(log) {
    return {
        type: SUCCESS_DELETE_LOG,
        receivedAt: Date.now(),
        log: normalizeDato(log)
    }
}

function errorDeleteLog(error) {
    return {
        type: ERROR_DELETE_LOG,
        error: error,
    }
}

export function resetDeleteLog(error) {
    return {
        type: RESET_DELETE_LOG,
        error: error,
    }
}

export function deleteLog(log) {
    return {
        type: DELETE_LOG,
        log
    }
}

export function saveDeleteLog(log) {
    return dispatch => {
        dispatch(requestDeleteLog());
        return logsApi.saveDelete(log)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteLog());
                            dispatch(receiveDeleteLog(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteLog(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteLog(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteLog(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteLog(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteLog(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT LOG
export const REQUEST_PRINT_LOG = "REQUEST_PRINT_LOG";
export const SUCCESS_PRINT_LOG = "SUCCESS_PRINT_LOG";
export const ERROR_PRINT_LOG = "ERROR_PRINT_LOG";
export const RESET_PRINT_LOG = "RESET_PRINT_LOG";

function requestPrintLog() {
    return {
        type: REQUEST_PRINT_LOG,
    }
}

function receivePrintLog(turnos) {
    return {
        type: SUCCESS_PRINT_LOG,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintLog(error) {
    return {
        type: ERROR_PRINT_LOG,
        error: error,
    }
}

export function resetPrintLog() {
    return {
        type: RESET_PRINT_LOG,
    }
}

export function printLog(idLog) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintLog());
        return logsApi.printLog(idLog)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintLog(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintLog(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintLog(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintLog(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintLog(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}