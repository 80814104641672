import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {CircularProgress, DialogContent} from "@material-ui/core";
import bannerMobile from "../../assets/img/bannerMobile.jpeg";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    preview: {
        maxWidth: "100%",
        textAlign: "center",
        "& img": {
            width: "100%",
            maxWidth: "100%",
            backgroundColor: "white",
        },
        "& video": {
            width: "100%",
            maxWidth: "100%",
            backgroundColor: "white",
            display: "none"
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        zIndex: 99999,
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewImageVideo(props) {
    const classes = useStyles();
    const {file, setPreviewOpen, previewOpen} = props;
    let img = React.useRef();
    let video = React.useRef();

    const [loading, setLoading] = React.useState(false);


    useEffect(() => {
            if (previewOpen) {
                let reader = new FileReader();
                reader.onloadend = (e) => {
                    renderBanner(file, reader);
                    setLoading(false);
                };
                if (file) {
                    setLoading(true);
                    reader.readAsDataURL(file);
                }
            }
        }, [previewOpen]
    )

    const renderBanner = (fileSubido, reader) => {
        if (fileSubido.type.indexOf("image") !== -1) {
            img.current.src = reader.result;
            video.current.style.display = 'none';
            img.current.style.display = 'inherit';
        } else {
            video.current.src = reader.result;
            img.current.style.display = 'none';
            video.current.style.display = 'inherit';
        }
    }


    const handleClickOpen = () => {
        setPreviewOpen(true);
    };

    const handleClose = () => {
        setPreviewOpen(false);
    };

    return (
        <div>
            <Dialog fullScreen open={previewOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Visor
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.preview}>
                    <img ref={img} src={""} alt=""/>
                    <video id={"video"} ref={video} alt="" autoPlay={true} controls={true}/>
                    <CircularProgress className={classes.progress}
                                      style={{display: !loading ? "none" : "inherit"}}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}