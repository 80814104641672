import {normalize, schema, denormalize} from 'normalizr';

const menu = new schema.Entity('menus', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {menus: [menu]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = menu;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = menu;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default menu;
