import {
    INVALIDATE_PERMISSIONS,
    ERROR_PERMISSIONS,
    RECEIVE_PERMISSIONS,
    REQUEST_PERMISSIONS,
    RESET_PERMISSIONS,
    ERROR_PERMISSION,
    RECEIVE_PERMISSION,
    REQUEST_PERMISSION,
    UPDATE_PERMISSION,
    REQUEST_UPDATE_PERMISSION,
    SUCCESS_UPDATE_PERMISSION,
    ERROR_UPDATE_PERMISSION,
    RESET_UPDATE_PERMISSION,
    REQUEST_UPDATE_PERMISSIONS,
    SUCCESS_UPDATE_PERMISSIONS,
    ERROR_UPDATE_PERMISSIONS,
    RESET_UPDATE_PERMISSIONS,
    CREATE_PERMISSION,
    ERROR_CREATE_PERMISSION,
    REQUEST_CREATE_PERMISSION,
    RESET_CREATE_PERMISSION,
    SUCCESS_CREATE_PERMISSION,
    REQUEST_CREATE_PERMISSIONS,
    SUCCESS_CREATE_PERMISSIONS,
    ERROR_CREATE_PERMISSIONS,
    RESET_CREATE_PERMISSIONS,
    DELETE_PERMISSION,
    DELETE_CREATE_PERMISSION,
    DELETE_UPDATE_PERMISSION,
    REQUEST_DELETE_PERMISSION,
    SUCCESS_DELETE_PERMISSION,
    ERROR_DELETE_PERMISSION,
    RESET_DELETE_PERMISSION,
    REQUEST_PRINT_PERMISSION,
    SUCCESS_PRINT_PERMISSION,
    ERROR_PRINT_PERMISSION,
    RESET_PRINT_PERMISSION,
    RECEIVE_FILE_PERMISSION
} from '../actions/PermissionActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        permissions: {},
        files: {},
    }
}

function permissionsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PERMISSIONS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PERMISSIONS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PERMISSIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PERMISSIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                permissions: {}
            });
        case RECEIVE_PERMISSIONS:
            let dato = action.permissions.entities.permissions;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                permissions: merge({}, state.permissions, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PERMISSION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PERMISSION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PERMISSION:
            let datoPermission = action.permission.entities.permissions;
            return Object.assign({}, state, {
                permissions: merge({}, state.permissions, datoPermission),
                isFetching: false,
            });
        case RECEIVE_FILE_PERMISSION:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PERMISSION:
            let datoPermissionEliminado = action.permission.entities.permissions;
            return Object.assign({}, state, {
                permissions: mergeWith(clone(datoPermissionEliminado), state.permissions, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PERMISSION:
            let datoPermissionCreado = action.permission.entities.permissions;
            return Object.assign({}, state, {
                permissions: mergeWith(clone(datoPermissionCreado), state.permissions, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PERMISSIONS:
            let datosPermissionCreado = action.permissions.entities.permissions;
                return Object.assign({}, state, {
                    permissions: mergeWith(clone(datosPermissionCreado), state.permissions, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PERMISSION:
            let datoPermissionActualizado = action.permission.entities.permissions;
            return Object.assign({}, state, {
                permissions: mergeWith(clone(datoPermissionActualizado), state.permissions, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PERMISSIONS:
            let datosPermissionActualizado = action.permissions.entities.permissions;
                return Object.assign({}, state, {
                    permissions: mergeWith(clone(datosPermissionActualizado), state.permissions, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                permissions: {}
            });
        default:
            return state
    }
}


function allPermissions(state = [], action) {
    switch (action.type) {
        case RECEIVE_PERMISSIONS:
            return action.permissions.result && action.permissions.result.permissions ? union(action.permissions.result.permissions, state) : (action.permissions.result ? action.permissions.result : state) ;
        case RECEIVE_PERMISSION:
                return action.permission.result ? union([action.permission.result], state) : state;
        
        case SUCCESS_CREATE_PERMISSION:
                   let datoPermissionSCreate = action.permission.entities.permissions;
                   let idNuevoSCreate = null;
                   if (Object.values(datoPermissionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoPermissionSCreate)[0] && Object.values(datoPermissionSCreate)[0].id ? Object.values(datoPermissionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PERMISSIONS:
                   let permissionsCreate = action.permissions.entities && action.permissions.entities.permissions ? action.permissions.entities.permissions : null;
                   return permissionsCreate ?
                       union(state, Object.values(permissionsCreate).map((permissions) => {
                           return permissions.id
                       })) : state;
        case RESET_PERMISSIONS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPermissions(state = null, action) {
    switch (action.type) {
        case RECEIVE_PERMISSIONS:
            return action.permissions && action.permissions.result.total ? action.permissions.result.total : 0;
        case RESET_PERMISSIONS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PERMISSION:
            let dato = action.permission.entities.permissions;
            let permission = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: permission ? permission : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PERMISSION:
            let idsUpdate = [];
            Object.values(action.permission).map((permissionUpdate) => {
                if (permissionUpdate && permissionUpdate.id)
                    idsUpdate.push(permissionUpdate.id);
            });
            return merge({}, state, {
                activo: action.permission,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PERMISSION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PERMISSION:
            let datoPermissionActualizado = {};
            if (Object.values(action.permission.entities.permissions).length > 0)
                datoPermissionActualizado = Object.values(action.permission.entities.permissions)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoPermissionActualizado
            });
        case ERROR_UPDATE_PERMISSION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PERMISSIONS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PERMISSIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PERMISSIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PERMISSION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_PERMISSION:
            let datoPermissionDelete = action.permission;
            let idsDelete = [];
           Object.values(action.permission).map((permissionDelete) => {
               if (permissionDelete && permissionDelete.id)
                   idsDelete.push(permissionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoPermissionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PERMISSION:
                       let datoPermissionDeleteUpdate = action.permission;
                       let idsDeleteUpdate = [];
                      Object.values(action.permission).map((permissionDelete) => {
                          if (permissionDelete && permissionDelete.id)
                              idsDeleteUpdate.push(permissionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoPermissionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PERMISSION:
                    let datoPermissionDeleted = {};
                    if (Object.values(action.permission.entities.permissions).length > 0)
                        datoPermissionDeleted = Object.values(action.permission.entities.permissions)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoPermissionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PERMISSION:
             let idsCreate = [];
             Object.values(action.permission).map((permissionCreate) => {
                 if (permissionCreate && permissionCreate.id)
                     idsCreate.push(permissionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.permission,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PERMISSION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PERMISSION:
            let datoPermissionNuevo = {};
            if (Object.values(action.permission.entities.permissions).length > 0)
                datoPermissionNuevo = Object.values(action.permission.entities.permissions)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoPermissionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PERMISSION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PERMISSIONS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PERMISSIONS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PERMISSIONS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PERMISSION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_PERMISSION:
           let datoPermissionDelete = action.permission;
           let idsDelete = [];
           Object.values(action.permission).map((permissionDelete) => {
               if (permissionDelete && permissionDelete.id)
                   idsDelete.push(permissionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoPermissionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PERMISSION:
                  let datoPermissionDeleteCreate = action.permission;
                  let idsDeleteCreate = [];
                  Object.values(action.permission).map((permissionDelete) => {
                      if (permissionDelete && permissionDelete.id)
                          idsDeleteCreate.push(permissionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoPermissionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PERMISSION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.permission,
                error: null,
            });
        case REQUEST_DELETE_PERMISSION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PERMISSION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PERMISSION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PERMISSION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PERMISSION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PERMISSION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PERMISSION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const permissions = combineReducers({
    byId: permissionsById,
    allIds: allPermissions,
    update: update,
    create: create,
    totalPermissions: totalPermissions,
    delete: deleter,
    print: print
});

export default permissions;