//api
import languagesApi from "../api/languagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//LANGUAGES
export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';
export const INVALIDATE_LANGUAGES = 'INVALIDATE_LANGUAGES';
export const ERROR_LANGUAGES = "ERROR_LANGUAGES";
export const RESET_LANGUAGES = "RESET_LANGUAGES";


export function invalidateLanguages() {
    return {
        type: INVALIDATE_LANGUAGES
    }
}

function requestLanguages() {
    return {
        type: REQUEST_LANGUAGES,
    }
}

function receiveLanguages(json) {
    return {
        type: RECEIVE_LANGUAGES,
    languages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorLanguages(error) {
    return {
        type: ERROR_LANGUAGES,
        error: error,
    }
}

export function resetLanguages() {
    return {
        type: RESET_LANGUAGES
    }
}

export function fetchLanguages(filtros) {
    return dispatch => {
        dispatch(requestLanguages());
        return languagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLanguages(state) {
    const languages = state.languages.byId;
    if (!languages) {
        return true
    } else if (languages.isFetching) {
        return false
    } else {
        return languages.didInvalidate;
    }
}

export function fetchLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchLanguages(getState())) {
            return dispatch(fetchLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_LANGUAGE = 'REQUEST_LANGUAGE';
export const RECEIVE_LANGUAGE = 'RECEIVE_LANGUAGE';
export const INVALIDATE_LANGUAGE = 'INVALIDATE_LANGUAGE';
export const ERROR_LANGUAGE = "ERROR_LANGUAGE";
export const RESET_LANGUAGE = "RESET_LANGUAGE";


export function invalidateLanguage() {
    return {
        type: INVALIDATE_LANGUAGE
    }
}

function requestLanguage() {
    return {
        type: REQUEST_LANGUAGE,
    }
}

export function receiveLanguage(json) {
    return {
        type: RECEIVE_LANGUAGE,
    language: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorLanguage(error) {
    return {
        type: ERROR_LANGUAGE,
        error: error,
    }
}

export function fetchLanguage(idLanguage) {
    return dispatch => {
        dispatch(requestLanguage());
        return languagesApi.getOne(idLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_LANGUAGE = 'RECEIVE_FILE_LANGUAGE';

function receiveFileLanguage(file) {
    return {
        type: RECEIVE_FILE_LANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileLanguage(idLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return languagesApi.getFile(idLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const REQUEST_UPDATE_LANGUAGE = "REQUEST_UPDATE_LANGUAGE";
export const SUCCESS_UPDATE_LANGUAGE = "SUCCESS_UPDATE_LANGUAGE";
export const ERROR_UPDATE_LANGUAGE = "ERROR_UPDATE_LANGUAGE";
export const RESET_UPDATE_LANGUAGE = "RESET_UPDATE_LANGUAGE";
export const DELETE_UPDATE_LANGUAGE = "DELETE_UPDATE_LANGUAGE";

function requestUpdateLanguage() {
    return {
        type: REQUEST_UPDATE_LANGUAGE,
    }
}

function receiveUpdateLanguage(language) {
    return {
        type: SUCCESS_UPDATE_LANGUAGE,
        receivedAt: Date.now(),
        language: normalizeDato(language)
    }
}

function errorUpdateLanguage(error) {
    return {
        type: ERROR_UPDATE_LANGUAGE,
        error: error,
    }
}

export function resetUpdateLanguage() {
    return {
        type: RESET_UPDATE_LANGUAGE,
    }
}

export function updateLanguage(language) {
    return {
        type: UPDATE_LANGUAGE,
        language
    }
}

export function saveUpdateLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let language = denormalizeDato(getState().languages.update.activo, store);

        return languagesApi.saveUpdate(language)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateLanguage(language) {
    return {
        type: DELETE_UPDATE_LANGUAGE,
        language
    }
}

//UPDATE LANGUAGES
export const REQUEST_UPDATE_LANGUAGES = "REQUEST_UPDATE_LANGUAGES";
export const SUCCESS_UPDATE_LANGUAGES = "SUCCESS_UPDATE_LANGUAGES";
export const ERROR_UPDATE_LANGUAGES = "ERROR_UPDATE_LANGUAGES";
export const RESET_UPDATE_LANGUAGES = "RESET_UPDATE_LANGUAGES";

function requestUpdateLanguages() {
    return {
        type: REQUEST_UPDATE_LANGUAGES,
}
}

function receiveUpdateLanguages(languages) {
    return {
        type: SUCCESS_UPDATE_LANGUAGES,
    receivedAt: Date.now(),
        languages: normalizeDatos(languages)
}
}

function errorUpdateLanguages(error) {
    return {
        type: ERROR_UPDATE_LANGUAGES,
    error: error,
}
}

export function resetUpdateLanguages() {
    return {
        type: RESET_UPDATE_LANGUAGES,
}
}

export function saveUpdateLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let languages = getState().languages.update.activos.map((idLanguage) => {
            return denormalizeDato(getState().languages.update.activo[idLanguage], store);
        });

        return languagesApi.saveUpdateLanguages(languages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA LANGUAGE
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const REQUEST_CREATE_LANGUAGE = "REQUEST_CREATE_LANGUAGE";
export const SUCCESS_CREATE_LANGUAGE = "SUCCESS_CREATE_LANGUAGE";
export const ERROR_CREATE_LANGUAGE = "ERROR_CREATE_LANGUAGE";
export const RESET_CREATE_LANGUAGE = "RESET_CREATE_LANGUAGE";
export const DELETE_CREATE_LANGUAGE = "DELETE_CREATE_LANGUAGE";


//ALTA LANGUAGE
function requestCreateLanguage() {
    return {
        type: REQUEST_CREATE_LANGUAGE,
    }
}

function receiveCreateLanguage(language) {
    return {
        type: SUCCESS_CREATE_LANGUAGE,
        receivedAt: Date.now(),
        language: normalizeDato(language)
    }
}

export function errorCreateLanguage(error) {
    return {
        type: ERROR_CREATE_LANGUAGE,
        error: error,
    }
}

export function resetCreateLanguage() {
    return {
        type: RESET_CREATE_LANGUAGE
    }
}

export function createLanguage(language) {
    return {
        type: CREATE_LANGUAGE,
        language
    }
}

export function deleteCreateLanguage(language) {
    return {
        type: DELETE_CREATE_LANGUAGE,
        language
    }
}

export function saveCreateLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let language = denormalizeDato(getState().languages.create.nuevo, store);

        return languagesApi.saveCreate(language)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE LANGUAGES
export const REQUEST_CREATE_LANGUAGES = "REQUEST_CREATE_LANGUAGES";
export const SUCCESS_CREATE_LANGUAGES = "SUCCESS_CREATE_LANGUAGES";
export const ERROR_CREATE_LANGUAGES = "ERROR_CREATE_LANGUAGES";
export const RESET_CREATE_LANGUAGES = "RESET_CREATE_LANGUAGES";

function requestCreateLanguages() {
    return {
        type: REQUEST_CREATE_LANGUAGES,
}
}

function receiveCreateLanguages(languages) {
    return {
        type: SUCCESS_CREATE_LANGUAGES,
    receivedAt: Date.now(),
        languages: normalizeDatos(languages)
}
}

function errorCreateLanguages(error) {
    return {
        type: ERROR_CREATE_LANGUAGES,
    error: error,
}
}

export function resetCreateLanguages() {
    return {
        type: RESET_CREATE_LANGUAGES,
}
}

export function saveCreateLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let languages = getState().languages.create.nuevos.map((idLanguage) => {
            return denormalizeDato(getState().languages.create.nuevo[idLanguage], store);
        });

        return languagesApi.saveCreateLanguages(languages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE LANGUAGE
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const REQUEST_DELETE_LANGUAGE = "REQUEST_DELETE_LANGUAGE";
export const SUCCESS_DELETE_LANGUAGE = "SUCCESS_DELETE_LANGUAGE";
export const ERROR_DELETE_LANGUAGE = "ERROR_DELETE_LANGUAGE";
export const RESET_DELETE_LANGUAGE = "RESET_DELETE_LANGUAGE";

function requestDeleteLanguage() {
    return {
        type: REQUEST_DELETE_LANGUAGE,
    }
}

function receiveDeleteLanguage(language) {
    return {
        type: SUCCESS_DELETE_LANGUAGE,
        receivedAt: Date.now(),
        language: normalizeDato(language)
    }
}

function errorDeleteLanguage(error) {
    return {
        type: ERROR_DELETE_LANGUAGE,
        error: error,
    }
}

export function resetDeleteLanguage(error) {
    return {
        type: RESET_DELETE_LANGUAGE,
        error: error,
    }
}

export function deleteLanguage(language) {
    return {
        type: DELETE_LANGUAGE,
        language
    }
}

export function saveDeleteLanguage(language) {
    return dispatch => {
        dispatch(requestDeleteLanguage());
        return languagesApi.saveDelete(language)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteLanguage());
                            dispatch(receiveDeleteLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT LANGUAGE
export const REQUEST_PRINT_LANGUAGE = "REQUEST_PRINT_LANGUAGE";
export const SUCCESS_PRINT_LANGUAGE = "SUCCESS_PRINT_LANGUAGE";
export const ERROR_PRINT_LANGUAGE = "ERROR_PRINT_LANGUAGE";
export const RESET_PRINT_LANGUAGE = "RESET_PRINT_LANGUAGE";

function requestPrintLanguage() {
    return {
        type: REQUEST_PRINT_LANGUAGE,
    }
}

function receivePrintLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_LANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintLanguage(error) {
    return {
        type: ERROR_PRINT_LANGUAGE,
        error: error,
    }
}

export function resetPrintLanguage() {
    return {
        type: RESET_PRINT_LANGUAGE,
    }
}

export function printLanguage(idLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintLanguage());
        return languagesApi.printLanguage(idLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}