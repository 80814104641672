import {normalize, schema, denormalize} from 'normalizr';

const productLanguage = new schema.Entity('productsLanguages', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {productsLanguages: [productLanguage]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = productLanguage;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = productLanguage;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default productLanguage;
