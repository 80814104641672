//api
import assetsCountriesApi from "../api/assetsCountriesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAssetsCountries";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ASSETSCOUNTRIES
export const REQUEST_ASSETSCOUNTRIES = 'REQUEST_ASSETSCOUNTRIES';
export const RECEIVE_ASSETSCOUNTRIES = 'RECEIVE_ASSETSCOUNTRIES';
export const INVALIDATE_ASSETSCOUNTRIES = 'INVALIDATE_ASSETSCOUNTRIES';
export const ERROR_ASSETSCOUNTRIES = "ERROR_ASSETSCOUNTRIES";
export const RESET_ASSETSCOUNTRIES = "RESET_ASSETSCOUNTRIES";


export function invalidateAssetsCountries() {
    return {
        type: INVALIDATE_ASSETSCOUNTRIES
    }
}

function requestAssetsCountries() {
    return {
        type: REQUEST_ASSETSCOUNTRIES,
    }
}

function receiveAssetsCountries(json) {
    return {
        type: RECEIVE_ASSETSCOUNTRIES,
    assetsCountries: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAssetsCountries(error) {
    return {
        type: ERROR_ASSETSCOUNTRIES,
        error: error,
    }
}

export function resetAssetsCountries() {
    return {
        type: RESET_ASSETSCOUNTRIES
    }
}

export function fetchAssetsCountries(filtros) {
    return dispatch => {
        dispatch(requestAssetsCountries());
        return assetsCountriesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssetsCountries(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetsCountries(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAssetsCountries(state) {
    const assetsCountries = state.assetsCountries.byId;
    if (!assetsCountries) {
        return true
    } else if (assetsCountries.isFetching) {
        return false
    } else {
        return assetsCountries.didInvalidate;
    }
}

export function fetchAssetsCountriesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAssetsCountries(getState())) {
            return dispatch(fetchAssetsCountries(filtros))
        }
    }
}


//MODEL
export const REQUEST_ASSETCOUNTRY = 'REQUEST_ASSETCOUNTRY';
export const RECEIVE_ASSETCOUNTRY = 'RECEIVE_ASSETCOUNTRY';
export const INVALIDATE_ASSETCOUNTRY = 'INVALIDATE_ASSETCOUNTRY';
export const ERROR_ASSETCOUNTRY = "ERROR_ASSETCOUNTRY";
export const RESET_ASSETCOUNTRY = "RESET_ASSETCOUNTRY";


export function invalidateAssetCountry() {
    return {
        type: INVALIDATE_ASSETCOUNTRY
    }
}

function requestAssetCountry() {
    return {
        type: REQUEST_ASSETCOUNTRY,
    }
}

export function receiveAssetCountry(json) {
    return {
        type: RECEIVE_ASSETCOUNTRY,
    assetCountry: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAssetCountry(error) {
    return {
        type: ERROR_ASSETCOUNTRY,
        error: error,
    }
}

export function fetchAssetCountry(idAssetCountry) {
    return dispatch => {
        dispatch(requestAssetCountry());
        return assetsCountriesApi.getOne(idAssetCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssetCountry(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ASSETCOUNTRY = 'RECEIVE_FILE_ASSETCOUNTRY';

function receiveFileAssetCountry(file) {
    return {
        type: RECEIVE_FILE_ASSETCOUNTRY,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAssetCountry(idAssetCountry, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return assetsCountriesApi.getFile(idAssetCountry, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAssetCountry(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ASSETCOUNTRY = 'UPDATE_ASSETCOUNTRY';
export const REQUEST_UPDATE_ASSETCOUNTRY = "REQUEST_UPDATE_ASSETCOUNTRY";
export const SUCCESS_UPDATE_ASSETCOUNTRY = "SUCCESS_UPDATE_ASSETCOUNTRY";
export const ERROR_UPDATE_ASSETCOUNTRY = "ERROR_UPDATE_ASSETCOUNTRY";
export const RESET_UPDATE_ASSETCOUNTRY = "RESET_UPDATE_ASSETCOUNTRY";
export const DELETE_UPDATE_ASSETCOUNTRY = "DELETE_UPDATE_ASSETCOUNTRY";

function requestUpdateAssetCountry() {
    return {
        type: REQUEST_UPDATE_ASSETCOUNTRY,
    }
}

function receiveUpdateAssetCountry(assetCountry) {
    return {
        type: SUCCESS_UPDATE_ASSETCOUNTRY,
        receivedAt: Date.now(),
        assetCountry: normalizeDato(assetCountry)
    }
}

function errorUpdateAssetCountry(error) {
    return {
        type: ERROR_UPDATE_ASSETCOUNTRY,
        error: error,
    }
}

export function resetUpdateAssetCountry() {
    return {
        type: RESET_UPDATE_ASSETCOUNTRY,
    }
}

export function updateAssetCountry(assetCountry) {
    return {
        type: UPDATE_ASSETCOUNTRY,
        assetCountry
    }
}

export function saveUpdateAssetCountry() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssetCountry());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetCountry = denormalizeDato(getState().assetsCountries.update.activo, store);

        return assetsCountriesApi.saveUpdate(assetCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssetCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAssetsCountries(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAssetsCountries(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAssetCountry(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAssetCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAssetCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAssetCountry(assetCountry) {
    return {
        type: DELETE_UPDATE_ASSETCOUNTRY,
        assetCountry
    }
}

//UPDATE ASSETSCOUNTRIES
export const REQUEST_UPDATE_ASSETSCOUNTRIES = "REQUEST_UPDATE_ASSETSCOUNTRIES";
export const SUCCESS_UPDATE_ASSETSCOUNTRIES = "SUCCESS_UPDATE_ASSETSCOUNTRIES";
export const ERROR_UPDATE_ASSETSCOUNTRIES = "ERROR_UPDATE_ASSETSCOUNTRIES";
export const RESET_UPDATE_ASSETSCOUNTRIES = "RESET_UPDATE_ASSETSCOUNTRIES";

function requestUpdateAssetsCountries() {
    return {
        type: REQUEST_UPDATE_ASSETSCOUNTRIES,
}
}

function receiveUpdateAssetsCountries(assetsCountries) {
    return {
        type: SUCCESS_UPDATE_ASSETSCOUNTRIES,
    receivedAt: Date.now(),
        assetsCountries: normalizeDatos(assetsCountries)
}
}

function errorUpdateAssetsCountries(error) {
    return {
        type: ERROR_UPDATE_ASSETSCOUNTRIES,
    error: error,
}
}

export function resetUpdateAssetsCountries() {
    return {
        type: RESET_UPDATE_ASSETSCOUNTRIES,
}
}

export function saveUpdateAssetsCountries() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssetsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetsCountries = getState().assetsCountries.update.activos.map((idAssetCountry) => {
            return denormalizeDato(getState().assetsCountries.update.activo[idAssetCountry], store);
        });

        return assetsCountriesApi.saveUpdateAssetsCountries(assetsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssetsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAssetsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAssetsCountries(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAssetsCountries(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAssetsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAssetsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ASSETCOUNTRY
export const CREATE_ASSETCOUNTRY = 'CREATE_ASSETCOUNTRY';
export const REQUEST_CREATE_ASSETCOUNTRY = "REQUEST_CREATE_ASSETCOUNTRY";
export const SUCCESS_CREATE_ASSETCOUNTRY = "SUCCESS_CREATE_ASSETCOUNTRY";
export const ERROR_CREATE_ASSETCOUNTRY = "ERROR_CREATE_ASSETCOUNTRY";
export const RESET_CREATE_ASSETCOUNTRY = "RESET_CREATE_ASSETCOUNTRY";
export const DELETE_CREATE_ASSETCOUNTRY = "DELETE_CREATE_ASSETCOUNTRY";


//ALTA ASSETCOUNTRY
function requestCreateAssetCountry() {
    return {
        type: REQUEST_CREATE_ASSETCOUNTRY,
    }
}

function receiveCreateAssetCountry(assetCountry) {
    return {
        type: SUCCESS_CREATE_ASSETCOUNTRY,
        receivedAt: Date.now(),
        assetCountry: normalizeDato(assetCountry)
    }
}

export function errorCreateAssetCountry(error) {
    return {
        type: ERROR_CREATE_ASSETCOUNTRY,
        error: error,
    }
}

export function resetCreateAssetCountry() {
    return {
        type: RESET_CREATE_ASSETCOUNTRY
    }
}

export function createAssetCountry(assetCountry) {
    return {
        type: CREATE_ASSETCOUNTRY,
        assetCountry
    }
}

export function deleteCreateAssetCountry(assetCountry) {
    return {
        type: DELETE_CREATE_ASSETCOUNTRY,
        assetCountry
    }
}

export function saveCreateAssetCountry() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssetCountry());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetCountry = denormalizeDato(getState().assetsCountries.create.nuevo, store);

        return assetsCountriesApi.saveCreate(assetCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssetCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAssetsCountries(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAssetsCountries(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAssetCountry(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAssetCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAssetCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ASSETSCOUNTRIES
export const REQUEST_CREATE_ASSETSCOUNTRIES = "REQUEST_CREATE_ASSETSCOUNTRIES";
export const SUCCESS_CREATE_ASSETSCOUNTRIES = "SUCCESS_CREATE_ASSETSCOUNTRIES";
export const ERROR_CREATE_ASSETSCOUNTRIES = "ERROR_CREATE_ASSETSCOUNTRIES";
export const RESET_CREATE_ASSETSCOUNTRIES = "RESET_CREATE_ASSETSCOUNTRIES";

function requestCreateAssetsCountries() {
    return {
        type: REQUEST_CREATE_ASSETSCOUNTRIES,
}
}

function receiveCreateAssetsCountries(assetsCountries) {
    return {
        type: SUCCESS_CREATE_ASSETSCOUNTRIES,
    receivedAt: Date.now(),
        assetsCountries: normalizeDatos(assetsCountries)
}
}

function errorCreateAssetsCountries(error) {
    return {
        type: ERROR_CREATE_ASSETSCOUNTRIES,
    error: error,
}
}

export function resetCreateAssetsCountries() {
    return {
        type: RESET_CREATE_ASSETSCOUNTRIES,
}
}

export function saveCreateAssetsCountries() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssetsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetsCountries = getState().assetsCountries.create.nuevos.map((idAssetCountry) => {
            return denormalizeDato(getState().assetsCountries.create.nuevo[idAssetCountry], store);
        });

        return assetsCountriesApi.saveCreateAssetsCountries(assetsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssetsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAssetsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAssetsCountries(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAssetsCountries(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAssetsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAssetsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ASSETCOUNTRY
export const DELETE_ASSETCOUNTRY = 'DELETE_ASSETCOUNTRY';
export const REQUEST_DELETE_ASSETCOUNTRY = "REQUEST_DELETE_ASSETCOUNTRY";
export const SUCCESS_DELETE_ASSETCOUNTRY = "SUCCESS_DELETE_ASSETCOUNTRY";
export const ERROR_DELETE_ASSETCOUNTRY = "ERROR_DELETE_ASSETCOUNTRY";
export const RESET_DELETE_ASSETCOUNTRY = "RESET_DELETE_ASSETCOUNTRY";

function requestDeleteAssetCountry() {
    return {
        type: REQUEST_DELETE_ASSETCOUNTRY,
    }
}

function receiveDeleteAssetCountry(assetCountry) {
    return {
        type: SUCCESS_DELETE_ASSETCOUNTRY,
        receivedAt: Date.now(),
        assetCountry: normalizeDato(assetCountry)
    }
}

function errorDeleteAssetCountry(error) {
    return {
        type: ERROR_DELETE_ASSETCOUNTRY,
        error: error,
    }
}

export function resetDeleteAssetCountry(error) {
    return {
        type: RESET_DELETE_ASSETCOUNTRY,
        error: error,
    }
}

export function deleteAssetCountry(assetCountry) {
    return {
        type: DELETE_ASSETCOUNTRY,
        assetCountry
    }
}

export function saveDeleteAssetCountry(assetCountry) {
    return dispatch => {
        dispatch(requestDeleteAssetCountry());
        return assetsCountriesApi.saveDelete(assetCountry)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAssetCountry());
                            dispatch(receiveDeleteAssetCountry(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAssetCountry(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAssetCountry(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAssetCountry(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAssetCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ASSETCOUNTRY
export const REQUEST_PRINT_ASSETCOUNTRY = "REQUEST_PRINT_ASSETCOUNTRY";
export const SUCCESS_PRINT_ASSETCOUNTRY = "SUCCESS_PRINT_ASSETCOUNTRY";
export const ERROR_PRINT_ASSETCOUNTRY = "ERROR_PRINT_ASSETCOUNTRY";
export const RESET_PRINT_ASSETCOUNTRY = "RESET_PRINT_ASSETCOUNTRY";

function requestPrintAssetCountry() {
    return {
        type: REQUEST_PRINT_ASSETCOUNTRY,
    }
}

function receivePrintAssetCountry(turnos) {
    return {
        type: SUCCESS_PRINT_ASSETCOUNTRY,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAssetCountry(error) {
    return {
        type: ERROR_PRINT_ASSETCOUNTRY,
        error: error,
    }
}

export function resetPrintAssetCountry() {
    return {
        type: RESET_PRINT_ASSETCOUNTRY,
    }
}

export function printAssetCountry(idAssetCountry) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAssetCountry());
        return assetsCountriesApi.printAssetCountry(idAssetCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAssetCountry(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAssetCountry(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAssetCountry(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAssetCountry(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAssetCountry(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}