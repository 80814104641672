import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, TextField, Typography} from '@material-ui/core';
import HTMLEditor from "../../library/HTMLEditor";
//Icons
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
}));


export default function ProductLanguage(props) {
    const classes = styles();
    const {changeProductLanguage, language, productLanguage, handleChangeProductLanguage} = props;
    //Hooks
    //State

    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['clean']
        ],
    };

    let formats = [
        'bold', 'italic', 'underline', 'strike',
    ];

    const handleChangeTextEditorProductLanguage = (content, delta, source, editor, idField, idProduct, idLanguage) => {
        if (source === 'user') {
            let id = idProduct ? idProduct : Date.now() + Math.random() + "-";
            let cambio = {};
            cambio[idField] = content;
            changeProductLanguage(cambio, id, idLanguage);
        }
    }

    return (
        <Box component={"div"} className={classes.form}>
            <Typography variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <Box component={"div"} marginTop={"10px"}>
                <TextField
                    id="nameProduct"
                    placeholder="Nombre de producto"
                    variant="outlined"
                    label={"Nombre de producto"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        // disabled: disabled
                    }}
                    fullWidth
                    value={productLanguage ? productLanguage.nameProduct : ""}
                    onChange={(e) => handleChangeProductLanguage(e, productLanguage ? productLanguage.id : null,language ? language.id : null)}
                    // onKeyDown={(e) => handleKeyDown(e)}
                />
                <HTMLEditor modules={modules} formats={formats} textLabel={"Descripción corta"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorProductLanguage(content, delta, source, editor, "shortDesProduct", productLanguage ? productLanguage.id : null,language ? language.id : null)}
                            value={productLanguage ? productLanguage.shortDesProduct : ""} rows={"50px"}/>
                <HTMLEditor modules={modules} formats={formats} textLabel={"Descripción larga"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorProductLanguage(content, delta, source, editor, "desProduct", productLanguage ? productLanguage.id : null,language ? language.id : null)}
                            value={productLanguage ? productLanguage.desProduct : ""} rows={"200px"}/>
            </Box>
        </Box>
    );
}

ProductLanguage.propTypes = {}