//api
import pagesApi from "../api/pagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizePages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PAGES
export const REQUEST_PAGES = 'REQUEST_PAGES';
export const RECEIVE_PAGES = 'RECEIVE_PAGES';
export const INVALIDATE_PAGES = 'INVALIDATE_PAGES';
export const ERROR_PAGES = "ERROR_PAGES";
export const RESET_PAGES = "RESET_PAGES";


export function invalidatePages() {
    return {
        type: INVALIDATE_PAGES
    }
}

function requestPages() {
    return {
        type: REQUEST_PAGES,
    }
}

function receivePages(json) {
    return {
        type: RECEIVE_PAGES,
    pages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPages(error) {
    return {
        type: ERROR_PAGES,
        error: error,
    }
}

export function resetPages() {
    return {
        type: RESET_PAGES
    }
}

export function fetchPages(filtros) {
    return dispatch => {
        dispatch(requestPages());
        return pagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPages(state) {
    const pages = state.pages.byId;
    if (!pages) {
        return true
    } else if (pages.isFetching) {
        return false
    } else {
        return pages.didInvalidate;
    }
}

export function fetchPagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPages(getState())) {
            return dispatch(fetchPages(filtros))
        }
    }
}


//MODEL
export const REQUEST_PAGE = 'REQUEST_PAGE';
export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const INVALIDATE_PAGE = 'INVALIDATE_PAGE';
export const ERROR_PAGE = "ERROR_PAGE";
export const RESET_PAGE = "RESET_PAGE";


export function invalidatePage() {
    return {
        type: INVALIDATE_PAGE
    }
}

function requestPage() {
    return {
        type: REQUEST_PAGE,
    }
}

export function receivePage(json) {
    return {
        type: RECEIVE_PAGE,
    page: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPage(error) {
    return {
        type: ERROR_PAGE,
        error: error,
    }
}

export function fetchPage(idPage) {
    return dispatch => {
        dispatch(requestPage());
        return pagesApi.getOne(idPage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PAGE = 'RECEIVE_FILE_PAGE';

function receiveFilePage(file) {
    return {
        type: RECEIVE_FILE_PAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFilePage(idPage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return pagesApi.getFile(idPage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFilePage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const REQUEST_UPDATE_PAGE = "REQUEST_UPDATE_PAGE";
export const SUCCESS_UPDATE_PAGE = "SUCCESS_UPDATE_PAGE";
export const ERROR_UPDATE_PAGE = "ERROR_UPDATE_PAGE";
export const RESET_UPDATE_PAGE = "RESET_UPDATE_PAGE";
export const DELETE_UPDATE_PAGE = "DELETE_UPDATE_PAGE";

function requestUpdatePage() {
    return {
        type: REQUEST_UPDATE_PAGE,
    }
}

function receiveUpdatePage(page) {
    return {
        type: SUCCESS_UPDATE_PAGE,
        receivedAt: Date.now(),
        page: normalizeDato(page)
    }
}

function errorUpdatePage(error) {
    return {
        type: ERROR_UPDATE_PAGE,
        error: error,
    }
}

export function resetUpdatePage() {
    return {
        type: RESET_UPDATE_PAGE,
    }
}

export function updatePage(page) {
    return {
        type: UPDATE_PAGE,
        page
    }
}

export function saveUpdatePage() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let page = denormalizeDato(getState().pages.update.activo, store);

        return pagesApi.saveUpdate(page)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdatePage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdatePages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdatePages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdatePage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdatePage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdatePage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdatePage(page) {
    return {
        type: DELETE_UPDATE_PAGE,
        page
    }
}

//UPDATE PAGES
export const REQUEST_UPDATE_PAGES = "REQUEST_UPDATE_PAGES";
export const SUCCESS_UPDATE_PAGES = "SUCCESS_UPDATE_PAGES";
export const ERROR_UPDATE_PAGES = "ERROR_UPDATE_PAGES";
export const RESET_UPDATE_PAGES = "RESET_UPDATE_PAGES";

function requestUpdatePages() {
    return {
        type: REQUEST_UPDATE_PAGES,
}
}

function receiveUpdatePages(pages) {
    return {
        type: SUCCESS_UPDATE_PAGES,
    receivedAt: Date.now(),
        pages: normalizeDatos(pages)
}
}

function errorUpdatePages(error) {
    return {
        type: ERROR_UPDATE_PAGES,
    error: error,
}
}

export function resetUpdatePages() {
    return {
        type: RESET_UPDATE_PAGES,
}
}

export function saveUpdatePages() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let pages = getState().pages.update.activos.map((idPage) => {
            return denormalizeDato(getState().pages.update.activo[idPage], store);
        });

        return pagesApi.saveUpdatePages(pages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdatePages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdatePages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdatePages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdatePages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PAGE
export const CREATE_PAGE = 'CREATE_PAGE';
export const REQUEST_CREATE_PAGE = "REQUEST_CREATE_PAGE";
export const SUCCESS_CREATE_PAGE = "SUCCESS_CREATE_PAGE";
export const ERROR_CREATE_PAGE = "ERROR_CREATE_PAGE";
export const RESET_CREATE_PAGE = "RESET_CREATE_PAGE";
export const DELETE_CREATE_PAGE = "DELETE_CREATE_PAGE";


//ALTA PAGE
function requestCreatePage() {
    return {
        type: REQUEST_CREATE_PAGE,
    }
}

function receiveCreatePage(page) {
    return {
        type: SUCCESS_CREATE_PAGE,
        receivedAt: Date.now(),
        page: normalizeDato(page)
    }
}

export function errorCreatePage(error) {
    return {
        type: ERROR_CREATE_PAGE,
        error: error,
    }
}

export function resetCreatePage() {
    return {
        type: RESET_CREATE_PAGE
    }
}

export function createPage(page) {
    return {
        type: CREATE_PAGE,
        page
    }
}

export function deleteCreatePage(page) {
    return {
        type: DELETE_CREATE_PAGE,
        page
    }
}

export function saveCreatePage() {
    return (dispatch, getState) => {
        dispatch(requestCreatePage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let page = denormalizeDato(getState().pages.create.nuevo, store);

        return pagesApi.saveCreate(page)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreatePage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreatePages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreatePages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreatePage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreatePage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreatePage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PAGES
export const REQUEST_CREATE_PAGES = "REQUEST_CREATE_PAGES";
export const SUCCESS_CREATE_PAGES = "SUCCESS_CREATE_PAGES";
export const ERROR_CREATE_PAGES = "ERROR_CREATE_PAGES";
export const RESET_CREATE_PAGES = "RESET_CREATE_PAGES";

function requestCreatePages() {
    return {
        type: REQUEST_CREATE_PAGES,
}
}

function receiveCreatePages(pages) {
    return {
        type: SUCCESS_CREATE_PAGES,
    receivedAt: Date.now(),
        pages: normalizeDatos(pages)
}
}

function errorCreatePages(error) {
    return {
        type: ERROR_CREATE_PAGES,
    error: error,
}
}

export function resetCreatePages() {
    return {
        type: RESET_CREATE_PAGES,
}
}

export function saveCreatePages() {
    return (dispatch, getState) => {
        dispatch(requestCreatePages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let pages = getState().pages.create.nuevos.map((idPage) => {
            return denormalizeDato(getState().pages.create.nuevo[idPage], store);
        });

        return pagesApi.saveCreatePages(pages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreatePages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreatePages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreatePages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreatePages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PAGE
export const DELETE_PAGE = 'DELETE_PAGE';
export const REQUEST_DELETE_PAGE = "REQUEST_DELETE_PAGE";
export const SUCCESS_DELETE_PAGE = "SUCCESS_DELETE_PAGE";
export const ERROR_DELETE_PAGE = "ERROR_DELETE_PAGE";
export const RESET_DELETE_PAGE = "RESET_DELETE_PAGE";

function requestDeletePage() {
    return {
        type: REQUEST_DELETE_PAGE,
    }
}

function receiveDeletePage(page) {
    return {
        type: SUCCESS_DELETE_PAGE,
        receivedAt: Date.now(),
        page: normalizeDato(page)
    }
}

function errorDeletePage(error) {
    return {
        type: ERROR_DELETE_PAGE,
        error: error,
    }
}

export function resetDeletePage(error) {
    return {
        type: RESET_DELETE_PAGE,
        error: error,
    }
}

export function deletePage(page) {
    return {
        type: DELETE_PAGE,
        page
    }
}

export function saveDeletePage(page) {
    return dispatch => {
        dispatch(requestDeletePage());
        return pagesApi.saveDelete(page)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeletePage());
                            dispatch(receiveDeletePage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeletePage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeletePage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeletePage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeletePage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeletePage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PAGE
export const REQUEST_PRINT_PAGE = "REQUEST_PRINT_PAGE";
export const SUCCESS_PRINT_PAGE = "SUCCESS_PRINT_PAGE";
export const ERROR_PRINT_PAGE = "ERROR_PRINT_PAGE";
export const RESET_PRINT_PAGE = "RESET_PRINT_PAGE";

function requestPrintPage() {
    return {
        type: REQUEST_PRINT_PAGE,
    }
}

function receivePrintPage(turnos) {
    return {
        type: SUCCESS_PRINT_PAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintPage(error) {
    return {
        type: ERROR_PRINT_PAGE,
        error: error,
    }
}

export function resetPrintPage() {
    return {
        type: RESET_PRINT_PAGE,
    }
}

export function printPage(idPage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintPage());
        return pagesApi.printPage(idPage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintPage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintPage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintPage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintPage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintPage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}