import {
    INVALIDATE_MENUS,
    ERROR_MENUS,
    RECEIVE_MENUS,
    REQUEST_MENUS,
    RESET_MENUS,
    ERROR_MENU,
    RECEIVE_MENU,
    REQUEST_MENU,
    UPDATE_MENU,
    REQUEST_UPDATE_MENU,
    SUCCESS_UPDATE_MENU,
    ERROR_UPDATE_MENU,
    RESET_UPDATE_MENU,
    REQUEST_UPDATE_MENUS,
    SUCCESS_UPDATE_MENUS,
    ERROR_UPDATE_MENUS,
    RESET_UPDATE_MENUS,
    CREATE_MENU,
    ERROR_CREATE_MENU,
    REQUEST_CREATE_MENU,
    RESET_CREATE_MENU,
    SUCCESS_CREATE_MENU,
    REQUEST_CREATE_MENUS,
    SUCCESS_CREATE_MENUS,
    ERROR_CREATE_MENUS,
    RESET_CREATE_MENUS,
    DELETE_MENU,
    DELETE_CREATE_MENU,
    DELETE_UPDATE_MENU,
    REQUEST_DELETE_MENU,
    SUCCESS_DELETE_MENU,
    ERROR_DELETE_MENU,
    RESET_DELETE_MENU,
    REQUEST_PRINT_MENU,
    SUCCESS_PRINT_MENU,
    ERROR_PRINT_MENU,
    RESET_PRINT_MENU,
    RECEIVE_FILE_MENU
} from '../actions/MenuActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        menus: {},
        files: {},
    }
}

function menusById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_MENUS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_MENUS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_MENUS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_MENUS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                menus: {}
            });
        case RECEIVE_MENUS:
            let dato = action.menus.entities.menus;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                menus: merge({}, state.menus, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_MENU:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_MENU:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_MENU:
            let datoMenu = action.menu.entities.menus;
            return Object.assign({}, state, {
                menus: merge({}, state.menus, datoMenu),
                isFetching: false,
            });
        case RECEIVE_FILE_MENU:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_MENU:
            let datoMenuEliminado = action.menu.entities.menus;
            return Object.assign({}, state, {
                menus: mergeWith(clone(datoMenuEliminado), state.menus, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_MENU:
            let datoMenuCreado = action.menu.entities.menus;
            return Object.assign({}, state, {
                menus: mergeWith(clone(datoMenuCreado), state.menus, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_MENUS:
            let datosMenuCreado = action.menus.entities.menus;
                return Object.assign({}, state, {
                    menus: mergeWith(clone(datosMenuCreado), state.menus, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_MENU:
            let datoMenuActualizado = action.menu.entities.menus;
            return Object.assign({}, state, {
                menus: mergeWith(clone(datoMenuActualizado), state.menus, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_MENUS:
            let datosMenuActualizado = action.menus.entities.menus;
                return Object.assign({}, state, {
                    menus: mergeWith(clone(datosMenuActualizado), state.menus, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                menus: {}
            });
        default:
            return state
    }
}


function allMenus(state = [], action) {
    switch (action.type) {
        case RECEIVE_MENUS:
            return action.menus.result && action.menus.result.menus ? union(action.menus.result.menus, state) : (action.menus.result ? action.menus.result : state) ;
        case RECEIVE_MENU:
                return action.menu.result ? union([action.menu.result], state) : state;
        
        case SUCCESS_CREATE_MENU:
                   let datoMenuSCreate = action.menu.entities.menus;
                   let idNuevoSCreate = null;
                   if (Object.values(datoMenuSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoMenuSCreate)[0] && Object.values(datoMenuSCreate)[0].id ? Object.values(datoMenuSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_MENUS:
                   let menusCreate = action.menus.entities && action.menus.entities.menus ? action.menus.entities.menus : null;
                   return menusCreate ?
                       union(state, Object.values(menusCreate).map((menus) => {
                           return menus.id
                       })) : state;
        case RESET_MENUS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalMenus(state = null, action) {
    switch (action.type) {
        case RECEIVE_MENUS:
            return action.menus && action.menus.result.total ? action.menus.result.total : 0;
        case RESET_MENUS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_MENU:
            let dato = action.menu.entities.menus;
            let menu = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: menu ? menu : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_MENU:
            let idsUpdate = [];
            Object.values(action.menu).map((menuUpdate) => {
                if (menuUpdate && menuUpdate.id)
                    idsUpdate.push(menuUpdate.id);
            });
            return merge({}, state, {
                activo: action.menu,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_MENU:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_MENU:
            let datoMenuActualizado = {};
            if (Object.values(action.menu.entities.menus).length > 0)
                datoMenuActualizado = Object.values(action.menu.entities.menus)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoMenuActualizado
            });
        case ERROR_UPDATE_MENU:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_MENUS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_MENUS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_MENUS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_MENU:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_MENU:
            let datoMenuDelete = action.menu;
            let idsDelete = [];
           Object.values(action.menu).map((menuDelete) => {
               if (menuDelete && menuDelete.id)
                   idsDelete.push(menuDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoMenuDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_MENU:
                       let datoMenuDeleteUpdate = action.menu;
                       let idsDeleteUpdate = [];
                      Object.values(action.menu).map((menuDelete) => {
                          if (menuDelete && menuDelete.id)
                              idsDeleteUpdate.push(menuDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoMenuDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_MENU:
                    let datoMenuDeleted = {};
                    if (Object.values(action.menu.entities.menus).length > 0)
                        datoMenuDeleted = Object.values(action.menu.entities.menus)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoMenuDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_MENU:
             let idsCreate = [];
             Object.values(action.menu).map((menuCreate) => {
                 if (menuCreate && menuCreate.id)
                     idsCreate.push(menuCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.menu,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_MENU:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_MENU:
            let datoMenuNuevo = {};
            if (Object.values(action.menu.entities.menus).length > 0)
                datoMenuNuevo = Object.values(action.menu.entities.menus)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoMenuNuevo,
                nuevos: []
            });
        case ERROR_CREATE_MENU:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_MENUS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_MENUS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_MENUS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_MENU:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_MENU:
           let datoMenuDelete = action.menu;
           let idsDelete = [];
           Object.values(action.menu).map((menuDelete) => {
               if (menuDelete && menuDelete.id)
                   idsDelete.push(menuDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoMenuDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_MENU:
                  let datoMenuDeleteCreate = action.menu;
                  let idsDeleteCreate = [];
                  Object.values(action.menu).map((menuDelete) => {
                      if (menuDelete && menuDelete.id)
                          idsDeleteCreate.push(menuDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoMenuDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_MENU:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.menu,
                error: null,
            });
        case REQUEST_DELETE_MENU:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_MENU:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_MENU:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_MENU:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_MENU:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_MENU:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_MENU:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const menus = combineReducers({
    byId: menusById,
    allIds: allMenus,
    update: update,
    create: create,
    totalMenus: totalMenus,
    delete: deleter,
    print: print
});

export default menus;