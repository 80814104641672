import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clone from "lodash/clone";
import union from "lodash/union";
import * as PropTypes from "prop-types";
//Components
import {Box, Button, CircularProgress, IconButton, Typography} from '@material-ui/core';
import clsx from "clsx";
//Icons
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
//Img
import bannerMobile from "../../../assets/img/bannerMobile.jpeg";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    dateForm: {
        padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "100px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        width: "100%",
        maxWidth: "200px",
        height: "120px",
        paddingLeft: "6px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        },
        "& video": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
            display: "none"
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        zIndex: 99999,
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));

export default function BannerType(props) {
    const classes = styles();
    let file1 = React.createRef();
    let file2 = React.createRef();
    let imgMobile = React.useRef();
    let imgDesktop = React.useRef();
    let videoMobile = React.useRef();
    let videoDesktop = React.useRef();
    const {
        language,
        assetLanguage,
        files,
        setFiles,
        handleChangeAssetLanguage,
        fileMobile,
        fileDesktop,
        previewImageVideoAssetLanguage,
        downloadFileAssetLanguage,
        changeAssetLanguage, disabled,
        deleteFile
    } = props;
    //Hooks
    //State
    const [loadingMobile, setLoadingMobile] = React.useState(false);
    const [loadingDesktop, setLoadingDesktop] = React.useState(false);

    useEffect(() => {
            if (assetLanguage && assetLanguage.filenameDesktop)
                setLoadingDesktop(true);
            if (assetLanguage && assetLanguage.filenameMobile)
                setLoadingMobile(true);
        }, []
    )

    //Hago la preview de las fotos cargadas del backend
    useEffect(() => {
            previewFile(fileMobile, "mobile");
            previewFile(fileDesktop, "desktop");
        }, [fileDesktop, fileMobile]
    )

    const renderBanner = (type, fileSubido, reader) => {
        if (imgMobile.current && videoMobile.current && imgDesktop.current && videoDesktop.current)
            if (type === 'mobile') {
                setLoadingMobile(false);
                if (!fileSubido) {
                    imgMobile.current.src = "";
                    videoMobile.current.src = "";
                } else if (fileSubido.type.indexOf("image") !== -1) {
                    imgMobile.current.src = reader.result;
                    videoMobile.current.style.display = 'none';
                    imgMobile.current.style.display = 'inherit';
                } else {
                    videoMobile.current.src = reader.result;
                    imgMobile.current.style.display = 'none';
                    videoMobile.current.style.display = 'inherit';
                }
            } else {
                setLoadingDesktop(false);
                if (!fileSubido) {
                    imgDesktop.current.src = "";
                    videoDesktop.current.src = "";
                } else if (fileSubido.type.indexOf("image") !== -1) {
                    imgDesktop.current.src = reader.result;
                    videoDesktop.current.style.display = 'none';
                    imgDesktop.current.style.display = 'inherit';
                } else {
                    videoDesktop.current.src = reader.result;
                    imgDesktop.current.style.display = 'none';
                    videoDesktop.current.style.display = 'inherit';
                }
            }
    }

    const handleChangeImageAssetLanguage = (e, type) => {
        e.preventDefault();
        //Preview
        let fotos = [];
        let file = type === 'mobile' ? file1 : file2;
        if (type === 'mobile')
            setLoadingMobile(true);
        else
            setLoadingDesktop(true);
        for (let i = 0; i < file.current.files.length; i++) {
            let filename = file.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(file.current.files[i], 'name', {
                writable: true,
                value: filename
            });
            fotos.push(file.current.files[i]);
        }
        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        for (let i = 0; i < file.current.files.length; i++) {
            let fileSubido = file.current.files[i];

            let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                renderBanner(type, fileSubido, reader);
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
            let id = assetLanguage ? assetLanguage.id : Date.now() + Math.random() + "-";
            let cambio = assetLanguage ? clone(assetLanguage) : {};
            let fileNameAnterior = null;
            if (type === 'mobile') {
                cambio.filenameMobile = filename;
            }
            else {
                cambio.filenameDesktop = filename;
            }
            changeAssetLanguage(cambio, id, language.id);
        }
    };

    const previewFile = (file, type) => {
        let reader = new FileReader();
        reader.onloadend = (e) => {
            renderBanner(type, file, reader);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else
            renderBanner(type, file, reader);
    }

    const handleDeleteFileAssetLanguage = (attr) => {
        let e = {};
        e.target = {}
        e.target.id = attr;
        e.target.value = null;
        handleChangeAssetLanguage(e, assetLanguage.id, language.id);
    }



    return (
        <Box component={"div"} className={classes.dateForm}>
            <Typography
                variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <Box component={"div"} marginTop={"10px"} display={"flex"}>
                <Box component={"div"} className={clsx([classes.fileInput, classes.mobile])}>
                    <Button className={classes.buttonUpload} disabled={disabled}>
                        <label htmlFor={"file" + language.id}
                               className={classes.labelButton}
                            // ref={instance => labelRef.current = instance}
                        >
                            <AddPhotoAlternateIcon color={"primary"}/>
                            <Typography variant={"caption"} color={"primary"}>
                                Mobile
                            </Typography>
                            <img id={"img" + language.id} ref={imgMobile} alt=""/>
                            <video id={"video" + language.id} ref={videoMobile} src={bannerMobile} alt=""/>
                            <CircularProgress className={classes.progress}
                                              style={{display: !loadingMobile ? "none" : "inherit"}}/>
                        </label>

                        <input ref={file1} type="file" name="file" id={"file" + language.id} className="inputfile"
                               accept="image/*, video/mp4"
                               onChange={(e) => handleChangeImageAssetLanguage(e, "mobile")}
                        />
                    </Button>
                    {
                        !!fileMobile &&
                        <Box display={"flex"} flexDirection={"row"} width={'130px'} alignItems={"center"}>

                            <IconButton edge="end" aria-label="delete" size={"small"}
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => handleDeleteFileAssetLanguage("filenameMobile")}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton edge="end" size={"small"}
                                        aria-label="descargar"
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => downloadFileAssetLanguage(fileMobile)}
                            >
                                <CloudDownloadIcon/>
                            </IconButton>
                            <IconButton edge="end" size={"small"}
                                        aria-label="visualizar"
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => previewImageVideoAssetLanguage(fileMobile)}
                            >
                                <VisibilityIcon/>
                            </IconButton>
                        </Box>
                    }
                </Box>
                <Box component={"div"} className={clsx([classes.fileInput, classes.desktop])}>
                    <Button className={classes.buttonUpload} disabled={disabled}>
                        <label htmlFor={"file2" + language.id}
                               className={classes.labelButton}
                            // ref={instance => labelRef.current = instance}
                        >
                            <AddPhotoAlternateIcon color={"primary"}/>
                            <Typography variant={"caption"} color={"primary"}>
                                Desktop
                            </Typography>
                            <img ref={imgDesktop} src={""} alt=""/>
                            <video id={"video" + language.id} ref={videoDesktop} src={bannerMobile} alt=""/>
                            <CircularProgress className={classes.progress}
                                              style={{display: !loadingDesktop ? "none" : "inherit"}}/>
                        </label>
                        <input ref={file2} type="file" name="file2" id={"file2" + language.id} className="inputfile"
                               accept="image/*, video/mp4"
                               onChange={(e) => handleChangeImageAssetLanguage(e, "desktop")}
                        />
                    </Button>
                    {
                        !!fileDesktop &&
                        <Box display={"flex"} flexDirection={"row"} width={'130px'} alignItems={"center"}>

                            <IconButton edge="end" aria-label="delete" size={"small"}
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => handleDeleteFileAssetLanguage("filenameDesktop")}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton edge="end" size={"small"}
                                        aria-label="descargar"
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => downloadFileAssetLanguage(fileDesktop)}
                            >
                                <CloudDownloadIcon/>
                            </IconButton>
                            <IconButton edge="end" size={"small"}
                                        aria-label="visualizar"
                                        disabled={assetLanguage && assetLanguage.status === 0}
                                        onClick={() => previewImageVideoAssetLanguage(fileDesktop)}
                            >
                                <VisibilityIcon/>
                            </IconButton>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

BannerType.propTypes = {}