import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//Actions
import {olvideMiPassword, resetLogin} from '../../actions/AuthActions';
import FormHelperText from "@material-ui/core/FormHelperText";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
//     '@global': {
//         body: {
//             backgroundColor: theme.palette.common.white,
//         },
//     },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

export default function SolicitudOlvideMiPassword(props) {
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const dispatch = useDispatch();
    let msj = useRef();
    let mounted = useRef();
    let form = useRef();


    //Store
    const auth = useSelector(state => state.auth);

    const loading = auth.isFetching;

    useEffect(() => {
        return function cleanup() {
            dispatch(resetLogin());
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!auth.isFetching && !auth.errorMessage) {
                msj.current.style.display = "inline";
                form.current.style.display = "none";
            }
        }
    }, [auth.isFetching]);


    async function submitForm(e) {
        e.preventDefault();
        msj.current.style.display = "none";
        await dispatch(olvideMiPassword(username));
    }

    let Errores = "";
    let error = auth.errorMessage;
    if (error != null && Object.keys(error).length > 0 && typeof error == "object") {
        Errores = Object.keys(error).map((nombre, index) => {
            let mensajeCompleto = "";
            if (error[nombre])
                error[nombre].forEach((mensaje) => {
                    mensajeCompleto += mensaje;
                });
            return <p key={nombre} style={{margin: "0px", color: "red"}}>{mensajeCompleto}</p>;
        });
    } else
        Errores = <p key={0} style={{margin: "0px", color: "red"}}>{error}</p>;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Recuperar contraseña
                </Typography>
                <br/>
                {Errores}
                <form ref={form} className={classes.form} noValidate onSubmit={(e) => submitForm(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Email"
                        name="username"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <div className={classes.wrapper}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Recuperar contraseña
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>
                </form>
                <FormHelperText ref={msj} style={{display: "none", color: "green"}}>Revise su casilla de email. Le
                    enviamos un email con los pasos a seguir.</FormHelperText>
            </div>
        </Container>
    );
}