//api
import attributesGroupsApi from "../api/attributesGroupsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAttributesGroups";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ATTRIBUTESGROUPS
export const REQUEST_ATTRIBUTESGROUPS = 'REQUEST_ATTRIBUTESGROUPS';
export const RECEIVE_ATTRIBUTESGROUPS = 'RECEIVE_ATTRIBUTESGROUPS';
export const INVALIDATE_ATTRIBUTESGROUPS = 'INVALIDATE_ATTRIBUTESGROUPS';
export const ERROR_ATTRIBUTESGROUPS = "ERROR_ATTRIBUTESGROUPS";
export const RESET_ATTRIBUTESGROUPS = "RESET_ATTRIBUTESGROUPS";


export function invalidateAttributesGroups() {
    return {
        type: INVALIDATE_ATTRIBUTESGROUPS
    }
}

function requestAttributesGroups() {
    return {
        type: REQUEST_ATTRIBUTESGROUPS,
    }
}

function receiveAttributesGroups(json) {
    return {
        type: RECEIVE_ATTRIBUTESGROUPS,
    attributesGroups: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAttributesGroups(error) {
    return {
        type: ERROR_ATTRIBUTESGROUPS,
        error: error,
    }
}

export function resetAttributesGroups() {
    return {
        type: RESET_ATTRIBUTESGROUPS
    }
}

export function fetchAttributesGroups(filtros) {
    return dispatch => {
        dispatch(requestAttributesGroups());
        return attributesGroupsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttributesGroups(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributesGroups(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributesGroups(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAttributesGroups(state) {
    const attributesGroups = state.attributesGroups.byId;
    if (!attributesGroups) {
        return true
    } else if (attributesGroups.isFetching) {
        return false
    } else {
        return attributesGroups.didInvalidate;
    }
}

export function fetchAttributesGroupsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAttributesGroups(getState())) {
            return dispatch(fetchAttributesGroups(filtros))
        }
    }
}


//MODEL
export const REQUEST_ATTRIBUTEGROUP = 'REQUEST_ATTRIBUTEGROUP';
export const RECEIVE_ATTRIBUTEGROUP = 'RECEIVE_ATTRIBUTEGROUP';
export const INVALIDATE_ATTRIBUTEGROUP = 'INVALIDATE_ATTRIBUTEGROUP';
export const ERROR_ATTRIBUTEGROUP = "ERROR_ATTRIBUTEGROUP";
export const RESET_ATTRIBUTEGROUP = "RESET_ATTRIBUTEGROUP";


export function invalidateAttributeGroup() {
    return {
        type: INVALIDATE_ATTRIBUTEGROUP
    }
}

function requestAttributeGroup() {
    return {
        type: REQUEST_ATTRIBUTEGROUP,
    }
}

export function receiveAttributeGroup(json) {
    return {
        type: RECEIVE_ATTRIBUTEGROUP,
    attributeGroup: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAttributeGroup(error) {
    return {
        type: ERROR_ATTRIBUTEGROUP,
        error: error,
    }
}

export function fetchAttributeGroup(idAttributeGroup) {
    return dispatch => {
        dispatch(requestAttributeGroup());
        return attributesGroupsApi.getOne(idAttributeGroup)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttributeGroup(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributeGroup(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ATTRIBUTEGROUP = 'RECEIVE_FILE_ATTRIBUTEGROUP';

function receiveFileAttributeGroup(file) {
    return {
        type: RECEIVE_FILE_ATTRIBUTEGROUP,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAttributeGroup(idAttributeGroup, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return attributesGroupsApi.getFile(idAttributeGroup, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAttributeGroup(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributeGroup(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ATTRIBUTEGROUP = 'UPDATE_ATTRIBUTEGROUP';
export const REQUEST_UPDATE_ATTRIBUTEGROUP = "REQUEST_UPDATE_ATTRIBUTEGROUP";
export const SUCCESS_UPDATE_ATTRIBUTEGROUP = "SUCCESS_UPDATE_ATTRIBUTEGROUP";
export const ERROR_UPDATE_ATTRIBUTEGROUP = "ERROR_UPDATE_ATTRIBUTEGROUP";
export const RESET_UPDATE_ATTRIBUTEGROUP = "RESET_UPDATE_ATTRIBUTEGROUP";
export const DELETE_UPDATE_ATTRIBUTEGROUP = "DELETE_UPDATE_ATTRIBUTEGROUP";

function requestUpdateAttributeGroup() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTEGROUP,
    }
}

function receiveUpdateAttributeGroup(attributeGroup) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTEGROUP,
        receivedAt: Date.now(),
        attributeGroup: normalizeDato(attributeGroup)
    }
}

function errorUpdateAttributeGroup(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTEGROUP,
        error: error,
    }
}

export function resetUpdateAttributeGroup() {
    return {
        type: RESET_UPDATE_ATTRIBUTEGROUP,
    }
}

export function updateAttributeGroup(attributeGroup) {
    return {
        type: UPDATE_ATTRIBUTEGROUP,
        attributeGroup
    }
}

export function saveUpdateAttributeGroup() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttributeGroup());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributeGroup = denormalizeDato(getState().attributesGroups.update.activo, store);

        return attributesGroupsApi.saveUpdate(attributeGroup)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttributeGroup(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAttributesGroups(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAttributesGroups(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAttributeGroup(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAttributeGroup(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAttributeGroup(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAttributeGroup(attributeGroup) {
    return {
        type: DELETE_UPDATE_ATTRIBUTEGROUP,
        attributeGroup
    }
}

//UPDATE ATTRIBUTESGROUPS
export const REQUEST_UPDATE_ATTRIBUTESGROUPS = "REQUEST_UPDATE_ATTRIBUTESGROUPS";
export const SUCCESS_UPDATE_ATTRIBUTESGROUPS = "SUCCESS_UPDATE_ATTRIBUTESGROUPS";
export const ERROR_UPDATE_ATTRIBUTESGROUPS = "ERROR_UPDATE_ATTRIBUTESGROUPS";
export const RESET_UPDATE_ATTRIBUTESGROUPS = "RESET_UPDATE_ATTRIBUTESGROUPS";

function requestUpdateAttributesGroups() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTESGROUPS,
}
}

function receiveUpdateAttributesGroups(attributesGroups) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTESGROUPS,
    receivedAt: Date.now(),
        attributesGroups: normalizeDatos(attributesGroups)
}
}

function errorUpdateAttributesGroups(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTESGROUPS,
    error: error,
}
}

export function resetUpdateAttributesGroups() {
    return {
        type: RESET_UPDATE_ATTRIBUTESGROUPS,
}
}

export function saveUpdateAttributesGroups() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttributesGroups());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributesGroups = getState().attributesGroups.update.activos.map((idAttributeGroup) => {
            return denormalizeDato(getState().attributesGroups.update.activo[idAttributeGroup], store);
        });

        return attributesGroupsApi.saveUpdateAttributesGroups(attributesGroups)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttributesGroups(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAttributesGroups(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAttributesGroups(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAttributesGroups(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAttributesGroups(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAttributesGroups(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ATTRIBUTEGROUP
export const CREATE_ATTRIBUTEGROUP = 'CREATE_ATTRIBUTEGROUP';
export const REQUEST_CREATE_ATTRIBUTEGROUP = "REQUEST_CREATE_ATTRIBUTEGROUP";
export const SUCCESS_CREATE_ATTRIBUTEGROUP = "SUCCESS_CREATE_ATTRIBUTEGROUP";
export const ERROR_CREATE_ATTRIBUTEGROUP = "ERROR_CREATE_ATTRIBUTEGROUP";
export const RESET_CREATE_ATTRIBUTEGROUP = "RESET_CREATE_ATTRIBUTEGROUP";
export const DELETE_CREATE_ATTRIBUTEGROUP = "DELETE_CREATE_ATTRIBUTEGROUP";


//ALTA ATTRIBUTEGROUP
function requestCreateAttributeGroup() {
    return {
        type: REQUEST_CREATE_ATTRIBUTEGROUP,
    }
}

function receiveCreateAttributeGroup(attributeGroup) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTEGROUP,
        receivedAt: Date.now(),
        attributeGroup: normalizeDato(attributeGroup)
    }
}

export function errorCreateAttributeGroup(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTEGROUP,
        error: error,
    }
}

export function resetCreateAttributeGroup() {
    return {
        type: RESET_CREATE_ATTRIBUTEGROUP
    }
}

export function createAttributeGroup(attributeGroup) {
    return {
        type: CREATE_ATTRIBUTEGROUP,
        attributeGroup
    }
}

export function deleteCreateAttributeGroup(attributeGroup) {
    return {
        type: DELETE_CREATE_ATTRIBUTEGROUP,
        attributeGroup
    }
}

export function saveCreateAttributeGroup() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttributeGroup());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributeGroup = denormalizeDato(getState().attributesGroups.create.nuevo, store);

        return attributesGroupsApi.saveCreate(attributeGroup)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttributeGroup(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAttributesGroups(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAttributesGroups(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAttributeGroup(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAttributeGroup(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAttributeGroup(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ATTRIBUTESGROUPS
export const REQUEST_CREATE_ATTRIBUTESGROUPS = "REQUEST_CREATE_ATTRIBUTESGROUPS";
export const SUCCESS_CREATE_ATTRIBUTESGROUPS = "SUCCESS_CREATE_ATTRIBUTESGROUPS";
export const ERROR_CREATE_ATTRIBUTESGROUPS = "ERROR_CREATE_ATTRIBUTESGROUPS";
export const RESET_CREATE_ATTRIBUTESGROUPS = "RESET_CREATE_ATTRIBUTESGROUPS";

function requestCreateAttributesGroups() {
    return {
        type: REQUEST_CREATE_ATTRIBUTESGROUPS,
}
}

function receiveCreateAttributesGroups(attributesGroups) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTESGROUPS,
    receivedAt: Date.now(),
        attributesGroups: normalizeDatos(attributesGroups)
}
}

function errorCreateAttributesGroups(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTESGROUPS,
    error: error,
}
}

export function resetCreateAttributesGroups() {
    return {
        type: RESET_CREATE_ATTRIBUTESGROUPS,
}
}

export function saveCreateAttributesGroups() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttributesGroups());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributesGroups = getState().attributesGroups.create.nuevos.map((idAttributeGroup) => {
            return denormalizeDato(getState().attributesGroups.create.nuevo[idAttributeGroup], store);
        });

        return attributesGroupsApi.saveCreateAttributesGroups(attributesGroups)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttributesGroups(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAttributesGroups(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAttributesGroups(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAttributesGroups(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAttributesGroups(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAttributesGroups(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ATTRIBUTEGROUP
export const DELETE_ATTRIBUTEGROUP = 'DELETE_ATTRIBUTEGROUP';
export const REQUEST_DELETE_ATTRIBUTEGROUP = "REQUEST_DELETE_ATTRIBUTEGROUP";
export const SUCCESS_DELETE_ATTRIBUTEGROUP = "SUCCESS_DELETE_ATTRIBUTEGROUP";
export const ERROR_DELETE_ATTRIBUTEGROUP = "ERROR_DELETE_ATTRIBUTEGROUP";
export const RESET_DELETE_ATTRIBUTEGROUP = "RESET_DELETE_ATTRIBUTEGROUP";

function requestDeleteAttributeGroup() {
    return {
        type: REQUEST_DELETE_ATTRIBUTEGROUP,
    }
}

function receiveDeleteAttributeGroup(attributeGroup) {
    return {
        type: SUCCESS_DELETE_ATTRIBUTEGROUP,
        receivedAt: Date.now(),
        attributeGroup: normalizeDato(attributeGroup)
    }
}

function errorDeleteAttributeGroup(error) {
    return {
        type: ERROR_DELETE_ATTRIBUTEGROUP,
        error: error,
    }
}

export function resetDeleteAttributeGroup(error) {
    return {
        type: RESET_DELETE_ATTRIBUTEGROUP,
        error: error,
    }
}

export function deleteAttributeGroup(attributeGroup) {
    return {
        type: DELETE_ATTRIBUTEGROUP,
        attributeGroup
    }
}

export function saveDeleteAttributeGroup(attributeGroup) {
    return dispatch => {
        dispatch(requestDeleteAttributeGroup());
        return attributesGroupsApi.saveDelete(attributeGroup)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAttributeGroup());
                            dispatch(receiveDeleteAttributeGroup(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAttributeGroup(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAttributeGroup(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAttributeGroup(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAttributeGroup(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ATTRIBUTEGROUP
export const REQUEST_PRINT_ATTRIBUTEGROUP = "REQUEST_PRINT_ATTRIBUTEGROUP";
export const SUCCESS_PRINT_ATTRIBUTEGROUP = "SUCCESS_PRINT_ATTRIBUTEGROUP";
export const ERROR_PRINT_ATTRIBUTEGROUP = "ERROR_PRINT_ATTRIBUTEGROUP";
export const RESET_PRINT_ATTRIBUTEGROUP = "RESET_PRINT_ATTRIBUTEGROUP";

function requestPrintAttributeGroup() {
    return {
        type: REQUEST_PRINT_ATTRIBUTEGROUP,
    }
}

function receivePrintAttributeGroup(turnos) {
    return {
        type: SUCCESS_PRINT_ATTRIBUTEGROUP,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAttributeGroup(error) {
    return {
        type: ERROR_PRINT_ATTRIBUTEGROUP,
        error: error,
    }
}

export function resetPrintAttributeGroup() {
    return {
        type: RESET_PRINT_ATTRIBUTEGROUP,
    }
}

export function printAttributeGroup(idAttributeGroup) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAttributeGroup());
        return attributesGroupsApi.printAttributeGroup(idAttributeGroup)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAttributeGroup(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAttributeGroup(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAttributeGroup(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAttributeGroup(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAttributeGroup(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}