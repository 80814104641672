import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, Button, CircularProgress, Typography} from '@material-ui/core';
import clsx from "clsx";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
//Icons
import clone from "lodash/clone";
import union from "lodash/union";
import {useDispatch, useSelector} from "react-redux";
import productReset from "../../reset/productReset";
import productAttributeValueReset from "../../reset/productAttributeValueReset";
import productAttributeValueLanguageReset from "../../reset/productAttributeValueLanguageReset";
import attributeReset from "../../reset/attributeReset";
import attributeGroupReset from "../../reset/attributeGroupReset";
import attributeLanguageReset from "../../reset/attributeLanguageReset";
import assetReset from "../../reset/assetReset";
//Img


const styles = makeStyles((theme) => ({
    form: {
        // padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "120px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        // width: "100%",
        width: "200px",
        height: "200px",
        paddingLeft: "6px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));


export default function ImageFileProduct(props) {
    const classes = styles();
    const dispatch = useDispatch();
    let textFile = React.useRef();
    let inputfileprod = React.createRef();
    let img = React.useRef();
    const mounted = useRef();
    const {
        product,
        files,
        setFiles,
        changeProduct,
        file,
    } = props;
    //Hooks
    const products = useSelector(state => state.products);
    //State
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
           previewFile(null);
        }
    }, [products.create.isCreating]);

    //Hago la preview de las fotos cargadas del backend
    useEffect(() => {
            previewFile(file);
        }, [file]
    )

    const previewFile = (file, type) => {
        let reader = new FileReader();
        reader.onloadend = (e) => {
            img.current.src = reader.result;
            setLoading(false);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else if(img.current)
            img.current.src = "";
    }

    const handleChangeFileAssetLanguage = (e) => {
        e.preventDefault();
        //Preview
        let fotos = [];
        setLoading(true);
        for (let i = 0; i < inputfileprod.current.files.length; i++) {
            let filename = inputfileprod.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(inputfileprod.current.files[i], 'name', {
                writable: true,
                value: filename
            });
            fotos.push(inputfileprod.current.files[i]);
        }
        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        for (let i = 0; i < inputfileprod.current.files.length; i++) {
            let fileSubido = inputfileprod.current.files[i];

            let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                setLoading(false);
                img.current.src = reader.result;
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
            let cambio = {};
            cambio.filename = filename;
            changeProduct(cambio);
        }
    };


    return (
        <Box component={"div"} className={classes.form} display={"flex"} justifyContent={"center"}
             alignItems={"center"}>
            <Box display={"flex"} component={"div"} className={classes.containerFileInput}>
                <Box component={"div"} className={clsx([classes.fileInput, classes.desktop])}>
                    <Button className={classes.buttonUpload}>
                        <label htmlFor={"mainFileProduct"}
                               className={classes.labelButton}
                            // ref={instance => labelRef.current = instance}
                        >
                            <AddPhotoAlternateIcon color={"primary"}/>
                            <Typography variant={"caption"} color={"primary"}>
                                Subir imagen
                            </Typography>
                            <img ref={img} src={""} alt=""/>
                            <CircularProgress className={classes.progress}
                                              style={{display: !loading ? "none" : "inherit"}}/>
                        </label>
                        <input ref={inputfileprod} type="file" name="mainFileProduct" id={"mainFileProduct"}
                               className="inputfile"
                               accept="image/*"
                               onChange={(e) => handleChangeFileAssetLanguage(e)}
                        />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

ImageFileProduct.propTypes = {
    legend: PropTypes.string,
}