import {resetPages, resetCreatePage, resetUpdatePage, resetDeletePage} from "../actions/PageActions";
import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";import {resetSections, resetCreateSection, resetUpdateSection, resetDeleteSection} from "../actions/SectionActions";

var resetPage = {
    resetAll(dispatch) {
        dispatch(resetPages());
        dispatch(resetCreatePage());
        dispatch(resetUpdatePage());
        dispatch(resetDeletePage());
       dispatch(resetAssets());
dispatch(resetCreateAsset());
dispatch(resetUpdateAsset());
dispatch(resetDeleteAsset());dispatch(resetSections());
dispatch(resetCreateSection());
dispatch(resetUpdateSection());
dispatch(resetDeleteSection());
    },
};

export default resetPage;