//api
import productsCountriesApi from "../api/productsCountriesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductsCountries";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTSCOUNTRIES
export const REQUEST_PRODUCTSCOUNTRIES = 'REQUEST_PRODUCTSCOUNTRIES';
export const RECEIVE_PRODUCTSCOUNTRIES = 'RECEIVE_PRODUCTSCOUNTRIES';
export const INVALIDATE_PRODUCTSCOUNTRIES = 'INVALIDATE_PRODUCTSCOUNTRIES';
export const ERROR_PRODUCTSCOUNTRIES = "ERROR_PRODUCTSCOUNTRIES";
export const RESET_PRODUCTSCOUNTRIES = "RESET_PRODUCTSCOUNTRIES";


export function invalidateProductsCountries() {
    return {
        type: INVALIDATE_PRODUCTSCOUNTRIES
    }
}

function requestProductsCountries() {
    return {
        type: REQUEST_PRODUCTSCOUNTRIES,
    }
}

function receiveProductsCountries(json) {
    return {
        type: RECEIVE_PRODUCTSCOUNTRIES,
    productsCountries: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductsCountries(error) {
    return {
        type: ERROR_PRODUCTSCOUNTRIES,
        error: error,
    }
}

export function resetProductsCountries() {
    return {
        type: RESET_PRODUCTSCOUNTRIES
    }
}

export function fetchProductsCountries(filtros) {
    return dispatch => {
        dispatch(requestProductsCountries());
        return productsCountriesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductsCountries(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductsCountries(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductsCountries(state) {
    const productsCountries = state.productsCountries.byId;
    if (!productsCountries) {
        return true
    } else if (productsCountries.isFetching) {
        return false
    } else {
        return productsCountries.didInvalidate;
    }
}

export function fetchProductsCountriesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductsCountries(getState())) {
            return dispatch(fetchProductsCountries(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTCOUNTRY = 'REQUEST_PRODUCTCOUNTRY';
export const RECEIVE_PRODUCTCOUNTRY = 'RECEIVE_PRODUCTCOUNTRY';
export const INVALIDATE_PRODUCTCOUNTRY = 'INVALIDATE_PRODUCTCOUNTRY';
export const ERROR_PRODUCTCOUNTRY = "ERROR_PRODUCTCOUNTRY";
export const RESET_PRODUCTCOUNTRY = "RESET_PRODUCTCOUNTRY";


export function invalidateProductCountry() {
    return {
        type: INVALIDATE_PRODUCTCOUNTRY
    }
}

function requestProductCountry() {
    return {
        type: REQUEST_PRODUCTCOUNTRY,
    }
}

export function receiveProductCountry(json) {
    return {
        type: RECEIVE_PRODUCTCOUNTRY,
    productCountry: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductCountry(error) {
    return {
        type: ERROR_PRODUCTCOUNTRY,
        error: error,
    }
}

export function fetchProductCountry(idProductCountry) {
    return dispatch => {
        dispatch(requestProductCountry());
        return productsCountriesApi.getOne(idProductCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductCountry(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PRODUCTCOUNTRY = 'RECEIVE_FILE_PRODUCTCOUNTRY';

function receiveFileProductCountry(file) {
    return {
        type: RECEIVE_FILE_PRODUCTCOUNTRY,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileProductCountry(idProductCountry, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return productsCountriesApi.getFile(idProductCountry, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileProductCountry(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PRODUCTCOUNTRY = 'UPDATE_PRODUCTCOUNTRY';
export const REQUEST_UPDATE_PRODUCTCOUNTRY = "REQUEST_UPDATE_PRODUCTCOUNTRY";
export const SUCCESS_UPDATE_PRODUCTCOUNTRY = "SUCCESS_UPDATE_PRODUCTCOUNTRY";
export const ERROR_UPDATE_PRODUCTCOUNTRY = "ERROR_UPDATE_PRODUCTCOUNTRY";
export const RESET_UPDATE_PRODUCTCOUNTRY = "RESET_UPDATE_PRODUCTCOUNTRY";
export const DELETE_UPDATE_PRODUCTCOUNTRY = "DELETE_UPDATE_PRODUCTCOUNTRY";

function requestUpdateProductCountry() {
    return {
        type: REQUEST_UPDATE_PRODUCTCOUNTRY,
    }
}

function receiveUpdateProductCountry(productCountry) {
    return {
        type: SUCCESS_UPDATE_PRODUCTCOUNTRY,
        receivedAt: Date.now(),
        productCountry: normalizeDato(productCountry)
    }
}

function errorUpdateProductCountry(error) {
    return {
        type: ERROR_UPDATE_PRODUCTCOUNTRY,
        error: error,
    }
}

export function resetUpdateProductCountry() {
    return {
        type: RESET_UPDATE_PRODUCTCOUNTRY,
    }
}

export function updateProductCountry(productCountry) {
    return {
        type: UPDATE_PRODUCTCOUNTRY,
        productCountry
    }
}

export function saveUpdateProductCountry() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductCountry());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productCountry = denormalizeDato(getState().productsCountries.update.activo, store);

        return productsCountriesApi.saveUpdate(productCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductsCountries(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductsCountries(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductCountry(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductCountry(productCountry) {
    return {
        type: DELETE_UPDATE_PRODUCTCOUNTRY,
        productCountry
    }
}

//UPDATE PRODUCTSCOUNTRIES
export const REQUEST_UPDATE_PRODUCTSCOUNTRIES = "REQUEST_UPDATE_PRODUCTSCOUNTRIES";
export const SUCCESS_UPDATE_PRODUCTSCOUNTRIES = "SUCCESS_UPDATE_PRODUCTSCOUNTRIES";
export const ERROR_UPDATE_PRODUCTSCOUNTRIES = "ERROR_UPDATE_PRODUCTSCOUNTRIES";
export const RESET_UPDATE_PRODUCTSCOUNTRIES = "RESET_UPDATE_PRODUCTSCOUNTRIES";

function requestUpdateProductsCountries() {
    return {
        type: REQUEST_UPDATE_PRODUCTSCOUNTRIES,
}
}

function receiveUpdateProductsCountries(productsCountries) {
    return {
        type: SUCCESS_UPDATE_PRODUCTSCOUNTRIES,
    receivedAt: Date.now(),
        productsCountries: normalizeDatos(productsCountries)
}
}

function errorUpdateProductsCountries(error) {
    return {
        type: ERROR_UPDATE_PRODUCTSCOUNTRIES,
    error: error,
}
}

export function resetUpdateProductsCountries() {
    return {
        type: RESET_UPDATE_PRODUCTSCOUNTRIES,
}
}

export function saveUpdateProductsCountries() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsCountries = getState().productsCountries.update.activos.map((idProductCountry) => {
            return denormalizeDato(getState().productsCountries.update.activo[idProductCountry], store);
        });

        return productsCountriesApi.saveUpdateProductsCountries(productsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductsCountries(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductsCountries(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTCOUNTRY
export const CREATE_PRODUCTCOUNTRY = 'CREATE_PRODUCTCOUNTRY';
export const REQUEST_CREATE_PRODUCTCOUNTRY = "REQUEST_CREATE_PRODUCTCOUNTRY";
export const SUCCESS_CREATE_PRODUCTCOUNTRY = "SUCCESS_CREATE_PRODUCTCOUNTRY";
export const ERROR_CREATE_PRODUCTCOUNTRY = "ERROR_CREATE_PRODUCTCOUNTRY";
export const RESET_CREATE_PRODUCTCOUNTRY = "RESET_CREATE_PRODUCTCOUNTRY";
export const DELETE_CREATE_PRODUCTCOUNTRY = "DELETE_CREATE_PRODUCTCOUNTRY";


//ALTA PRODUCTCOUNTRY
function requestCreateProductCountry() {
    return {
        type: REQUEST_CREATE_PRODUCTCOUNTRY,
    }
}

function receiveCreateProductCountry(productCountry) {
    return {
        type: SUCCESS_CREATE_PRODUCTCOUNTRY,
        receivedAt: Date.now(),
        productCountry: normalizeDato(productCountry)
    }
}

export function errorCreateProductCountry(error) {
    return {
        type: ERROR_CREATE_PRODUCTCOUNTRY,
        error: error,
    }
}

export function resetCreateProductCountry() {
    return {
        type: RESET_CREATE_PRODUCTCOUNTRY
    }
}

export function createProductCountry(productCountry) {
    return {
        type: CREATE_PRODUCTCOUNTRY,
        productCountry
    }
}

export function deleteCreateProductCountry(productCountry) {
    return {
        type: DELETE_CREATE_PRODUCTCOUNTRY,
        productCountry
    }
}

export function saveCreateProductCountry() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductCountry());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productCountry = denormalizeDato(getState().productsCountries.create.nuevo, store);

        return productsCountriesApi.saveCreate(productCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductsCountries(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductsCountries(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductCountry(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTSCOUNTRIES
export const REQUEST_CREATE_PRODUCTSCOUNTRIES = "REQUEST_CREATE_PRODUCTSCOUNTRIES";
export const SUCCESS_CREATE_PRODUCTSCOUNTRIES = "SUCCESS_CREATE_PRODUCTSCOUNTRIES";
export const ERROR_CREATE_PRODUCTSCOUNTRIES = "ERROR_CREATE_PRODUCTSCOUNTRIES";
export const RESET_CREATE_PRODUCTSCOUNTRIES = "RESET_CREATE_PRODUCTSCOUNTRIES";

function requestCreateProductsCountries() {
    return {
        type: REQUEST_CREATE_PRODUCTSCOUNTRIES,
}
}

function receiveCreateProductsCountries(productsCountries) {
    return {
        type: SUCCESS_CREATE_PRODUCTSCOUNTRIES,
    receivedAt: Date.now(),
        productsCountries: normalizeDatos(productsCountries)
}
}

function errorCreateProductsCountries(error) {
    return {
        type: ERROR_CREATE_PRODUCTSCOUNTRIES,
    error: error,
}
}

export function resetCreateProductsCountries() {
    return {
        type: RESET_CREATE_PRODUCTSCOUNTRIES,
}
}

export function saveCreateProductsCountries() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsCountries = getState().productsCountries.create.nuevos.map((idProductCountry) => {
            return denormalizeDato(getState().productsCountries.create.nuevo[idProductCountry], store);
        });

        return productsCountriesApi.saveCreateProductsCountries(productsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductsCountries(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductsCountries(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTCOUNTRY
export const DELETE_PRODUCTCOUNTRY = 'DELETE_PRODUCTCOUNTRY';
export const REQUEST_DELETE_PRODUCTCOUNTRY = "REQUEST_DELETE_PRODUCTCOUNTRY";
export const SUCCESS_DELETE_PRODUCTCOUNTRY = "SUCCESS_DELETE_PRODUCTCOUNTRY";
export const ERROR_DELETE_PRODUCTCOUNTRY = "ERROR_DELETE_PRODUCTCOUNTRY";
export const RESET_DELETE_PRODUCTCOUNTRY = "RESET_DELETE_PRODUCTCOUNTRY";

function requestDeleteProductCountry() {
    return {
        type: REQUEST_DELETE_PRODUCTCOUNTRY,
    }
}

function receiveDeleteProductCountry(productCountry) {
    return {
        type: SUCCESS_DELETE_PRODUCTCOUNTRY,
        receivedAt: Date.now(),
        productCountry: normalizeDato(productCountry)
    }
}

function errorDeleteProductCountry(error) {
    return {
        type: ERROR_DELETE_PRODUCTCOUNTRY,
        error: error,
    }
}

export function resetDeleteProductCountry(error) {
    return {
        type: RESET_DELETE_PRODUCTCOUNTRY,
        error: error,
    }
}

export function deleteProductCountry(productCountry) {
    return {
        type: DELETE_PRODUCTCOUNTRY,
        productCountry
    }
}

export function saveDeleteProductCountry(productCountry) {
    return dispatch => {
        dispatch(requestDeleteProductCountry());
        return productsCountriesApi.saveDelete(productCountry)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductCountry());
                            dispatch(receiveDeleteProductCountry(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductCountry(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductCountry(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductCountry(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTCOUNTRY
export const REQUEST_PRINT_PRODUCTCOUNTRY = "REQUEST_PRINT_PRODUCTCOUNTRY";
export const SUCCESS_PRINT_PRODUCTCOUNTRY = "SUCCESS_PRINT_PRODUCTCOUNTRY";
export const ERROR_PRINT_PRODUCTCOUNTRY = "ERROR_PRINT_PRODUCTCOUNTRY";
export const RESET_PRINT_PRODUCTCOUNTRY = "RESET_PRINT_PRODUCTCOUNTRY";

function requestPrintProductCountry() {
    return {
        type: REQUEST_PRINT_PRODUCTCOUNTRY,
    }
}

function receivePrintProductCountry(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTCOUNTRY,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductCountry(error) {
    return {
        type: ERROR_PRINT_PRODUCTCOUNTRY,
        error: error,
    }
}

export function resetPrintProductCountry() {
    return {
        type: RESET_PRINT_PRODUCTCOUNTRY,
    }
}

export function printProductCountry(idProductCountry) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductCountry());
        return productsCountriesApi.printProductCountry(idProductCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductCountry(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductCountry(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductCountry(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductCountry(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductCountry(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}