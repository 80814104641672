import {
    INVALIDATE_CATEGORIES,
    ERROR_CATEGORIES,
    RECEIVE_CATEGORIES,
    REQUEST_CATEGORIES,
    RESET_CATEGORIES,
    ERROR_CATEGORY,
    RECEIVE_CATEGORY,
    REQUEST_CATEGORY,
    UPDATE_CATEGORY,
    REQUEST_UPDATE_CATEGORY,
    SUCCESS_UPDATE_CATEGORY,
    ERROR_UPDATE_CATEGORY,
    RESET_UPDATE_CATEGORY,
    REQUEST_UPDATE_CATEGORIES,
    SUCCESS_UPDATE_CATEGORIES,
    ERROR_UPDATE_CATEGORIES,
    RESET_UPDATE_CATEGORIES,
    CREATE_CATEGORY,
    ERROR_CREATE_CATEGORY,
    REQUEST_CREATE_CATEGORY,
    RESET_CREATE_CATEGORY,
    SUCCESS_CREATE_CATEGORY,
    REQUEST_CREATE_CATEGORIES,
    SUCCESS_CREATE_CATEGORIES,
    ERROR_CREATE_CATEGORIES,
    RESET_CREATE_CATEGORIES,
    DELETE_CATEGORY,
    DELETE_CREATE_CATEGORY,
    DELETE_UPDATE_CATEGORY,
    REQUEST_DELETE_CATEGORY,
    SUCCESS_DELETE_CATEGORY,
    ERROR_DELETE_CATEGORY,
    RESET_DELETE_CATEGORY,
    REQUEST_PRINT_CATEGORY,
    SUCCESS_PRINT_CATEGORY,
    ERROR_PRINT_CATEGORY,
    RESET_PRINT_CATEGORY,
    RECEIVE_FILE_CATEGORY
} from '../actions/CategoryActions';
import {
    CREATE_CATEGORYLANGUAGE,
    UPDATE_CATEGORYLANGUAGE,
    DELETE_CATEGORYLANGUAGE
} from '../actions/CategoryLanguageActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        categories: {},
        files: {},
    }
}

function categoriesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_CATEGORIES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                categories: {}
            });
        case RECEIVE_CATEGORIES:
            let dato = action.categories.entities.categories;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categories: merge({}, state.categories, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_CATEGORY:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_CATEGORY:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_CATEGORY:
            let datoCategory = action.category.entities.categories;
            return Object.assign({}, state, {
                categories: merge({}, state.categories, datoCategory),
                isFetching: false,
            });
        case RECEIVE_FILE_CATEGORY:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_CATEGORY:
            let datoCategoryEliminado = action.category.entities.categories;
            return Object.assign({}, state, {
                categories: mergeWith(clone(datoCategoryEliminado), state.categories, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORY:
            let datoCategoryCreado = action.category.entities.categories;
            return Object.assign({}, state, {
                categories: mergeWith(clone(datoCategoryCreado), state.categories, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORIES:
            let datosCategoryCreado = action.categories.entities.categories;
                return Object.assign({}, state, {
                    categories: mergeWith(clone(datosCategoryCreado), state.categories, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_CATEGORY:
            let datoCategoryActualizado = action.category.entities.categories;
            return Object.assign({}, state, {
                categories: mergeWith(clone(datoCategoryActualizado), state.categories, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_CATEGORIES:
            let datosCategoryActualizado = action.categories.entities.categories;
                return Object.assign({}, state, {
                    categories: mergeWith(clone(datosCategoryActualizado), state.categories, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                categories: {}
            });
        default:
            return state
    }
}


function allCategories(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIES:
            return action.categories.result && action.categories.result.categories ? union(action.categories.result.categories, state) : (action.categories.result ? action.categories.result : state) ;
        case RECEIVE_CATEGORY:
                return action.category.result ? union([action.category.result], state) : state;
        
        case SUCCESS_CREATE_CATEGORY:
                   let datoCategorySCreate = action.category.entities.categories;
                   let idNuevoSCreate = null;
                   if (Object.values(datoCategorySCreate).length > 0)
                       idNuevoSCreate = Object.values(datoCategorySCreate)[0] && Object.values(datoCategorySCreate)[0].id ? Object.values(datoCategorySCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_CATEGORIES:
                   let categoriesCreate = action.categories.entities && action.categories.entities.categories ? action.categories.entities.categories : null;
                   return categoriesCreate ?
                       union(state, Object.values(categoriesCreate).map((categories) => {
                           return categories.id
                       })) : state;
        case RESET_CATEGORIES:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalCategories(state = null, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIES:
            return action.categories && action.categories.result.total ? action.categories.result.total : 0;
        case RESET_CATEGORIES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORY:
            let dato = action.category.entities.categories;
            let category = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: category ? category : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_CATEGORY:
            let idsUpdate = [];
            Object.values(action.category).map((categoryUpdate) => {
                if (categoryUpdate && categoryUpdate.id)
                    idsUpdate.push(categoryUpdate.id);
            });
            return merge({}, state, {
                activo: action.category,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_CATEGORY:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_CATEGORY:
            let datoCategoryActualizado = {};
            if (Object.values(action.category.entities.categories).length > 0)
                datoCategoryActualizado = Object.values(action.category.entities.categories)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoCategoryActualizado
            });
        case ERROR_UPDATE_CATEGORY:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_CATEGORIES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_CATEGORIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_CATEGORIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_CATEGORY:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_CATEGORYLANGUAGE:
    let categorycategoryLanguageCreateActivo = clone(state.activo);
    let categorycategoryLanguageCreateActivos = clone(state.activos);
    Object.values(action.categoryLanguage).map((categoryLanguageCreate) => {
        if(categoryLanguageCreate && categoryLanguageCreate.idCategory && categorycategoryLanguageCreateActivo[categoryLanguageCreate.idCategory]){
            if(categoryLanguageCreate.idCategory.toString().indexOf("-") === -1)
                categorycategoryLanguageCreateActivo[categoryLanguageCreate.idCategory].category_language = union(categorycategoryLanguageCreateActivo.category_language, [categoryLanguageCreate.id]);
        } else if (categoryLanguageCreate) {
            categorycategoryLanguageCreateActivo.category_language = union(categorycategoryLanguageCreateActivo.category_language ? categorycategoryLanguageCreateActivo.category_language : [], [categoryLanguageCreate.id]);
        }
        if(categoryLanguageCreate && categoryLanguageCreate.idCategory && categoryLanguageCreate.idCategory.toString().indexOf("-") === -1)
            categorycategoryLanguageCreateActivos = union(categorycategoryLanguageCreateActivos, [categoryLanguageCreate.idCategory]);
    });
    return Object.assign({}, state, {
        activo: categorycategoryLanguageCreateActivo,
        activos: categorycategoryLanguageCreateActivos
    });
case UPDATE_CATEGORYLANGUAGE:
    let categorycategoryLanguageUpdateActivo = clone(state.activo);
        let categorycategoryLanguageUpdateActivos = clone(state.activos);
        Object.values(action.categoryLanguage).map((categoryLanguageUpdate) => {
            if(categoryLanguageUpdate && categoryLanguageUpdate.idCategory && categorycategoryLanguageUpdateActivo[categoryLanguageUpdate.idCategory]){
                if(categoryLanguageUpdate.idCategory.toString().indexOf("-") === -1)
                    categorycategoryLanguageUpdateActivo[categoryLanguageUpdate.idCategory].category_language = union(categorycategoryLanguageUpdateActivo.category_language, [categoryLanguageUpdate.id]);
            } else if (categoryLanguageUpdate) {
                categorycategoryLanguageUpdateActivo.category_language = union(categorycategoryLanguageUpdateActivo.category_language ? categorycategoryLanguageUpdateActivo.category_language : [], [categoryLanguageUpdate.id]);
            }
            if(categoryLanguageUpdate && categoryLanguageUpdate.idCategory && categoryLanguageUpdate.idCategory.toString().indexOf("-") === -1)
                categorycategoryLanguageUpdateActivos = union(categorycategoryLanguageUpdateActivos, [categoryLanguageUpdate.idCategory]);
        });
        return Object.assign({}, state, {
            activo: categorycategoryLanguageUpdateActivo,
            activos: categorycategoryLanguageUpdateActivos
        });
case DELETE_CATEGORYLANGUAGE:
    let categorycategoryLanguageDeleteActivo = clone(state.activo);
    let categorycategoryLanguageDeleteActivos = clone(state.activos);
    Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
        if(categoryLanguageDelete && categoryLanguageDelete.idCategory && categorycategoryLanguageDeleteActivo[categoryLanguageDelete.idCategory]){
            if(categoryLanguageDelete.idCategory.toString().indexOf("-") === -1)
                categorycategoryLanguageDeleteActivo[categoryLanguageDelete.idCategory].category_language = difference(categorycategoryLanguageDeleteActivo.category_language, [categoryLanguageDelete.id]);
        } else if (categoryLanguageDelete) {
            categorycategoryLanguageDeleteActivo.category_language = difference(categorycategoryLanguageDeleteActivo.category_language ? categorycategoryLanguageDeleteActivo.category_language : [], [categoryLanguageDelete.id]);
        }
        if(categoryLanguageDelete && categoryLanguageDelete.idCategory && categoryLanguageDelete.idCategory.toString().indexOf("-") === -1)
            categorycategoryLanguageDeleteActivos = union(categorycategoryLanguageDeleteActivos, [categoryLanguageDelete.idCategory]);
    });
    return Object.assign({}, state, {
        activo: categorycategoryLanguageDeleteActivo,
        activos: categorycategoryLanguageDeleteActivos
    });

           

        case DELETE_CATEGORY:
            let datoCategoryDelete = action.category;
            let idsDelete = [];
           Object.values(action.category).map((categoryDelete) => {
               if (categoryDelete && categoryDelete.id)
                   idsDelete.push(categoryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoCategoryDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_CATEGORY:
                       let datoCategoryDeleteUpdate = action.category;
                       let idsDeleteUpdate = [];
                      Object.values(action.category).map((categoryDelete) => {
                          if (categoryDelete && categoryDelete.id)
                              idsDeleteUpdate.push(categoryDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoCategoryDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_CATEGORY:
                    let datoCategoryDeleted = {};
                    if (Object.values(action.category.entities.categories).length > 0)
                        datoCategoryDeleted = Object.values(action.category.entities.categories)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoCategoryDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CATEGORY:
             let idsCreate = [];
             Object.values(action.category).map((categoryCreate) => {
                 if (categoryCreate && categoryCreate.id)
                     idsCreate.push(categoryCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.category,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_CATEGORY:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CATEGORY:
            let datoCategoryNuevo = {};
            if (Object.values(action.category.entities.categories).length > 0)
                datoCategoryNuevo = Object.values(action.category.entities.categories)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoCategoryNuevo,
                nuevos: []
            });
        case ERROR_CREATE_CATEGORY:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_CATEGORIES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_CATEGORIES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_CATEGORIES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CATEGORY:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE CATEGORYLANGUAGE
case CREATE_CATEGORYLANGUAGE:
    let categorycategoryLanguageCreateActivo = clone(state.nuevo);
    Object.values(action.categoryLanguage).map((categoryLanguageCreate) => {
        if(categoryLanguageCreate && categoryLanguageCreate.idCategory && categorycategoryLanguageCreateActivo[categoryLanguageCreate.idCategory]){
            if(categoryLanguageCreate.idCategory.toString().indexOf("-") !== -1)
                categorycategoryLanguageCreateActivo[categoryLanguageCreate.idCategory].category_language = union(categorycategoryLanguageCreateActivo.category_language, [categoryLanguageCreate.id]);
        } else if (categoryLanguageCreate) {
            categorycategoryLanguageCreateActivo.category_language = union(categorycategoryLanguageCreateActivo.category_language ? categorycategoryLanguageCreateActivo.category_language : [], [categoryLanguageCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: categorycategoryLanguageCreateActivo,
        //nuevos: categoryLanguageCreate && categoryLanguageCreate.idCategory ? union(state.nuevos, [categoryLanguageCreate.idCategory]) : state.nuevos,
    });
case UPDATE_CATEGORYLANGUAGE:
    let categorycategoryLanguageUpdateActivo = clone(state.nuevo);
    Object.values(action.categoryLanguage).map((categoryLanguageUpdate) => {
        if(categoryLanguageUpdate && categoryLanguageUpdate.idCategory && categorycategoryLanguageUpdateActivo[categoryLanguageUpdate.idCategory]){
            if(categoryLanguageUpdate.idCategory.toString().indexOf("-") !== -1)
                categorycategoryLanguageUpdateActivo[categoryLanguageUpdate.idCategory].category_language = union(categorycategoryLanguageUpdateActivo.category_language, [categoryLanguageUpdate.id]);
        } else if(categoryLanguageUpdate){
            categorycategoryLanguageUpdateActivo.category_language = union(categorycategoryLanguageUpdateActivo.category_language ? categorycategoryLanguageUpdateActivo.category_language : [], [categoryLanguageUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: categorycategoryLanguageUpdateActivo,
        //nuevos: categoryLanguageUpdate && categoryLanguageUpdate.idCategory ? union(state.nuevos, [categoryLanguageUpdate.idCategory]) : state.nuevos,
    });
case DELETE_CATEGORYLANGUAGE:
    let categorycategoryLanguageDeleteActivo = clone(state.nuevo);
    Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
        if(categoryLanguageDelete && categoryLanguageDelete.idCategory && categoryLanguageDelete.idCategory && categorycategoryLanguageDeleteActivo[categoryLanguageDelete.idCategory]){
            if(categoryLanguageDelete.idCategory.toString().indexOf("-") !== -1)
                categorycategoryLanguageDeleteActivo[categoryLanguageDelete.idCategory].category_language = difference(categorycategoryLanguageDeleteActivo.category_language, [categoryLanguageDelete.id]);
        } else if (categoryLanguageDelete) {
            categorycategoryLanguageDeleteActivo.category_language = difference(categorycategoryLanguageDeleteActivo.category_language ? categorycategoryLanguageDeleteActivo.category_language : [], [categoryLanguageDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: categorycategoryLanguageDeleteActivo,
        //nuevos: categoryLanguageDelete && categoryLanguageDelete.idCategory ? union(state.nuevos, [categoryLanguageDelete.idCategory]) : state.nuevos,
    });

             
        case DELETE_CATEGORY:
           let datoCategoryDelete = action.category;
           let idsDelete = [];
           Object.values(action.category).map((categoryDelete) => {
               if (categoryDelete && categoryDelete.id)
                   idsDelete.push(categoryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoCategoryDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_CATEGORY:
                  let datoCategoryDeleteCreate = action.category;
                  let idsDeleteCreate = [];
                  Object.values(action.category).map((categoryDelete) => {
                      if (categoryDelete && categoryDelete.id)
                          idsDeleteCreate.push(categoryDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoCategoryDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_CATEGORY:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.category,
                error: null,
            });
        case REQUEST_DELETE_CATEGORY:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_CATEGORY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_CATEGORY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_CATEGORY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_CATEGORY:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_CATEGORY:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_CATEGORY:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const categories = combineReducers({
    byId: categoriesById,
    allIds: allCategories,
    update: update,
    create: create,
    totalCategories: totalCategories,
    delete: deleter,
    print: print
});

export default categories;