import {normalize, schema, denormalize} from 'normalizr';

const country = new schema.Entity('countries', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {countries: [country]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = country;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = country;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default country;
