import {normalize, schema, denormalize} from 'normalizr';
// import section from "./normalizeSections";
import typeFileSection from "./normalizeTypesFilesSections";

const section = new schema.Entity('sections', {
    type_file_section: [typeFileSection]}, {idAttribute: "id"});
const page = new schema.Entity('pages', {section: [section]}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {pages: [page]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = page;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = page;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default page;
