import {
    INVALIDATE_PRODUCTSCOUNTRIES,
    ERROR_PRODUCTSCOUNTRIES,
    RECEIVE_PRODUCTSCOUNTRIES,
    REQUEST_PRODUCTSCOUNTRIES,
    RESET_PRODUCTSCOUNTRIES,
    ERROR_PRODUCTCOUNTRY,
    RECEIVE_PRODUCTCOUNTRY,
    REQUEST_PRODUCTCOUNTRY,
    UPDATE_PRODUCTCOUNTRY,
    REQUEST_UPDATE_PRODUCTCOUNTRY,
    SUCCESS_UPDATE_PRODUCTCOUNTRY,
    ERROR_UPDATE_PRODUCTCOUNTRY,
    RESET_UPDATE_PRODUCTCOUNTRY,
    REQUEST_UPDATE_PRODUCTSCOUNTRIES,
    SUCCESS_UPDATE_PRODUCTSCOUNTRIES,
    ERROR_UPDATE_PRODUCTSCOUNTRIES,
    RESET_UPDATE_PRODUCTSCOUNTRIES,
    CREATE_PRODUCTCOUNTRY,
    ERROR_CREATE_PRODUCTCOUNTRY,
    REQUEST_CREATE_PRODUCTCOUNTRY,
    RESET_CREATE_PRODUCTCOUNTRY,
    SUCCESS_CREATE_PRODUCTCOUNTRY,
    REQUEST_CREATE_PRODUCTSCOUNTRIES,
    SUCCESS_CREATE_PRODUCTSCOUNTRIES,
    ERROR_CREATE_PRODUCTSCOUNTRIES,
    RESET_CREATE_PRODUCTSCOUNTRIES,
    DELETE_PRODUCTCOUNTRY,
    DELETE_CREATE_PRODUCTCOUNTRY,
    DELETE_UPDATE_PRODUCTCOUNTRY,
    REQUEST_DELETE_PRODUCTCOUNTRY,
    SUCCESS_DELETE_PRODUCTCOUNTRY,
    ERROR_DELETE_PRODUCTCOUNTRY,
    RESET_DELETE_PRODUCTCOUNTRY,
    REQUEST_PRINT_PRODUCTCOUNTRY,
    SUCCESS_PRINT_PRODUCTCOUNTRY,
    ERROR_PRINT_PRODUCTCOUNTRY,
    RESET_PRINT_PRODUCTCOUNTRY,
    RECEIVE_FILE_PRODUCTCOUNTRY
} from '../actions/ProductCountryActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productsCountries: {},
        files: {},
    }
}

function productsCountriesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productsCountries: {}
            });
        case RECEIVE_PRODUCTSCOUNTRIES:
            let dato = action.productsCountries.entities.productsCountries;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productsCountries: merge({}, state.productsCountries, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTCOUNTRY:
            let datoProductCountry = action.productCountry.entities.productsCountries;
            return Object.assign({}, state, {
                productsCountries: merge({}, state.productsCountries, datoProductCountry),
                isFetching: false,
            });
        case RECEIVE_FILE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PRODUCTCOUNTRY:
            let datoProductCountryEliminado = action.productCountry.entities.productsCountries;
            return Object.assign({}, state, {
                productsCountries: mergeWith(clone(datoProductCountryEliminado), state.productsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTCOUNTRY:
            let datoProductCountryCreado = action.productCountry.entities.productsCountries;
            return Object.assign({}, state, {
                productsCountries: mergeWith(clone(datoProductCountryCreado), state.productsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTSCOUNTRIES:
            let datosProductCountryCreado = action.productsCountries.entities.productsCountries;
                return Object.assign({}, state, {
                    productsCountries: mergeWith(clone(datosProductCountryCreado), state.productsCountries, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTCOUNTRY:
            let datoProductCountryActualizado = action.productCountry.entities.productsCountries;
            return Object.assign({}, state, {
                productsCountries: mergeWith(clone(datoProductCountryActualizado), state.productsCountries, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTSCOUNTRIES:
            let datosProductCountryActualizado = action.productsCountries.entities.productsCountries;
                return Object.assign({}, state, {
                    productsCountries: mergeWith(clone(datosProductCountryActualizado), state.productsCountries, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : {};
    return Object.assign({}, state, {
        productsCountries: merge({}, state.productsCountries, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsCountries ? action.products.entities.productsCountries : {};
    return Object.assign({}, state, {
        productsCountries: merge({}, state.productsCountries, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : {};
    return Object.assign({}, state, {
        productsCountries: mergeWith(clone(datoproductEliminado), pickBy(state.productsCountries, function(productCountry) {return productCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : {};
    return Object.assign({}, state, {
        productsCountries: mergeWith(clone(datoproductCreado), pickBy(state.productsCountries, function(productCountry) {return productCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : {};
    return Object.assign({}, state, {
        productsCountries: mergeWith(clone(datoproductActualizado), pickBy(state.productsCountries, function(productCountry) {return productCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.productsCountries ? action.products.entities.productsCountries : {} ;
        return Object.assign({}, state, {
            productsCountries: mergeWith(clone(datosproductActualizado), state.productsCountries, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productsCountries: {}
            });
        default:
            return state
    }
}


function allProductsCountries(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSCOUNTRIES:
            return action.productsCountries.result && action.productsCountries.result.productsCountries ? union(action.productsCountries.result.productsCountries, state) : (action.productsCountries.result ? action.productsCountries.result : state) ;
        case RECEIVE_PRODUCTCOUNTRY:
                return action.productCountry.result ? union([action.productCountry.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTCOUNTRY:
                   let datoProductCountrySCreate = action.productCountry.entities.productsCountries;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductCountrySCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductCountrySCreate)[0] && Object.values(datoProductCountrySCreate)[0].id ? Object.values(datoProductCountrySCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTSCOUNTRIES:
                   let productsCountriesCreate = action.productsCountries.entities && action.productsCountries.entities.productsCountries ? action.productsCountries.entities.productsCountries : null;
                   return productsCountriesCreate ?
                       union(state, Object.values(productsCountriesCreate).map((productsCountries) => {
                           return productsCountries.id
                       })) : state;
        case RESET_PRODUCTSCOUNTRIES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsCountries ? action.products.entities.productsCountries : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.productsCountries ? action.product.entities.productsCountries : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.productsCountries ? action.products.entities.productsCountries : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductsCountries(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSCOUNTRIES:
            return action.productsCountries && action.productsCountries.result.total ? action.productsCountries.result.total : 0;
        case RESET_PRODUCTSCOUNTRIES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTCOUNTRY:
            let dato = action.productCountry.entities.productsCountries;
            let productCountry = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productCountry ? productCountry : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTCOUNTRY:
            let idsUpdate = [];
            Object.values(action.productCountry).map((productCountryUpdate) => {
                if (productCountryUpdate && productCountryUpdate.id)
                    idsUpdate.push(productCountryUpdate.id);
            });
            return merge({}, state, {
                activo: action.productCountry,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTCOUNTRY:
            let datoProductCountryActualizado = {};
            if (Object.values(action.productCountry.entities.productsCountries).length > 0)
                datoProductCountryActualizado = Object.values(action.productCountry.entities.productsCountries)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductCountryActualizado
            });
        case ERROR_UPDATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_PRODUCTCOUNTRY:
            let datoProductCountryDelete = action.productCountry;
            let idsDelete = [];
           Object.values(action.productCountry).map((productCountryDelete) => {
               if (productCountryDelete && productCountryDelete.id)
                   idsDelete.push(productCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductCountryDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTCOUNTRY:
                       let datoProductCountryDeleteUpdate = action.productCountry;
                       let idsDeleteUpdate = [];
                      Object.values(action.productCountry).map((productCountryDelete) => {
                          if (productCountryDelete && productCountryDelete.id)
                              idsDeleteUpdate.push(productCountryDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductCountryDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTCOUNTRY:
                    let datoProductCountryDeleted = {};
                    if (Object.values(action.productCountry.entities.productsCountries).length > 0)
                        datoProductCountryDeleted = Object.values(action.productCountry.entities.productsCountries)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductCountryDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTCOUNTRY:
             let idsCreate = [];
             Object.values(action.productCountry).map((productCountryCreate) => {
                 if (productCountryCreate && productCountryCreate.id)
                     idsCreate.push(productCountryCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productCountry,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTCOUNTRY:
            let datoProductCountryNuevo = {};
            if (Object.values(action.productCountry.entities.productsCountries).length > 0)
                datoProductCountryNuevo = Object.values(action.productCountry.entities.productsCountries)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductCountryNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTCOUNTRY:
           let datoProductCountryDelete = action.productCountry;
           let idsDelete = [];
           Object.values(action.productCountry).map((productCountryDelete) => {
               if (productCountryDelete && productCountryDelete.id)
                   idsDelete.push(productCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductCountryDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTCOUNTRY:
                  let datoProductCountryDeleteCreate = action.productCountry;
                  let idsDeleteCreate = [];
                  Object.values(action.productCountry).map((productCountryDelete) => {
                      if (productCountryDelete && productCountryDelete.id)
                          idsDeleteCreate.push(productCountryDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductCountryDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTCOUNTRY:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productCountry,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productsCountries = combineReducers({
    byId: productsCountriesById,
    allIds: allProductsCountries,
    update: update,
    create: create,
    totalProductsCountries: totalProductsCountries,
    delete: deleter,
    print: print
});

export default productsCountries;