//api
import menusApi from "../api/menusApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeMenus";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//MENUS
export const REQUEST_MENUS = 'REQUEST_MENUS';
export const RECEIVE_MENUS = 'RECEIVE_MENUS';
export const INVALIDATE_MENUS = 'INVALIDATE_MENUS';
export const ERROR_MENUS = "ERROR_MENUS";
export const RESET_MENUS = "RESET_MENUS";


export function invalidateMenus() {
    return {
        type: INVALIDATE_MENUS
    }
}

function requestMenus() {
    return {
        type: REQUEST_MENUS,
    }
}

function receiveMenus(json) {
    return {
        type: RECEIVE_MENUS,
    menus: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorMenus(error) {
    return {
        type: ERROR_MENUS,
        error: error,
    }
}

export function resetMenus() {
    return {
        type: RESET_MENUS
    }
}

export function fetchMenus(filtros) {
    return dispatch => {
        dispatch(requestMenus());
        return menusApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMenus(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMenus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMenus(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchMenus(state) {
    const menus = state.menus.byId;
    if (!menus) {
        return true
    } else if (menus.isFetching) {
        return false
    } else {
        return menus.didInvalidate;
    }
}

export function fetchMenusIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchMenus(getState())) {
            return dispatch(fetchMenus(filtros))
        }
    }
}


//MODEL
export const REQUEST_MENU = 'REQUEST_MENU';
export const RECEIVE_MENU = 'RECEIVE_MENU';
export const INVALIDATE_MENU = 'INVALIDATE_MENU';
export const ERROR_MENU = "ERROR_MENU";
export const RESET_MENU = "RESET_MENU";


export function invalidateMenu() {
    return {
        type: INVALIDATE_MENU
    }
}

function requestMenu() {
    return {
        type: REQUEST_MENU,
    }
}

export function receiveMenu(json) {
    return {
        type: RECEIVE_MENU,
    menu: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorMenu(error) {
    return {
        type: ERROR_MENU,
        error: error,
    }
}

export function fetchMenu(idMenu) {
    return dispatch => {
        dispatch(requestMenu());
        return menusApi.getOne(idMenu)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMenu(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorMenu(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMenu(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_MENU = 'RECEIVE_FILE_MENU';

function receiveFileMenu(file) {
    return {
        type: RECEIVE_FILE_MENU,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileMenu(idMenu, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return menusApi.getFile(idMenu, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileMenu(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMenu(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMenu(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_MENU = 'UPDATE_MENU';
export const REQUEST_UPDATE_MENU = "REQUEST_UPDATE_MENU";
export const SUCCESS_UPDATE_MENU = "SUCCESS_UPDATE_MENU";
export const ERROR_UPDATE_MENU = "ERROR_UPDATE_MENU";
export const RESET_UPDATE_MENU = "RESET_UPDATE_MENU";
export const DELETE_UPDATE_MENU = "DELETE_UPDATE_MENU";

function requestUpdateMenu() {
    return {
        type: REQUEST_UPDATE_MENU,
    }
}

function receiveUpdateMenu(menu) {
    return {
        type: SUCCESS_UPDATE_MENU,
        receivedAt: Date.now(),
        menu: normalizeDato(menu)
    }
}

function errorUpdateMenu(error) {
    return {
        type: ERROR_UPDATE_MENU,
        error: error,
    }
}

export function resetUpdateMenu() {
    return {
        type: RESET_UPDATE_MENU,
    }
}

export function updateMenu(menu) {
    return {
        type: UPDATE_MENU,
        menu
    }
}

export function saveUpdateMenu() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMenu());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let menu = denormalizeDato(getState().menus.update.activo, store);

        return menusApi.saveUpdate(menu)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMenu(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateMenu(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateMenus(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateMenus(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateMenu(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateMenu(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateMenu(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateMenu(menu) {
    return {
        type: DELETE_UPDATE_MENU,
        menu
    }
}

//UPDATE MENUS
export const REQUEST_UPDATE_MENUS = "REQUEST_UPDATE_MENUS";
export const SUCCESS_UPDATE_MENUS = "SUCCESS_UPDATE_MENUS";
export const ERROR_UPDATE_MENUS = "ERROR_UPDATE_MENUS";
export const RESET_UPDATE_MENUS = "RESET_UPDATE_MENUS";

function requestUpdateMenus() {
    return {
        type: REQUEST_UPDATE_MENUS,
}
}

function receiveUpdateMenus(menus) {
    return {
        type: SUCCESS_UPDATE_MENUS,
    receivedAt: Date.now(),
        menus: normalizeDatos(menus)
}
}

function errorUpdateMenus(error) {
    return {
        type: ERROR_UPDATE_MENUS,
    error: error,
}
}

export function resetUpdateMenus() {
    return {
        type: RESET_UPDATE_MENUS,
}
}

export function saveUpdateMenus() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMenus());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let menus = getState().menus.update.activos.map((idMenu) => {
            return denormalizeDato(getState().menus.update.activo[idMenu], store);
        });

        return menusApi.saveUpdateMenus(menus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMenus(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateMenus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateMenus(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateMenus(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateMenus(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateMenus(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA MENU
export const CREATE_MENU = 'CREATE_MENU';
export const REQUEST_CREATE_MENU = "REQUEST_CREATE_MENU";
export const SUCCESS_CREATE_MENU = "SUCCESS_CREATE_MENU";
export const ERROR_CREATE_MENU = "ERROR_CREATE_MENU";
export const RESET_CREATE_MENU = "RESET_CREATE_MENU";
export const DELETE_CREATE_MENU = "DELETE_CREATE_MENU";


//ALTA MENU
function requestCreateMenu() {
    return {
        type: REQUEST_CREATE_MENU,
    }
}

function receiveCreateMenu(menu) {
    return {
        type: SUCCESS_CREATE_MENU,
        receivedAt: Date.now(),
        menu: normalizeDato(menu)
    }
}

export function errorCreateMenu(error) {
    return {
        type: ERROR_CREATE_MENU,
        error: error,
    }
}

export function resetCreateMenu() {
    return {
        type: RESET_CREATE_MENU
    }
}

export function createMenu(menu) {
    return {
        type: CREATE_MENU,
        menu
    }
}

export function deleteCreateMenu(menu) {
    return {
        type: DELETE_CREATE_MENU,
        menu
    }
}

export function saveCreateMenu() {
    return (dispatch, getState) => {
        dispatch(requestCreateMenu());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let menu = denormalizeDato(getState().menus.create.nuevo, store);

        return menusApi.saveCreate(menu)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMenu(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateMenu(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateMenus(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateMenus(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateMenu(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateMenu(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateMenu(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE MENUS
export const REQUEST_CREATE_MENUS = "REQUEST_CREATE_MENUS";
export const SUCCESS_CREATE_MENUS = "SUCCESS_CREATE_MENUS";
export const ERROR_CREATE_MENUS = "ERROR_CREATE_MENUS";
export const RESET_CREATE_MENUS = "RESET_CREATE_MENUS";

function requestCreateMenus() {
    return {
        type: REQUEST_CREATE_MENUS,
}
}

function receiveCreateMenus(menus) {
    return {
        type: SUCCESS_CREATE_MENUS,
    receivedAt: Date.now(),
        menus: normalizeDatos(menus)
}
}

function errorCreateMenus(error) {
    return {
        type: ERROR_CREATE_MENUS,
    error: error,
}
}

export function resetCreateMenus() {
    return {
        type: RESET_CREATE_MENUS,
}
}

export function saveCreateMenus() {
    return (dispatch, getState) => {
        dispatch(requestCreateMenus());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let menus = getState().menus.create.nuevos.map((idMenu) => {
            return denormalizeDato(getState().menus.create.nuevo[idMenu], store);
        });

        return menusApi.saveCreateMenus(menus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMenus(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateMenus(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateMenus(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateMenus(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateMenus(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateMenus(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE MENU
export const DELETE_MENU = 'DELETE_MENU';
export const REQUEST_DELETE_MENU = "REQUEST_DELETE_MENU";
export const SUCCESS_DELETE_MENU = "SUCCESS_DELETE_MENU";
export const ERROR_DELETE_MENU = "ERROR_DELETE_MENU";
export const RESET_DELETE_MENU = "RESET_DELETE_MENU";

function requestDeleteMenu() {
    return {
        type: REQUEST_DELETE_MENU,
    }
}

function receiveDeleteMenu(menu) {
    return {
        type: SUCCESS_DELETE_MENU,
        receivedAt: Date.now(),
        menu: normalizeDato(menu)
    }
}

function errorDeleteMenu(error) {
    return {
        type: ERROR_DELETE_MENU,
        error: error,
    }
}

export function resetDeleteMenu(error) {
    return {
        type: RESET_DELETE_MENU,
        error: error,
    }
}

export function deleteMenu(menu) {
    return {
        type: DELETE_MENU,
        menu
    }
}

export function saveDeleteMenu(menu) {
    return dispatch => {
        dispatch(requestDeleteMenu());
        return menusApi.saveDelete(menu)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteMenu());
                            dispatch(receiveDeleteMenu(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteMenu(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteMenu(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteMenu(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteMenu(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteMenu(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT MENU
export const REQUEST_PRINT_MENU = "REQUEST_PRINT_MENU";
export const SUCCESS_PRINT_MENU = "SUCCESS_PRINT_MENU";
export const ERROR_PRINT_MENU = "ERROR_PRINT_MENU";
export const RESET_PRINT_MENU = "RESET_PRINT_MENU";

function requestPrintMenu() {
    return {
        type: REQUEST_PRINT_MENU,
    }
}

function receivePrintMenu(turnos) {
    return {
        type: SUCCESS_PRINT_MENU,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintMenu(error) {
    return {
        type: ERROR_PRINT_MENU,
        error: error,
    }
}

export function resetPrintMenu() {
    return {
        type: RESET_PRINT_MENU,
    }
}

export function printMenu(idMenu) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintMenu());
        return menusApi.printMenu(idMenu)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintMenu(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintMenu(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintMenu(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintMenu(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintMenu(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}