import {resetProductsAttributesValuesLanguages, resetCreateProductAttributeValueLanguage, resetUpdateProductAttributeValueLanguage, resetDeleteProductAttributeValueLanguage} from "../actions/ProductAttributeValueLanguageActions";
import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";

var resetProductAttributeValueLanguage = {
    resetAll(dispatch) {
        dispatch(resetProductsAttributesValuesLanguages());
        dispatch(resetCreateProductAttributeValueLanguage());
        dispatch(resetUpdateProductAttributeValueLanguage());
        dispatch(resetDeleteProductAttributeValueLanguage());
       dispatch(resetProducts());
dispatch(resetCreateProduct());
dispatch(resetUpdateProduct());
dispatch(resetDeleteProduct());
    },
};

export default resetProductAttributeValueLanguage;