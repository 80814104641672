import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
//Components
import {
    Chip,
    IconButton,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import Grow from "../../../library/Grow";
import CustomPaper from "../../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../../library/CustomPaper/PaperHeader";
import PaperFooter from "../../../library/CustomPaper/PaperFooter";
import Box from "@material-ui/core/Box";
import {Skeleton} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import BannerPreviewType from "../PreviewsTypesElements/BannerPreviewType";
import HTMLPreviewType from "../PreviewsTypesElements/HTMLPreviewType";
import FilePreviewType from "../PreviewsTypesElements/FilePreviewType";
import ProductPreviewType from "../PreviewsTypesElements/ProductPreviewType";

import Spacing from "../../../library/Spacing";
//Img
import CropFreeIcon from "@material-ui/icons/Search";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import PhoneIcon from "@material-ui/icons/Phone";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import DoneIcon from "@material-ui/icons/Done";
//reset
//Actions
import {fetchAssets, invalidateAssets, resetAssets, saveDeleteAsset} from "../../../actions/AssetActions";
import {fetchFileAssetLanguage} from "../../../actions/AssetLanguageActions";
import CRUDEmbeddedtAsset from "./CRUDEmbeddedtAsset";

let moment = require('moment');

const useStyles = makeStyles(theme => ({
    typographyLanguage: {
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "flex-start",
            flexWrap: "nowrap",
        },
    }
}));

export default function EmbeddedAssetsList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {id, fk} = props;

    //Store
    const assets = useSelector(state => state.assets);
    const assetsLanguages = useSelector(state => state.assetsLanguages);
    const assetsCountries = useSelector(state => state.assetsCountries);
    const languages = useSelector(state => state.languages);
    const countries = useSelector(state => state.countries);
    const typesFiles = useSelector(state => state.typesFiles);
    const typesFilesSections = useSelector(state => state.typesFilesSections);
    const products = useSelector(state => state.products);

    //State
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState("");
    const [searchFetch, setSearchFetch] = useState("");
    const [order, setOrder] = useState("id");
    const [direction, setDirection] = useState("asc");
    const [page, setPage] = useState(1);
    const [idAsset, setIdAsset] = useState(null);

    const disabled = assets.byId.isFetching || assets.delete.isDeleting;

    //Hooks
    const timer = useRef();

    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: search ? search : "",
            order: order,
            direction: direction,
            page: page,
            limit: 10,
            with: ["assetLanguage", "assetCountry", "typeFile", "product"]
        };
        filtros[fk] = id;

        dispatch(resetAssets());
        dispatch(invalidateAssets());
        if (id)
            dispatch(fetchAssets(filtros));
    }, [searchFetch, page, order, direction]);


    useEffect(() => {
        if (assets.byId.isFetching === false) {
            fetchFiles();
        }
    }, [assets.byId.isFetching]);

    const fetchFiles = () => {
        let countFiles = 0;
        assets.allIds.forEach((idAsset) => {
            let asset = assets.byId.assets[idAsset];
            let typeFile = asset ? typesFiles.byId.typesFiles[asset.idTypeFile] : null;
            if (asset && asset.asset_language && typeFile) {
                asset.asset_language.forEach((idAssetLanguage) => {
                    let assetLanguage = assetsLanguages.byId.assetsLanguages[idAssetLanguage];
                    if (assetLanguage && assetLanguage.filenameDesktop)
                        countFiles++;
                    if (assetLanguage && assetLanguage.filenameMobile)
                        countFiles++;
                    if (assetLanguage && assetLanguage.filename)
                        countFiles++;
                })
                if (asset.asset_language && (assetsLanguages.byId.files.size !== countFiles)) {
                    asset.asset_language.forEach((idDocumentoAtencion, i) => {
                        if (typeFile.codType === 'BANNER') {
                            dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "mobile"}));
                            dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "desktop"}));
                        } else {
                            dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "file"}));
                        }
                    })
                }
            }
        })
    }

    const changeOrder = (or) => {
        setOrder(or ? or : "");
        setDirection(direction === "asc" ? "desc" : "asc");
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {

        if ((!searchPhase && !search) || (searchPhase === "")) {
            setSearchFetch("");
        } else {
            setPage(1);
            setSearchFetch(searchPhase ? searchPhase : searchFetch);
        }
    };

    const handleChangePage = (e, page) => {
        setPage(page ? page : "1");
    };

    const handleChangeEstado = (idAsset) => {
        let asset = assets.byId.assets[idAsset];
        dispatch(saveDeleteAsset(asset));
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpenChangeAsset = (idAsset) => {
        handleClickOpen();
        setIdAsset(idAsset);
    };

    let Assets = [];

    if (assets.byId.isFetching) {
        Assets = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(10).keys()].map((i) =>
                    <TableCell><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    if (!assets.byId.isFetching)
        Assets = assets.allIds.map(idAsset => {
            let asset = assets.byId.assets[idAsset];
            let typeFile = asset ? typesFiles.byId.typesFiles[asset.idTypeFile] : null;
            let product = asset ? products.byId.products[asset.idProduct] : null;
            let assetLanguages = [];
            if (typeFile.codType === 'PRODUCT')
                assetLanguages.push(<ProductPreviewType product={product}/>)
            if (typeFile.codType === 'BANNER' && asset.asset_language.length > 0) {
                let assetLanguage = assetsLanguages.byId.assetsLanguages[asset.asset_language[0]];
                let language = assetLanguage ? languages.byId.languages[assetLanguage.idLanguage] : null;
                if (!language)
                    language = {id: null, desLanguage: "Multi-idioma", unicodeIcon: "🏳️‍🌈"};
                let fileDesktop = assetLanguage ? assetsLanguages.byId.files[assetLanguage.filenameDesktop] : null;
                let fileMobile = assetLanguage ? assetsLanguages.byId.files[assetLanguage.filenameMobile] : null;
                assetLanguages.push(<BannerPreviewType fileDesktop={fileDesktop} fileMobile={fileMobile}
                                                       language={language}
                                                       assetLanguage={assetLanguage} showImages={true}/>);
            }
            assetLanguages.push(asset.asset_language.map((idAssetLanguage, i) => {
                let assetLanguage = assetsLanguages.byId.assetsLanguages[idAssetLanguage];
                let language = assetLanguage ? languages.byId.languages[assetLanguage.idLanguage] : null;
                if (!language)
                    language = {id: null, desLanguage: "Multi-idioma", unicodeIcon: "🏳️‍🌈"};
                if (assetLanguage && assetLanguage.status === 1 && typeFile)

                    switch (typeFile.codType) {
                        case "BANNER":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "HTML":
                            return <HTMLPreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "PDF":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "IMAGE":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "CSV":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "OFFICE":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "PRODUCT":
                            return <HTMLPreviewType language={language} assetLanguage={assetLanguage}/>;
                    }

            }))
            let assetCountries = asset.asset_country.map((idAssetCountry) => {
                let assetCountry = assetsCountries.byId.assetsCountries[idAssetCountry];
                let country = assetCountry ? countries.byId.countries[assetCountry.idCountry] : null;
                if (country)
                    return (
                        country.unicodeIcon
                    )
            })
            return (
                <TableRow key={asset.id}>
                    <TableCell>{asset ? asset.id : ""}</TableCell>
                    <TableCell>{asset ? asset.nameAsset : ""}</TableCell>
                    <TableCell>{asset ? asset.order : ""}</TableCell>
                    <TableCell>{typeFile ? typeFile.nameType : ""}</TableCell>
                    <TableCell>{asset && asset.type ? asset.type : ""}</TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                            {
                                assetLanguages
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"column"} flexWrap={"wrap"}>
                            {
                                assetCountries
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            {asset ? (moment(asset.dateFrom).format("DD-MM-YYYY") + (asset.dateTo ? "/" + moment(asset.dateTo).format("DD-MM-YYYY") : "")) : ""}
                            {
                                ((!asset.dateTo && moment(asset.dateFrom).isSameOrBefore(moment())) || moment().isBetween(moment(asset.dateFrom), moment(asset.dateTo)))
                                &&
                                <Chip
                                    icon={<DoneIcon/>}
                                    label="Vigente"
                                    color="primary"
                                    variant="outlined"
                                    style={{marginBottom: "20px"}}
                                    size={"small"}
                                />
                            }
                            {
                                !((!asset.dateTo && moment(asset.dateFrom).isSameOrBefore(moment())) || moment().isBetween(moment(asset.dateFrom), moment(asset.dateTo)))
                                &&
                                <Chip
                                    icon={<DoneIcon/>}
                                    label="Fuera de fecha"
                                    color="secondary"
                                    variant="outlined"
                                    style={{marginBottom: "20px"}}
                                    size={"small"}
                                />
                            }
                        </Box></TableCell>
                    <TableCell>
                        <Switch
                            disabled={disabled}
                            checked={!!asset.status}
                            onChange={() => handleChangeEstado(asset.id)}
                            name={"estado"}
                            color={"primary"}
                            size={"small"}
                        />
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Editar">
                            <IconButton aria-label="editar" size={"small"} disabled={disabled}
                                        onClick={() => handleOpenChangeAsset(idAsset)}>
                                <EditIcon color="secondary"/>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )
        });

    if (Assets.length === 0)
        Assets.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={11}>No se encontraron contenidos</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(assets.totalAssets ? assets.totalAssets / 10 : 0);


    return (
        <Box>
            <CustomPaper>
                <PaperHeader>
                    <Typography variant={"overline"} component={"span"}>
                        Contenido
                    </Typography>
                </PaperHeader>
                <PaperHeader>
                    <TextField
                        id="search"
                        placeholder="Buscar ..."
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CropFreeIcon/>
                                    </IconButton>
                                </InputAdornment>,
                            disabled: disabled
                        }}
                        value={search ? search : ""}
                        onChange={(e) => changeSearch(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Spacing/>
                    <Grow/>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddIcon/>}
                        onClick={handleClickOpen}
                        disabled={disabled || !id}
                    >
                        Agregar
                    </Button>
                </PaperHeader>
                <Box component={"div"} width={"100%"} padding={"0 24px 5px 24px"}>

                </Box>
                <TableContainer>
                    <Table aria-label="Tabla de contenido">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "id"}
                                        direction={direction}
                                        onClick={() => changeOrder("id")}
                                    >
                                        <b>ID</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "nameAsset"}
                                        direction={direction}
                                        onClick={() => changeOrder("nameAsset")}
                                    >
                                        <b>Nombre</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"50px"}>
                                    <b>Orden</b>
                                </TableCell>
                                <TableCell width={"100px"}>
                                    <b>Tipo</b>
                                </TableCell>
                                <TableCell width={"100px"}>
                                    <b>Categoría</b>
                                </TableCell>
                                <TableCell width={"500px"}>
                                    <b>Idiomas</b>
                                </TableCell>
                                <TableCell width={"100px"}>
                                    <b>Países</b>
                                </TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell width={"10px"}/>
                                <TableCell width={"10px"}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Assets
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaperFooter style={{justifyContent: "center"}}>
                    {
                        !!totalPaginas && totalPaginas > 1 &&
                        <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                    onChange={handleChangePage} size={"small"}/>
                    }
                </PaperFooter>
            </CustomPaper>
            <CRUDEmbeddedtAsset open={open} setOpen={setOpen} id={id} fk={fk} idAsset={idAsset}
                                setIdAsset={setIdAsset}/>
        </Box>
    );
}
