import { combineReducers } from 'redux'
import auth from "./authReducer";
import assets from "./assets";
import assetsCountries from "./assetsCountries";
import assetsLanguages from "./assetsLanguages";
import pages from "./pages";
import sections from "./sections";
import languages from "./languages";
import countries from "./countries";
import typesFiles from "./typesFiles";
import typesFilesSections from "./typesFilesSections";
import products from "./products";
import categories from "./categories";
import productsCountries from "./productsCountries";
import productsLanguages from "./productsLanguages";
import categoriesLanguages from "./categoriesLanguages";
import attributes from "./attributes";
import attributesGroups from "./attributesGroups";
import productsAttributesValues from "./productsAttributesValues";
import productsAttributesValuesLanguages from "./productsAttributesValuesLanguages";
import attributesLanguages from "./attributesLanguages";
import associatedProducts from "./associatedProducts";
import posts from "./posts";
import postsCountries from "./postsCountries";
import postsLanguages from "./postsLanguages";
import logs from "./logs";
import users from "./users";
import roles from "./roles";
import menus from "./menus";
import permissions from "./permissions";

const appReducers = combineReducers({
    auth,
    assets,
    assetsCountries,
    assetsLanguages,
    associatedProducts,
    attributes,
    attributesLanguages,
    attributesGroups,
    categories,
    categoriesLanguages,
    countries,
    languages,
    logs,
    menus,
    pages,
    permissions,
    posts,
    postsLanguages,
    postsCountries,
    products,
    productsAttributesValues,
    productsAttributesValuesLanguages,
    productsCountries,
    productsLanguages,
    roles,
    sections,
    typesFiles,
    typesFilesSections,
    users
});

export default appReducers;