import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, TextField, Typography} from '@material-ui/core';
//Icons
//Img


const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "100px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        width: "100%",
        maxWidth: "200px",
        height: "120px",
        paddingLeft: "6px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));


export default function URL(props) {
    const classes = styles();
    const {
        onChange,
        language,
        assetLanguage,
    } = props;

    return (
        <Box component={"div"} className={classes.form}>
            <Typography
                variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <TextField
                id="text1"
                placeholder="Descripción del link"
                variant="outlined"
                label={"Descripción del link"}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // disabled: disabled
                }}
                fullWidth
                style={{margin: "12px 0 8px 0"}}
                value={assetLanguage ? assetLanguage.text1 : ""}
                onChange={(e) => onChange(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                // onKeyDown={(e) => handleKeyDown(e)}
            />
            <TextField
                id="urlAction"
                placeholder="URL"
                variant="outlined"
                label={"URL"}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // disabled: disabled
                }}
                fullWidth
                style={{margin: "12px 0 8px 0"}}
                value={assetLanguage ? assetLanguage.urlAction : ""}
                onChange={(e) => onChange(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                // onKeyDown={(e) => handleKeyDown(e)}
            />
        </Box>
    );
}

URL.propTypes = {
    legend: PropTypes.string,
}