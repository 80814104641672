//api
import permissionsApi from "../api/permissionsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizePermissions";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PERMISSIONS
export const REQUEST_PERMISSIONS = 'REQUEST_PERMISSIONS';
export const RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS';
export const INVALIDATE_PERMISSIONS = 'INVALIDATE_PERMISSIONS';
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS";
export const RESET_PERMISSIONS = "RESET_PERMISSIONS";


export function invalidatePermissions() {
    return {
        type: INVALIDATE_PERMISSIONS
    }
}

function requestPermissions() {
    return {
        type: REQUEST_PERMISSIONS,
    }
}

function receivePermissions(json) {
    return {
        type: RECEIVE_PERMISSIONS,
    permissions: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPermissions(error) {
    return {
        type: ERROR_PERMISSIONS,
        error: error,
    }
}

export function resetPermissions() {
    return {
        type: RESET_PERMISSIONS
    }
}

export function fetchPermissions(filtros) {
    return dispatch => {
        dispatch(requestPermissions());
        return permissionsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePermissions(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPermissions(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPermissions(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPermissions(state) {
    const permissions = state.permissions.byId;
    if (!permissions) {
        return true
    } else if (permissions.isFetching) {
        return false
    } else {
        return permissions.didInvalidate;
    }
}

export function fetchPermissionsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPermissions(getState())) {
            return dispatch(fetchPermissions(filtros))
        }
    }
}


//MODEL
export const REQUEST_PERMISSION = 'REQUEST_PERMISSION';
export const RECEIVE_PERMISSION = 'RECEIVE_PERMISSION';
export const INVALIDATE_PERMISSION = 'INVALIDATE_PERMISSION';
export const ERROR_PERMISSION = "ERROR_PERMISSION";
export const RESET_PERMISSION = "RESET_PERMISSION";


export function invalidatePermission() {
    return {
        type: INVALIDATE_PERMISSION
    }
}

function requestPermission() {
    return {
        type: REQUEST_PERMISSION,
    }
}

export function receivePermission(json) {
    return {
        type: RECEIVE_PERMISSION,
    permission: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPermission(error) {
    return {
        type: ERROR_PERMISSION,
        error: error,
    }
}

export function fetchPermission(idPermission) {
    return dispatch => {
        dispatch(requestPermission());
        return permissionsApi.getOne(idPermission)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePermission(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPermission(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPermission(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PERMISSION = 'RECEIVE_FILE_PERMISSION';

function receiveFilePermission(file) {
    return {
        type: RECEIVE_FILE_PERMISSION,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFilePermission(idPermission, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return permissionsApi.getFile(idPermission, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFilePermission(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPermission(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPermission(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const REQUEST_UPDATE_PERMISSION = "REQUEST_UPDATE_PERMISSION";
export const SUCCESS_UPDATE_PERMISSION = "SUCCESS_UPDATE_PERMISSION";
export const ERROR_UPDATE_PERMISSION = "ERROR_UPDATE_PERMISSION";
export const RESET_UPDATE_PERMISSION = "RESET_UPDATE_PERMISSION";
export const DELETE_UPDATE_PERMISSION = "DELETE_UPDATE_PERMISSION";

function requestUpdatePermission() {
    return {
        type: REQUEST_UPDATE_PERMISSION,
    }
}

function receiveUpdatePermission(permission) {
    return {
        type: SUCCESS_UPDATE_PERMISSION,
        receivedAt: Date.now(),
        permission: normalizeDato(permission)
    }
}

function errorUpdatePermission(error) {
    return {
        type: ERROR_UPDATE_PERMISSION,
        error: error,
    }
}

export function resetUpdatePermission() {
    return {
        type: RESET_UPDATE_PERMISSION,
    }
}

export function updatePermission(permission) {
    return {
        type: UPDATE_PERMISSION,
        permission
    }
}

export function saveUpdatePermission() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePermission());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let permission = denormalizeDato(getState().permissions.update.activo, store);

        return permissionsApi.saveUpdate(permission)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePermission(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdatePermission(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdatePermissions(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdatePermissions(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdatePermission(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdatePermission(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdatePermission(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdatePermission(permission) {
    return {
        type: DELETE_UPDATE_PERMISSION,
        permission
    }
}

//UPDATE PERMISSIONS
export const REQUEST_UPDATE_PERMISSIONS = "REQUEST_UPDATE_PERMISSIONS";
export const SUCCESS_UPDATE_PERMISSIONS = "SUCCESS_UPDATE_PERMISSIONS";
export const ERROR_UPDATE_PERMISSIONS = "ERROR_UPDATE_PERMISSIONS";
export const RESET_UPDATE_PERMISSIONS = "RESET_UPDATE_PERMISSIONS";

function requestUpdatePermissions() {
    return {
        type: REQUEST_UPDATE_PERMISSIONS,
}
}

function receiveUpdatePermissions(permissions) {
    return {
        type: SUCCESS_UPDATE_PERMISSIONS,
    receivedAt: Date.now(),
        permissions: normalizeDatos(permissions)
}
}

function errorUpdatePermissions(error) {
    return {
        type: ERROR_UPDATE_PERMISSIONS,
    error: error,
}
}

export function resetUpdatePermissions() {
    return {
        type: RESET_UPDATE_PERMISSIONS,
}
}

export function saveUpdatePermissions() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePermissions());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let permissions = getState().permissions.update.activos.map((idPermission) => {
            return denormalizeDato(getState().permissions.update.activo[idPermission], store);
        });

        return permissionsApi.saveUpdatePermissions(permissions)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePermissions(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePermissions(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdatePermissions(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdatePermissions(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdatePermissions(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdatePermissions(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PERMISSION
export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const REQUEST_CREATE_PERMISSION = "REQUEST_CREATE_PERMISSION";
export const SUCCESS_CREATE_PERMISSION = "SUCCESS_CREATE_PERMISSION";
export const ERROR_CREATE_PERMISSION = "ERROR_CREATE_PERMISSION";
export const RESET_CREATE_PERMISSION = "RESET_CREATE_PERMISSION";
export const DELETE_CREATE_PERMISSION = "DELETE_CREATE_PERMISSION";


//ALTA PERMISSION
function requestCreatePermission() {
    return {
        type: REQUEST_CREATE_PERMISSION,
    }
}

function receiveCreatePermission(permission) {
    return {
        type: SUCCESS_CREATE_PERMISSION,
        receivedAt: Date.now(),
        permission: normalizeDato(permission)
    }
}

export function errorCreatePermission(error) {
    return {
        type: ERROR_CREATE_PERMISSION,
        error: error,
    }
}

export function resetCreatePermission() {
    return {
        type: RESET_CREATE_PERMISSION
    }
}

export function createPermission(permission) {
    return {
        type: CREATE_PERMISSION,
        permission
    }
}

export function deleteCreatePermission(permission) {
    return {
        type: DELETE_CREATE_PERMISSION,
        permission
    }
}

export function saveCreatePermission() {
    return (dispatch, getState) => {
        dispatch(requestCreatePermission());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let permission = denormalizeDato(getState().permissions.create.nuevo, store);

        return permissionsApi.saveCreate(permission)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePermission(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreatePermission(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreatePermissions(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreatePermissions(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreatePermission(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreatePermission(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreatePermission(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PERMISSIONS
export const REQUEST_CREATE_PERMISSIONS = "REQUEST_CREATE_PERMISSIONS";
export const SUCCESS_CREATE_PERMISSIONS = "SUCCESS_CREATE_PERMISSIONS";
export const ERROR_CREATE_PERMISSIONS = "ERROR_CREATE_PERMISSIONS";
export const RESET_CREATE_PERMISSIONS = "RESET_CREATE_PERMISSIONS";

function requestCreatePermissions() {
    return {
        type: REQUEST_CREATE_PERMISSIONS,
}
}

function receiveCreatePermissions(permissions) {
    return {
        type: SUCCESS_CREATE_PERMISSIONS,
    receivedAt: Date.now(),
        permissions: normalizeDatos(permissions)
}
}

function errorCreatePermissions(error) {
    return {
        type: ERROR_CREATE_PERMISSIONS,
    error: error,
}
}

export function resetCreatePermissions() {
    return {
        type: RESET_CREATE_PERMISSIONS,
}
}

export function saveCreatePermissions() {
    return (dispatch, getState) => {
        dispatch(requestCreatePermissions());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let permissions = getState().permissions.create.nuevos.map((idPermission) => {
            return denormalizeDato(getState().permissions.create.nuevo[idPermission], store);
        });

        return permissionsApi.saveCreatePermissions(permissions)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePermissions(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePermissions(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreatePermissions(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreatePermissions(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreatePermissions(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreatePermissions(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PERMISSION
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const REQUEST_DELETE_PERMISSION = "REQUEST_DELETE_PERMISSION";
export const SUCCESS_DELETE_PERMISSION = "SUCCESS_DELETE_PERMISSION";
export const ERROR_DELETE_PERMISSION = "ERROR_DELETE_PERMISSION";
export const RESET_DELETE_PERMISSION = "RESET_DELETE_PERMISSION";

function requestDeletePermission() {
    return {
        type: REQUEST_DELETE_PERMISSION,
    }
}

function receiveDeletePermission(permission) {
    return {
        type: SUCCESS_DELETE_PERMISSION,
        receivedAt: Date.now(),
        permission: normalizeDato(permission)
    }
}

function errorDeletePermission(error) {
    return {
        type: ERROR_DELETE_PERMISSION,
        error: error,
    }
}

export function resetDeletePermission(error) {
    return {
        type: RESET_DELETE_PERMISSION,
        error: error,
    }
}

export function deletePermission(permission) {
    return {
        type: DELETE_PERMISSION,
        permission
    }
}

export function saveDeletePermission(permission) {
    return dispatch => {
        dispatch(requestDeletePermission());
        return permissionsApi.saveDelete(permission)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeletePermission());
                            dispatch(receiveDeletePermission(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeletePermission(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeletePermission(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeletePermission(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeletePermission(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeletePermission(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PERMISSION
export const REQUEST_PRINT_PERMISSION = "REQUEST_PRINT_PERMISSION";
export const SUCCESS_PRINT_PERMISSION = "SUCCESS_PRINT_PERMISSION";
export const ERROR_PRINT_PERMISSION = "ERROR_PRINT_PERMISSION";
export const RESET_PRINT_PERMISSION = "RESET_PRINT_PERMISSION";

function requestPrintPermission() {
    return {
        type: REQUEST_PRINT_PERMISSION,
    }
}

function receivePrintPermission(turnos) {
    return {
        type: SUCCESS_PRINT_PERMISSION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintPermission(error) {
    return {
        type: ERROR_PRINT_PERMISSION,
        error: error,
    }
}

export function resetPrintPermission() {
    return {
        type: RESET_PRINT_PERMISSION,
    }
}

export function printPermission(idPermission) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintPermission());
        return permissionsApi.printPermission(idPermission)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintPermission(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintPermission(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintPermission(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintPermission(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintPermission(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}