import {
    INVALIDATE_PRODUCTSLANGUAGES,
    ERROR_PRODUCTSLANGUAGES,
    RECEIVE_PRODUCTSLANGUAGES,
    REQUEST_PRODUCTSLANGUAGES,
    RESET_PRODUCTSLANGUAGES,
    ERROR_PRODUCTLANGUAGE,
    RECEIVE_PRODUCTLANGUAGE,
    REQUEST_PRODUCTLANGUAGE,
    UPDATE_PRODUCTLANGUAGE,
    REQUEST_UPDATE_PRODUCTLANGUAGE,
    SUCCESS_UPDATE_PRODUCTLANGUAGE,
    ERROR_UPDATE_PRODUCTLANGUAGE,
    RESET_UPDATE_PRODUCTLANGUAGE,
    REQUEST_UPDATE_PRODUCTSLANGUAGES,
    SUCCESS_UPDATE_PRODUCTSLANGUAGES,
    ERROR_UPDATE_PRODUCTSLANGUAGES,
    RESET_UPDATE_PRODUCTSLANGUAGES,
    CREATE_PRODUCTLANGUAGE,
    ERROR_CREATE_PRODUCTLANGUAGE,
    REQUEST_CREATE_PRODUCTLANGUAGE,
    RESET_CREATE_PRODUCTLANGUAGE,
    SUCCESS_CREATE_PRODUCTLANGUAGE,
    REQUEST_CREATE_PRODUCTSLANGUAGES,
    SUCCESS_CREATE_PRODUCTSLANGUAGES,
    ERROR_CREATE_PRODUCTSLANGUAGES,
    RESET_CREATE_PRODUCTSLANGUAGES,
    DELETE_PRODUCTLANGUAGE,
    DELETE_CREATE_PRODUCTLANGUAGE,
    DELETE_UPDATE_PRODUCTLANGUAGE,
    REQUEST_DELETE_PRODUCTLANGUAGE,
    SUCCESS_DELETE_PRODUCTLANGUAGE,
    ERROR_DELETE_PRODUCTLANGUAGE,
    RESET_DELETE_PRODUCTLANGUAGE,
    REQUEST_PRINT_PRODUCTLANGUAGE,
    SUCCESS_PRINT_PRODUCTLANGUAGE,
    ERROR_PRINT_PRODUCTLANGUAGE,
    RESET_PRINT_PRODUCTLANGUAGE,
    RECEIVE_FILE_PRODUCTLANGUAGE
} from '../actions/ProductLanguageActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productsLanguages: {},
        files: {},
    }
}

function productsLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productsLanguages: {}
            });
        case RECEIVE_PRODUCTSLANGUAGES:
            let dato = action.productsLanguages.entities.productsLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productsLanguages: merge({}, state.productsLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTLANGUAGE:
            let datoProductLanguage = action.productLanguage.entities.productsLanguages;
            return Object.assign({}, state, {
                productsLanguages: merge({}, state.productsLanguages, datoProductLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PRODUCTLANGUAGE:
            let datoProductLanguageEliminado = action.productLanguage.entities.productsLanguages;
            return Object.assign({}, state, {
                productsLanguages: mergeWith(clone(datoProductLanguageEliminado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTLANGUAGE:
            let datoProductLanguageCreado = action.productLanguage.entities.productsLanguages;
            return Object.assign({}, state, {
                productsLanguages: mergeWith(clone(datoProductLanguageCreado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTSLANGUAGES:
            let datosProductLanguageCreado = action.productsLanguages.entities.productsLanguages;
                return Object.assign({}, state, {
                    productsLanguages: mergeWith(clone(datosProductLanguageCreado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTLANGUAGE:
            let datoProductLanguageActualizado = action.productLanguage.entities.productsLanguages;
            return Object.assign({}, state, {
                productsLanguages: mergeWith(clone(datoProductLanguageActualizado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTSLANGUAGES:
            let datosProductLanguageActualizado = action.productsLanguages.entities.productsLanguages;
                return Object.assign({}, state, {
                    productsLanguages: mergeWith(clone(datosProductLanguageActualizado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : {};
    return Object.assign({}, state, {
        productsLanguages: merge({}, state.productsLanguages, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsLanguages ? action.products.entities.productsLanguages : {};
    return Object.assign({}, state, {
        productsLanguages: merge({}, state.productsLanguages, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : {};
    return Object.assign({}, state, {
        productsLanguages: mergeWith(clone(datoproductEliminado), pickBy(state.productsLanguages, function(productLanguage) {return productLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : {};
    return Object.assign({}, state, {
        productsLanguages: mergeWith(clone(datoproductCreado), pickBy(state.productsLanguages, function(productLanguage) {return productLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : {};
    return Object.assign({}, state, {
        productsLanguages: mergeWith(clone(datoproductActualizado), pickBy(state.productsLanguages, function(productLanguage) {return productLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.productsLanguages ? action.products.entities.productsLanguages : {} ;
        return Object.assign({}, state, {
            productsLanguages: mergeWith(clone(datosproductActualizado), state.productsLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productsLanguages: {}
            });
        default:
            return state
    }
}


function allProductsLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSLANGUAGES:
            return action.productsLanguages.result && action.productsLanguages.result.productsLanguages ? union(action.productsLanguages.result.productsLanguages, state) : (action.productsLanguages.result ? action.productsLanguages.result : state) ;
        case RECEIVE_PRODUCTLANGUAGE:
                return action.productLanguage.result ? union([action.productLanguage.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTLANGUAGE:
                   let datoProductLanguageSCreate = action.productLanguage.entities.productsLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductLanguageSCreate)[0] && Object.values(datoProductLanguageSCreate)[0].id ? Object.values(datoProductLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTSLANGUAGES:
                   let productsLanguagesCreate = action.productsLanguages.entities && action.productsLanguages.entities.productsLanguages ? action.productsLanguages.entities.productsLanguages : null;
                   return productsLanguagesCreate ?
                       union(state, Object.values(productsLanguagesCreate).map((productsLanguages) => {
                           return productsLanguages.id
                       })) : state;
        case RESET_PRODUCTSLANGUAGES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsLanguages ? action.products.entities.productsLanguages : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.productsLanguages ? action.product.entities.productsLanguages : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.productsLanguages ? action.products.entities.productsLanguages : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductsLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSLANGUAGES:
            return action.productsLanguages && action.productsLanguages.result.total ? action.productsLanguages.result.total : 0;
        case RESET_PRODUCTSLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTLANGUAGE:
            let dato = action.productLanguage.entities.productsLanguages;
            let productLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productLanguage ? productLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTLANGUAGE:
            let idsUpdate = [];
            Object.values(action.productLanguage).map((productLanguageUpdate) => {
                if (productLanguageUpdate && productLanguageUpdate.id)
                    idsUpdate.push(productLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.productLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTLANGUAGE:
            let datoProductLanguageActualizado = {};
            if (Object.values(action.productLanguage.entities.productsLanguages).length > 0)
                datoProductLanguageActualizado = Object.values(action.productLanguage.entities.productsLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductLanguageActualizado
            });
        case ERROR_UPDATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_PRODUCTLANGUAGE:
            let datoProductLanguageDelete = action.productLanguage;
            let idsDelete = [];
           Object.values(action.productLanguage).map((productLanguageDelete) => {
               if (productLanguageDelete && productLanguageDelete.id)
                   idsDelete.push(productLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTLANGUAGE:
                       let datoProductLanguageDeleteUpdate = action.productLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.productLanguage).map((productLanguageDelete) => {
                          if (productLanguageDelete && productLanguageDelete.id)
                              idsDeleteUpdate.push(productLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTLANGUAGE:
                    let datoProductLanguageDeleted = {};
                    if (Object.values(action.productLanguage.entities.productsLanguages).length > 0)
                        datoProductLanguageDeleted = Object.values(action.productLanguage.entities.productsLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTLANGUAGE:
             let idsCreate = [];
             Object.values(action.productLanguage).map((productLanguageCreate) => {
                 if (productLanguageCreate && productLanguageCreate.id)
                     idsCreate.push(productLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTLANGUAGE:
            let datoProductLanguageNuevo = {};
            if (Object.values(action.productLanguage.entities.productsLanguages).length > 0)
                datoProductLanguageNuevo = Object.values(action.productLanguage.entities.productsLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTLANGUAGE:
           let datoProductLanguageDelete = action.productLanguage;
           let idsDelete = [];
           Object.values(action.productLanguage).map((productLanguageDelete) => {
               if (productLanguageDelete && productLanguageDelete.id)
                   idsDelete.push(productLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTLANGUAGE:
                  let datoProductLanguageDeleteCreate = action.productLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.productLanguage).map((productLanguageDelete) => {
                      if (productLanguageDelete && productLanguageDelete.id)
                          idsDeleteCreate.push(productLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTLANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productLanguage,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productsLanguages = combineReducers({
    byId: productsLanguagesById,
    allIds: allProductsLanguages,
    update: update,
    create: create,
    totalProductsLanguages: totalProductsLanguages,
    delete: deleter,
    print: print
});

export default productsLanguages;