import {
    INVALIDATE_ASSETSCOUNTRIES,
    ERROR_ASSETSCOUNTRIES,
    RECEIVE_ASSETSCOUNTRIES,
    REQUEST_ASSETSCOUNTRIES,
    RESET_ASSETSCOUNTRIES,
    ERROR_ASSETCOUNTRY,
    RECEIVE_ASSETCOUNTRY,
    REQUEST_ASSETCOUNTRY,
    UPDATE_ASSETCOUNTRY,
    REQUEST_UPDATE_ASSETCOUNTRY,
    SUCCESS_UPDATE_ASSETCOUNTRY,
    ERROR_UPDATE_ASSETCOUNTRY,
    RESET_UPDATE_ASSETCOUNTRY,
    REQUEST_UPDATE_ASSETSCOUNTRIES,
    SUCCESS_UPDATE_ASSETSCOUNTRIES,
    ERROR_UPDATE_ASSETSCOUNTRIES,
    RESET_UPDATE_ASSETSCOUNTRIES,
    CREATE_ASSETCOUNTRY,
    ERROR_CREATE_ASSETCOUNTRY,
    REQUEST_CREATE_ASSETCOUNTRY,
    RESET_CREATE_ASSETCOUNTRY,
    SUCCESS_CREATE_ASSETCOUNTRY,
    REQUEST_CREATE_ASSETSCOUNTRIES,
    SUCCESS_CREATE_ASSETSCOUNTRIES,
    ERROR_CREATE_ASSETSCOUNTRIES,
    RESET_CREATE_ASSETSCOUNTRIES,
    DELETE_ASSETCOUNTRY,
    DELETE_CREATE_ASSETCOUNTRY,
    DELETE_UPDATE_ASSETCOUNTRY,
    REQUEST_DELETE_ASSETCOUNTRY,
    SUCCESS_DELETE_ASSETCOUNTRY,
    ERROR_DELETE_ASSETCOUNTRY,
    RESET_DELETE_ASSETCOUNTRY,
    REQUEST_PRINT_ASSETCOUNTRY,
    SUCCESS_PRINT_ASSETCOUNTRY,
    ERROR_PRINT_ASSETCOUNTRY,
    RESET_PRINT_ASSETCOUNTRY,
    RECEIVE_FILE_ASSETCOUNTRY
} from '../actions/AssetCountryActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        assetsCountries: {},
        files: {},
    }
}

function assetsCountriesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                assetsCountries: {}
            });
        case RECEIVE_ASSETSCOUNTRIES:
            let dato = action.assetsCountries.entities.assetsCountries;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                assetsCountries: merge({}, state.assetsCountries, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ASSETCOUNTRY:
            let datoAssetCountry = action.assetCountry.entities.assetsCountries;
            return Object.assign({}, state, {
                assetsCountries: merge({}, state.assetsCountries, datoAssetCountry),
                isFetching: false,
            });
        case RECEIVE_FILE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ASSETCOUNTRY:
            let datoAssetCountryEliminado = action.assetCountry.entities.assetsCountries;
            return Object.assign({}, state, {
                assetsCountries: mergeWith(clone(datoAssetCountryEliminado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSETCOUNTRY:
            let datoAssetCountryCreado = action.assetCountry.entities.assetsCountries;
            return Object.assign({}, state, {
                assetsCountries: mergeWith(clone(datoAssetCountryCreado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSETSCOUNTRIES:
            let datosAssetCountryCreado = action.assetsCountries.entities.assetsCountries;
                return Object.assign({}, state, {
                    assetsCountries: mergeWith(clone(datosAssetCountryCreado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ASSETCOUNTRY:
            let datoAssetCountryActualizado = action.assetCountry.entities.assetsCountries;
            return Object.assign({}, state, {
                assetsCountries: mergeWith(clone(datoAssetCountryActualizado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ASSETSCOUNTRIES:
            let datosAssetCountryActualizado = action.assetsCountries.entities.assetsCountries;
                return Object.assign({}, state, {
                    assetsCountries: mergeWith(clone(datosAssetCountryActualizado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : {};
    return Object.assign({}, state, {
        assetsCountries: merge({}, state.assetsCountries, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.assetsCountries ? action.assets.entities.assetsCountries : {};
    return Object.assign({}, state, {
        assetsCountries: merge({}, state.assetsCountries, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : {};
    return Object.assign({}, state, {
        assetsCountries: mergeWith(clone(datoassetEliminado), pickBy(state.assetsCountries, function(assetCountry) {return assetCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : {};
    return Object.assign({}, state, {
        assetsCountries: mergeWith(clone(datoassetCreado), pickBy(state.assetsCountries, function(assetCountry) {return assetCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : {};
    return Object.assign({}, state, {
        assetsCountries: mergeWith(clone(datoassetActualizado), pickBy(state.assetsCountries, function(assetCountry) {return assetCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.assetsCountries ? action.assets.entities.assetsCountries : {} ;
        return Object.assign({}, state, {
            assetsCountries: mergeWith(clone(datosassetActualizado), state.assetsCountries, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                assetsCountries: {}
            });
        default:
            return state
    }
}


function allAssetsCountries(state = [], action) {
    switch (action.type) {
        case RECEIVE_ASSETSCOUNTRIES:
            return action.assetsCountries.result && action.assetsCountries.result.assetsCountries ? union(action.assetsCountries.result.assetsCountries, state) : (action.assetsCountries.result ? action.assetsCountries.result : state) ;
        case RECEIVE_ASSETCOUNTRY:
                return action.assetCountry.result ? union([action.assetCountry.result], state) : state;
        
        case SUCCESS_CREATE_ASSETCOUNTRY:
                   let datoAssetCountrySCreate = action.assetCountry.entities.assetsCountries;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAssetCountrySCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAssetCountrySCreate)[0] && Object.values(datoAssetCountrySCreate)[0].id ? Object.values(datoAssetCountrySCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ASSETSCOUNTRIES:
                   let assetsCountriesCreate = action.assetsCountries.entities && action.assetsCountries.entities.assetsCountries ? action.assetsCountries.entities.assetsCountries : null;
                   return assetsCountriesCreate ?
                       union(state, Object.values(assetsCountriesCreate).map((assetsCountries) => {
                           return assetsCountries.id
                       })) : state;
        case RESET_ASSETSCOUNTRIES:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.assetsCountries ? action.assets.entities.assetsCountries : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.assetsCountries ? action.asset.entities.assetsCountries : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.assetsCountries ? action.assets.entities.assetsCountries : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAssetsCountries(state = null, action) {
    switch (action.type) {
        case RECEIVE_ASSETSCOUNTRIES:
            return action.assetsCountries && action.assetsCountries.result.total ? action.assetsCountries.result.total : 0;
        case RESET_ASSETSCOUNTRIES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ASSETCOUNTRY:
            let dato = action.assetCountry.entities.assetsCountries;
            let assetCountry = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: assetCountry ? assetCountry : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ASSETCOUNTRY:
            let idsUpdate = [];
            Object.values(action.assetCountry).map((assetCountryUpdate) => {
                if (assetCountryUpdate && assetCountryUpdate.id)
                    idsUpdate.push(assetCountryUpdate.id);
            });
            return merge({}, state, {
                activo: action.assetCountry,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ASSETCOUNTRY:
            let datoAssetCountryActualizado = {};
            if (Object.values(action.assetCountry.entities.assetsCountries).length > 0)
                datoAssetCountryActualizado = Object.values(action.assetCountry.entities.assetsCountries)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAssetCountryActualizado
            });
        case ERROR_UPDATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ASSETSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ASSETSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ASSETSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ASSETCOUNTRY:
            let datoAssetCountryDelete = action.assetCountry;
            let idsDelete = [];
           Object.values(action.assetCountry).map((assetCountryDelete) => {
               if (assetCountryDelete && assetCountryDelete.id)
                   idsDelete.push(assetCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAssetCountryDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ASSETCOUNTRY:
                       let datoAssetCountryDeleteUpdate = action.assetCountry;
                       let idsDeleteUpdate = [];
                      Object.values(action.assetCountry).map((assetCountryDelete) => {
                          if (assetCountryDelete && assetCountryDelete.id)
                              idsDeleteUpdate.push(assetCountryDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAssetCountryDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ASSETCOUNTRY:
                    let datoAssetCountryDeleted = {};
                    if (Object.values(action.assetCountry.entities.assetsCountries).length > 0)
                        datoAssetCountryDeleted = Object.values(action.assetCountry.entities.assetsCountries)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAssetCountryDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ASSETCOUNTRY:
             let idsCreate = [];
             Object.values(action.assetCountry).map((assetCountryCreate) => {
                 if (assetCountryCreate && assetCountryCreate.id)
                     idsCreate.push(assetCountryCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.assetCountry,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ASSETCOUNTRY:
            let datoAssetCountryNuevo = {};
            if (Object.values(action.assetCountry.entities.assetsCountries).length > 0)
                datoAssetCountryNuevo = Object.values(action.assetCountry.entities.assetsCountries)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAssetCountryNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ASSETSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ASSETCOUNTRY:
           let datoAssetCountryDelete = action.assetCountry;
           let idsDelete = [];
           Object.values(action.assetCountry).map((assetCountryDelete) => {
               if (assetCountryDelete && assetCountryDelete.id)
                   idsDelete.push(assetCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAssetCountryDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ASSETCOUNTRY:
                  let datoAssetCountryDeleteCreate = action.assetCountry;
                  let idsDeleteCreate = [];
                  Object.values(action.assetCountry).map((assetCountryDelete) => {
                      if (assetCountryDelete && assetCountryDelete.id)
                          idsDeleteCreate.push(assetCountryDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAssetCountryDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ASSETCOUNTRY:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.assetCountry,
                error: null,
            });
        case REQUEST_DELETE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ASSETCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const assetsCountries = combineReducers({
    byId: assetsCountriesById,
    allIds: allAssetsCountries,
    update: update,
    create: create,
    totalAssetsCountries: totalAssetsCountries,
    delete: deleter,
    print: print
});

export default assetsCountries;