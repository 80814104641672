//api
import productsAttributesValuesApi from "../api/productsAttributesValuesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductsAttributesValues";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTSATTRIBUTESVALUES
export const REQUEST_PRODUCTSATTRIBUTESVALUES = 'REQUEST_PRODUCTSATTRIBUTESVALUES';
export const RECEIVE_PRODUCTSATTRIBUTESVALUES = 'RECEIVE_PRODUCTSATTRIBUTESVALUES';
export const INVALIDATE_PRODUCTSATTRIBUTESVALUES = 'INVALIDATE_PRODUCTSATTRIBUTESVALUES';
export const ERROR_PRODUCTSATTRIBUTESVALUES = "ERROR_PRODUCTSATTRIBUTESVALUES";
export const RESET_PRODUCTSATTRIBUTESVALUES = "RESET_PRODUCTSATTRIBUTESVALUES";


export function invalidateProductsAttributesValues() {
    return {
        type: INVALIDATE_PRODUCTSATTRIBUTESVALUES
    }
}

function requestProductsAttributesValues() {
    return {
        type: REQUEST_PRODUCTSATTRIBUTESVALUES,
    }
}

function receiveProductsAttributesValues(json) {
    return {
        type: RECEIVE_PRODUCTSATTRIBUTESVALUES,
    productsAttributesValues: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductsAttributesValues(error) {
    return {
        type: ERROR_PRODUCTSATTRIBUTESVALUES,
        error: error,
    }
}

export function resetProductsAttributesValues() {
    return {
        type: RESET_PRODUCTSATTRIBUTESVALUES
    }
}

export function fetchProductsAttributesValues(filtros) {
    return dispatch => {
        dispatch(requestProductsAttributesValues());
        return productsAttributesValuesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductsAttributesValues(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductsAttributesValues(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductsAttributesValues(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductsAttributesValues(state) {
    const productsAttributesValues = state.productsAttributesValues.byId;
    if (!productsAttributesValues) {
        return true
    } else if (productsAttributesValues.isFetching) {
        return false
    } else {
        return productsAttributesValues.didInvalidate;
    }
}

export function fetchProductsAttributesValuesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductsAttributesValues(getState())) {
            return dispatch(fetchProductsAttributesValues(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTATTRIBUTEVALUE = 'REQUEST_PRODUCTATTRIBUTEVALUE';
export const RECEIVE_PRODUCTATTRIBUTEVALUE = 'RECEIVE_PRODUCTATTRIBUTEVALUE';
export const INVALIDATE_PRODUCTATTRIBUTEVALUE = 'INVALIDATE_PRODUCTATTRIBUTEVALUE';
export const ERROR_PRODUCTATTRIBUTEVALUE = "ERROR_PRODUCTATTRIBUTEVALUE";
export const RESET_PRODUCTATTRIBUTEVALUE = "RESET_PRODUCTATTRIBUTEVALUE";


export function invalidateProductAttributeValue() {
    return {
        type: INVALIDATE_PRODUCTATTRIBUTEVALUE
    }
}

function requestProductAttributeValue() {
    return {
        type: REQUEST_PRODUCTATTRIBUTEVALUE,
    }
}

export function receiveProductAttributeValue(json) {
    return {
        type: RECEIVE_PRODUCTATTRIBUTEVALUE,
    productAttributeValue: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductAttributeValue(error) {
    return {
        type: ERROR_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function fetchProductAttributeValue(idProductAttributeValue) {
    return dispatch => {
        dispatch(requestProductAttributeValue());
        return productsAttributesValuesApi.getOne(idProductAttributeValue)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductAttributeValue(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductAttributeValue(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PRODUCTATTRIBUTEVALUE = 'RECEIVE_FILE_PRODUCTATTRIBUTEVALUE';

function receiveFileProductAttributeValue(file) {
    return {
        type: RECEIVE_FILE_PRODUCTATTRIBUTEVALUE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileProductAttributeValue(idProductAttributeValue, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return productsAttributesValuesApi.getFile(idProductAttributeValue, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileProductAttributeValue(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductAttributeValue(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PRODUCTATTRIBUTEVALUE = 'UPDATE_PRODUCTATTRIBUTEVALUE';
export const REQUEST_UPDATE_PRODUCTATTRIBUTEVALUE = "REQUEST_UPDATE_PRODUCTATTRIBUTEVALUE";
export const SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE = "SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE";
export const ERROR_UPDATE_PRODUCTATTRIBUTEVALUE = "ERROR_UPDATE_PRODUCTATTRIBUTEVALUE";
export const RESET_UPDATE_PRODUCTATTRIBUTEVALUE = "RESET_UPDATE_PRODUCTATTRIBUTEVALUE";
export const DELETE_UPDATE_PRODUCTATTRIBUTEVALUE = "DELETE_UPDATE_PRODUCTATTRIBUTEVALUE";

function requestUpdateProductAttributeValue() {
    return {
        type: REQUEST_UPDATE_PRODUCTATTRIBUTEVALUE,
    }
}

function receiveUpdateProductAttributeValue(productAttributeValue) {
    return {
        type: SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE,
        receivedAt: Date.now(),
        productAttributeValue: normalizeDato(productAttributeValue)
    }
}

function errorUpdateProductAttributeValue(error) {
    return {
        type: ERROR_UPDATE_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function resetUpdateProductAttributeValue() {
    return {
        type: RESET_UPDATE_PRODUCTATTRIBUTEVALUE,
    }
}

export function updateProductAttributeValue(productAttributeValue) {
    return {
        type: UPDATE_PRODUCTATTRIBUTEVALUE,
        productAttributeValue
    }
}

export function saveUpdateProductAttributeValue() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductAttributeValue());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productAttributeValue = denormalizeDato(getState().productsAttributesValues.update.activo, store);

        return productsAttributesValuesApi.saveUpdate(productAttributeValue)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductAttributeValue(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductsAttributesValues(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductsAttributesValues(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductAttributeValue(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductAttributeValue(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductAttributeValue(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductAttributeValue(productAttributeValue) {
    return {
        type: DELETE_UPDATE_PRODUCTATTRIBUTEVALUE,
        productAttributeValue
    }
}

//UPDATE PRODUCTSATTRIBUTESVALUES
export const REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUES = "REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUES";
export const SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES = "SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES";
export const ERROR_UPDATE_PRODUCTSATTRIBUTESVALUES = "ERROR_UPDATE_PRODUCTSATTRIBUTESVALUES";
export const RESET_UPDATE_PRODUCTSATTRIBUTESVALUES = "RESET_UPDATE_PRODUCTSATTRIBUTESVALUES";

function requestUpdateProductsAttributesValues() {
    return {
        type: REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUES,
}
}

function receiveUpdateProductsAttributesValues(productsAttributesValues) {
    return {
        type: SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES,
    receivedAt: Date.now(),
        productsAttributesValues: normalizeDatos(productsAttributesValues)
}
}

function errorUpdateProductsAttributesValues(error) {
    return {
        type: ERROR_UPDATE_PRODUCTSATTRIBUTESVALUES,
    error: error,
}
}

export function resetUpdateProductsAttributesValues() {
    return {
        type: RESET_UPDATE_PRODUCTSATTRIBUTESVALUES,
}
}

export function saveUpdateProductsAttributesValues() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductsAttributesValues());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsAttributesValues = getState().productsAttributesValues.update.activos.map((idProductAttributeValue) => {
            return denormalizeDato(getState().productsAttributesValues.update.activo[idProductAttributeValue], store);
        });

        return productsAttributesValuesApi.saveUpdateProductsAttributesValues(productsAttributesValues)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductsAttributesValues(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductsAttributesValues(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductsAttributesValues(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductsAttributesValues(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductsAttributesValues(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductsAttributesValues(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTATTRIBUTEVALUE
export const CREATE_PRODUCTATTRIBUTEVALUE = 'CREATE_PRODUCTATTRIBUTEVALUE';
export const REQUEST_CREATE_PRODUCTATTRIBUTEVALUE = "REQUEST_CREATE_PRODUCTATTRIBUTEVALUE";
export const SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE = "SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE";
export const ERROR_CREATE_PRODUCTATTRIBUTEVALUE = "ERROR_CREATE_PRODUCTATTRIBUTEVALUE";
export const RESET_CREATE_PRODUCTATTRIBUTEVALUE = "RESET_CREATE_PRODUCTATTRIBUTEVALUE";
export const DELETE_CREATE_PRODUCTATTRIBUTEVALUE = "DELETE_CREATE_PRODUCTATTRIBUTEVALUE";


//ALTA PRODUCTATTRIBUTEVALUE
function requestCreateProductAttributeValue() {
    return {
        type: REQUEST_CREATE_PRODUCTATTRIBUTEVALUE,
    }
}

function receiveCreateProductAttributeValue(productAttributeValue) {
    return {
        type: SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE,
        receivedAt: Date.now(),
        productAttributeValue: normalizeDato(productAttributeValue)
    }
}

export function errorCreateProductAttributeValue(error) {
    return {
        type: ERROR_CREATE_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function resetCreateProductAttributeValue() {
    return {
        type: RESET_CREATE_PRODUCTATTRIBUTEVALUE
    }
}

export function createProductAttributeValue(productAttributeValue) {
    return {
        type: CREATE_PRODUCTATTRIBUTEVALUE,
        productAttributeValue
    }
}

export function deleteCreateProductAttributeValue(productAttributeValue) {
    return {
        type: DELETE_CREATE_PRODUCTATTRIBUTEVALUE,
        productAttributeValue
    }
}

export function saveCreateProductAttributeValue() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductAttributeValue());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productAttributeValue = denormalizeDato(getState().productsAttributesValues.create.nuevo, store);

        return productsAttributesValuesApi.saveCreate(productAttributeValue)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductAttributeValue(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductsAttributesValues(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductsAttributesValues(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductAttributeValue(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductAttributeValue(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductAttributeValue(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTSATTRIBUTESVALUES
export const REQUEST_CREATE_PRODUCTSATTRIBUTESVALUES = "REQUEST_CREATE_PRODUCTSATTRIBUTESVALUES";
export const SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES = "SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES";
export const ERROR_CREATE_PRODUCTSATTRIBUTESVALUES = "ERROR_CREATE_PRODUCTSATTRIBUTESVALUES";
export const RESET_CREATE_PRODUCTSATTRIBUTESVALUES = "RESET_CREATE_PRODUCTSATTRIBUTESVALUES";

function requestCreateProductsAttributesValues() {
    return {
        type: REQUEST_CREATE_PRODUCTSATTRIBUTESVALUES,
}
}

function receiveCreateProductsAttributesValues(productsAttributesValues) {
    return {
        type: SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES,
    receivedAt: Date.now(),
        productsAttributesValues: normalizeDatos(productsAttributesValues)
}
}

function errorCreateProductsAttributesValues(error) {
    return {
        type: ERROR_CREATE_PRODUCTSATTRIBUTESVALUES,
    error: error,
}
}

export function resetCreateProductsAttributesValues() {
    return {
        type: RESET_CREATE_PRODUCTSATTRIBUTESVALUES,
}
}

export function saveCreateProductsAttributesValues() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductsAttributesValues());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsAttributesValues = getState().productsAttributesValues.create.nuevos.map((idProductAttributeValue) => {
            return denormalizeDato(getState().productsAttributesValues.create.nuevo[idProductAttributeValue], store);
        });

        return productsAttributesValuesApi.saveCreateProductsAttributesValues(productsAttributesValues)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductsAttributesValues(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductsAttributesValues(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductsAttributesValues(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductsAttributesValues(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductsAttributesValues(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductsAttributesValues(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTATTRIBUTEVALUE
export const DELETE_PRODUCTATTRIBUTEVALUE = 'DELETE_PRODUCTATTRIBUTEVALUE';
export const REQUEST_DELETE_PRODUCTATTRIBUTEVALUE = "REQUEST_DELETE_PRODUCTATTRIBUTEVALUE";
export const SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE = "SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE";
export const ERROR_DELETE_PRODUCTATTRIBUTEVALUE = "ERROR_DELETE_PRODUCTATTRIBUTEVALUE";
export const RESET_DELETE_PRODUCTATTRIBUTEVALUE = "RESET_DELETE_PRODUCTATTRIBUTEVALUE";

function requestDeleteProductAttributeValue() {
    return {
        type: REQUEST_DELETE_PRODUCTATTRIBUTEVALUE,
    }
}

function receiveDeleteProductAttributeValue(productAttributeValue) {
    return {
        type: SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE,
        receivedAt: Date.now(),
        productAttributeValue: normalizeDato(productAttributeValue)
    }
}

function errorDeleteProductAttributeValue(error) {
    return {
        type: ERROR_DELETE_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function resetDeleteProductAttributeValue(error) {
    return {
        type: RESET_DELETE_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function deleteProductAttributeValue(productAttributeValue) {
    return {
        type: DELETE_PRODUCTATTRIBUTEVALUE,
        productAttributeValue
    }
}

export function saveDeleteProductAttributeValue(productAttributeValue) {
    return dispatch => {
        dispatch(requestDeleteProductAttributeValue());
        return productsAttributesValuesApi.saveDelete(productAttributeValue)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductAttributeValue());
                            dispatch(receiveDeleteProductAttributeValue(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductAttributeValue(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductAttributeValue(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductAttributeValue(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductAttributeValue(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTATTRIBUTEVALUE
export const REQUEST_PRINT_PRODUCTATTRIBUTEVALUE = "REQUEST_PRINT_PRODUCTATTRIBUTEVALUE";
export const SUCCESS_PRINT_PRODUCTATTRIBUTEVALUE = "SUCCESS_PRINT_PRODUCTATTRIBUTEVALUE";
export const ERROR_PRINT_PRODUCTATTRIBUTEVALUE = "ERROR_PRINT_PRODUCTATTRIBUTEVALUE";
export const RESET_PRINT_PRODUCTATTRIBUTEVALUE = "RESET_PRINT_PRODUCTATTRIBUTEVALUE";

function requestPrintProductAttributeValue() {
    return {
        type: REQUEST_PRINT_PRODUCTATTRIBUTEVALUE,
    }
}

function receivePrintProductAttributeValue(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTATTRIBUTEVALUE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductAttributeValue(error) {
    return {
        type: ERROR_PRINT_PRODUCTATTRIBUTEVALUE,
        error: error,
    }
}

export function resetPrintProductAttributeValue() {
    return {
        type: RESET_PRINT_PRODUCTATTRIBUTEVALUE,
    }
}

export function printProductAttributeValue(idProductAttributeValue) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductAttributeValue());
        return productsAttributesValuesApi.printProductAttributeValue(idProductAttributeValue)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductAttributeValue(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductAttributeValue(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductAttributeValue(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductAttributeValue(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}