import {
    INVALIDATE_POSTSCOUNTRIES,
    ERROR_POSTSCOUNTRIES,
    RECEIVE_POSTSCOUNTRIES,
    REQUEST_POSTSCOUNTRIES,
    RESET_POSTSCOUNTRIES,
    ERROR_POSTCOUNTRY,
    RECEIVE_POSTCOUNTRY,
    REQUEST_POSTCOUNTRY,
    UPDATE_POSTCOUNTRY,
    REQUEST_UPDATE_POSTCOUNTRY,
    SUCCESS_UPDATE_POSTCOUNTRY,
    ERROR_UPDATE_POSTCOUNTRY,
    RESET_UPDATE_POSTCOUNTRY,
    REQUEST_UPDATE_POSTSCOUNTRIES,
    SUCCESS_UPDATE_POSTSCOUNTRIES,
    ERROR_UPDATE_POSTSCOUNTRIES,
    RESET_UPDATE_POSTSCOUNTRIES,
    CREATE_POSTCOUNTRY,
    ERROR_CREATE_POSTCOUNTRY,
    REQUEST_CREATE_POSTCOUNTRY,
    RESET_CREATE_POSTCOUNTRY,
    SUCCESS_CREATE_POSTCOUNTRY,
    REQUEST_CREATE_POSTSCOUNTRIES,
    SUCCESS_CREATE_POSTSCOUNTRIES,
    ERROR_CREATE_POSTSCOUNTRIES,
    RESET_CREATE_POSTSCOUNTRIES,
    DELETE_POSTCOUNTRY,
    DELETE_CREATE_POSTCOUNTRY,
    DELETE_UPDATE_POSTCOUNTRY,
    REQUEST_DELETE_POSTCOUNTRY,
    SUCCESS_DELETE_POSTCOUNTRY,
    ERROR_DELETE_POSTCOUNTRY,
    RESET_DELETE_POSTCOUNTRY,
    REQUEST_PRINT_POSTCOUNTRY,
    SUCCESS_PRINT_POSTCOUNTRY,
    ERROR_PRINT_POSTCOUNTRY,
    RESET_PRINT_POSTCOUNTRY,
    RECEIVE_FILE_POSTCOUNTRY
} from '../actions/PostCountryActions';

import {
    RECEIVE_POST,
    RECEIVE_POSTS,
    SUCCESS_DELETE_POST,
    SUCCESS_CREATE_POST,
    SUCCESS_UPDATE_POST,
    SUCCESS_UPDATE_POSTS

} from '../actions/PostActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        postsCountries: {},
        files: {},
    }
}

function postsCountriesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                postsCountries: {}
            });
        case RECEIVE_POSTSCOUNTRIES:
            let dato = action.postsCountries.entities.postsCountries;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                postsCountries: merge({}, state.postsCountries, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_POSTCOUNTRY:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_POSTCOUNTRY:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_POSTCOUNTRY:
            let datoPostCountry = action.postCountry.entities.postsCountries;
            return Object.assign({}, state, {
                postsCountries: merge({}, state.postsCountries, datoPostCountry),
                isFetching: false,
            });
        case RECEIVE_FILE_POSTCOUNTRY:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_POSTCOUNTRY:
            let datoPostCountryEliminado = action.postCountry.entities.postsCountries;
            return Object.assign({}, state, {
                postsCountries: mergeWith(clone(datoPostCountryEliminado), state.postsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POSTCOUNTRY:
            let datoPostCountryCreado = action.postCountry.entities.postsCountries;
            return Object.assign({}, state, {
                postsCountries: mergeWith(clone(datoPostCountryCreado), state.postsCountries, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POSTSCOUNTRIES:
            let datosPostCountryCreado = action.postsCountries.entities.postsCountries;
                return Object.assign({}, state, {
                    postsCountries: mergeWith(clone(datosPostCountryCreado), state.postsCountries, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_POSTCOUNTRY:
            let datoPostCountryActualizado = action.postCountry.entities.postsCountries;
            return Object.assign({}, state, {
                postsCountries: mergeWith(clone(datoPostCountryActualizado), state.postsCountries, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_POSTSCOUNTRIES:
            let datosPostCountryActualizado = action.postsCountries.entities.postsCountries;
                return Object.assign({}, state, {
                    postsCountries: mergeWith(clone(datosPostCountryActualizado), state.postsCountries, (objValue, srcValue) => {return objValue;})
                });

            //POST
case RECEIVE_POST:
    let post = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : {};
    return Object.assign({}, state, {
        postsCountries: merge({}, state.postsCountries, post),
    });
case RECEIVE_POSTS:
    let posts = action.posts.entities && action.posts.entities.postsCountries ? action.posts.entities.postsCountries : {};
    return Object.assign({}, state, {
        postsCountries: merge({}, state.postsCountries, posts),
    });
case SUCCESS_DELETE_POST:
    let datopostEliminado = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : {};
    return Object.assign({}, state, {
        postsCountries: mergeWith(clone(datopostEliminado), pickBy(state.postsCountries, function(postCountry) {return postCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_POST:
    let datopostCreado = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : {};
    return Object.assign({}, state, {
        postsCountries: mergeWith(clone(datopostCreado), pickBy(state.postsCountries, function(postCountry) {return postCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_POST:
    let datopostActualizado = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : {};
    return Object.assign({}, state, {
        postsCountries: mergeWith(clone(datopostActualizado), pickBy(state.postsCountries, function(postCountry) {return postCountry.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_POSTS:
    let datospostActualizado = action.posts.entities && action.posts.entities.postsCountries ? action.posts.entities.postsCountries : {} ;
        return Object.assign({}, state, {
            postsCountries: mergeWith(clone(datospostActualizado), state.postsCountries, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                postsCountries: {}
            });
        default:
            return state
    }
}


function allPostsCountries(state = [], action) {
    switch (action.type) {
        case RECEIVE_POSTSCOUNTRIES:
            return action.postsCountries.result && action.postsCountries.result.postsCountries ? union(action.postsCountries.result.postsCountries, state) : (action.postsCountries.result ? action.postsCountries.result : state) ;
        case RECEIVE_POSTCOUNTRY:
                return action.postCountry.result ? union([action.postCountry.result], state) : state;
        
        case SUCCESS_CREATE_POSTCOUNTRY:
                   let datoPostCountrySCreate = action.postCountry.entities.postsCountries;
                   let idNuevoSCreate = null;
                   if (Object.values(datoPostCountrySCreate).length > 0)
                       idNuevoSCreate = Object.values(datoPostCountrySCreate)[0] && Object.values(datoPostCountrySCreate)[0].id ? Object.values(datoPostCountrySCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_POSTSCOUNTRIES:
                   let postsCountriesCreate = action.postsCountries.entities && action.postsCountries.entities.postsCountries ? action.postsCountries.entities.postsCountries : null;
                   return postsCountriesCreate ?
                       union(state, Object.values(postsCountriesCreate).map((postsCountries) => {
                           return postsCountries.id
                       })) : state;
        case RESET_POSTSCOUNTRIES:
            return [];

            case RECEIVE_POST:
    let post = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : null ;
    return post ?
        union(state, Object.values(post).map((post) => {
            return post.id
        })) : state;
case RECEIVE_POSTS:
    let posts = action.posts.entities && action.posts.entities.postsCountries ? action.posts.entities.postsCountries : null;
    return posts ?
        union(state, Object.values(posts).map((posts) => {
            return posts.id
        })) : state;

case SUCCESS_DELETE_POST:
    let postDelete = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : null ;
        return postDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postDelete).map((post) => {
                return post.id
            })) : state;
case SUCCESS_CREATE_POST:
   let postCreate = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : null ;
       return postCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postCreate).map((post) => {
               return post.id
           })) : state;
case SUCCESS_UPDATE_POST:
    let postUpdate = action.post.entities && action.post.entities.postsCountries ? action.post.entities.postsCountries : null ;
        return postUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(postUpdate).map((post) => {
                return post.id
            })) : state;
 case SUCCESS_UPDATE_POSTS:
     let postsUpdate = action.posts.entities && action.posts.entities.postsCountries ? action.posts.entities.postsCountries : null;
        return postsUpdate ?
            union(state, Object.values(postsUpdate).map((posts) => {
                return posts.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPostsCountries(state = null, action) {
    switch (action.type) {
        case RECEIVE_POSTSCOUNTRIES:
            return action.postsCountries && action.postsCountries.result.total ? action.postsCountries.result.total : 0;
        case RESET_POSTSCOUNTRIES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_POSTCOUNTRY:
            let dato = action.postCountry.entities.postsCountries;
            let postCountry = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: postCountry ? postCountry : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_POSTCOUNTRY:
            let idsUpdate = [];
            Object.values(action.postCountry).map((postCountryUpdate) => {
                if (postCountryUpdate && postCountryUpdate.id)
                    idsUpdate.push(postCountryUpdate.id);
            });
            return merge({}, state, {
                activo: action.postCountry,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_POSTCOUNTRY:
            let datoPostCountryActualizado = {};
            if (Object.values(action.postCountry.entities.postsCountries).length > 0)
                datoPostCountryActualizado = Object.values(action.postCountry.entities.postsCountries)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoPostCountryActualizado
            });
        case ERROR_UPDATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_POSTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_POSTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_POSTSCOUNTRIES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //POST
//TODO ver si esta bien
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_POSTCOUNTRY:
            let datoPostCountryDelete = action.postCountry;
            let idsDelete = [];
           Object.values(action.postCountry).map((postCountryDelete) => {
               if (postCountryDelete && postCountryDelete.id)
                   idsDelete.push(postCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoPostCountryDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_POSTCOUNTRY:
                       let datoPostCountryDeleteUpdate = action.postCountry;
                       let idsDeleteUpdate = [];
                      Object.values(action.postCountry).map((postCountryDelete) => {
                          if (postCountryDelete && postCountryDelete.id)
                              idsDeleteUpdate.push(postCountryDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoPostCountryDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_POSTCOUNTRY:
                    let datoPostCountryDeleted = {};
                    if (Object.values(action.postCountry.entities.postsCountries).length > 0)
                        datoPostCountryDeleted = Object.values(action.postCountry.entities.postsCountries)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoPostCountryDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_POSTCOUNTRY:
             let idsCreate = [];
             Object.values(action.postCountry).map((postCountryCreate) => {
                 if (postCountryCreate && postCountryCreate.id)
                     idsCreate.push(postCountryCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.postCountry,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_POSTCOUNTRY:
            let datoPostCountryNuevo = {};
            if (Object.values(action.postCountry.entities.postsCountries).length > 0)
                datoPostCountryNuevo = Object.values(action.postCountry.entities.postsCountries)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoPostCountryNuevo,
                nuevos: []
            });
        case ERROR_CREATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_POSTSCOUNTRIES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //POST
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_POSTCOUNTRY:
           let datoPostCountryDelete = action.postCountry;
           let idsDelete = [];
           Object.values(action.postCountry).map((postCountryDelete) => {
               if (postCountryDelete && postCountryDelete.id)
                   idsDelete.push(postCountryDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoPostCountryDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_POSTCOUNTRY:
                  let datoPostCountryDeleteCreate = action.postCountry;
                  let idsDeleteCreate = [];
                  Object.values(action.postCountry).map((postCountryDelete) => {
                      if (postCountryDelete && postCountryDelete.id)
                          idsDeleteCreate.push(postCountryDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoPostCountryDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_POSTCOUNTRY:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.postCountry,
                error: null,
            });
        case REQUEST_DELETE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_POSTCOUNTRY:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //POST
 case SUCCESS_CREATE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_POST:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_POSTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_POSTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_POSTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_POSTCOUNTRY:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const postsCountries = combineReducers({
    byId: postsCountriesById,
    allIds: allPostsCountries,
    update: update,
    create: create,
    totalPostsCountries: totalPostsCountries,
    delete: deleter,
    print: print
});

export default postsCountries;