import {
    INVALIDATE_ASSOCIATEDPRODUCTS,
    ERROR_ASSOCIATEDPRODUCTS,
    RECEIVE_ASSOCIATEDPRODUCTS,
    REQUEST_ASSOCIATEDPRODUCTS,
    RESET_ASSOCIATEDPRODUCTS,
    ERROR_ASSOCIATEDPRODUCT,
    RECEIVE_ASSOCIATEDPRODUCT,
    REQUEST_ASSOCIATEDPRODUCT,
    UPDATE_ASSOCIATEDPRODUCT,
    REQUEST_UPDATE_ASSOCIATEDPRODUCT,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCT,
    ERROR_UPDATE_ASSOCIATEDPRODUCT,
    RESET_UPDATE_ASSOCIATEDPRODUCT,
    REQUEST_UPDATE_ASSOCIATEDPRODUCTS,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCTS,
    ERROR_UPDATE_ASSOCIATEDPRODUCTS,
    RESET_UPDATE_ASSOCIATEDPRODUCTS,
    CREATE_ASSOCIATEDPRODUCT,
    ERROR_CREATE_ASSOCIATEDPRODUCT,
    REQUEST_CREATE_ASSOCIATEDPRODUCT,
    RESET_CREATE_ASSOCIATEDPRODUCT,
    SUCCESS_CREATE_ASSOCIATEDPRODUCT,
    REQUEST_CREATE_ASSOCIATEDPRODUCTS,
    SUCCESS_CREATE_ASSOCIATEDPRODUCTS,
    ERROR_CREATE_ASSOCIATEDPRODUCTS,
    RESET_CREATE_ASSOCIATEDPRODUCTS,
    DELETE_ASSOCIATEDPRODUCT,
    DELETE_CREATE_ASSOCIATEDPRODUCT,
    DELETE_UPDATE_ASSOCIATEDPRODUCT,
    REQUEST_DELETE_ASSOCIATEDPRODUCT,
    SUCCESS_DELETE_ASSOCIATEDPRODUCT,
    ERROR_DELETE_ASSOCIATEDPRODUCT,
    RESET_DELETE_ASSOCIATEDPRODUCT,
    REQUEST_PRINT_ASSOCIATEDPRODUCT,
    SUCCESS_PRINT_ASSOCIATEDPRODUCT,
    ERROR_PRINT_ASSOCIATEDPRODUCT,
    RESET_PRINT_ASSOCIATEDPRODUCT,
    RECEIVE_FILE_ASSOCIATEDPRODUCT
} from '../actions/AssociatedProductActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        associatedProducts: {},
        files: {},
    }
}

function associatedProductsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                associatedProducts: {}
            });
        case RECEIVE_ASSOCIATEDPRODUCTS:
            let dato = action.associatedProducts.entities.associatedProducts;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                associatedProducts: merge({}, state.associatedProducts, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ASSOCIATEDPRODUCT:
            let datoAssociatedProduct = action.associatedProduct.entities.associatedProducts;
            return Object.assign({}, state, {
                associatedProducts: merge({}, state.associatedProducts, datoAssociatedProduct),
                isFetching: false,
            });
        case RECEIVE_FILE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductEliminado = action.associatedProduct.entities.associatedProducts;
            return Object.assign({}, state, {
                associatedProducts: mergeWith(clone(datoAssociatedProductEliminado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductCreado = action.associatedProduct.entities.associatedProducts;
            return Object.assign({}, state, {
                associatedProducts: mergeWith(clone(datoAssociatedProductCreado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSOCIATEDPRODUCTS:
            let datosAssociatedProductCreado = action.associatedProducts.entities.associatedProducts;
                return Object.assign({}, state, {
                    associatedProducts: mergeWith(clone(datosAssociatedProductCreado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductActualizado = action.associatedProduct.entities.associatedProducts;
            return Object.assign({}, state, {
                associatedProducts: mergeWith(clone(datoAssociatedProductActualizado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
            let datosAssociatedProductActualizado = action.associatedProducts.entities.associatedProducts;
                return Object.assign({}, state, {
                    associatedProducts: mergeWith(clone(datosAssociatedProductActualizado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : {};
    return Object.assign({}, state, {
        associatedProducts: merge({}, state.associatedProducts, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.associatedProducts ? action.products.entities.associatedProducts : {};
    return Object.assign({}, state, {
        associatedProducts: merge({}, state.associatedProducts, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : {};
    return Object.assign({}, state, {
        associatedProducts: mergeWith(clone(datoproductEliminado), pickBy(state.associatedProducts, function(associatedProduct) {return associatedProduct.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : {};
    return Object.assign({}, state, {
        associatedProducts: mergeWith(clone(datoproductCreado), pickBy(state.associatedProducts, function(associatedProduct) {return associatedProduct.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : {};
    return Object.assign({}, state, {
        associatedProducts: mergeWith(clone(datoproductActualizado), pickBy(state.associatedProducts, function(associatedProduct) {return associatedProduct.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.associatedProducts ? action.products.entities.associatedProducts : {} ;
        return Object.assign({}, state, {
            associatedProducts: mergeWith(clone(datosproductActualizado), state.associatedProducts, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                associatedProducts: {}
            });
        default:
            return state
    }
}


function allAssociatedProducts(state = [], action) {
    switch (action.type) {
        case RECEIVE_ASSOCIATEDPRODUCTS:
            return action.associatedProducts.result && action.associatedProducts.result.associatedProducts ? union(action.associatedProducts.result.associatedProducts, state) : (action.associatedProducts.result ? action.associatedProducts.result : state) ;
        case RECEIVE_ASSOCIATEDPRODUCT:
                return action.associatedProduct.result ? union([action.associatedProduct.result], state) : state;
        
        case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
                   let datoAssociatedProductSCreate = action.associatedProduct.entities.associatedProducts;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAssociatedProductSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAssociatedProductSCreate)[0] && Object.values(datoAssociatedProductSCreate)[0].id ? Object.values(datoAssociatedProductSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ASSOCIATEDPRODUCTS:
                   let associatedProductsCreate = action.associatedProducts.entities && action.associatedProducts.entities.associatedProducts ? action.associatedProducts.entities.associatedProducts : null;
                   return associatedProductsCreate ?
                       union(state, Object.values(associatedProductsCreate).map((associatedProducts) => {
                           return associatedProducts.id
                       })) : state;
        case RESET_ASSOCIATEDPRODUCTS:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.associatedProducts ? action.products.entities.associatedProducts : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.associatedProducts ? action.product.entities.associatedProducts : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.associatedProducts ? action.products.entities.associatedProducts : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAssociatedProducts(state = null, action) {
    switch (action.type) {
        case RECEIVE_ASSOCIATEDPRODUCTS:
            return action.associatedProducts && action.associatedProducts.result.total ? action.associatedProducts.result.total : 0;
        case RESET_ASSOCIATEDPRODUCTS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ASSOCIATEDPRODUCT:
            let dato = action.associatedProduct.entities.associatedProducts;
            let associatedProduct = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: associatedProduct ? associatedProduct : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ASSOCIATEDPRODUCT:
            let idsUpdate = [];
            Object.values(action.associatedProduct).map((associatedProductUpdate) => {
                if (associatedProductUpdate && associatedProductUpdate.id)
                    idsUpdate.push(associatedProductUpdate.id);
            });
            return merge({}, state, {
                activo: action.associatedProduct,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductActualizado = {};
            if (Object.values(action.associatedProduct.entities.associatedProducts).length > 0)
                datoAssociatedProductActualizado = Object.values(action.associatedProduct.entities.associatedProducts)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAssociatedProductActualizado
            });
        case ERROR_UPDATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ASSOCIATEDPRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ASSOCIATEDPRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductDelete = action.associatedProduct;
            let idsDelete = [];
           Object.values(action.associatedProduct).map((associatedProductDelete) => {
               if (associatedProductDelete && associatedProductDelete.id)
                   idsDelete.push(associatedProductDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAssociatedProductDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ASSOCIATEDPRODUCT:
                       let datoAssociatedProductDeleteUpdate = action.associatedProduct;
                       let idsDeleteUpdate = [];
                      Object.values(action.associatedProduct).map((associatedProductDelete) => {
                          if (associatedProductDelete && associatedProductDelete.id)
                              idsDeleteUpdate.push(associatedProductDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAssociatedProductDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
                    let datoAssociatedProductDeleted = {};
                    if (Object.values(action.associatedProduct.entities.associatedProducts).length > 0)
                        datoAssociatedProductDeleted = Object.values(action.associatedProduct.entities.associatedProducts)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAssociatedProductDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ASSOCIATEDPRODUCT:
             let idsCreate = [];
             Object.values(action.associatedProduct).map((associatedProductCreate) => {
                 if (associatedProductCreate && associatedProductCreate.id)
                     idsCreate.push(associatedProductCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.associatedProduct,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
            let datoAssociatedProductNuevo = {};
            if (Object.values(action.associatedProduct.entities.associatedProducts).length > 0)
                datoAssociatedProductNuevo = Object.values(action.associatedProduct.entities.associatedProducts)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAssociatedProductNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ASSOCIATEDPRODUCTS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ASSOCIATEDPRODUCT:
           let datoAssociatedProductDelete = action.associatedProduct;
           let idsDelete = [];
           Object.values(action.associatedProduct).map((associatedProductDelete) => {
               if (associatedProductDelete && associatedProductDelete.id)
                   idsDelete.push(associatedProductDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAssociatedProductDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ASSOCIATEDPRODUCT:
                  let datoAssociatedProductDeleteCreate = action.associatedProduct;
                  let idsDeleteCreate = [];
                  Object.values(action.associatedProduct).map((associatedProductDelete) => {
                      if (associatedProductDelete && associatedProductDelete.id)
                          idsDeleteCreate.push(associatedProductDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAssociatedProductDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ASSOCIATEDPRODUCT:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.associatedProduct,
                error: null,
            });
        case REQUEST_DELETE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ASSOCIATEDPRODUCT:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const associatedProducts = combineReducers({
    byId: associatedProductsById,
    allIds: allAssociatedProducts,
    update: update,
    create: create,
    totalAssociatedProducts: totalAssociatedProducts,
    delete: deleter,
    print: print
});

export default associatedProducts;