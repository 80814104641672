//api
import typesFilesSectionsApi from "../api/typesFilesSectionsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeTypesFilesSections";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//TYPESFILESSECTIONS
export const REQUEST_TYPESFILESSECTIONS = 'REQUEST_TYPESFILESSECTIONS';
export const RECEIVE_TYPESFILESSECTIONS = 'RECEIVE_TYPESFILESSECTIONS';
export const INVALIDATE_TYPESFILESSECTIONS = 'INVALIDATE_TYPESFILESSECTIONS';
export const ERROR_TYPESFILESSECTIONS = "ERROR_TYPESFILESSECTIONS";
export const RESET_TYPESFILESSECTIONS = "RESET_TYPESFILESSECTIONS";


export function invalidateTypesFilesSections() {
    return {
        type: INVALIDATE_TYPESFILESSECTIONS
    }
}

function requestTypesFilesSections() {
    return {
        type: REQUEST_TYPESFILESSECTIONS,
    }
}

function receiveTypesFilesSections(json) {
    return {
        type: RECEIVE_TYPESFILESSECTIONS,
    typesFilesSections: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorTypesFilesSections(error) {
    return {
        type: ERROR_TYPESFILESSECTIONS,
        error: error,
    }
}

export function resetTypesFilesSections() {
    return {
        type: RESET_TYPESFILESSECTIONS
    }
}

export function fetchTypesFilesSections(filtros) {
    return dispatch => {
        dispatch(requestTypesFilesSections());
        return typesFilesSectionsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveTypesFilesSections(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorTypesFilesSections(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTypesFilesSections(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchTypesFilesSections(state) {
    const typesFilesSections = state.typesFilesSections.byId;
    if (!typesFilesSections) {
        return true
    } else if (typesFilesSections.isFetching) {
        return false
    } else {
        return typesFilesSections.didInvalidate;
    }
}

export function fetchTypesFilesSectionsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchTypesFilesSections(getState())) {
            return dispatch(fetchTypesFilesSections(filtros))
        }
    }
}


//MODEL
export const REQUEST_TYPEFILESECTION = 'REQUEST_TYPEFILESECTION';
export const RECEIVE_TYPEFILESECTION = 'RECEIVE_TYPEFILESECTION';
export const INVALIDATE_TYPEFILESECTION = 'INVALIDATE_TYPEFILESECTION';
export const ERROR_TYPEFILESECTION = "ERROR_TYPEFILESECTION";
export const RESET_TYPEFILESECTION = "RESET_TYPEFILESECTION";


export function invalidateTypeFileSection() {
    return {
        type: INVALIDATE_TYPEFILESECTION
    }
}

function requestTypeFileSection() {
    return {
        type: REQUEST_TYPEFILESECTION,
    }
}

export function receiveTypeFileSection(json) {
    return {
        type: RECEIVE_TYPEFILESECTION,
    typeFileSection: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorTypeFileSection(error) {
    return {
        type: ERROR_TYPEFILESECTION,
        error: error,
    }
}

export function fetchTypeFileSection(idTypeFileSection) {
    return dispatch => {
        dispatch(requestTypeFileSection());
        return typesFilesSectionsApi.getOne(idTypeFileSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveTypeFileSection(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTypeFileSection(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_TYPEFILESECTION = 'RECEIVE_FILE_TYPEFILESECTION';

function receiveFileTypeFileSection(file) {
    return {
        type: RECEIVE_FILE_TYPEFILESECTION,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileTypeFileSection(idTypeFileSection, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return typesFilesSectionsApi.getFile(idTypeFileSection, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileTypeFileSection(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTypeFileSection(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_TYPEFILESECTION = 'UPDATE_TYPEFILESECTION';
export const REQUEST_UPDATE_TYPEFILESECTION = "REQUEST_UPDATE_TYPEFILESECTION";
export const SUCCESS_UPDATE_TYPEFILESECTION = "SUCCESS_UPDATE_TYPEFILESECTION";
export const ERROR_UPDATE_TYPEFILESECTION = "ERROR_UPDATE_TYPEFILESECTION";
export const RESET_UPDATE_TYPEFILESECTION = "RESET_UPDATE_TYPEFILESECTION";
export const DELETE_UPDATE_TYPEFILESECTION = "DELETE_UPDATE_TYPEFILESECTION";

function requestUpdateTypeFileSection() {
    return {
        type: REQUEST_UPDATE_TYPEFILESECTION,
    }
}

function receiveUpdateTypeFileSection(typeFileSection) {
    return {
        type: SUCCESS_UPDATE_TYPEFILESECTION,
        receivedAt: Date.now(),
        typeFileSection: normalizeDato(typeFileSection)
    }
}

function errorUpdateTypeFileSection(error) {
    return {
        type: ERROR_UPDATE_TYPEFILESECTION,
        error: error,
    }
}

export function resetUpdateTypeFileSection() {
    return {
        type: RESET_UPDATE_TYPEFILESECTION,
    }
}

export function updateTypeFileSection(typeFileSection) {
    return {
        type: UPDATE_TYPEFILESECTION,
        typeFileSection
    }
}

export function saveUpdateTypeFileSection() {
    return (dispatch, getState) => {
        dispatch(requestUpdateTypeFileSection());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let typeFileSection = denormalizeDato(getState().typesFilesSections.update.activo, store);

        return typesFilesSectionsApi.saveUpdate(typeFileSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateTypeFileSection(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateTypesFilesSections(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateTypesFilesSections(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateTypeFileSection(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateTypeFileSection(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateTypeFileSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateTypeFileSection(typeFileSection) {
    return {
        type: DELETE_UPDATE_TYPEFILESECTION,
        typeFileSection
    }
}

//UPDATE TYPESFILESSECTIONS
export const REQUEST_UPDATE_TYPESFILESSECTIONS = "REQUEST_UPDATE_TYPESFILESSECTIONS";
export const SUCCESS_UPDATE_TYPESFILESSECTIONS = "SUCCESS_UPDATE_TYPESFILESSECTIONS";
export const ERROR_UPDATE_TYPESFILESSECTIONS = "ERROR_UPDATE_TYPESFILESSECTIONS";
export const RESET_UPDATE_TYPESFILESSECTIONS = "RESET_UPDATE_TYPESFILESSECTIONS";

function requestUpdateTypesFilesSections() {
    return {
        type: REQUEST_UPDATE_TYPESFILESSECTIONS,
}
}

function receiveUpdateTypesFilesSections(typesFilesSections) {
    return {
        type: SUCCESS_UPDATE_TYPESFILESSECTIONS,
    receivedAt: Date.now(),
        typesFilesSections: normalizeDatos(typesFilesSections)
}
}

function errorUpdateTypesFilesSections(error) {
    return {
        type: ERROR_UPDATE_TYPESFILESSECTIONS,
    error: error,
}
}

export function resetUpdateTypesFilesSections() {
    return {
        type: RESET_UPDATE_TYPESFILESSECTIONS,
}
}

export function saveUpdateTypesFilesSections() {
    return (dispatch, getState) => {
        dispatch(requestUpdateTypesFilesSections());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let typesFilesSections = getState().typesFilesSections.update.activos.map((idTypeFileSection) => {
            return denormalizeDato(getState().typesFilesSections.update.activo[idTypeFileSection], store);
        });

        return typesFilesSectionsApi.saveUpdateTypesFilesSections(typesFilesSections)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateTypesFilesSections(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateTypesFilesSections(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateTypesFilesSections(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateTypesFilesSections(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateTypesFilesSections(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateTypesFilesSections(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA TYPEFILESECTION
export const CREATE_TYPEFILESECTION = 'CREATE_TYPEFILESECTION';
export const REQUEST_CREATE_TYPEFILESECTION = "REQUEST_CREATE_TYPEFILESECTION";
export const SUCCESS_CREATE_TYPEFILESECTION = "SUCCESS_CREATE_TYPEFILESECTION";
export const ERROR_CREATE_TYPEFILESECTION = "ERROR_CREATE_TYPEFILESECTION";
export const RESET_CREATE_TYPEFILESECTION = "RESET_CREATE_TYPEFILESECTION";
export const DELETE_CREATE_TYPEFILESECTION = "DELETE_CREATE_TYPEFILESECTION";


//ALTA TYPEFILESECTION
function requestCreateTypeFileSection() {
    return {
        type: REQUEST_CREATE_TYPEFILESECTION,
    }
}

function receiveCreateTypeFileSection(typeFileSection) {
    return {
        type: SUCCESS_CREATE_TYPEFILESECTION,
        receivedAt: Date.now(),
        typeFileSection: normalizeDato(typeFileSection)
    }
}

export function errorCreateTypeFileSection(error) {
    return {
        type: ERROR_CREATE_TYPEFILESECTION,
        error: error,
    }
}

export function resetCreateTypeFileSection() {
    return {
        type: RESET_CREATE_TYPEFILESECTION
    }
}

export function createTypeFileSection(typeFileSection) {
    return {
        type: CREATE_TYPEFILESECTION,
        typeFileSection
    }
}

export function deleteCreateTypeFileSection(typeFileSection) {
    return {
        type: DELETE_CREATE_TYPEFILESECTION,
        typeFileSection
    }
}

export function saveCreateTypeFileSection() {
    return (dispatch, getState) => {
        dispatch(requestCreateTypeFileSection());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let typeFileSection = denormalizeDato(getState().typesFilesSections.create.nuevo, store);

        return typesFilesSectionsApi.saveCreate(typeFileSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateTypeFileSection(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateTypesFilesSections(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateTypesFilesSections(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateTypeFileSection(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateTypeFileSection(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateTypeFileSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE TYPESFILESSECTIONS
export const REQUEST_CREATE_TYPESFILESSECTIONS = "REQUEST_CREATE_TYPESFILESSECTIONS";
export const SUCCESS_CREATE_TYPESFILESSECTIONS = "SUCCESS_CREATE_TYPESFILESSECTIONS";
export const ERROR_CREATE_TYPESFILESSECTIONS = "ERROR_CREATE_TYPESFILESSECTIONS";
export const RESET_CREATE_TYPESFILESSECTIONS = "RESET_CREATE_TYPESFILESSECTIONS";

function requestCreateTypesFilesSections() {
    return {
        type: REQUEST_CREATE_TYPESFILESSECTIONS,
}
}

function receiveCreateTypesFilesSections(typesFilesSections) {
    return {
        type: SUCCESS_CREATE_TYPESFILESSECTIONS,
    receivedAt: Date.now(),
        typesFilesSections: normalizeDatos(typesFilesSections)
}
}

function errorCreateTypesFilesSections(error) {
    return {
        type: ERROR_CREATE_TYPESFILESSECTIONS,
    error: error,
}
}

export function resetCreateTypesFilesSections() {
    return {
        type: RESET_CREATE_TYPESFILESSECTIONS,
}
}

export function saveCreateTypesFilesSections() {
    return (dispatch, getState) => {
        dispatch(requestCreateTypesFilesSections());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let typesFilesSections = getState().typesFilesSections.create.nuevos.map((idTypeFileSection) => {
            return denormalizeDato(getState().typesFilesSections.create.nuevo[idTypeFileSection], store);
        });

        return typesFilesSectionsApi.saveCreateTypesFilesSections(typesFilesSections)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateTypesFilesSections(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateTypesFilesSections(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateTypesFilesSections(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateTypesFilesSections(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateTypesFilesSections(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateTypesFilesSections(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE TYPEFILESECTION
export const DELETE_TYPEFILESECTION = 'DELETE_TYPEFILESECTION';
export const REQUEST_DELETE_TYPEFILESECTION = "REQUEST_DELETE_TYPEFILESECTION";
export const SUCCESS_DELETE_TYPEFILESECTION = "SUCCESS_DELETE_TYPEFILESECTION";
export const ERROR_DELETE_TYPEFILESECTION = "ERROR_DELETE_TYPEFILESECTION";
export const RESET_DELETE_TYPEFILESECTION = "RESET_DELETE_TYPEFILESECTION";

function requestDeleteTypeFileSection() {
    return {
        type: REQUEST_DELETE_TYPEFILESECTION,
    }
}

function receiveDeleteTypeFileSection(typeFileSection) {
    return {
        type: SUCCESS_DELETE_TYPEFILESECTION,
        receivedAt: Date.now(),
        typeFileSection: normalizeDato(typeFileSection)
    }
}

function errorDeleteTypeFileSection(error) {
    return {
        type: ERROR_DELETE_TYPEFILESECTION,
        error: error,
    }
}

export function resetDeleteTypeFileSection(error) {
    return {
        type: RESET_DELETE_TYPEFILESECTION,
        error: error,
    }
}

export function deleteTypeFileSection(typeFileSection) {
    return {
        type: DELETE_TYPEFILESECTION,
        typeFileSection
    }
}

export function saveDeleteTypeFileSection(typeFileSection) {
    return dispatch => {
        dispatch(requestDeleteTypeFileSection());
        return typesFilesSectionsApi.saveDelete(typeFileSection)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteTypeFileSection());
                            dispatch(receiveDeleteTypeFileSection(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteTypeFileSection(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteTypeFileSection(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteTypeFileSection(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteTypeFileSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT TYPEFILESECTION
export const REQUEST_PRINT_TYPEFILESECTION = "REQUEST_PRINT_TYPEFILESECTION";
export const SUCCESS_PRINT_TYPEFILESECTION = "SUCCESS_PRINT_TYPEFILESECTION";
export const ERROR_PRINT_TYPEFILESECTION = "ERROR_PRINT_TYPEFILESECTION";
export const RESET_PRINT_TYPEFILESECTION = "RESET_PRINT_TYPEFILESECTION";

function requestPrintTypeFileSection() {
    return {
        type: REQUEST_PRINT_TYPEFILESECTION,
    }
}

function receivePrintTypeFileSection(turnos) {
    return {
        type: SUCCESS_PRINT_TYPEFILESECTION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintTypeFileSection(error) {
    return {
        type: ERROR_PRINT_TYPEFILESECTION,
        error: error,
    }
}

export function resetPrintTypeFileSection() {
    return {
        type: RESET_PRINT_TYPEFILESECTION,
    }
}

export function printTypeFileSection(idTypeFileSection) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintTypeFileSection());
        return typesFilesSectionsApi.printTypeFileSection(idTypeFileSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintTypeFileSection(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintTypeFileSection(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintTypeFileSection(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintTypeFileSection(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintTypeFileSection(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}