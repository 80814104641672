import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import moment from "moment";
import clone from "lodash/clone";
//Components
import {
  AppBar,
  Box,
  Breadcrumbs,
  Chip,
  Container,
  Dialog,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomPaper from "../../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../../library/CustomPaper/PaperHeader";
import ContainerBtn from "../../../library/Buttons/ContainerBtn";
import BtnSave from "../../../library/Buttons/BtnSave";
import BtnCancel from "../../../library/Buttons/BtnCancel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import BannerType from "../TypesElements/BannerType";
import TextType from "../TypesElements/TextType";
import FileType from "../TypesElements/FileType";
import Grid from "@material-ui/core/Grid";
import PaperBody from "../../../library/CustomPaper/PaperBody";
import PreviewImageVideo from "../PreviewImageVideo";
import AssetsCountriesList from "../AssetsCountriesList";
import ImageFileType from "../TypesElements/ImageFileType";
import ModalSelectProductType from "../TypesElements/ProductType";
import ProductTextType from "../TypesElements/ProductTextType";
//Img
import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import PhoneIcon from "@material-ui/icons/Phone";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
//Hooks
import { useValidador } from "../../../utils/validador";
import { useDispatch, useSelector } from "react-redux";
//Actions
import {
  createAsset,
  fetchAsset,
  resetCreateAsset,
  resetDeleteAsset,
  resetUpdateAsset,
  saveCreateAsset,
  saveUpdateAsset,
  updateAsset,
} from "../../../actions/AssetActions";
import { fetchTypesFilesIfNeeded } from "../../../actions/TypeFileActions";
import {
  createAssetLanguage,
  deleteAssetLanguage,
  fetchFileAssetLanguage,
  resetAssetsLanguages,
  resetCreateAssetLanguage,
  resetDeleteAssetLanguage,
  resetUpdateAssetLanguage,
  updateAssetLanguage,
} from "../../../actions/AssetLanguageActions";
import { fetchLanguagesIfNeeded } from "../../../actions/LanguageActions";
import {
  createAssetCountry,
  deleteAssetCountry,
  resetAssetsCountries,
  resetCreateAssetCountry,
  resetDeleteAssetCountry,
  resetUpdateAssetCountry,
} from "../../../actions/AssetCountryActions";
//reset
import typeFileReset from "../../../reset/typeFileReset";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import resetAsset from "../../../reset/assetReset";
import URL from "../TypesElements/URL";
import VideoType from "../TypesElements/VideoType";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(2),
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      flexWrap: "nowrap",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function CRUDEmbeddedtAsset(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idAsset, id, setOpen, open, fk, setIdAsset } = props;
  const mounted = useRef();
  const msjCountries = useRef();

  //Store
  const assets = useSelector((state) => state.assets);
  const asset = idAsset ? assets.update.activo : assets.create.nuevo;
  const assetsLanguages = useSelector((state) => state.assetsLanguages);
  const assetsCountries = useSelector((state) => state.assetsCountries);
  const languages = useSelector((state) => state.languages);
  const typesFiles = useSelector((state) => state.typesFiles);
  const typeFile =
    asset && asset.idTypeFile
      ? typesFiles.byId.typesFiles[asset.idTypeFile]
      : null;

  //State
  const [files, setFiles] = useState([]);
  const [
    refreshCheckboxSelectAllCountries,
    setRefreshCheckboxSelectAllCountries,
  ] = useState(0);
  const [previewFile, setPreviewFile] = React.useState(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);

  const guardandoLoading = idAsset
    ? assets.update.isUpdating
    : assets.create.isCreating;
  const disabled =
    (idAsset ? assets.update.isUpdating : assets.create.isCreating) ||
    assets.byId.isFetching;
  const error = idAsset ? assets.update.error : assets.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    nameAsset: ["required"],
  };
  const [validador, validar] = useValidador(reglas);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchTypesFilesIfNeeded({}));
    dispatch(fetchLanguagesIfNeeded({}));
    console.log("idAsset", idAsset);
    if (idAsset) dispatch(fetchAsset(idAsset));
    else if (id) {
      let cambio = {};
      cambio[fk] = parseInt(id);
      changeAsset(cambio);
    }

    return function cleanup() {
      // assetReset.resetAll(dispatch);
      // typeFileReset.resetAll(dispatch);
    };
  }, [idAsset, id]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!assets.create.isCreating && !assets.create.error) {
        dispatch(resetCreateAsset());
        dispatch(resetCreateAssetLanguage());
        setFiles([]);
        handleClose();
        window.scrollTo(0, 0);
      }
    }
  }, [assets.create.isCreating]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!assets.update.isUpdating && !assets.update.error) {
        handleClose();
        setFiles([]);
        fetchFiles();
      }
    }
  }, [assets.update.isUpdating]);

  const fetchFiles = () => {
    let countFiles = 0;
    if (asset.asset_language)
      asset.asset_language.forEach((idAssetLanguage) => {
        let assetLanguage =
          assetsLanguages.byId.assetsLanguages[idAssetLanguage];
        if (assetLanguage && assetLanguage.filenameDesktop) countFiles++;
        if (assetLanguage && assetLanguage.filenameMobile) countFiles++;
      });
    if (
      typeFile &&
      (typeFile.codType === "BANNER" ||
        typeFile.codType === "IMAGE" ||
        typeFile.codType === "PDF" ||
        typeFile.codType === "CSV" ||
        typeFile.codType === "OFFICE") &&
      asset.asset_language &&
      assetsLanguages.byId.files.size !== countFiles
    ) {
      asset.asset_language.forEach((idDocumentoAtencion, i) => {
        if (typeFile.codType === "BANNER") {
          dispatch(
            fetchFileAssetLanguage(idDocumentoAtencion, { type: "mobile" })
          );
          dispatch(
            fetchFileAssetLanguage(idDocumentoAtencion, { type: "desktop" })
          );
        } else {
          dispatch(
            fetchFileAssetLanguage(idDocumentoAtencion, { type: "file" })
          );
        }
      });
    }
  };

  //Asset
  const handleChangeAsset = (e) => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeAsset(cambio);
  };

  const handleChangeAssetTypeFile = (e) => {
    dispatch(resetCreateAssetLanguage());
    dispatch(resetUpdateAssetLanguage());
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    cambio.asset_language = null;
    cambio.idProduct = null;
    changeAsset(cambio);
  };

  const handleChangeAssetDate = (date, id) => {
    let cambio = {};
    if (date && date.isValid()) {
      cambio[id] = date ? moment(date).format("YYYY-MM-DD") : null;
      changeAsset(cambio);
    }
  };

  const changeAsset = (cambio) => {
    if (idAsset) dispatch(updateAsset(cambio));
    else dispatch(createAsset(cambio));
  };

  const handleChangeStatusAsset = () => {
    let cambio = {};
    cambio.status = !asset.status;
    changeAsset(cambio);
  };

  //Asset language
  const handleChangeAssetLanguage = (e, idAssetLanguage, idLanguage) => {
    let id = idAssetLanguage
      ? idAssetLanguage
      : Date.now() + Math.random() + "-";
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeAssetLanguage(cambio, id, idLanguage);
  };

  const changeAssetLanguage = (cambio, idAssetLanguage, idLanguage) => {
    //Verificar si ya esta siendo actualizado
    let assetLanguage =
      idAssetLanguage &&
      assetsLanguages.update.activos.indexOf(idAssetLanguage) === -1 &&
      assetsLanguages.create.nuevos.indexOf(idAssetLanguage) === -1
        ? assetsLanguages.byId.assetsLanguages[idAssetLanguage]
        : null;
    if (assetLanguage) {
      let actualizar = assetLanguage ? clone(assetLanguage) : null;
      if (actualizar) {
        let cambioAssetLanguage = {};
        cambioAssetLanguage[actualizar.id] = actualizar;
        if (idAssetLanguage.toString().indexOf("-") === -1)
          dispatch(updateAssetLanguage(cambioAssetLanguage));
        else dispatch(createAssetLanguage(cambioAssetLanguage));
      }
    }
    cambio.id = idAssetLanguage;
    cambio.idAsset = idAsset;
    cambio.idLanguage = idLanguage;
    let cambioAssetLanguage = {};
    cambioAssetLanguage[idAssetLanguage] = cambio;
    if (idAssetLanguage.toString().indexOf("-") === -1)
      dispatch(updateAssetLanguage(cambioAssetLanguage));
    else dispatch(createAssetLanguage(cambioAssetLanguage));
  };

  const deleteAssetLanguageFile = (assetLanguage) => {
    let cambioUbicacion = {};
    cambioUbicacion[assetLanguage.id] = clone(assetLanguage);
    dispatch(deleteAssetLanguage(cambioUbicacion));
  };

  const downloadFileAssetLanguage = (file) => {
    let fileSubido = file;
    let reader = new FileReader();
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    reader.onloadend = function () {
      a.href = reader.result;
      a.download = file.name;
      a.click();
    };
    if (fileSubido) {
      reader.readAsDataURL(fileSubido);
    }
  };

  const previewFileAssetLanguage = (file) => {
    let fileSubido = file;
    let reader = new FileReader();
    reader.onloadend = function () {
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
          encodeURI(reader.result) +
          "'></iframe>"
      );
    };
    if (fileSubido) {
      reader.readAsDataURL(fileSubido);
    }
  };
  const previewImageVideoAssetLanguage = (file) => {
    setPreviewFile(file);
    setPreviewOpen(true);
  };

  //Lista países
  const handleChangeAssetCountry = (idCountry, selectAll) => {
    let id = idCountry + "-";
    let esAssetCountry = false;
    if (asset.asset_country)
      esAssetCountry = asset.asset_country.some((idAssetCountry) => {
        let assetCountry = assetsCountries.byId.assetsCountries[idAssetCountry];
        if (assetsCountries.update.activos.indexOf(idAssetCountry) !== -1)
          assetCountry = assetsCountries.update.activo[idAssetCountry];
        if (assetsCountries.create.nuevos.indexOf(idAssetCountry) !== -1)
          assetCountry = assetsCountries.create.nuevo[idAssetCountry];
        if (assetCountry.idCountry === idCountry) id = idAssetCountry;
        return assetCountry.idCountry === idCountry;
      });
    let cambio = {};
    cambio[id] = { idAsset: idAsset, idCountry: idCountry, id: id };
    if (esAssetCountry) {
      dispatch(deleteAssetCountry(cambio));
    } else dispatch(createAssetCountry(cambio));
  };

  const isUbicacionSelected = (idCountry) => {
    let isAssetCountry = false;
    if (asset.asset_country)
      isAssetCountry = asset.asset_country.some((idAssetCountry) => {
        let assetCountry = assetsCountries.byId.assetsCountries[idAssetCountry];
        if (assetsCountries.update.activos.indexOf(idAssetCountry) !== -1)
          assetCountry = assetsCountries.update.activo[idAssetCountry];
        if (assetsCountries.create.nuevos.indexOf(idAssetCountry) !== -1)
          assetCountry = assetsCountries.create.nuevo[idAssetCountry];
        if (assetCountry) return assetCountry.idCountry === idCountry;
        else return false;
      });
    return isAssetCountry;
  };

  //Submit
  const onSubmit = (e) => {
    e.preventDefault();
    let cambio = {};
    cambio[fk] = parseInt(id);
    changeAsset(cambio);
    if (validador.isValid()) {
      let arrFiles = deleteFiles();
      if (idAsset) dispatch(saveUpdateAsset(arrFiles));
      else dispatch(saveCreateAsset(arrFiles));
    }
  };

  const deleteFiles = () => {
    let arrayFiles = clone(files);
    files.forEach((file, index) => {
      let onUse = asset.asset_language.some((idAssetLanguage) => {
        let assetLanguage =
          assetsLanguages.byId.assetsLanguages[idAssetLanguage];
        if (assetsLanguages.update.activos.indexOf(idAssetLanguage) !== -1)
          assetLanguage = assetsLanguages.update.activo[idAssetLanguage];
        if (assetsLanguages.create.nuevos.indexOf(idAssetLanguage) !== -1)
          assetLanguage = assetsLanguages.create.nuevo[idAssetLanguage];
        if (
          assetLanguage &&
          (assetLanguage.filename === file.name ||
            assetLanguage.filenameMobile === file.name ||
            assetLanguage.filenameDesktop === file.name)
        ) {
          return true;
        }
        return false;
      });
      if (!onUse) arrayFiles.splice(index, 1);
    });
    return arrayFiles;
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetCreateAsset());
    dispatch(resetUpdateAsset());
    dispatch(resetDeleteAsset());
    dispatch(resetCreateAssetLanguage());
    dispatch(resetUpdateAssetLanguage());
    dispatch(resetDeleteAssetLanguage());
    dispatch(resetCreateAssetCountry());
    dispatch(resetUpdateAssetCountry());
    dispatch(resetDeleteAssetCountry());
    setFiles([]);
    setIdAsset(null);
  };

  //Agregar multiidioma
  let idsLanguages = clone(languages.allIds);
  idsLanguages.push(null);

  return (
    // Formulario para editar post
    <Dialog fullScreen open={open} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={disabled}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Contenido
            </Typography>
            {/* Boton para guardar los cambios realizados */}
            <Button
              type={"submit"}
              autoFocus
              color="inherit"
              disabled={disabled}
            >
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent id="dialogCon">
          <Container maxWidth={"xg"}>
            <PreviewImageVideo
              file={previewFile}
              setPreviewOpen={setPreviewOpen}
              previewOpen={previewOpen}
            />
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
              <Link color="inherit" to="/">
                Home
              </Link>
              <Link color="inherit" to="/assets">
                Contenidos
              </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
              {!idAsset ? "Nuevo contenido" : "Modificar contenido"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <CustomPaper>
                  <PaperHeader>
                    <Typography variant={"overline"} component={"span"}>
                      Datos del contenido
                    </Typography>
                  </PaperHeader>
                  <PaperBody spacing={2}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: "15px" }}
                    >
                      <TextField
                        id="nameAsset"
                        placeholder="Descripción"
                        variant="outlined"
                        label={"Descripción"}
                        helperText={
                          "Describa el contenido para encontrarlo fácilmente"
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disabled: disabled,
                          type: "text",
                        }}
                        disabled={disabled}
                        fullWidth
                        value={asset ? asset.nameAsset : ""}
                        onChange={handleChangeAsset}
                        onBlur={validar}
                        error={!validador.isValidById("nameAsset")}
                        helperText={validador.getHelperTextById("nameAsset")}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha desde"
                        format="DD/MM/yyyy"
                        disabled={disabled}
                        InputAdornmentProps={{ position: "end" }}
                        value={asset && asset.dateFrom ? asset.dateFrom : null}
                        onChange={(date) =>
                          handleChangeAssetDate(date, "dateFrom")
                        }
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha hasta"
                        format="DD/MM/yyyy"
                        disabled={disabled}
                        InputAdornmentProps={{ position: "end" }}
                        value={asset && asset.dateTo ? asset.dateTo : null}
                        onChange={(date) =>
                          handleChangeAssetDate(date, "dateTo")
                        }
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box display={"flex"} justifyContent={"flex-end"}>
                        {((!asset.dateTo &&
                          moment(asset.dateFrom).isSameOrBefore(moment())) ||
                          moment().isBetween(
                            moment(asset.dateFrom),
                            moment(asset.dateTo)
                          )) && (
                          <Chip
                            icon={<DoneIcon />}
                            label="Vigente"
                            color="primary"
                            variant="outlined"
                            style={{ marginBottom: "20px" }}
                          />
                        )}
                        {!(
                          (!asset.dateTo &&
                            moment(asset.dateFrom).isSameOrBefore(moment())) ||
                          moment().isBetween(
                            moment(asset.dateFrom),
                            moment(asset.dateTo)
                          )
                        ) && (
                          <Chip
                            icon={<DoneIcon />}
                            label="Fuera de fecha"
                            color="secondary"
                            variant="outlined"
                            style={{ marginBottom: "20px" }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        id="order"
                        placeholder="Orden del contenido #"
                        variant="outlined"
                        label={"# de orden del contenido en la sección"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disabled: disabled,
                          type: "number",
                          min: 1,
                        }}
                        disabled={disabled}
                        fullWidth
                        value={asset ? asset.order : ""}
                        onChange={(e) => handleChangeAsset(e)}
                        // onKeyDown={(e) => handleKeyDown(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        visibility: !idAsset ? "hidden" : "inherit",
                        marginTop: "20px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={asset && !!asset.status}
                            onChange={() => handleChangeStatusAsset()}
                            disabled={disabled}
                            name="estado"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        }
                        label="Estado"
                      />
                    </Grid>
                  </PaperBody>
                </CustomPaper>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <CustomPaper>
                  <PaperHeader>
                    {/* <Typography variant={"overline"} component={"span"}>
                      ¿En qué países se visualizará este contenido?
                    </Typography> */}
                  </PaperHeader>
                  <AssetsCountriesList
                    onSelect={handleChangeAssetCountry}
                    isSelected={isUbicacionSelected}
                    refresh={refreshCheckboxSelectAllCountries}
                    disabled={disabled}
                    // title="¿En qué países se visualizará este contenido?"
                    msjCountries={msjCountries}
                  />
                </CustomPaper>
              </Grid>
            </Grid>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={"overline"} component={"span"}>
                  Contenido
                </Typography>
              </PaperHeader>
              <PaperBody>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id={"idTypeFile"}
                    select
                    fullWidth
                    label={"Tipo elemento"}
                    value={asset && asset.idTypeFile ? asset.idTypeFile : ""}
                    onChange={(e) => handleChangeAssetTypeFile(e)}
                    InputProps={{
                      id: "idTypeFile",
                      name: "idTypeFile",
                    }}
                    disabled={disabled || !!idAsset}
                    helperText={
                      typeFile && typeFile.desType ? typeFile.desType : ""
                    }
                    variant="outlined"
                    onBlur={validador.validar}
                    error={!validador.isValidById("idTypeFile")}
                    // helperText={validador.getHelperTextById("idTypeFile")}
                  >
                    {typesFiles.allIds.map((idTypeFile) => {
                      let typeFile = typesFiles.byId.typesFiles[idTypeFile];
                      return (
                        <MenuItem key={idTypeFile} value={idTypeFile}>
                          {typeFile.nameType}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {typeFile && typeFile.codType === "PRODUCT" && (
                    <ModalSelectProductType
                      idProduct={
                        asset && asset.idProduct ? asset.idProduct : null
                      }
                      changeAsset={changeAsset}
                    />
                  )}
                  {idsLanguages &&
                    idsLanguages.map((idLanguage, i) => {
                      let language = languages.byId.languages[idLanguage];
                      if (idLanguage === null)
                        language = {
                          id: null,
                          desLanguage: "Multi-idioma",
                          unicodeIcon: "🏳️‍🌈",
                        };
                      let assetLanguageFound = null;
                      if (asset && asset.asset_language)
                        asset.asset_language.some((idAssetLanguage) => {
                          let assetLanguage =
                            assetsLanguages.byId.assetsLanguages[
                              idAssetLanguage
                            ];
                          if (
                            assetsLanguages.update.activos.indexOf(
                              idAssetLanguage
                            ) !== -1
                          )
                            assetLanguage =
                              assetsLanguages.update.activo[idAssetLanguage];
                          if (
                            assetsLanguages.create.nuevos.indexOf(
                              idAssetLanguage
                            ) !== -1
                          )
                            assetLanguage =
                              assetsLanguages.create.nuevo[idAssetLanguage];
                          if (
                            assetLanguage &&
                            assetLanguage.idLanguage === idLanguage &&
                            (assetLanguage.status === undefined ||
                              assetLanguage.status === 1)
                          ) {
                            assetLanguageFound = assetLanguage;
                            return true;
                          }
                          return false;
                        });
                      let file = assetLanguageFound
                        ? assetsLanguages.byId.files[
                            assetLanguageFound.filename
                          ]
                        : null;
                      if (typeFile)
                        switch (typeFile.codType) {
                          case "BANNER":
                            let fileDesktop = assetLanguageFound
                              ? assetsLanguages.byId.files[
                                  assetLanguageFound.filenameDesktop
                                ]
                              : null;
                            let fileMobile = assetLanguageFound
                              ? assetsLanguages.byId.files[
                                  assetLanguageFound.filenameMobile
                                ]
                              : null;
                            return (
                              <BannerType
                                files={files}
                                setFiles={setFiles}
                                fileDesktop={fileDesktop}
                                fileMobile={fileMobile}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                handleChangeAssetLanguage={
                                  handleChangeAssetLanguage
                                }
                                changeAssetLanguage={changeAssetLanguage}
                                previewImageVideoAssetLanguage={
                                  previewImageVideoAssetLanguage
                                }
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                disabled={disabled}
                              />
                            );
                          case "HTML":
                            return (
                              <TextType
                                language={language}
                                assetLanguage={assetLanguageFound}
                                changeAssetLanguage={changeAssetLanguage}
                                handleChangeAssetLanguage={
                                  handleChangeAssetLanguage
                                }
                              />
                            );
                          case "PDF":
                            return (
                              <FileType
                                legend={"Subir solo archivo .pdf"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={".pdf"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "FILE":
                            return (
                              <FileType
                                legend={"Subir archivo"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={"*"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "IMAGE":
                            return (
                              <ImageFileType
                                legend={"Subir solo archivos de imágenes"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={"image/*"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "CSV":
                            return (
                              <FileType
                                legend={"Subir solo archivos CSV"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={".csv,.xls,xlsx"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "OFFICE":
                            return (
                              <FileType
                                legend={"Subir solo archivos de office"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={
                                  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
                                }
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "PRODUCT":
                            return (
                              <ProductTextType
                                language={language}
                                assetLanguage={assetLanguageFound}
                                changeAssetLanguage={changeAssetLanguage}
                              />
                            );
                          case "URL":
                            return (
                              <URL
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                              />
                            );
                          case "URL VIDEO":
                            return (
                              <VideoType
                                language={language}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                              />
                            );
                          case "VIDEO":
                            return (
                              <FileType
                                legend={"Subir solo archivos de video"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={"video/*"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                          case "LOGO":
                            return (
                              <ImageFileType
                                legend={"Subir solo archivos de imágenes"}
                                files={files}
                                setFiles={setFiles}
                                acceptTypes={"image/*"}
                                file={file}
                                language={language}
                                assetLanguage={assetLanguageFound}
                                onChange={handleChangeAssetLanguage}
                                changeAssetLanguage={changeAssetLanguage}
                                deleteAssetLanguage={deleteAssetLanguageFile}
                                downloadFileAssetLanguage={
                                  downloadFileAssetLanguage
                                }
                                previewFileAssetLanguage={
                                  previewFileAssetLanguage
                                }
                              />
                            );
                        }
                    })}
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Container>
        </DialogContent>
      </form>
    </Dialog>
  );
}
