import {resetAttributes, resetCreateAttribute, resetUpdateAttribute, resetDeleteAttribute} from "../actions/AttributeActions";
import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";

var resetAttribute = {
    resetAll(dispatch) {
        dispatch(resetAttributes());
        dispatch(resetCreateAttribute());
        dispatch(resetUpdateAttribute());
        dispatch(resetDeleteAttribute());
       dispatch(resetProducts());
dispatch(resetCreateProduct());
dispatch(resetUpdateProduct());
dispatch(resetDeleteProduct());
    },
};

export default resetAttribute;