//api
import postsLanguagesApi from "../api/postsLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizePostsLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//POSTSLANGUAGES
export const REQUEST_POSTSLANGUAGES = 'REQUEST_POSTSLANGUAGES';
export const RECEIVE_POSTSLANGUAGES = 'RECEIVE_POSTSLANGUAGES';
export const INVALIDATE_POSTSLANGUAGES = 'INVALIDATE_POSTSLANGUAGES';
export const ERROR_POSTSLANGUAGES = "ERROR_POSTSLANGUAGES";
export const RESET_POSTSLANGUAGES = "RESET_POSTSLANGUAGES";


export function invalidatePostsLanguages() {
    return {
        type: INVALIDATE_POSTSLANGUAGES
    }
}

function requestPostsLanguages() {
    return {
        type: REQUEST_POSTSLANGUAGES,
    }
}

function receivePostsLanguages(json) {
    return {
        type: RECEIVE_POSTSLANGUAGES,
    postsLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPostsLanguages(error) {
    return {
        type: ERROR_POSTSLANGUAGES,
        error: error,
    }
}

export function resetPostsLanguages() {
    return {
        type: RESET_POSTSLANGUAGES
    }
}

export function fetchPostsLanguages(filtros) {
    return dispatch => {
        dispatch(requestPostsLanguages());
        return postsLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePostsLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPostsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostsLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPostsLanguages(state) {
    const postsLanguages = state.postsLanguages.byId;
    if (!postsLanguages) {
        return true
    } else if (postsLanguages.isFetching) {
        return false
    } else {
        return postsLanguages.didInvalidate;
    }
}

export function fetchPostsLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPostsLanguages(getState())) {
            return dispatch(fetchPostsLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_POSTLANGUAGE = 'REQUEST_POSTLANGUAGE';
export const RECEIVE_POSTLANGUAGE = 'RECEIVE_POSTLANGUAGE';
export const INVALIDATE_POSTLANGUAGE = 'INVALIDATE_POSTLANGUAGE';
export const ERROR_POSTLANGUAGE = "ERROR_POSTLANGUAGE";
export const RESET_POSTLANGUAGE = "RESET_POSTLANGUAGE";


export function invalidatePostLanguage() {
    return {
        type: INVALIDATE_POSTLANGUAGE
    }
}

function requestPostLanguage() {
    return {
        type: REQUEST_POSTLANGUAGE,
    }
}

export function receivePostLanguage(json) {
    return {
        type: RECEIVE_POSTLANGUAGE,
    postLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPostLanguage(error) {
    return {
        type: ERROR_POSTLANGUAGE,
        error: error,
    }
}

export function fetchPostLanguage(idPostLanguage) {
    return dispatch => {
        dispatch(requestPostLanguage());
        return postsLanguagesApi.getOne(idPostLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePostLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_POSTLANGUAGE = 'RECEIVE_FILE_POSTLANGUAGE';

function receiveFilePostLanguage(file) {
    return {
        type: RECEIVE_FILE_POSTLANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFilePostLanguage(idPostLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return postsLanguagesApi.getFile(idPostLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFilePostLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_POSTLANGUAGE = 'UPDATE_POSTLANGUAGE';
export const REQUEST_UPDATE_POSTLANGUAGE = "REQUEST_UPDATE_POSTLANGUAGE";
export const SUCCESS_UPDATE_POSTLANGUAGE = "SUCCESS_UPDATE_POSTLANGUAGE";
export const ERROR_UPDATE_POSTLANGUAGE = "ERROR_UPDATE_POSTLANGUAGE";
export const RESET_UPDATE_POSTLANGUAGE = "RESET_UPDATE_POSTLANGUAGE";
export const DELETE_UPDATE_POSTLANGUAGE = "DELETE_UPDATE_POSTLANGUAGE";

function requestUpdatePostLanguage() {
    return {
        type: REQUEST_UPDATE_POSTLANGUAGE,
    }
}

function receiveUpdatePostLanguage(postLanguage) {
    return {
        type: SUCCESS_UPDATE_POSTLANGUAGE,
        receivedAt: Date.now(),
        postLanguage: normalizeDato(postLanguage)
    }
}

function errorUpdatePostLanguage(error) {
    return {
        type: ERROR_UPDATE_POSTLANGUAGE,
        error: error,
    }
}

export function resetUpdatePostLanguage() {
    return {
        type: RESET_UPDATE_POSTLANGUAGE,
    }
}

export function updatePostLanguage(postLanguage) {
    return {
        type: UPDATE_POSTLANGUAGE,
        postLanguage
    }
}

export function saveUpdatePostLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePostLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postLanguage = denormalizeDato(getState().postsLanguages.update.activo, store);

        return postsLanguagesApi.saveUpdate(postLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePostLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdatePostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdatePostsLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdatePostsLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdatePostLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdatePostLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdatePostLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdatePostLanguage(postLanguage) {
    return {
        type: DELETE_UPDATE_POSTLANGUAGE,
        postLanguage
    }
}

//UPDATE POSTSLANGUAGES
export const REQUEST_UPDATE_POSTSLANGUAGES = "REQUEST_UPDATE_POSTSLANGUAGES";
export const SUCCESS_UPDATE_POSTSLANGUAGES = "SUCCESS_UPDATE_POSTSLANGUAGES";
export const ERROR_UPDATE_POSTSLANGUAGES = "ERROR_UPDATE_POSTSLANGUAGES";
export const RESET_UPDATE_POSTSLANGUAGES = "RESET_UPDATE_POSTSLANGUAGES";

function requestUpdatePostsLanguages() {
    return {
        type: REQUEST_UPDATE_POSTSLANGUAGES,
}
}

function receiveUpdatePostsLanguages(postsLanguages) {
    return {
        type: SUCCESS_UPDATE_POSTSLANGUAGES,
    receivedAt: Date.now(),
        postsLanguages: normalizeDatos(postsLanguages)
}
}

function errorUpdatePostsLanguages(error) {
    return {
        type: ERROR_UPDATE_POSTSLANGUAGES,
    error: error,
}
}

export function resetUpdatePostsLanguages() {
    return {
        type: RESET_UPDATE_POSTSLANGUAGES,
}
}

export function saveUpdatePostsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePostsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postsLanguages = getState().postsLanguages.update.activos.map((idPostLanguage) => {
            return denormalizeDato(getState().postsLanguages.update.activo[idPostLanguage], store);
        });

        return postsLanguagesApi.saveUpdatePostsLanguages(postsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePostsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePostsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdatePostsLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdatePostsLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdatePostsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdatePostsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA POSTLANGUAGE
export const CREATE_POSTLANGUAGE = 'CREATE_POSTLANGUAGE';
export const REQUEST_CREATE_POSTLANGUAGE = "REQUEST_CREATE_POSTLANGUAGE";
export const SUCCESS_CREATE_POSTLANGUAGE = "SUCCESS_CREATE_POSTLANGUAGE";
export const ERROR_CREATE_POSTLANGUAGE = "ERROR_CREATE_POSTLANGUAGE";
export const RESET_CREATE_POSTLANGUAGE = "RESET_CREATE_POSTLANGUAGE";
export const DELETE_CREATE_POSTLANGUAGE = "DELETE_CREATE_POSTLANGUAGE";


//ALTA POSTLANGUAGE
function requestCreatePostLanguage() {
    return {
        type: REQUEST_CREATE_POSTLANGUAGE,
    }
}

function receiveCreatePostLanguage(postLanguage) {
    return {
        type: SUCCESS_CREATE_POSTLANGUAGE,
        receivedAt: Date.now(),
        postLanguage: normalizeDato(postLanguage)
    }
}

export function errorCreatePostLanguage(error) {
    return {
        type: ERROR_CREATE_POSTLANGUAGE,
        error: error,
    }
}

export function resetCreatePostLanguage() {
    return {
        type: RESET_CREATE_POSTLANGUAGE
    }
}

export function createPostLanguage(postLanguage) {
    return {
        type: CREATE_POSTLANGUAGE,
        postLanguage
    }
}

export function deleteCreatePostLanguage(postLanguage) {
    return {
        type: DELETE_CREATE_POSTLANGUAGE,
        postLanguage
    }
}

export function saveCreatePostLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreatePostLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postLanguage = denormalizeDato(getState().postsLanguages.create.nuevo, store);

        return postsLanguagesApi.saveCreate(postLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePostLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreatePostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreatePostsLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreatePostsLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreatePostLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreatePostLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreatePostLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE POSTSLANGUAGES
export const REQUEST_CREATE_POSTSLANGUAGES = "REQUEST_CREATE_POSTSLANGUAGES";
export const SUCCESS_CREATE_POSTSLANGUAGES = "SUCCESS_CREATE_POSTSLANGUAGES";
export const ERROR_CREATE_POSTSLANGUAGES = "ERROR_CREATE_POSTSLANGUAGES";
export const RESET_CREATE_POSTSLANGUAGES = "RESET_CREATE_POSTSLANGUAGES";

function requestCreatePostsLanguages() {
    return {
        type: REQUEST_CREATE_POSTSLANGUAGES,
}
}

function receiveCreatePostsLanguages(postsLanguages) {
    return {
        type: SUCCESS_CREATE_POSTSLANGUAGES,
    receivedAt: Date.now(),
        postsLanguages: normalizeDatos(postsLanguages)
}
}

function errorCreatePostsLanguages(error) {
    return {
        type: ERROR_CREATE_POSTSLANGUAGES,
    error: error,
}
}

export function resetCreatePostsLanguages() {
    return {
        type: RESET_CREATE_POSTSLANGUAGES,
}
}

export function saveCreatePostsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreatePostsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postsLanguages = getState().postsLanguages.create.nuevos.map((idPostLanguage) => {
            return denormalizeDato(getState().postsLanguages.create.nuevo[idPostLanguage], store);
        });

        return postsLanguagesApi.saveCreatePostsLanguages(postsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePostsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePostsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreatePostsLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreatePostsLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreatePostsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreatePostsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE POSTLANGUAGE
export const DELETE_POSTLANGUAGE = 'DELETE_POSTLANGUAGE';
export const REQUEST_DELETE_POSTLANGUAGE = "REQUEST_DELETE_POSTLANGUAGE";
export const SUCCESS_DELETE_POSTLANGUAGE = "SUCCESS_DELETE_POSTLANGUAGE";
export const ERROR_DELETE_POSTLANGUAGE = "ERROR_DELETE_POSTLANGUAGE";
export const RESET_DELETE_POSTLANGUAGE = "RESET_DELETE_POSTLANGUAGE";

function requestDeletePostLanguage() {
    return {
        type: REQUEST_DELETE_POSTLANGUAGE,
    }
}

function receiveDeletePostLanguage(postLanguage) {
    return {
        type: SUCCESS_DELETE_POSTLANGUAGE,
        receivedAt: Date.now(),
        postLanguage: normalizeDato(postLanguage)
    }
}

function errorDeletePostLanguage(error) {
    return {
        type: ERROR_DELETE_POSTLANGUAGE,
        error: error,
    }
}

export function resetDeletePostLanguage(error) {
    return {
        type: RESET_DELETE_POSTLANGUAGE,
        error: error,
    }
}

export function deletePostLanguage(postLanguage) {
    return {
        type: DELETE_POSTLANGUAGE,
        postLanguage
    }
}

export function saveDeletePostLanguage(postLanguage) {
    return dispatch => {
        dispatch(requestDeletePostLanguage());
        return postsLanguagesApi.saveDelete(postLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeletePostLanguage());
                            dispatch(receiveDeletePostLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeletePostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeletePostLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeletePostLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeletePostLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeletePostLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT POSTLANGUAGE
export const REQUEST_PRINT_POSTLANGUAGE = "REQUEST_PRINT_POSTLANGUAGE";
export const SUCCESS_PRINT_POSTLANGUAGE = "SUCCESS_PRINT_POSTLANGUAGE";
export const ERROR_PRINT_POSTLANGUAGE = "ERROR_PRINT_POSTLANGUAGE";
export const RESET_PRINT_POSTLANGUAGE = "RESET_PRINT_POSTLANGUAGE";

function requestPrintPostLanguage() {
    return {
        type: REQUEST_PRINT_POSTLANGUAGE,
    }
}

function receivePrintPostLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_POSTLANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintPostLanguage(error) {
    return {
        type: ERROR_PRINT_POSTLANGUAGE,
        error: error,
    }
}

export function resetPrintPostLanguage() {
    return {
        type: RESET_PRINT_POSTLANGUAGE,
    }
}

export function printPostLanguage(idPostLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintPostLanguage());
        return postsLanguagesApi.printPostLanguage(idPostLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintPostLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintPostLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintPostLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintPostLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintPostLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}