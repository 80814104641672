import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
//Components
import {Typography,} from '@material-ui/core';
import Box from "@material-ui/core/Box";
//Img
//Actions

const useStyles = makeStyles(theme => ({
    typographyLanguage: {
        maxWidth: "500px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        "& p": {
            padding: "0px",
            margin: "0px",
            display: "inline"
        }
    }
}));

export default function FilePreviewType(props) {
    const classes = useStyles();
    const {assetLanguage, language} = props;

    return (
        <Typography variant={"caption"} className={classes.typographyLanguage}>
            <Box display={"flex"} flexDirection={"row"} style={{fontSize:'x-large'}}>
                {language ? language.unicodeIcon : ""}
            </Box>
        </Typography>
    );
}
