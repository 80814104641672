//api
import assetsLanguagesApi from "../api/assetsLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAssetsLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ASSETSLANGUAGES
export const REQUEST_ASSETSLANGUAGES = 'REQUEST_ASSETSLANGUAGES';
export const RECEIVE_ASSETSLANGUAGES = 'RECEIVE_ASSETSLANGUAGES';
export const INVALIDATE_ASSETSLANGUAGES = 'INVALIDATE_ASSETSLANGUAGES';
export const ERROR_ASSETSLANGUAGES = "ERROR_ASSETSLANGUAGES";
export const RESET_ASSETSLANGUAGES = "RESET_ASSETSLANGUAGES";


export function invalidateAssetsLanguages() {
    return {
        type: INVALIDATE_ASSETSLANGUAGES
    }
}

function requestAssetsLanguages() {
    return {
        type: REQUEST_ASSETSLANGUAGES,
    }
}

function receiveAssetsLanguages(json) {
    return {
        type: RECEIVE_ASSETSLANGUAGES,
    assetsLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAssetsLanguages(error) {
    return {
        type: ERROR_ASSETSLANGUAGES,
        error: error,
    }
}

export function resetAssetsLanguages() {
    return {
        type: RESET_ASSETSLANGUAGES
    }
}

export function fetchAssetsLanguages(filtros) {
    return dispatch => {
        dispatch(requestAssetsLanguages());
        return assetsLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssetsLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetsLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAssetsLanguages(state) {
    const assetsLanguages = state.assetsLanguages.byId;
    if (!assetsLanguages) {
        return true
    } else if (assetsLanguages.isFetching) {
        return false
    } else {
        return assetsLanguages.didInvalidate;
    }
}

export function fetchAssetsLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAssetsLanguages(getState())) {
            return dispatch(fetchAssetsLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_ASSETLANGUAGE = 'REQUEST_ASSETLANGUAGE';
export const RECEIVE_ASSETLANGUAGE = 'RECEIVE_ASSETLANGUAGE';
export const INVALIDATE_ASSETLANGUAGE = 'INVALIDATE_ASSETLANGUAGE';
export const ERROR_ASSETLANGUAGE = "ERROR_ASSETLANGUAGE";
export const RESET_ASSETLANGUAGE = "RESET_ASSETLANGUAGE";


export function invalidateAssetLanguage() {
    return {
        type: INVALIDATE_ASSETLANGUAGE
    }
}

function requestAssetLanguage() {
    return {
        type: REQUEST_ASSETLANGUAGE,
    }
}

export function receiveAssetLanguage(json) {
    return {
        type: RECEIVE_ASSETLANGUAGE,
    assetLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAssetLanguage(error) {
    return {
        type: ERROR_ASSETLANGUAGE,
        error: error,
    }
}

export function fetchAssetLanguage(idAssetLanguage) {
    return dispatch => {
        dispatch(requestAssetLanguage());
        return assetsLanguagesApi.getOne(idAssetLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAssetLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ASSETLANGUAGE = 'RECEIVE_FILE_ASSETLANGUAGE';

function receiveFileAssetLanguage(file) {
    return {
        type: RECEIVE_FILE_ASSETLANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAssetLanguage(idAssetLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return assetsLanguagesApi.getFile(idAssetLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAssetLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAssetLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ASSETLANGUAGE = 'UPDATE_ASSETLANGUAGE';
export const REQUEST_UPDATE_ASSETLANGUAGE = "REQUEST_UPDATE_ASSETLANGUAGE";
export const SUCCESS_UPDATE_ASSETLANGUAGE = "SUCCESS_UPDATE_ASSETLANGUAGE";
export const ERROR_UPDATE_ASSETLANGUAGE = "ERROR_UPDATE_ASSETLANGUAGE";
export const RESET_UPDATE_ASSETLANGUAGE = "RESET_UPDATE_ASSETLANGUAGE";
export const DELETE_UPDATE_ASSETLANGUAGE = "DELETE_UPDATE_ASSETLANGUAGE";

function requestUpdateAssetLanguage() {
    return {
        type: REQUEST_UPDATE_ASSETLANGUAGE,
    }
}

function receiveUpdateAssetLanguage(assetLanguage) {
    return {
        type: SUCCESS_UPDATE_ASSETLANGUAGE,
        receivedAt: Date.now(),
        assetLanguage: normalizeDato(assetLanguage)
    }
}

function errorUpdateAssetLanguage(error) {
    return {
        type: ERROR_UPDATE_ASSETLANGUAGE,
        error: error,
    }
}

export function resetUpdateAssetLanguage() {
    return {
        type: RESET_UPDATE_ASSETLANGUAGE,
    }
}

export function updateAssetLanguage(assetLanguage) {
    return {
        type: UPDATE_ASSETLANGUAGE,
        assetLanguage
    }
}

export function saveUpdateAssetLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssetLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetLanguage = denormalizeDato(getState().assetsLanguages.update.activo, store);

        return assetsLanguagesApi.saveUpdate(assetLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssetLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAssetsLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAssetsLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAssetLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAssetLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAssetLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAssetLanguage(assetLanguage) {
    return {
        type: DELETE_UPDATE_ASSETLANGUAGE,
        assetLanguage
    }
}

//UPDATE ASSETSLANGUAGES
export const REQUEST_UPDATE_ASSETSLANGUAGES = "REQUEST_UPDATE_ASSETSLANGUAGES";
export const SUCCESS_UPDATE_ASSETSLANGUAGES = "SUCCESS_UPDATE_ASSETSLANGUAGES";
export const ERROR_UPDATE_ASSETSLANGUAGES = "ERROR_UPDATE_ASSETSLANGUAGES";
export const RESET_UPDATE_ASSETSLANGUAGES = "RESET_UPDATE_ASSETSLANGUAGES";

function requestUpdateAssetsLanguages() {
    return {
        type: REQUEST_UPDATE_ASSETSLANGUAGES,
}
}

function receiveUpdateAssetsLanguages(assetsLanguages) {
    return {
        type: SUCCESS_UPDATE_ASSETSLANGUAGES,
    receivedAt: Date.now(),
        assetsLanguages: normalizeDatos(assetsLanguages)
}
}

function errorUpdateAssetsLanguages(error) {
    return {
        type: ERROR_UPDATE_ASSETSLANGUAGES,
    error: error,
}
}

export function resetUpdateAssetsLanguages() {
    return {
        type: RESET_UPDATE_ASSETSLANGUAGES,
}
}

export function saveUpdateAssetsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAssetsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetsLanguages = getState().assetsLanguages.update.activos.map((idAssetLanguage) => {
            return denormalizeDato(getState().assetsLanguages.update.activo[idAssetLanguage], store);
        });

        return assetsLanguagesApi.saveUpdateAssetsLanguages(assetsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAssetsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAssetsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAssetsLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAssetsLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAssetsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAssetsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ASSETLANGUAGE
export const CREATE_ASSETLANGUAGE = 'CREATE_ASSETLANGUAGE';
export const REQUEST_CREATE_ASSETLANGUAGE = "REQUEST_CREATE_ASSETLANGUAGE";
export const SUCCESS_CREATE_ASSETLANGUAGE = "SUCCESS_CREATE_ASSETLANGUAGE";
export const ERROR_CREATE_ASSETLANGUAGE = "ERROR_CREATE_ASSETLANGUAGE";
export const RESET_CREATE_ASSETLANGUAGE = "RESET_CREATE_ASSETLANGUAGE";
export const DELETE_CREATE_ASSETLANGUAGE = "DELETE_CREATE_ASSETLANGUAGE";


//ALTA ASSETLANGUAGE
function requestCreateAssetLanguage() {
    return {
        type: REQUEST_CREATE_ASSETLANGUAGE,
    }
}

function receiveCreateAssetLanguage(assetLanguage) {
    return {
        type: SUCCESS_CREATE_ASSETLANGUAGE,
        receivedAt: Date.now(),
        assetLanguage: normalizeDato(assetLanguage)
    }
}

export function errorCreateAssetLanguage(error) {
    return {
        type: ERROR_CREATE_ASSETLANGUAGE,
        error: error,
    }
}

export function resetCreateAssetLanguage() {
    return {
        type: RESET_CREATE_ASSETLANGUAGE
    }
}

export function createAssetLanguage(assetLanguage) {
    return {
        type: CREATE_ASSETLANGUAGE,
        assetLanguage
    }
}

export function deleteCreateAssetLanguage(assetLanguage) {
    return {
        type: DELETE_CREATE_ASSETLANGUAGE,
        assetLanguage
    }
}

export function saveCreateAssetLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssetLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetLanguage = denormalizeDato(getState().assetsLanguages.create.nuevo, store);

        return assetsLanguagesApi.saveCreate(assetLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssetLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAssetsLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAssetsLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAssetLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAssetLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAssetLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ASSETSLANGUAGES
export const REQUEST_CREATE_ASSETSLANGUAGES = "REQUEST_CREATE_ASSETSLANGUAGES";
export const SUCCESS_CREATE_ASSETSLANGUAGES = "SUCCESS_CREATE_ASSETSLANGUAGES";
export const ERROR_CREATE_ASSETSLANGUAGES = "ERROR_CREATE_ASSETSLANGUAGES";
export const RESET_CREATE_ASSETSLANGUAGES = "RESET_CREATE_ASSETSLANGUAGES";

function requestCreateAssetsLanguages() {
    return {
        type: REQUEST_CREATE_ASSETSLANGUAGES,
}
}

function receiveCreateAssetsLanguages(assetsLanguages) {
    return {
        type: SUCCESS_CREATE_ASSETSLANGUAGES,
    receivedAt: Date.now(),
        assetsLanguages: normalizeDatos(assetsLanguages)
}
}

function errorCreateAssetsLanguages(error) {
    return {
        type: ERROR_CREATE_ASSETSLANGUAGES,
    error: error,
}
}

export function resetCreateAssetsLanguages() {
    return {
        type: RESET_CREATE_ASSETSLANGUAGES,
}
}

export function saveCreateAssetsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateAssetsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let assetsLanguages = getState().assetsLanguages.create.nuevos.map((idAssetLanguage) => {
            return denormalizeDato(getState().assetsLanguages.create.nuevo[idAssetLanguage], store);
        });

        return assetsLanguagesApi.saveCreateAssetsLanguages(assetsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAssetsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAssetsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAssetsLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAssetsLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAssetsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAssetsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ASSETLANGUAGE
export const DELETE_ASSETLANGUAGE = 'DELETE_ASSETLANGUAGE';
export const REQUEST_DELETE_ASSETLANGUAGE = "REQUEST_DELETE_ASSETLANGUAGE";
export const SUCCESS_DELETE_ASSETLANGUAGE = "SUCCESS_DELETE_ASSETLANGUAGE";
export const ERROR_DELETE_ASSETLANGUAGE = "ERROR_DELETE_ASSETLANGUAGE";
export const RESET_DELETE_ASSETLANGUAGE = "RESET_DELETE_ASSETLANGUAGE";

function requestDeleteAssetLanguage() {
    return {
        type: REQUEST_DELETE_ASSETLANGUAGE,
    }
}

function receiveDeleteAssetLanguage(assetLanguage) {
    return {
        type: SUCCESS_DELETE_ASSETLANGUAGE,
        receivedAt: Date.now(),
        assetLanguage: normalizeDato(assetLanguage)
    }
}

function errorDeleteAssetLanguage(error) {
    return {
        type: ERROR_DELETE_ASSETLANGUAGE,
        error: error,
    }
}

export function resetDeleteAssetLanguage(error) {
    return {
        type: RESET_DELETE_ASSETLANGUAGE,
        error: error,
    }
}

export function deleteAssetLanguage(assetLanguage) {
    return {
        type: DELETE_ASSETLANGUAGE,
        assetLanguage
    }
}

export function saveDeleteAssetLanguage(assetLanguage) {
    return dispatch => {
        dispatch(requestDeleteAssetLanguage());
        return assetsLanguagesApi.saveDelete(assetLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAssetLanguage());
                            dispatch(receiveDeleteAssetLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAssetLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAssetLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAssetLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAssetLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ASSETLANGUAGE
export const REQUEST_PRINT_ASSETLANGUAGE = "REQUEST_PRINT_ASSETLANGUAGE";
export const SUCCESS_PRINT_ASSETLANGUAGE = "SUCCESS_PRINT_ASSETLANGUAGE";
export const ERROR_PRINT_ASSETLANGUAGE = "ERROR_PRINT_ASSETLANGUAGE";
export const RESET_PRINT_ASSETLANGUAGE = "RESET_PRINT_ASSETLANGUAGE";

function requestPrintAssetLanguage() {
    return {
        type: REQUEST_PRINT_ASSETLANGUAGE,
    }
}

function receivePrintAssetLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_ASSETLANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAssetLanguage(error) {
    return {
        type: ERROR_PRINT_ASSETLANGUAGE,
        error: error,
    }
}

export function resetPrintAssetLanguage() {
    return {
        type: RESET_PRINT_ASSETLANGUAGE,
    }
}

export function printAssetLanguage(idAssetLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAssetLanguage());
        return assetsLanguagesApi.printAssetLanguage(idAssetLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAssetLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAssetLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAssetLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAssetLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAssetLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}