import {resetAttributesGroups, resetCreateAttributeGroup, resetUpdateAttributeGroup, resetDeleteAttributeGroup} from "../actions/AttributeGroupActions";
import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";

var resetAttributeGroup = {
    resetAll(dispatch) {
        dispatch(resetAttributesGroups());
        dispatch(resetCreateAttributeGroup());
        dispatch(resetUpdateAttributeGroup());
        dispatch(resetDeleteAttributeGroup());
       dispatch(resetProducts());
dispatch(resetCreateProduct());
dispatch(resetUpdateProduct());
dispatch(resetDeleteProduct());
    },
};

export default resetAttributeGroup;