import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './assets/css/Index.css';
import 'react-quill/dist/quill.snow.css';

//Router
import { Redirect, Route, Router, Switch } from "react-router-dom";
import history from './history';
//Redux
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers/index';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
//Components
import Layout from "./component/Layout/Layout.js";
import Login from "./component/Login/Login"
// import '@fontsource/roboto';
import "@fontsource/public-sans";
import SolicitudOlvideMiPassword from "./component/Login/SolicitudOlvideMiPassword";
import RestablecerOlvideMiPassword from "./component/Login/RestablecerOlvideMiPassword";
// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    createStoreWithMiddleware = applyMiddleware(thunk, loggerMiddleware)(createStore);
    console.log("Mode: Debug ð")
} else {
    console.log("Mode: Production ð")
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

    //delete console.log
    (function () {
        var method;
        var noop = function noop() {
        };
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            console[method] = noop;
        }
    }());
}

const store = createStoreWithMiddleware(appReducers);
//For auth
const PrivateRoute = ({component, ...rest}) => ( // eslint-disable-line
    <Route
        {...rest} render={props => (
        (store.getState().auth.loggedIn &&
            store.getState().auth.usuarioLogueado)
            //true
            ? (
                React.createElement(component, props)
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location}, // eslint-disable-line
                    }}
                />
            )
    )}
    />
);
ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/olvido/:token" component={RestablecerOlvideMiPassword}/>
                <Route path="/olvido" component={SolicitudOlvideMiPassword}/>
                <Route path="/login" component={Login}/>
                <PrivateRoute path="/" component={Layout}/>
            </Switch>
        </Router>
    </Provider>, document.getElementById('root'));