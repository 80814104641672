import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, Button, CircularProgress, Typography} from '@material-ui/core';
import clsx from "clsx";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
//Icons
import clone from "lodash/clone";
import union from "lodash/union";
import {useDispatch, useSelector} from "react-redux";
import productReset from "../../reset/productReset";
import productAttributeValueReset from "../../reset/productAttributeValueReset";
import productAttributeValueLanguageReset from "../../reset/productAttributeValueLanguageReset";
import attributeReset from "../../reset/attributeReset";
import attributeGroupReset from "../../reset/attributeGroupReset";
import attributeLanguageReset from "../../reset/attributeLanguageReset";
import assetReset from "../../reset/assetReset";
import c from "../../constants/Constants";
//Img


const styles = makeStyles((theme) => ({
    form: {
        // padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "120px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        width: "100%",
        height: "100%",
        "& img": {
            objectFit: "cover",
            height: "100%",
        }
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));


export default function ImageFilePost(props) {
    const classes = styles();
    let inputfileprod = React.createRef();
    let img = React.useRef();
    const {
        disabled,
        files,
        setFiles,
        changePost,
        file,
    } = props;
    //State
    const [loading, setLoading] = React.useState(false);


    const handleChangeFileAssetLanguage = (e) => {
        e.preventDefault();
        //Preview
        let fotos = [];
        setLoading(true);
        for (let i = 0; i < inputfileprod.current.files.length; i++) {
            let filename = inputfileprod.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(inputfileprod.current.files[i], 'name', {
                writable: true,
                value: filename
            });
            fotos.push(inputfileprod.current.files[i]);
        }
        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        for (let i = 0; i < inputfileprod.current.files.length; i++) {
            let fileSubido = inputfileprod.current.files[i];

            let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                setLoading(false);
                img.current.src = reader.result;
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
            let cambio = {};
            cambio.filename = filename;
            changePost(cambio);
        }
    };


    return (
        <Box component={"div"} className={classes.form} width={"100%"} height={"100%"}>
            <Box component={"div"} className={clsx([classes.fileInput, classes.desktop])}>
                <Button className={classes.buttonUpload} disabled={disabled}>
                    <label htmlFor={"mainFilePost"}
                           className={classes.labelButton}
                    >
                        <AddPhotoAlternateIcon color={"primary"}/>
                        <Typography variant={"caption"} color={"primary"}>
                            Subir imagen portada
                        </Typography>
                        <img ref={img} src={file ? c.API_HOST + file : ""} alt=""/>
                        <CircularProgress className={classes.progress}
                                          style={{display: !loading ? "none" : "inherit"}}/>
                    </label>
                    <input disabled={disabled} ref={inputfileprod} type="file" name="mainFilePost" id={"mainFilePost"}
                           className="inputfile"
                           accept="image/*"
                           onChange={(e) => handleChangeFileAssetLanguage(e)}
                    />
                </Button>
            </Box>
        </Box>
    );
}

ImageFilePost.propTypes = {
    legend: PropTypes.string,
}