import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import clone from "lodash/clone";

export default function usePermisos(modulo) {
    let map = new Map();
    map.set("read", false);
    map.set("create", false);
    map.set("update", false);
    map.set("delete", false);
    const [permisosUsuario, setPermisosUsuario] = useState(map);
    //Store
    const permisos = useSelector(state => state.permissions);

    useEffect(() => {
        let mapPermisos = clone(permisosUsuario);
        permisos.allIds.forEach((idPermiso) => {
            let permiso = permisos.byId.permissions[idPermiso];
            if (permiso.urlModule === modulo)
                mapPermisos.set(permiso.permission, true);
        });
        setPermisosUsuario(mapPermisos);
    }, [permisos.allIds]);

    return permisosUsuario;
}