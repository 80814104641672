import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
//Components
import {
    IconButton,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import Grow from "../../library/Grow";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import PaperFooter from "../../library/CustomPaper/PaperFooter";
import Box from "@material-ui/core/Box";
import {Skeleton} from "@material-ui/lab";


import Spacing from "../../library/Spacing";
//Img
import CropFreeIcon from "@material-ui/icons/Search";
//reset
//Actions
import {
    fetchAssociatedProducts,
    invalidateAssociatedProducts,
    resetAssociatedProducts,
    saveDeleteAssociatedProduct
} from "../../actions/AssociatedProductActions";
import {fetchFileProduct} from "../../actions/ProductActions";

let moment = require('moment');

const useStyles = makeStyles(theme => ({
    typographyLanguage: {
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "flex-start",
            flexWrap: "nowrap",
        },
    }
}));

export default function AssociatedProductsList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {idProductAsset} = props;

    //Store
    const associatedProducts = useSelector(state => state.associatedProducts);
    const products = useSelector(state => state.products);

    //State
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState("");
    const [searchFetch, setSearchFetch] = useState("");
    const [order, setOrder] = useState("id");
    const [direction, setDirection] = useState("asc");
    const [page, setPage] = useState(1);
    const [idCategorySelected, setIdCategorySelected] = useState("");

    const disabled = associatedProducts.byId.isFetching || associatedProducts.delete.isDeleting;

    //Hooks
    const timer = useRef();
    const mounted = useRef();

    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);

        // if (searchPhase != search) {
        //     setSearch(searchPhase);
        // }

        return function cleanup() {

        }
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: search ? search : "",
            order: order,
            direction: direction,
            page: page,
            idProductAsset: idProductAsset,
            with: ["relatedProduct"],
            limit: 10
        };

        dispatch(resetAssociatedProducts());
        dispatch(invalidateAssociatedProducts());
        if (idProductAsset)
            dispatch(fetchAssociatedProducts(filtros));
    }, [searchFetch]);


    // useEffect(() => {
    //     if (associatedProducts.byId.isFetching === false) {
    //         fetchFiles();
    //     }
    // }, [associatedProducts.byId.isFetching]);
    //
    // const fetchFiles = () => {
    //     associatedProducts.allIds.forEach((idAsset) => {
    //         let associatedProduct = associatedProducts.byId.associatedProducts[idAsset];
    //         let product = associatedProduct ? associatedProducts.byId.associatedProducts[associatedProduct.idMainProduct] : null;
    //         if (product && !products.byId.files[product.filename]) {
    //             dispatch(fetchFileProduct(product.id, {}));
    //         }
    //     })
    // }

    const changeOrder = (or) => {
        setOrder(or ? or : "");
        setDirection(direction === "asc" ? "desc" : "asc");
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        if ((!searchPhase && !search) || (searchPhase === "")) {
            setSearchFetch("");
        } else {
            setPage(1);
            setSearchFetch(searchPhase ? searchPhase : searchFetch);
        }
    };

    const handleChangePage = (e, page) => {
        setPage(page ? page : "1");
    };

    const handleChangeEstado = (idAsset) => {
        let asset = associatedProducts.byId.associatedProducts[idAsset];
        dispatch(saveDeleteAssociatedProduct(asset));
    }

    const handleChangeMostrar = (e, value) => {
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("show", value ? value : "actives");
        props.history.push("/associatedProducts?" + paramsPagina.toString());
    };

    const handleChangeSection = (id) => {
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("idSection", id ? id : "");
        props.history.push("/associatedProducts?" + paramsPagina.toString());
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    let AssociatedProducts = [];

    if (associatedProducts.byId.isFetching) {
        AssociatedProducts = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(4).keys()].map((i) =>
                    <TableCell><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    if (!associatedProducts.byId.isFetching)
        AssociatedProducts = associatedProducts.allIds.map(idAssociatedProduct => {
            let associatedProduct = associatedProducts.byId.associatedProducts[idAssociatedProduct];
            let product = associatedProduct ? products.byId.products[associatedProduct.idRelatedProduct] : null;
            return (
                <TableRow key={associatedProduct.id}>
                    <TableCell>{associatedProduct ? associatedProduct.id : ""}</TableCell>
                    <TableCell>{product ? product.genericName : ""}</TableCell>
                    <TableCell>{associatedProduct ? associatedProduct.nameRelation : ""}</TableCell>
                    <TableCell>
                        <Switch
                            disabled={disabled}
                            checked={!!associatedProduct.status}
                            onChange={() => handleChangeEstado(associatedProduct.id)}
                            name={"estado"}
                            color={"primary"}
                            size={"small"}
                        />
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*    <Link to={"/associatedProducts/" + associatedProduct.id}*/}
                    {/*    >*/}
                    {/*        <Tooltip title="Editar">*/}
                    {/*            <IconButton aria-label="editar" size={"small"} disabled={disabled}>*/}
                    {/*                <EditIcon color="secondary"/>*/}
                    {/*            </IconButton>*/}
                    {/*        </Tooltip>*/}
                    {/*    </Link>*/}
                    {/*</TableCell>*/}
                </TableRow>
            )
        });

    if (AssociatedProducts.length === 0)
        AssociatedProducts.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={11}>No se encontraron productos asociados</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(associatedProducts.totalAssociatedProducts ? associatedProducts.totalAssociatedProducts / 10 : 0);


    return (
        <Box>
            <CustomPaper>
                <PaperHeader>
                    <Typography variant={"overline"} component={"span"}>
                        Productos asociados
                    </Typography>
                </PaperHeader>
                <PaperHeader>
                    <TextField
                        id="search"
                        placeholder="Buscar ..."
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CropFreeIcon/>
                                    </IconButton>
                                </InputAdornment>,
                            // disabled: disabled
                        }}
                        value={search ? search : ""}
                        onChange={(e) => changeSearch(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Spacing/>
                    {/*<ToggleButtonGroup*/}
                    {/*    value={show}*/}
                    {/*    exclusive*/}
                    {/*    onChange={handleChangeMostrar}*/}
                    {/*    aria-label="text alignment"*/}
                    {/*>*/}
                    {/*    <ToggleButton value="actives" aria-label="vigentes">*/}
                    {/*        Activos*/}
                    {/*    </ToggleButton>*/}
                    {/*    <ToggleButton value="todos" aria-label="todos">*/}
                    {/*        Todos*/}
                    {/*    </ToggleButton>*/}
                    {/*</ToggleButtonGroup>*/}
                    <Grow/>
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    className={classes.button}*/}
                    {/*    startIcon={<AddIcon/>}*/}
                    {/*    onClick={handleClickOpen}*/}
                    {/*    disabled={disabled || !idProductAsset}*/}
                    {/*>*/}
                    {/*    Agregar*/}
                    {/*</Button>*/}
                </PaperHeader>
                <Box component={"div"} width={"100%"} padding={"0 24px 5px 24px"}>

                </Box>
                <TableContainer>
                    <Table aria-label="Tabla de contenido">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "id"}
                                        direction={direction}
                                        onClick={() => changeOrder("id")}
                                    >
                                        <b>ID</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "nameAsset"}
                                        direction={direction}
                                        onClick={() => changeOrder("nameAsset")}
                                    >
                                        <b>Relación</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <b>Producto</b>
                                </TableCell>
                                <TableCell width={"10px"}/>
                                {/*<TableCell width={"10px"}/>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                AssociatedProducts
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaperFooter style={{justifyContent: "center"}}>
                    {
                        !!totalPaginas && totalPaginas > 1 &&
                        <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                    onChange={handleChangePage} size={"small"}/>
                    }
                </PaperFooter>
            </CustomPaper>
            {/*<CRUDEmbeddedtAsset open={open} setOpen={setOpen} idProductAsset={idProductAsset}/>*/}
        </Box>
    );
}
