import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";
import {resetAssetsLanguages, resetCreateAssetLanguage, resetUpdateAssetLanguage, resetDeleteAssetLanguage} from "../actions/AssetLanguageActions";import {resetAssetsCountries, resetCreateAssetCountry, resetUpdateAssetCountry, resetDeleteAssetCountry} from "../actions/AssetCountryActions";

var resetAsset = {
    resetAll(dispatch) {
        dispatch(resetAssets());
        dispatch(resetCreateAsset());
        dispatch(resetUpdateAsset());
        dispatch(resetDeleteAsset());
       dispatch(resetAssetsLanguages());
dispatch(resetCreateAssetLanguage());
dispatch(resetUpdateAssetLanguage());
dispatch(resetDeleteAssetLanguage());dispatch(resetAssetsCountries());
dispatch(resetCreateAssetCountry());
dispatch(resetUpdateAssetCountry());
dispatch(resetDeleteAssetCountry());
    },
};

export default resetAsset;