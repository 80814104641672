import {normalize, schema, denormalize} from 'normalizr';
import typeFileSection from "./normalizeTypesFilesSections";

const page = new schema.Entity('pages', {}, {idAttribute: "id"});
const section = new schema.Entity('sections', {
    page: page,
    type_file_section: [typeFileSection]}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {sections: [section]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = section;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = section;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default section;
