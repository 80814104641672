import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import c from '../../../constants/Constants';
//Components
import {
    Box,
    Container, Dialog, DialogContent, DialogTitle,
    InputAdornment, Modal,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import {useDispatch, useSelector} from "react-redux";
import productReset from "../../../reset/productReset";
import {
    fetchProduct,
    fetchProducts,
    invalidateProducts,
    resetProducts,
    saveDeleteProduct
} from "../../../actions/ProductActions";
import {Skeleton} from "@material-ui/lab";
import HTMLPreviewType from "../PreviewsTypesElements/HTMLPreviewType";
import CustomPaper from "../../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../../library/CustomPaper/PaperHeader";
import CropFreeIcon from "@material-ui/icons/Search";
import Grow from "../../../library/Grow";
import PaperFooter from "../../../library/CustomPaper/PaperFooter";
import Pagination from "@material-ui/lab/Pagination";
import ContainerBtn from "../../../library/Buttons/ContainerBtn";
import BtnAdd from "../../../library/Buttons/BtnAdd";
import {fetchCategories} from "../../../actions/CategoryActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Icons
//Img

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    root: {
        display: 'flex',
        justifyContent: "space-between"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        backgroundSize: "contain"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


function ModalSelectProductType(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {idProduct, changeAsset} = props;

    //Store
    const products = useSelector(state => state.products);
    const product = idProduct ? products.byId.products[idProduct] : null;
    const categories = useSelector(state => state.categories);
    const languages = useSelector(state => state.languages);
    const productsLanguages = useSelector(state => state.productsLanguages);
    const productsCountries = useSelector(state => state.productsCountries);
    const countries = useSelector(state => state.countries);

    //State
    const [search, setSearch] = useState("");
    const [searchFetch, setSearchFetch] = useState("");
    const [order, setOrder] = useState("id");
    const [direction, setDirection] = useState("asc");
    const [page, setPage] = useState(1);
    const [idCategorySelected, setIdCategorySelected] = useState("");


    // const params = new URLSearchParams(props.location.search);
    // const order = params.get("order") ? params.get("order") : "id";
    // const direction = params.get("direction") ? params.get("direction") : "asc";
    // const page = params.get("page") ? params.get("page") : 1;
    // const searchPhase = params.get("sp") ? params.get("sp") : "";
    // const idCategorySelected = params.get("idCategory") ? params.get("idCategory") : "";
    const category = categories ? categories.byId.categories[idCategorySelected] : null;

    //Hooks
    const timer = useRef();
    const mounted = useRef();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);

        // if (searchPhase != search) {
        //     setSearch(searchPhase);
        // }
        dispatch(fetchCategories({order: 1}));

        return function cleanup() {
            productReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: search ? search : "",
            order: order,
            direction: direction,
            page: page,
            idCategory: idCategorySelected,
            with: ["productLanguage", "productCountry", "mainAsset.assetLanguage"]
        };

        dispatch(resetProducts());
        dispatch(invalidateProducts());
        dispatch(fetchProducts(filtros));
    }, [searchFetch, page]);

    useEffect(() => {
        dispatch(fetchProduct(idProduct));
    }, [idProduct]);

    const changeOrder = (or) => {
        // params.set("order", or ? or : "");
        // let direction = params.get("direction");
        // params.set("direction", direction === "asc" ? "desc" : "asc");
        // props.history.push("/products?" + params.toString());

        setOrder(or ? or : "");
        setDirection(direction === "asc" ? "desc" : "asc");
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        // if ((!searchPhase && !search) || (searchPhase === "")) {
        //     params.delete("sp");
        //     props.history.push("/products?" + params.toString());
        // } else {
        //     params.delete("page");
        //     params.set("sp", searchPhase ? searchPhase : search);
        //     props.history.push("/products?" + params.toString());
        // }

        if ((!searchPhase && !search) || (searchPhase === "")) {
            setSearchFetch("");
        } else {
            setPage(1);
            setSearchFetch(searchPhase ? searchPhase : searchFetch);
        }
    };

    const handleChangePage = (e, page) => {
        // let pagina = page;
        // let paramsPagina = new URLSearchParams(props.location.search);
        // paramsPagina.set("page", pagina ? pagina : "1");
        // props.history.push("/products?" + paramsPagina.toString());
        setPage(page ? page : "1");
    };

    const handleSelectProduct = (idProduct) => {
        handleClose();
        let cambio = {};
        cambio.idProduct = idProduct;
        changeAsset(cambio);
    }

    let Products = [];

    if (products.byId.isFetching) {
        Products = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(6).keys()].map((i) =>
                    <TableCell><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    if (!products.byId.isFetching)
        Products = products.allIds.map(idProduct => {
            let product = products.byId.products[idProduct];
            let category = product ? categories.byId.categories[product.idCategory] : null;
            let productLanguages = product.product_language && product.product_language.map((idProductLanguage) => {
                let productLanguage = productsLanguages.byId.productsLanguages[idProductLanguage];
                let language = productLanguage ? languages.byId.languages[productLanguage.idLanguage] : null;
                if (productLanguage)
                    return (
                        <HTMLPreviewType productLanguage={productLanguage} language={language}/>
                    )
            })
            let productCountries = product && product.product_country && product.product_country.map((idProductCountry) => {
                let productCountry = productsCountries.byId.productsCountries[idProductCountry];
                let country = productCountry ? countries.byId.countries[productCountry.idCountry] : null;
                if (country)
                    return (
                        country.unicodeIcon
                    )
            })
            return (
                <TableRow key={product.id}>
                    <TableCell>{product ? product.id : ""}</TableCell>
                    <TableCell>{product ? product.codProduct : ""}</TableCell>
                    <TableCell>{product ? product.genericName : ""}</TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"column"}>
                            {
                                productLanguages
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"column"} flexWrap={"wrap"}>
                            {
                                productCountries
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Button variant="outlined" color="primary" onClick={() => handleSelectProduct(idProduct)}>
                            Seleccionar
                        </Button>
                    </TableCell>
                </TableRow>
            )
        });

    if (Products.length === 0)
        Products.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={11}>No se encontraron productos</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(products.totalProducts ? products.totalProducts / 50 : 0);


    return (
        <Box component={"div"} className={classes.form}>
            <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                    <Box component={"div"} display={"flex"} alignItems={"center"} height={'100%'}>
                        <Button variant="outlined" color="primary" onClick={handleOpen}>
                            Seleccionar producto destacado
                        </Button>
                    </Box>
                </Grid>
                {
                    !!idProduct &&
                    <Grid item md={6} sm={12} xs={12}>
                        <Card className={classes.root}>
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography component="h5" variant="h5">
                                        {product ? product.genericName : ""}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {product ? product.codProduct : ""}
                                    </Typography>
                                </CardContent>
                            </div>
                            <CardMedia
                                className={classes.cover}
                                image={product && product.main_asset && product.main_asset[0] && product.main_asset[0].asset_language && product.main_asset[0].asset_language[0] && product.main_asset[0].asset_language[0].path ? c.API_HOST + product.main_asset[0].asset_language[0].path : ""}
                                title="Live from space album cover"
                            />
                        </Card>
                    </Grid>
                }
            </Grid>
            <Dialog open={open} maxWidth={"lg"} fullWidth={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" onClose={handleClose}>
                    <Typography variant="h6">Seleccionar producto destacado</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CustomPaper>
                        <PaperHeader>
                            <TextField
                                id="search"
                                placeholder="Buscar ..."
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <CropFreeIcon/>
                                            </IconButton>
                                        </InputAdornment>,
                                    // disabled: disabled
                                }}
                                value={search ? search : ""}
                                onChange={(e) => changeSearch(e)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                            <Grow/>
                        </PaperHeader>
                        <TableContainer>
                            <Table aria-label="Tabla de contenido">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={order === "id"}
                                                direction={direction}
                                                onClick={() => changeOrder("id")}
                                            >
                                                <b>ID</b>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={order === "codProduct"}
                                                direction={direction}
                                                onClick={() => changeOrder("codProduct")}
                                            >
                                                <b>Código</b>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={order === "genericName"}
                                                direction={direction}
                                                onClick={() => changeOrder("genericName")}
                                            >
                                                <b>Nombre</b>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"500px"}>
                                            <b>Idiomas</b>
                                        </TableCell>
                                        <TableCell width={"100px"}>
                                            <b>Países</b>
                                        </TableCell>
                                        <TableCell width={"10px"}/>
                                        <TableCell width={"10px"}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Products
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaperFooter style={{justifyContent: "center"}}>
                            {
                                !!totalPaginas && totalPaginas > 1 &&
                                <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                            onChange={handleChangePage} size={"small"}/>
                            }
                        </PaperFooter>
                    </CustomPaper>
                    {/*<ContainerBtn>*/}
                    {/*    <BtnAdd onClick={() => props.history.push("/products/new")}/>*/}
                    {/*</ContainerBtn>*/}
                </DialogContent>
            </Dialog>
        </Box>)
}

ModalSelectProductType.propTypes = {}


export default ModalSelectProductType;