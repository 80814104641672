import {
    INVALIDATE_ATTRIBUTESGROUPS,
    ERROR_ATTRIBUTESGROUPS,
    RECEIVE_ATTRIBUTESGROUPS,
    REQUEST_ATTRIBUTESGROUPS,
    RESET_ATTRIBUTESGROUPS,
    ERROR_ATTRIBUTEGROUP,
    RECEIVE_ATTRIBUTEGROUP,
    REQUEST_ATTRIBUTEGROUP,
    UPDATE_ATTRIBUTEGROUP,
    REQUEST_UPDATE_ATTRIBUTEGROUP,
    SUCCESS_UPDATE_ATTRIBUTEGROUP,
    ERROR_UPDATE_ATTRIBUTEGROUP,
    RESET_UPDATE_ATTRIBUTEGROUP,
    REQUEST_UPDATE_ATTRIBUTESGROUPS,
    SUCCESS_UPDATE_ATTRIBUTESGROUPS,
    ERROR_UPDATE_ATTRIBUTESGROUPS,
    RESET_UPDATE_ATTRIBUTESGROUPS,
    CREATE_ATTRIBUTEGROUP,
    ERROR_CREATE_ATTRIBUTEGROUP,
    REQUEST_CREATE_ATTRIBUTEGROUP,
    RESET_CREATE_ATTRIBUTEGROUP,
    SUCCESS_CREATE_ATTRIBUTEGROUP,
    REQUEST_CREATE_ATTRIBUTESGROUPS,
    SUCCESS_CREATE_ATTRIBUTESGROUPS,
    ERROR_CREATE_ATTRIBUTESGROUPS,
    RESET_CREATE_ATTRIBUTESGROUPS,
    DELETE_ATTRIBUTEGROUP,
    DELETE_CREATE_ATTRIBUTEGROUP,
    DELETE_UPDATE_ATTRIBUTEGROUP,
    REQUEST_DELETE_ATTRIBUTEGROUP,
    SUCCESS_DELETE_ATTRIBUTEGROUP,
    ERROR_DELETE_ATTRIBUTEGROUP,
    RESET_DELETE_ATTRIBUTEGROUP,
    REQUEST_PRINT_ATTRIBUTEGROUP,
    SUCCESS_PRINT_ATTRIBUTEGROUP,
    ERROR_PRINT_ATTRIBUTEGROUP,
    RESET_PRINT_ATTRIBUTEGROUP,
    RECEIVE_FILE_ATTRIBUTEGROUP
} from '../actions/AttributeGroupActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        attributesGroups: {},
        files: {},
    }
}

function attributesGroupsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                attributesGroups: {}
            });
        case RECEIVE_ATTRIBUTESGROUPS:
            let dato = action.attributesGroups.entities.attributesGroups;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                attributesGroups: merge({}, state.attributesGroups, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ATTRIBUTEGROUP:
            let datoAttributeGroup = action.attributeGroup.entities.attributesGroups;
            return Object.assign({}, state, {
                attributesGroups: merge({}, state.attributesGroups, datoAttributeGroup),
                isFetching: false,
            });
        case RECEIVE_FILE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ATTRIBUTEGROUP:
            let datoAttributeGroupEliminado = action.attributeGroup.entities.attributesGroups;
            return Object.assign({}, state, {
                attributesGroups: mergeWith(clone(datoAttributeGroupEliminado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTEGROUP:
            let datoAttributeGroupCreado = action.attributeGroup.entities.attributesGroups;
            return Object.assign({}, state, {
                attributesGroups: mergeWith(clone(datoAttributeGroupCreado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTESGROUPS:
            let datosAttributeGroupCreado = action.attributesGroups.entities.attributesGroups;
                return Object.assign({}, state, {
                    attributesGroups: mergeWith(clone(datosAttributeGroupCreado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ATTRIBUTEGROUP:
            let datoAttributeGroupActualizado = action.attributeGroup.entities.attributesGroups;
            return Object.assign({}, state, {
                attributesGroups: mergeWith(clone(datoAttributeGroupActualizado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
            let datosAttributeGroupActualizado = action.attributesGroups.entities.attributesGroups;
                return Object.assign({}, state, {
                    attributesGroups: mergeWith(clone(datosAttributeGroupActualizado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : {};
    return Object.assign({}, state, {
        attributesGroups: merge({}, state.attributesGroups, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributesGroups ? action.products.entities.attributesGroups : {};
    return Object.assign({}, state, {
        attributesGroups: merge({}, state.attributesGroups, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : {};
    return Object.assign({}, state, {
        attributesGroups: mergeWith(clone(datoproductEliminado), pickBy(state.attributesGroups, function(attributeGroup) {return attributeGroup.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : {};
    return Object.assign({}, state, {
        attributesGroups: mergeWith(clone(datoproductCreado), pickBy(state.attributesGroups, function(attributeGroup) {return attributeGroup.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : {};
    return Object.assign({}, state, {
        attributesGroups: mergeWith(clone(datoproductActualizado), pickBy(state.attributesGroups, function(attributeGroup) {return attributeGroup.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.attributesGroups ? action.products.entities.attributesGroups : {} ;
        return Object.assign({}, state, {
            attributesGroups: mergeWith(clone(datosproductActualizado), state.attributesGroups, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                attributesGroups: {}
            });
        default:
            return state
    }
}


function allAttributesGroups(state = [], action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTESGROUPS:
            return action.attributesGroups.result && action.attributesGroups.result.attributesGroups ? union(action.attributesGroups.result.attributesGroups, state) : (action.attributesGroups.result ? action.attributesGroups.result : state) ;
        case RECEIVE_ATTRIBUTEGROUP:
                return action.attributeGroup.result ? union([action.attributeGroup.result], state) : state;
        
        case SUCCESS_CREATE_ATTRIBUTEGROUP:
                   let datoAttributeGroupSCreate = action.attributeGroup.entities.attributesGroups;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAttributeGroupSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAttributeGroupSCreate)[0] && Object.values(datoAttributeGroupSCreate)[0].id ? Object.values(datoAttributeGroupSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ATTRIBUTESGROUPS:
                   let attributesGroupsCreate = action.attributesGroups.entities && action.attributesGroups.entities.attributesGroups ? action.attributesGroups.entities.attributesGroups : null;
                   return attributesGroupsCreate ?
                       union(state, Object.values(attributesGroupsCreate).map((attributesGroups) => {
                           return attributesGroups.id
                       })) : state;
        case RESET_ATTRIBUTESGROUPS:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributesGroups ? action.products.entities.attributesGroups : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.attributesGroups ? action.product.entities.attributesGroups : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.attributesGroups ? action.products.entities.attributesGroups : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAttributesGroups(state = null, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTESGROUPS:
            return action.attributesGroups && action.attributesGroups.result.total ? action.attributesGroups.result.total : 0;
        case RESET_ATTRIBUTESGROUPS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTEGROUP:
            let dato = action.attributeGroup.entities.attributesGroups;
            let attributeGroup = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: attributeGroup ? attributeGroup : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ATTRIBUTEGROUP:
            let idsUpdate = [];
            Object.values(action.attributeGroup).map((attributeGroupUpdate) => {
                if (attributeGroupUpdate && attributeGroupUpdate.id)
                    idsUpdate.push(attributeGroupUpdate.id);
            });
            return merge({}, state, {
                activo: action.attributeGroup,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ATTRIBUTEGROUP:
            let datoAttributeGroupActualizado = {};
            if (Object.values(action.attributeGroup.entities.attributesGroups).length > 0)
                datoAttributeGroupActualizado = Object.values(action.attributeGroup.entities.attributesGroups)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAttributeGroupActualizado
            });
        case ERROR_UPDATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ATTRIBUTESGROUPS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ATTRIBUTESGROUPS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ATTRIBUTEGROUP:
            let datoAttributeGroupDelete = action.attributeGroup;
            let idsDelete = [];
           Object.values(action.attributeGroup).map((attributeGroupDelete) => {
               if (attributeGroupDelete && attributeGroupDelete.id)
                   idsDelete.push(attributeGroupDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAttributeGroupDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ATTRIBUTEGROUP:
                       let datoAttributeGroupDeleteUpdate = action.attributeGroup;
                       let idsDeleteUpdate = [];
                      Object.values(action.attributeGroup).map((attributeGroupDelete) => {
                          if (attributeGroupDelete && attributeGroupDelete.id)
                              idsDeleteUpdate.push(attributeGroupDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAttributeGroupDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ATTRIBUTEGROUP:
                    let datoAttributeGroupDeleted = {};
                    if (Object.values(action.attributeGroup.entities.attributesGroups).length > 0)
                        datoAttributeGroupDeleted = Object.values(action.attributeGroup.entities.attributesGroups)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAttributeGroupDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ATTRIBUTEGROUP:
             let idsCreate = [];
             Object.values(action.attributeGroup).map((attributeGroupCreate) => {
                 if (attributeGroupCreate && attributeGroupCreate.id)
                     idsCreate.push(attributeGroupCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.attributeGroup,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ATTRIBUTEGROUP:
            let datoAttributeGroupNuevo = {};
            if (Object.values(action.attributeGroup.entities.attributesGroups).length > 0)
                datoAttributeGroupNuevo = Object.values(action.attributeGroup.entities.attributesGroups)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAttributeGroupNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTESGROUPS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ATTRIBUTEGROUP:
           let datoAttributeGroupDelete = action.attributeGroup;
           let idsDelete = [];
           Object.values(action.attributeGroup).map((attributeGroupDelete) => {
               if (attributeGroupDelete && attributeGroupDelete.id)
                   idsDelete.push(attributeGroupDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeGroupDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ATTRIBUTEGROUP:
                  let datoAttributeGroupDeleteCreate = action.attributeGroup;
                  let idsDeleteCreate = [];
                  Object.values(action.attributeGroup).map((attributeGroupDelete) => {
                      if (attributeGroupDelete && attributeGroupDelete.id)
                          idsDeleteCreate.push(attributeGroupDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeGroupDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ATTRIBUTEGROUP:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.attributeGroup,
                error: null,
            });
        case REQUEST_DELETE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ATTRIBUTEGROUP:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const attributesGroups = combineReducers({
    byId: attributesGroupsById,
    allIds: allAttributesGroups,
    update: update,
    create: create,
    totalAttributesGroups: totalAttributesGroups,
    delete: deleter,
    print: print
});

export default attributesGroups;