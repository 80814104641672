import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from "react-redux";
//Components
import AssetsList from "./AssetsList";
import CRUDAsset from "./CRUDAsset";
import {fetchPermissionsIfNeeded, invalidatePermissions, resetPermissions} from "../../actions/PermissionActions";
import permissionReset from "../../reset/permissionReset";

export default function Assets() {

    const dispatch = useDispatch();

    //Effects
    useEffect(() => {
        dispatch(invalidatePermissions());
        dispatch(fetchPermissionsIfNeeded({show: "Mis permisos", module: "assets"}));
        return function cleanup() {
            permissionReset.resetAll(dispatch);
        }
    }, []);

    return (
        <Switch>
            <Route path="/assets/new/:idSection?" component={CRUDAsset}/>
            <Route path="/assets/:idAsset" component={CRUDAsset}/>
            <Route path="/assets" component={AssetsList}/>
        </Switch>

    );
}

