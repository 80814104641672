import {
    INVALIDATE_CATEGORIESLANGUAGES,
    ERROR_CATEGORIESLANGUAGES,
    RECEIVE_CATEGORIESLANGUAGES,
    REQUEST_CATEGORIESLANGUAGES,
    RESET_CATEGORIESLANGUAGES,
    ERROR_CATEGORYLANGUAGE,
    RECEIVE_CATEGORYLANGUAGE,
    REQUEST_CATEGORYLANGUAGE,
    UPDATE_CATEGORYLANGUAGE,
    REQUEST_UPDATE_CATEGORYLANGUAGE,
    SUCCESS_UPDATE_CATEGORYLANGUAGE,
    ERROR_UPDATE_CATEGORYLANGUAGE,
    RESET_UPDATE_CATEGORYLANGUAGE,
    REQUEST_UPDATE_CATEGORIESLANGUAGES,
    SUCCESS_UPDATE_CATEGORIESLANGUAGES,
    ERROR_UPDATE_CATEGORIESLANGUAGES,
    RESET_UPDATE_CATEGORIESLANGUAGES,
    CREATE_CATEGORYLANGUAGE,
    ERROR_CREATE_CATEGORYLANGUAGE,
    REQUEST_CREATE_CATEGORYLANGUAGE,
    RESET_CREATE_CATEGORYLANGUAGE,
    SUCCESS_CREATE_CATEGORYLANGUAGE,
    REQUEST_CREATE_CATEGORIESLANGUAGES,
    SUCCESS_CREATE_CATEGORIESLANGUAGES,
    ERROR_CREATE_CATEGORIESLANGUAGES,
    RESET_CREATE_CATEGORIESLANGUAGES,
    DELETE_CATEGORYLANGUAGE,
    DELETE_CREATE_CATEGORYLANGUAGE,
    DELETE_UPDATE_CATEGORYLANGUAGE,
    REQUEST_DELETE_CATEGORYLANGUAGE,
    SUCCESS_DELETE_CATEGORYLANGUAGE,
    ERROR_DELETE_CATEGORYLANGUAGE,
    RESET_DELETE_CATEGORYLANGUAGE,
    REQUEST_PRINT_CATEGORYLANGUAGE,
    SUCCESS_PRINT_CATEGORYLANGUAGE,
    ERROR_PRINT_CATEGORYLANGUAGE,
    RESET_PRINT_CATEGORYLANGUAGE,
    RECEIVE_FILE_CATEGORYLANGUAGE
} from '../actions/CategoryLanguageActions';

import {
    RECEIVE_CATEGORY,
    RECEIVE_CATEGORIES,
    SUCCESS_DELETE_CATEGORY,
    SUCCESS_CREATE_CATEGORY,
    SUCCESS_UPDATE_CATEGORY,
    SUCCESS_UPDATE_CATEGORIES

} from '../actions/CategoryActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        categoriesLanguages: {},
        files: {},
    }
}

function categoriesLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                categoriesLanguages: {}
            });
        case RECEIVE_CATEGORIESLANGUAGES:
            let dato = action.categoriesLanguages.entities.categoriesLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categoriesLanguages: merge({}, state.categoriesLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_CATEGORYLANGUAGE:
            let datoCategoryLanguage = action.categoryLanguage.entities.categoriesLanguages;
            return Object.assign({}, state, {
                categoriesLanguages: merge({}, state.categoriesLanguages, datoCategoryLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_CATEGORYLANGUAGE:
            let datoCategoryLanguageEliminado = action.categoryLanguage.entities.categoriesLanguages;
            return Object.assign({}, state, {
                categoriesLanguages: mergeWith(clone(datoCategoryLanguageEliminado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORYLANGUAGE:
            let datoCategoryLanguageCreado = action.categoryLanguage.entities.categoriesLanguages;
            return Object.assign({}, state, {
                categoriesLanguages: mergeWith(clone(datoCategoryLanguageCreado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORIESLANGUAGES:
            let datosCategoryLanguageCreado = action.categoriesLanguages.entities.categoriesLanguages;
                return Object.assign({}, state, {
                    categoriesLanguages: mergeWith(clone(datosCategoryLanguageCreado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_CATEGORYLANGUAGE:
            let datoCategoryLanguageActualizado = action.categoryLanguage.entities.categoriesLanguages;
            return Object.assign({}, state, {
                categoriesLanguages: mergeWith(clone(datoCategoryLanguageActualizado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_CATEGORIESLANGUAGES:
            let datosCategoryLanguageActualizado = action.categoriesLanguages.entities.categoriesLanguages;
                return Object.assign({}, state, {
                    categoriesLanguages: mergeWith(clone(datosCategoryLanguageActualizado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
                });

            //CATEGORY
case RECEIVE_CATEGORY:
    let category = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : {};
    return Object.assign({}, state, {
        categoriesLanguages: merge({}, state.categoriesLanguages, category),
    });
case RECEIVE_CATEGORIES:
    let categories = action.categories.entities && action.categories.entities.categoriesLanguages ? action.categories.entities.categoriesLanguages : {};
    return Object.assign({}, state, {
        categoriesLanguages: merge({}, state.categoriesLanguages, categories),
    });
case SUCCESS_DELETE_CATEGORY:
    let datocategoryEliminado = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : {};
    return Object.assign({}, state, {
        categoriesLanguages: mergeWith(clone(datocategoryEliminado), pickBy(state.categoriesLanguages, function(categoryLanguage) {return categoryLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_CATEGORY:
    let datocategoryCreado = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : {};
    return Object.assign({}, state, {
        categoriesLanguages: mergeWith(clone(datocategoryCreado), pickBy(state.categoriesLanguages, function(categoryLanguage) {return categoryLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_CATEGORY:
    let datocategoryActualizado = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : {};
    return Object.assign({}, state, {
        categoriesLanguages: mergeWith(clone(datocategoryActualizado), pickBy(state.categoriesLanguages, function(categoryLanguage) {return categoryLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_CATEGORIES:
    let datoscategoryActualizado = action.categories.entities && action.categories.entities.categoriesLanguages ? action.categories.entities.categoriesLanguages : {} ;
        return Object.assign({}, state, {
            categoriesLanguages: mergeWith(clone(datoscategoryActualizado), state.categoriesLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                categoriesLanguages: {}
            });
        default:
            return state
    }
}


function allCategoriesLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIESLANGUAGES:
            return action.categoriesLanguages.result && action.categoriesLanguages.result.categoriesLanguages ? union(action.categoriesLanguages.result.categoriesLanguages, state) : (action.categoriesLanguages.result ? action.categoriesLanguages.result : state) ;
        case RECEIVE_CATEGORYLANGUAGE:
                return action.categoryLanguage.result ? union([action.categoryLanguage.result], state) : state;
        
        case SUCCESS_CREATE_CATEGORYLANGUAGE:
                   let datoCategoryLanguageSCreate = action.categoryLanguage.entities.categoriesLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoCategoryLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoCategoryLanguageSCreate)[0] && Object.values(datoCategoryLanguageSCreate)[0].id ? Object.values(datoCategoryLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_CATEGORIESLANGUAGES:
                   let categoriesLanguagesCreate = action.categoriesLanguages.entities && action.categoriesLanguages.entities.categoriesLanguages ? action.categoriesLanguages.entities.categoriesLanguages : null;
                   return categoriesLanguagesCreate ?
                       union(state, Object.values(categoriesLanguagesCreate).map((categoriesLanguages) => {
                           return categoriesLanguages.id
                       })) : state;
        case RESET_CATEGORIESLANGUAGES:
            return [];

            case RECEIVE_CATEGORY:
    let category = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : null ;
    return category ?
        union(state, Object.values(category).map((category) => {
            return category.id
        })) : state;
case RECEIVE_CATEGORIES:
    let categories = action.categories.entities && action.categories.entities.categoriesLanguages ? action.categories.entities.categoriesLanguages : null;
    return categories ?
        union(state, Object.values(categories).map((categories) => {
            return categories.id
        })) : state;

case SUCCESS_DELETE_CATEGORY:
    let categoryDelete = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : null ;
        return categoryDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(categoryDelete).map((category) => {
                return category.id
            })) : state;
case SUCCESS_CREATE_CATEGORY:
   let categoryCreate = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : null ;
       return categoryCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(categoryCreate).map((category) => {
               return category.id
           })) : state;
case SUCCESS_UPDATE_CATEGORY:
    let categoryUpdate = action.category.entities && action.category.entities.categoriesLanguages ? action.category.entities.categoriesLanguages : null ;
        return categoryUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(categoryUpdate).map((category) => {
                return category.id
            })) : state;
 case SUCCESS_UPDATE_CATEGORIES:
     let categoriesUpdate = action.categories.entities && action.categories.entities.categoriesLanguages ? action.categories.entities.categoriesLanguages : null;
        return categoriesUpdate ?
            union(state, Object.values(categoriesUpdate).map((categories) => {
                return categories.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalCategoriesLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIESLANGUAGES:
            return action.categoriesLanguages && action.categoriesLanguages.result.total ? action.categoriesLanguages.result.total : 0;
        case RESET_CATEGORIESLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORYLANGUAGE:
            let dato = action.categoryLanguage.entities.categoriesLanguages;
            let categoryLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: categoryLanguage ? categoryLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_CATEGORYLANGUAGE:
            let idsUpdate = [];
            Object.values(action.categoryLanguage).map((categoryLanguageUpdate) => {
                if (categoryLanguageUpdate && categoryLanguageUpdate.id)
                    idsUpdate.push(categoryLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.categoryLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_CATEGORYLANGUAGE:
            let datoCategoryLanguageActualizado = {};
            if (Object.values(action.categoryLanguage.entities.categoriesLanguages).length > 0)
                datoCategoryLanguageActualizado = Object.values(action.categoryLanguage.entities.categoriesLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoCategoryLanguageActualizado
            });
        case ERROR_UPDATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_CATEGORIESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_CATEGORIESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_CATEGORIESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //CATEGORY
//TODO ver si esta bien
 case SUCCESS_CREATE_CATEGORY:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_CATEGORY:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_CATEGORY:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_CATEGORIES:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_CATEGORYLANGUAGE:
            let datoCategoryLanguageDelete = action.categoryLanguage;
            let idsDelete = [];
           Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
               if (categoryLanguageDelete && categoryLanguageDelete.id)
                   idsDelete.push(categoryLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoCategoryLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_CATEGORYLANGUAGE:
                       let datoCategoryLanguageDeleteUpdate = action.categoryLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
                          if (categoryLanguageDelete && categoryLanguageDelete.id)
                              idsDeleteUpdate.push(categoryLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoCategoryLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_CATEGORYLANGUAGE:
                    let datoCategoryLanguageDeleted = {};
                    if (Object.values(action.categoryLanguage.entities.categoriesLanguages).length > 0)
                        datoCategoryLanguageDeleted = Object.values(action.categoryLanguage.entities.categoriesLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoCategoryLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CATEGORYLANGUAGE:
             let idsCreate = [];
             Object.values(action.categoryLanguage).map((categoryLanguageCreate) => {
                 if (categoryLanguageCreate && categoryLanguageCreate.id)
                     idsCreate.push(categoryLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.categoryLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CATEGORYLANGUAGE:
            let datoCategoryLanguageNuevo = {};
            if (Object.values(action.categoryLanguage.entities.categoriesLanguages).length > 0)
                datoCategoryLanguageNuevo = Object.values(action.categoryLanguage.entities.categoriesLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoCategoryLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_CATEGORIESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //CATEGORY
 case SUCCESS_CREATE_CATEGORY:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_CATEGORY:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_CATEGORY:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_CATEGORIES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_CATEGORYLANGUAGE:
           let datoCategoryLanguageDelete = action.categoryLanguage;
           let idsDelete = [];
           Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
               if (categoryLanguageDelete && categoryLanguageDelete.id)
                   idsDelete.push(categoryLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoCategoryLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_CATEGORYLANGUAGE:
                  let datoCategoryLanguageDeleteCreate = action.categoryLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.categoryLanguage).map((categoryLanguageDelete) => {
                      if (categoryLanguageDelete && categoryLanguageDelete.id)
                          idsDeleteCreate.push(categoryLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoCategoryLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_CATEGORYLANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.categoryLanguage,
                error: null,
            });
        case REQUEST_DELETE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //CATEGORY
 case SUCCESS_CREATE_CATEGORY:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_CATEGORY:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_CATEGORY:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_CATEGORIES:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_CATEGORYLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const categoriesLanguages = combineReducers({
    byId: categoriesLanguagesById,
    allIds: allCategoriesLanguages,
    update: update,
    create: create,
    totalCategoriesLanguages: totalCategoriesLanguages,
    delete: deleter,
    print: print
});

export default categoriesLanguages;