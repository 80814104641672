//api
import productsAttributesValuesLanguagesApi from "../api/productsAttributesValuesLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductsAttributesValuesLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTSATTRIBUTESVALUESLANGUAGES
export const REQUEST_PRODUCTSATTRIBUTESVALUESLANGUAGES = 'REQUEST_PRODUCTSATTRIBUTESVALUESLANGUAGES';
export const RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES = 'RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES';
export const INVALIDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = 'INVALIDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES';
export const ERROR_PRODUCTSATTRIBUTESVALUESLANGUAGES = "ERROR_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES = "RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES";


export function invalidateProductsAttributesValuesLanguages() {
    return {
        type: INVALIDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES
    }
}

function requestProductsAttributesValuesLanguages() {
    return {
        type: REQUEST_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    }
}

function receiveProductsAttributesValuesLanguages(json) {
    return {
        type: RECEIVE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    productsAttributesValuesLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductsAttributesValuesLanguages(error) {
    return {
        type: ERROR_PRODUCTSATTRIBUTESVALUESLANGUAGES,
        error: error,
    }
}

export function resetProductsAttributesValuesLanguages() {
    return {
        type: RESET_PRODUCTSATTRIBUTESVALUESLANGUAGES
    }
}

export function fetchProductsAttributesValuesLanguages(filtros) {
    return dispatch => {
        dispatch(requestProductsAttributesValuesLanguages());
        return productsAttributesValuesLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductsAttributesValuesLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductsAttributesValuesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductsAttributesValuesLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductsAttributesValuesLanguages(state) {
    const productsAttributesValuesLanguages = state.productsAttributesValuesLanguages.byId;
    if (!productsAttributesValuesLanguages) {
        return true
    } else if (productsAttributesValuesLanguages.isFetching) {
        return false
    } else {
        return productsAttributesValuesLanguages.didInvalidate;
    }
}

export function fetchProductsAttributesValuesLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductsAttributesValuesLanguages(getState())) {
            return dispatch(fetchProductsAttributesValuesLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTATTRIBUTEVALUELANGUAGE = 'REQUEST_PRODUCTATTRIBUTEVALUELANGUAGE';
export const RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE = 'RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE';
export const INVALIDATE_PRODUCTATTRIBUTEVALUELANGUAGE = 'INVALIDATE_PRODUCTATTRIBUTEVALUELANGUAGE';
export const ERROR_PRODUCTATTRIBUTEVALUELANGUAGE = "ERROR_PRODUCTATTRIBUTEVALUELANGUAGE";
export const RESET_PRODUCTATTRIBUTEVALUELANGUAGE = "RESET_PRODUCTATTRIBUTEVALUELANGUAGE";


export function invalidateProductAttributeValueLanguage() {
    return {
        type: INVALIDATE_PRODUCTATTRIBUTEVALUELANGUAGE
    }
}

function requestProductAttributeValueLanguage() {
    return {
        type: REQUEST_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

export function receiveProductAttributeValueLanguage(json) {
    return {
        type: RECEIVE_PRODUCTATTRIBUTEVALUELANGUAGE,
    productAttributeValueLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductAttributeValueLanguage(error) {
    return {
        type: ERROR_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function fetchProductAttributeValueLanguage(idProductAttributeValueLanguage) {
    return dispatch => {
        dispatch(requestProductAttributeValueLanguage());
        return productsAttributesValuesLanguagesApi.getOne(idProductAttributeValueLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductAttributeValueLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PRODUCTATTRIBUTEVALUELANGUAGE = 'RECEIVE_FILE_PRODUCTATTRIBUTEVALUELANGUAGE';

function receiveFileProductAttributeValueLanguage(file) {
    return {
        type: RECEIVE_FILE_PRODUCTATTRIBUTEVALUELANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileProductAttributeValueLanguage(idProductAttributeValueLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return productsAttributesValuesLanguagesApi.getFile(idProductAttributeValueLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileProductAttributeValueLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = 'UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE';
export const REQUEST_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = "REQUEST_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = "SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const ERROR_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = "ERROR_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const RESET_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = "RESET_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const DELETE_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE = "DELETE_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE";

function requestUpdateProductAttributeValueLanguage() {
    return {
        type: REQUEST_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

function receiveUpdateProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        receivedAt: Date.now(),
        productAttributeValueLanguage: normalizeDato(productAttributeValueLanguage)
    }
}

function errorUpdateProductAttributeValueLanguage(error) {
    return {
        type: ERROR_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function resetUpdateProductAttributeValueLanguage() {
    return {
        type: RESET_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

export function updateProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        productAttributeValueLanguage
    }
}

export function saveUpdateProductAttributeValueLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductAttributeValueLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productAttributeValueLanguage = denormalizeDato(getState().productsAttributesValuesLanguages.update.activo, store);

        return productsAttributesValuesLanguagesApi.saveUpdate(productAttributeValueLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductAttributeValueLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductsAttributesValuesLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductsAttributesValuesLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductAttributeValueLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductAttributeValueLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: DELETE_UPDATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        productAttributeValueLanguage
    }
}

//UPDATE PRODUCTSATTRIBUTESVALUESLANGUAGES
export const REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const ERROR_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "ERROR_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const RESET_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "RESET_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";

function requestUpdateProductsAttributesValuesLanguages() {
    return {
        type: REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
}
}

function receiveUpdateProductsAttributesValuesLanguages(productsAttributesValuesLanguages) {
    return {
        type: SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    receivedAt: Date.now(),
        productsAttributesValuesLanguages: normalizeDatos(productsAttributesValuesLanguages)
}
}

function errorUpdateProductsAttributesValuesLanguages(error) {
    return {
        type: ERROR_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    error: error,
}
}

export function resetUpdateProductsAttributesValuesLanguages() {
    return {
        type: RESET_UPDATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
}
}

export function saveUpdateProductsAttributesValuesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductsAttributesValuesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsAttributesValuesLanguages = getState().productsAttributesValuesLanguages.update.activos.map((idProductAttributeValueLanguage) => {
            return denormalizeDato(getState().productsAttributesValuesLanguages.update.activo[idProductAttributeValueLanguage], store);
        });

        return productsAttributesValuesLanguagesApi.saveUpdateProductsAttributesValuesLanguages(productsAttributesValuesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductsAttributesValuesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductsAttributesValuesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductsAttributesValuesLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductsAttributesValuesLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductsAttributesValuesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductsAttributesValuesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTATTRIBUTEVALUELANGUAGE
export const CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = 'CREATE_PRODUCTATTRIBUTEVALUELANGUAGE';
export const REQUEST_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = "REQUEST_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = "SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const ERROR_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = "ERROR_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const RESET_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = "RESET_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const DELETE_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE = "DELETE_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE";


//ALTA PRODUCTATTRIBUTEVALUELANGUAGE
function requestCreateProductAttributeValueLanguage() {
    return {
        type: REQUEST_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

function receiveCreateProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: SUCCESS_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        receivedAt: Date.now(),
        productAttributeValueLanguage: normalizeDato(productAttributeValueLanguage)
    }
}

export function errorCreateProductAttributeValueLanguage(error) {
    return {
        type: ERROR_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function resetCreateProductAttributeValueLanguage() {
    return {
        type: RESET_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE
    }
}

export function createProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        productAttributeValueLanguage
    }
}

export function deleteCreateProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: DELETE_CREATE_PRODUCTATTRIBUTEVALUELANGUAGE,
        productAttributeValueLanguage
    }
}

export function saveCreateProductAttributeValueLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductAttributeValueLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productAttributeValueLanguage = denormalizeDato(getState().productsAttributesValuesLanguages.create.nuevo, store);

        return productsAttributesValuesLanguagesApi.saveCreate(productAttributeValueLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductAttributeValueLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductsAttributesValuesLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductsAttributesValuesLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductAttributeValueLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductAttributeValueLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTSATTRIBUTESVALUESLANGUAGES
export const REQUEST_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "REQUEST_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const ERROR_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "ERROR_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";
export const RESET_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES = "RESET_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES";

function requestCreateProductsAttributesValuesLanguages() {
    return {
        type: REQUEST_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
}
}

function receiveCreateProductsAttributesValuesLanguages(productsAttributesValuesLanguages) {
    return {
        type: SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    receivedAt: Date.now(),
        productsAttributesValuesLanguages: normalizeDatos(productsAttributesValuesLanguages)
}
}

function errorCreateProductsAttributesValuesLanguages(error) {
    return {
        type: ERROR_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
    error: error,
}
}

export function resetCreateProductsAttributesValuesLanguages() {
    return {
        type: RESET_CREATE_PRODUCTSATTRIBUTESVALUESLANGUAGES,
}
}

export function saveCreateProductsAttributesValuesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductsAttributesValuesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsAttributesValuesLanguages = getState().productsAttributesValuesLanguages.create.nuevos.map((idProductAttributeValueLanguage) => {
            return denormalizeDato(getState().productsAttributesValuesLanguages.create.nuevo[idProductAttributeValueLanguage], store);
        });

        return productsAttributesValuesLanguagesApi.saveCreateProductsAttributesValuesLanguages(productsAttributesValuesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductsAttributesValuesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductsAttributesValuesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductsAttributesValuesLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductsAttributesValuesLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductsAttributesValuesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductsAttributesValuesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTATTRIBUTEVALUELANGUAGE
export const DELETE_PRODUCTATTRIBUTEVALUELANGUAGE = 'DELETE_PRODUCTATTRIBUTEVALUELANGUAGE';
export const REQUEST_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE = "REQUEST_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE = "SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const ERROR_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE = "ERROR_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE";
export const RESET_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE = "RESET_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE";

function requestDeleteProductAttributeValueLanguage() {
    return {
        type: REQUEST_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

function receiveDeleteProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: SUCCESS_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
        receivedAt: Date.now(),
        productAttributeValueLanguage: normalizeDato(productAttributeValueLanguage)
    }
}

function errorDeleteProductAttributeValueLanguage(error) {
    return {
        type: ERROR_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function resetDeleteProductAttributeValueLanguage(error) {
    return {
        type: RESET_DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function deleteProductAttributeValueLanguage(productAttributeValueLanguage) {
    return {
        type: DELETE_PRODUCTATTRIBUTEVALUELANGUAGE,
        productAttributeValueLanguage
    }
}

export function saveDeleteProductAttributeValueLanguage(productAttributeValueLanguage) {
    return dispatch => {
        dispatch(requestDeleteProductAttributeValueLanguage());
        return productsAttributesValuesLanguagesApi.saveDelete(productAttributeValueLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductAttributeValueLanguage());
                            dispatch(receiveDeleteProductAttributeValueLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductAttributeValueLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductAttributeValueLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductAttributeValueLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTATTRIBUTEVALUELANGUAGE
export const REQUEST_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE = "REQUEST_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE";
export const SUCCESS_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE = "SUCCESS_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE";
export const ERROR_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE = "ERROR_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE";
export const RESET_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE = "RESET_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE";

function requestPrintProductAttributeValueLanguage() {
    return {
        type: REQUEST_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

function receivePrintProductAttributeValueLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductAttributeValueLanguage(error) {
    return {
        type: ERROR_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
        error: error,
    }
}

export function resetPrintProductAttributeValueLanguage() {
    return {
        type: RESET_PRINT_PRODUCTATTRIBUTEVALUELANGUAGE,
    }
}

export function printProductAttributeValueLanguage(idProductAttributeValueLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductAttributeValueLanguage());
        return productsAttributesValuesLanguagesApi.printProductAttributeValueLanguage(idProductAttributeValueLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductAttributeValueLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductAttributeValueLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductAttributeValueLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductAttributeValueLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductAttributeValueLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}