import {resetProductsAttributesValues, resetCreateProductAttributeValue, resetUpdateProductAttributeValue, resetDeleteProductAttributeValue} from "../actions/ProductAttributeValueActions";
import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";

var resetProductAttributeValue = {
    resetAll(dispatch) {
        dispatch(resetProductsAttributesValues());
        dispatch(resetCreateProductAttributeValue());
        dispatch(resetUpdateProductAttributeValue());
        dispatch(resetDeleteProductAttributeValue());
       dispatch(resetProducts());
dispatch(resetCreateProduct());
dispatch(resetUpdateProduct());
dispatch(resetDeleteProduct());
    },
};

export default resetProductAttributeValue;