//api
import productsLanguagesApi from "../api/productsLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductsLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTSLANGUAGES
export const REQUEST_PRODUCTSLANGUAGES = 'REQUEST_PRODUCTSLANGUAGES';
export const RECEIVE_PRODUCTSLANGUAGES = 'RECEIVE_PRODUCTSLANGUAGES';
export const INVALIDATE_PRODUCTSLANGUAGES = 'INVALIDATE_PRODUCTSLANGUAGES';
export const ERROR_PRODUCTSLANGUAGES = "ERROR_PRODUCTSLANGUAGES";
export const RESET_PRODUCTSLANGUAGES = "RESET_PRODUCTSLANGUAGES";


export function invalidateProductsLanguages() {
    return {
        type: INVALIDATE_PRODUCTSLANGUAGES
    }
}

function requestProductsLanguages() {
    return {
        type: REQUEST_PRODUCTSLANGUAGES,
    }
}

function receiveProductsLanguages(json) {
    return {
        type: RECEIVE_PRODUCTSLANGUAGES,
    productsLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductsLanguages(error) {
    return {
        type: ERROR_PRODUCTSLANGUAGES,
        error: error,
    }
}

export function resetProductsLanguages() {
    return {
        type: RESET_PRODUCTSLANGUAGES
    }
}

export function fetchProductsLanguages(filtros) {
    return dispatch => {
        dispatch(requestProductsLanguages());
        return productsLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductsLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductsLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductsLanguages(state) {
    const productsLanguages = state.productsLanguages.byId;
    if (!productsLanguages) {
        return true
    } else if (productsLanguages.isFetching) {
        return false
    } else {
        return productsLanguages.didInvalidate;
    }
}

export function fetchProductsLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductsLanguages(getState())) {
            return dispatch(fetchProductsLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTLANGUAGE = 'REQUEST_PRODUCTLANGUAGE';
export const RECEIVE_PRODUCTLANGUAGE = 'RECEIVE_PRODUCTLANGUAGE';
export const INVALIDATE_PRODUCTLANGUAGE = 'INVALIDATE_PRODUCTLANGUAGE';
export const ERROR_PRODUCTLANGUAGE = "ERROR_PRODUCTLANGUAGE";
export const RESET_PRODUCTLANGUAGE = "RESET_PRODUCTLANGUAGE";


export function invalidateProductLanguage() {
    return {
        type: INVALIDATE_PRODUCTLANGUAGE
    }
}

function requestProductLanguage() {
    return {
        type: REQUEST_PRODUCTLANGUAGE,
    }
}

export function receiveProductLanguage(json) {
    return {
        type: RECEIVE_PRODUCTLANGUAGE,
    productLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductLanguage(error) {
    return {
        type: ERROR_PRODUCTLANGUAGE,
        error: error,
    }
}

export function fetchProductLanguage(idProductLanguage) {
    return dispatch => {
        dispatch(requestProductLanguage());
        return productsLanguagesApi.getOne(idProductLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PRODUCTLANGUAGE = 'RECEIVE_FILE_PRODUCTLANGUAGE';

function receiveFileProductLanguage(file) {
    return {
        type: RECEIVE_FILE_PRODUCTLANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileProductLanguage(idProductLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return productsLanguagesApi.getFile(idProductLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileProductLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PRODUCTLANGUAGE = 'UPDATE_PRODUCTLANGUAGE';
export const REQUEST_UPDATE_PRODUCTLANGUAGE = "REQUEST_UPDATE_PRODUCTLANGUAGE";
export const SUCCESS_UPDATE_PRODUCTLANGUAGE = "SUCCESS_UPDATE_PRODUCTLANGUAGE";
export const ERROR_UPDATE_PRODUCTLANGUAGE = "ERROR_UPDATE_PRODUCTLANGUAGE";
export const RESET_UPDATE_PRODUCTLANGUAGE = "RESET_UPDATE_PRODUCTLANGUAGE";
export const DELETE_UPDATE_PRODUCTLANGUAGE = "DELETE_UPDATE_PRODUCTLANGUAGE";

function requestUpdateProductLanguage() {
    return {
        type: REQUEST_UPDATE_PRODUCTLANGUAGE,
    }
}

function receiveUpdateProductLanguage(productLanguage) {
    return {
        type: SUCCESS_UPDATE_PRODUCTLANGUAGE,
        receivedAt: Date.now(),
        productLanguage: normalizeDato(productLanguage)
    }
}

function errorUpdateProductLanguage(error) {
    return {
        type: ERROR_UPDATE_PRODUCTLANGUAGE,
        error: error,
    }
}

export function resetUpdateProductLanguage() {
    return {
        type: RESET_UPDATE_PRODUCTLANGUAGE,
    }
}

export function updateProductLanguage(productLanguage) {
    return {
        type: UPDATE_PRODUCTLANGUAGE,
        productLanguage
    }
}

export function saveUpdateProductLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productLanguage = denormalizeDato(getState().productsLanguages.update.activo, store);

        return productsLanguagesApi.saveUpdate(productLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductsLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductsLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductLanguage(productLanguage) {
    return {
        type: DELETE_UPDATE_PRODUCTLANGUAGE,
        productLanguage
    }
}

//UPDATE PRODUCTSLANGUAGES
export const REQUEST_UPDATE_PRODUCTSLANGUAGES = "REQUEST_UPDATE_PRODUCTSLANGUAGES";
export const SUCCESS_UPDATE_PRODUCTSLANGUAGES = "SUCCESS_UPDATE_PRODUCTSLANGUAGES";
export const ERROR_UPDATE_PRODUCTSLANGUAGES = "ERROR_UPDATE_PRODUCTSLANGUAGES";
export const RESET_UPDATE_PRODUCTSLANGUAGES = "RESET_UPDATE_PRODUCTSLANGUAGES";

function requestUpdateProductsLanguages() {
    return {
        type: REQUEST_UPDATE_PRODUCTSLANGUAGES,
}
}

function receiveUpdateProductsLanguages(productsLanguages) {
    return {
        type: SUCCESS_UPDATE_PRODUCTSLANGUAGES,
    receivedAt: Date.now(),
        productsLanguages: normalizeDatos(productsLanguages)
}
}

function errorUpdateProductsLanguages(error) {
    return {
        type: ERROR_UPDATE_PRODUCTSLANGUAGES,
    error: error,
}
}

export function resetUpdateProductsLanguages() {
    return {
        type: RESET_UPDATE_PRODUCTSLANGUAGES,
}
}

export function saveUpdateProductsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsLanguages = getState().productsLanguages.update.activos.map((idProductLanguage) => {
            return denormalizeDato(getState().productsLanguages.update.activo[idProductLanguage], store);
        });

        return productsLanguagesApi.saveUpdateProductsLanguages(productsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductsLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductsLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTLANGUAGE
export const CREATE_PRODUCTLANGUAGE = 'CREATE_PRODUCTLANGUAGE';
export const REQUEST_CREATE_PRODUCTLANGUAGE = "REQUEST_CREATE_PRODUCTLANGUAGE";
export const SUCCESS_CREATE_PRODUCTLANGUAGE = "SUCCESS_CREATE_PRODUCTLANGUAGE";
export const ERROR_CREATE_PRODUCTLANGUAGE = "ERROR_CREATE_PRODUCTLANGUAGE";
export const RESET_CREATE_PRODUCTLANGUAGE = "RESET_CREATE_PRODUCTLANGUAGE";
export const DELETE_CREATE_PRODUCTLANGUAGE = "DELETE_CREATE_PRODUCTLANGUAGE";


//ALTA PRODUCTLANGUAGE
function requestCreateProductLanguage() {
    return {
        type: REQUEST_CREATE_PRODUCTLANGUAGE,
    }
}

function receiveCreateProductLanguage(productLanguage) {
    return {
        type: SUCCESS_CREATE_PRODUCTLANGUAGE,
        receivedAt: Date.now(),
        productLanguage: normalizeDato(productLanguage)
    }
}

export function errorCreateProductLanguage(error) {
    return {
        type: ERROR_CREATE_PRODUCTLANGUAGE,
        error: error,
    }
}

export function resetCreateProductLanguage() {
    return {
        type: RESET_CREATE_PRODUCTLANGUAGE
    }
}

export function createProductLanguage(productLanguage) {
    return {
        type: CREATE_PRODUCTLANGUAGE,
        productLanguage
    }
}

export function deleteCreateProductLanguage(productLanguage) {
    return {
        type: DELETE_CREATE_PRODUCTLANGUAGE,
        productLanguage
    }
}

export function saveCreateProductLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productLanguage = denormalizeDato(getState().productsLanguages.create.nuevo, store);

        return productsLanguagesApi.saveCreate(productLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductsLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductsLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTSLANGUAGES
export const REQUEST_CREATE_PRODUCTSLANGUAGES = "REQUEST_CREATE_PRODUCTSLANGUAGES";
export const SUCCESS_CREATE_PRODUCTSLANGUAGES = "SUCCESS_CREATE_PRODUCTSLANGUAGES";
export const ERROR_CREATE_PRODUCTSLANGUAGES = "ERROR_CREATE_PRODUCTSLANGUAGES";
export const RESET_CREATE_PRODUCTSLANGUAGES = "RESET_CREATE_PRODUCTSLANGUAGES";

function requestCreateProductsLanguages() {
    return {
        type: REQUEST_CREATE_PRODUCTSLANGUAGES,
}
}

function receiveCreateProductsLanguages(productsLanguages) {
    return {
        type: SUCCESS_CREATE_PRODUCTSLANGUAGES,
    receivedAt: Date.now(),
        productsLanguages: normalizeDatos(productsLanguages)
}
}

function errorCreateProductsLanguages(error) {
    return {
        type: ERROR_CREATE_PRODUCTSLANGUAGES,
    error: error,
}
}

export function resetCreateProductsLanguages() {
    return {
        type: RESET_CREATE_PRODUCTSLANGUAGES,
}
}

export function saveCreateProductsLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductsLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productsLanguages = getState().productsLanguages.create.nuevos.map((idProductLanguage) => {
            return denormalizeDato(getState().productsLanguages.create.nuevo[idProductLanguage], store);
        });

        return productsLanguagesApi.saveCreateProductsLanguages(productsLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductsLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductsLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductsLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductsLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductsLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductsLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTLANGUAGE
export const DELETE_PRODUCTLANGUAGE = 'DELETE_PRODUCTLANGUAGE';
export const REQUEST_DELETE_PRODUCTLANGUAGE = "REQUEST_DELETE_PRODUCTLANGUAGE";
export const SUCCESS_DELETE_PRODUCTLANGUAGE = "SUCCESS_DELETE_PRODUCTLANGUAGE";
export const ERROR_DELETE_PRODUCTLANGUAGE = "ERROR_DELETE_PRODUCTLANGUAGE";
export const RESET_DELETE_PRODUCTLANGUAGE = "RESET_DELETE_PRODUCTLANGUAGE";

function requestDeleteProductLanguage() {
    return {
        type: REQUEST_DELETE_PRODUCTLANGUAGE,
    }
}

function receiveDeleteProductLanguage(productLanguage) {
    return {
        type: SUCCESS_DELETE_PRODUCTLANGUAGE,
        receivedAt: Date.now(),
        productLanguage: normalizeDato(productLanguage)
    }
}

function errorDeleteProductLanguage(error) {
    return {
        type: ERROR_DELETE_PRODUCTLANGUAGE,
        error: error,
    }
}

export function resetDeleteProductLanguage(error) {
    return {
        type: RESET_DELETE_PRODUCTLANGUAGE,
        error: error,
    }
}

export function deleteProductLanguage(productLanguage) {
    return {
        type: DELETE_PRODUCTLANGUAGE,
        productLanguage
    }
}

export function saveDeleteProductLanguage(productLanguage) {
    return dispatch => {
        dispatch(requestDeleteProductLanguage());
        return productsLanguagesApi.saveDelete(productLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductLanguage());
                            dispatch(receiveDeleteProductLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTLANGUAGE
export const REQUEST_PRINT_PRODUCTLANGUAGE = "REQUEST_PRINT_PRODUCTLANGUAGE";
export const SUCCESS_PRINT_PRODUCTLANGUAGE = "SUCCESS_PRINT_PRODUCTLANGUAGE";
export const ERROR_PRINT_PRODUCTLANGUAGE = "ERROR_PRINT_PRODUCTLANGUAGE";
export const RESET_PRINT_PRODUCTLANGUAGE = "RESET_PRINT_PRODUCTLANGUAGE";

function requestPrintProductLanguage() {
    return {
        type: REQUEST_PRINT_PRODUCTLANGUAGE,
    }
}

function receivePrintProductLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTLANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductLanguage(error) {
    return {
        type: ERROR_PRINT_PRODUCTLANGUAGE,
        error: error,
    }
}

export function resetPrintProductLanguage() {
    return {
        type: RESET_PRINT_PRODUCTLANGUAGE,
    }
}

export function printProductLanguage(idProductLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductLanguage());
        return productsLanguagesApi.printProductLanguage(idProductLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}