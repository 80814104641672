import {
    INVALIDATE_LANGUAGES,
    ERROR_LANGUAGES,
    RECEIVE_LANGUAGES,
    REQUEST_LANGUAGES,
    RESET_LANGUAGES,
    ERROR_LANGUAGE,
    RECEIVE_LANGUAGE,
    REQUEST_LANGUAGE,
    UPDATE_LANGUAGE,
    REQUEST_UPDATE_LANGUAGE,
    SUCCESS_UPDATE_LANGUAGE,
    ERROR_UPDATE_LANGUAGE,
    RESET_UPDATE_LANGUAGE,
    REQUEST_UPDATE_LANGUAGES,
    SUCCESS_UPDATE_LANGUAGES,
    ERROR_UPDATE_LANGUAGES,
    RESET_UPDATE_LANGUAGES,
    CREATE_LANGUAGE,
    ERROR_CREATE_LANGUAGE,
    REQUEST_CREATE_LANGUAGE,
    RESET_CREATE_LANGUAGE,
    SUCCESS_CREATE_LANGUAGE,
    REQUEST_CREATE_LANGUAGES,
    SUCCESS_CREATE_LANGUAGES,
    ERROR_CREATE_LANGUAGES,
    RESET_CREATE_LANGUAGES,
    DELETE_LANGUAGE,
    DELETE_CREATE_LANGUAGE,
    DELETE_UPDATE_LANGUAGE,
    REQUEST_DELETE_LANGUAGE,
    SUCCESS_DELETE_LANGUAGE,
    ERROR_DELETE_LANGUAGE,
    RESET_DELETE_LANGUAGE,
    REQUEST_PRINT_LANGUAGE,
    SUCCESS_PRINT_LANGUAGE,
    ERROR_PRINT_LANGUAGE,
    RESET_PRINT_LANGUAGE,
    RECEIVE_FILE_LANGUAGE
} from '../actions/LanguageActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        languages: {},
        files: {},
    }
}

function languagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_LANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_LANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_LANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_LANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                languages: {}
            });
        case RECEIVE_LANGUAGES:
            let dato = action.languages.entities.languages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                languages: merge({}, state.languages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_LANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_LANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_LANGUAGE:
            let datoLanguage = action.language.entities.languages;
            return Object.assign({}, state, {
                languages: merge({}, state.languages, datoLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_LANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_LANGUAGE:
            let datoLanguageEliminado = action.language.entities.languages;
            return Object.assign({}, state, {
                languages: mergeWith(clone(datoLanguageEliminado), state.languages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_LANGUAGE:
            let datoLanguageCreado = action.language.entities.languages;
            return Object.assign({}, state, {
                languages: mergeWith(clone(datoLanguageCreado), state.languages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_LANGUAGES:
            let datosLanguageCreado = action.languages.entities.languages;
                return Object.assign({}, state, {
                    languages: mergeWith(clone(datosLanguageCreado), state.languages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_LANGUAGE:
            let datoLanguageActualizado = action.language.entities.languages;
            return Object.assign({}, state, {
                languages: mergeWith(clone(datoLanguageActualizado), state.languages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_LANGUAGES:
            let datosLanguageActualizado = action.languages.entities.languages;
                return Object.assign({}, state, {
                    languages: mergeWith(clone(datosLanguageActualizado), state.languages, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                languages: {}
            });
        default:
            return state
    }
}


function allLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_LANGUAGES:
            return action.languages.result && action.languages.result.languages ? union(action.languages.result.languages, state) : (action.languages.result ? action.languages.result : state) ;
        case RECEIVE_LANGUAGE:
                return action.language.result ? union([action.language.result], state) : state;
        
        case SUCCESS_CREATE_LANGUAGE:
                   let datoLanguageSCreate = action.language.entities.languages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoLanguageSCreate)[0] && Object.values(datoLanguageSCreate)[0].id ? Object.values(datoLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_LANGUAGES:
                   let languagesCreate = action.languages.entities && action.languages.entities.languages ? action.languages.entities.languages : null;
                   return languagesCreate ?
                       union(state, Object.values(languagesCreate).map((languages) => {
                           return languages.id
                       })) : state;
        case RESET_LANGUAGES:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_LANGUAGES:
            return action.languages && action.languages.result.total ? action.languages.result.total : 0;
        case RESET_LANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_LANGUAGE:
            let dato = action.language.entities.languages;
            let language = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: language ? language : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_LANGUAGE:
            let idsUpdate = [];
            Object.values(action.language).map((languageUpdate) => {
                if (languageUpdate && languageUpdate.id)
                    idsUpdate.push(languageUpdate.id);
            });
            return merge({}, state, {
                activo: action.language,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_LANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_LANGUAGE:
            let datoLanguageActualizado = {};
            if (Object.values(action.language.entities.languages).length > 0)
                datoLanguageActualizado = Object.values(action.language.entities.languages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoLanguageActualizado
            });
        case ERROR_UPDATE_LANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_LANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_LANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_LANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_LANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_LANGUAGE:
            let datoLanguageDelete = action.language;
            let idsDelete = [];
           Object.values(action.language).map((languageDelete) => {
               if (languageDelete && languageDelete.id)
                   idsDelete.push(languageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_LANGUAGE:
                       let datoLanguageDeleteUpdate = action.language;
                       let idsDeleteUpdate = [];
                      Object.values(action.language).map((languageDelete) => {
                          if (languageDelete && languageDelete.id)
                              idsDeleteUpdate.push(languageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_LANGUAGE:
                    let datoLanguageDeleted = {};
                    if (Object.values(action.language.entities.languages).length > 0)
                        datoLanguageDeleted = Object.values(action.language.entities.languages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_LANGUAGE:
             let idsCreate = [];
             Object.values(action.language).map((languageCreate) => {
                 if (languageCreate && languageCreate.id)
                     idsCreate.push(languageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.language,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_LANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_LANGUAGE:
            let datoLanguageNuevo = {};
            if (Object.values(action.language.entities.languages).length > 0)
                datoLanguageNuevo = Object.values(action.language.entities.languages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_LANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_LANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_LANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_LANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_LANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_LANGUAGE:
           let datoLanguageDelete = action.language;
           let idsDelete = [];
           Object.values(action.language).map((languageDelete) => {
               if (languageDelete && languageDelete.id)
                   idsDelete.push(languageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_LANGUAGE:
                  let datoLanguageDeleteCreate = action.language;
                  let idsDeleteCreate = [];
                  Object.values(action.language).map((languageDelete) => {
                      if (languageDelete && languageDelete.id)
                          idsDeleteCreate.push(languageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_LANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.language,
                error: null,
            });
        case REQUEST_DELETE_LANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_LANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_LANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_LANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_LANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_LANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_LANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const languages = combineReducers({
    byId: languagesById,
    allIds: allLanguages,
    update: update,
    create: create,
    totalLanguages: totalLanguages,
    delete: deleter,
    print: print
});

export default languages;