import {resetTypesFiles, resetCreateTypeFile, resetUpdateTypeFile, resetDeleteTypeFile} from "../actions/TypeFileActions";
import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";

var resetTypeFile = {
    resetAll(dispatch) {
        dispatch(resetTypesFiles());
        dispatch(resetCreateTypeFile());
        dispatch(resetUpdateTypeFile());
        dispatch(resetDeleteTypeFile());
       dispatch(resetAssets());
dispatch(resetCreateAsset());
dispatch(resetUpdateAsset());
dispatch(resetDeleteAsset());
    },
};

export default resetTypeFile;