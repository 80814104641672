import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
//Components
import {Box, Typography} from '@material-ui/core';
import HTMLEditor from "../../../library/HTMLEditor";
import FormHelperText from '@material-ui/core/FormHelperText';
//Icons
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
}));


function ProductTextType(props) {
    const classes = styles();
    const {language, assetLanguage, changeAssetLanguage} = props;
    //Hooks
    //State

    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ],
    };

    const handleChangeTextEditorAssetLanguage = (content, delta, source, editor, idField, idAssetLanguage, idLanguage) => {
        if (source === 'user') {
            let id = idAssetLanguage ? idAssetLanguage : Date.now() + Math.random() + "-";
            let cambio = {};
            cambio[idField] = content;
            changeAssetLanguage(cambio, id, idLanguage);
        }
    }


    return (
        <Box component={"div"} className={classes.form}>
            <Typography variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <Box component={"div"} marginTop={"10px"}>
                <HTMLEditor modules={modules} textLabel={"Nombre pestaña"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text1", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text1 : ""}/>
                <HTMLEditor modules={modules} textLabel={"Título destacado"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text2", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text2 : ""}/>
                <HTMLEditor modules={modules} textLabel={"Descripción corta"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text3", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text3 : ""}/>
                <FormHelperText>En el caso de no completar la descripción corta, se utilizará la información del producto</FormHelperText>
            </Box>
        </Box>
    );
}

ProductTextType.propTypes = {}


function areEqual(prevProps, nextProps) {
    return prevProps.assetLanguage && nextProps.assetLanguage && prevProps.assetLanguage.id === nextProps.assetLanguage.id && prevProps.assetLanguage.text1 === nextProps.assetLanguage.text1 && prevProps.assetLanguage.text2 === nextProps.assetLanguage.text2 && prevProps.assetLanguage.text3 === nextProps.assetLanguage.text3 && prevProps.assetLanguage.text4 === nextProps.assetLanguage.text4 && prevProps.assetLanguage.textAction === nextProps.assetLanguage.textAction && prevProps.assetLanguage.textUrl === nextProps.assetLanguage.textUrl;
}

export default React.memo(ProductTextType, areEqual);