import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, TextField, Typography} from '@material-ui/core';
import HTMLEditor from "../../library/HTMLEditor";
//Icons
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
}));


export default function CategoryLanguage(props) {
    const classes = styles();
    const {changeCategoryLanguage, language, categoryLanguage, handleChangeCategoryLanguage} = props;
    //Hooks
    //State

    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['clean']
        ],
    };

    let formats = [
        'bold', 'italic', 'underline', 'strike',
    ];

    const handleChangeTextEditorCategoryLanguage = (content, delta, source, editor, idField, idCategory, idLanguage) => {
        if (source === 'user') {
            let id = idCategory ? idCategory : Date.now() + Math.random() + "-";
            let cambio = {};
            cambio[idField] = content;
            changeCategoryLanguage(cambio, id, idLanguage);
        }
    }

    return (
        <Box component={"div"} className={classes.form}>
            <Typography variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <Box component={"div"} marginTop={"10px"}>
                <TextField
                    id="nameCategory"
                    placeholder="Nombre de categoría"
                    variant="outlined"
                    label={"Nombre de categoría"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        // disabled: disabled
                    }}
                    fullWidth
                    value={categoryLanguage ? categoryLanguage.nameCategory : ""}
                    onChange={(e) => handleChangeCategoryLanguage(e, categoryLanguage ? categoryLanguage.id : null,language ? language.id : null)}
                    // onKeyDown={(e) => handleKeyDown(e)}
                />
                <HTMLEditor modules={modules} formats={formats} textLabel={"Descripción corta"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorCategoryLanguage(content, delta, source, editor, "shortDesCategory", categoryLanguage ? categoryLanguage.id : null,language ? language.id : null)}
                            value={categoryLanguage ? categoryLanguage.shortDesCategory : ""} rows={"50px"}/>
                <HTMLEditor modules={modules} formats={formats} textLabel={"Descripción larga"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorCategoryLanguage(content, delta, source, editor, "desCategory", categoryLanguage ? categoryLanguage.id : null,language ? language.id : null)}
                            value={categoryLanguage ? categoryLanguage.desCategory : ""} rows={"50px"}/>
            </Box>
        </Box>
    );
}

CategoryLanguage.propTypes = {}


function areEqual(prevProps, nextProps) {
    return prevProps.categoryLanguage && nextProps.categoryLanguage && prevProps.categoryLanguage.id === nextProps.categoryLanguage.id && prevProps.categoryLanguage.nameCategory === nextProps.categoryLanguage.nameCategory && prevProps.categoryLanguage.desCategory === nextProps.categoryLanguage.desCategory && prevProps.categoryLanguage.shoerDesCategory === nextProps.categoryLanguage.shoerDesCategory;
}

// export default React.memo(CategoryLanguage, areEqual);