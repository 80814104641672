import {normalize, schema, denormalize} from 'normalizr';
import assetLanguage from "./normalizeAssetsLanguages";
import assetCountry from "./normalizeAssetsCountries";
import section from "./normalizeSections";
import typeFile from "./normalizeTypesFiles";
import product from "./normalizeProducts";

const asset = new schema.Entity('assets', {
    asset_language: [assetLanguage],
    asset_country: [assetCountry],
    section: section,
    type_file: typeFile,
    product: product
}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {assets: [asset]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = asset;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = asset;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default asset;
