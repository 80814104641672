import {
    INVALIDATE_TYPESFILES,
    ERROR_TYPESFILES,
    RECEIVE_TYPESFILES,
    REQUEST_TYPESFILES,
    RESET_TYPESFILES,
    ERROR_TYPEFILE,
    RECEIVE_TYPEFILE,
    REQUEST_TYPEFILE,
    UPDATE_TYPEFILE,
    REQUEST_UPDATE_TYPEFILE,
    SUCCESS_UPDATE_TYPEFILE,
    ERROR_UPDATE_TYPEFILE,
    RESET_UPDATE_TYPEFILE,
    REQUEST_UPDATE_TYPESFILES,
    SUCCESS_UPDATE_TYPESFILES,
    ERROR_UPDATE_TYPESFILES,
    RESET_UPDATE_TYPESFILES,
    CREATE_TYPEFILE,
    ERROR_CREATE_TYPEFILE,
    REQUEST_CREATE_TYPEFILE,
    RESET_CREATE_TYPEFILE,
    SUCCESS_CREATE_TYPEFILE,
    REQUEST_CREATE_TYPESFILES,
    SUCCESS_CREATE_TYPESFILES,
    ERROR_CREATE_TYPESFILES,
    RESET_CREATE_TYPESFILES,
    DELETE_TYPEFILE,
    DELETE_CREATE_TYPEFILE,
    DELETE_UPDATE_TYPEFILE,
    REQUEST_DELETE_TYPEFILE,
    SUCCESS_DELETE_TYPEFILE,
    ERROR_DELETE_TYPEFILE,
    RESET_DELETE_TYPEFILE,
    REQUEST_PRINT_TYPEFILE,
    SUCCESS_PRINT_TYPEFILE,
    ERROR_PRINT_TYPEFILE,
    RESET_PRINT_TYPEFILE,
    RECEIVE_FILE_TYPEFILE
} from '../actions/TypeFileActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        typesFiles: {},
        files: {},
    }
}

function typesFilesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_TYPESFILES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_TYPESFILES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_TYPESFILES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_TYPESFILES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                typesFiles: {}
            });
        case RECEIVE_TYPESFILES:
            let dato = action.typesFiles.entities.typesFiles;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                typesFiles: merge({}, state.typesFiles, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_TYPEFILE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_TYPEFILE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_TYPEFILE:
            let datoTypeFile = action.typeFile.entities.typesFiles;
            return Object.assign({}, state, {
                typesFiles: merge({}, state.typesFiles, datoTypeFile),
                isFetching: false,
            });
        case RECEIVE_FILE_TYPEFILE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_TYPEFILE:
            let datoTypeFileEliminado = action.typeFile.entities.typesFiles;
            return Object.assign({}, state, {
                typesFiles: mergeWith(clone(datoTypeFileEliminado), state.typesFiles, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TYPEFILE:
            let datoTypeFileCreado = action.typeFile.entities.typesFiles;
            return Object.assign({}, state, {
                typesFiles: mergeWith(clone(datoTypeFileCreado), state.typesFiles, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TYPESFILES:
            let datosTypeFileCreado = action.typesFiles.entities.typesFiles;
                return Object.assign({}, state, {
                    typesFiles: mergeWith(clone(datosTypeFileCreado), state.typesFiles, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_TYPEFILE:
            let datoTypeFileActualizado = action.typeFile.entities.typesFiles;
            return Object.assign({}, state, {
                typesFiles: mergeWith(clone(datoTypeFileActualizado), state.typesFiles, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_TYPESFILES:
            let datosTypeFileActualizado = action.typesFiles.entities.typesFiles;
                return Object.assign({}, state, {
                    typesFiles: mergeWith(clone(datosTypeFileActualizado), state.typesFiles, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : {};
    return Object.assign({}, state, {
        typesFiles: merge({}, state.typesFiles, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.typesFiles ? action.assets.entities.typesFiles : {};
    return Object.assign({}, state, {
        typesFiles: merge({}, state.typesFiles, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : {};
    return Object.assign({}, state, {
        typesFiles: mergeWith(clone(datoassetEliminado), pickBy(state.typesFiles, function(typeFile) {return typeFile.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : {};
    return Object.assign({}, state, {
        typesFiles: mergeWith(clone(datoassetCreado), pickBy(state.typesFiles, function(typeFile) {return typeFile.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : {};
    return Object.assign({}, state, {
        typesFiles: mergeWith(clone(datoassetActualizado), pickBy(state.typesFiles, function(typeFile) {return typeFile.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.typesFiles ? action.assets.entities.typesFiles : {} ;
        return Object.assign({}, state, {
            typesFiles: mergeWith(clone(datosassetActualizado), state.typesFiles, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                typesFiles: {}
            });
        default:
            return state
    }
}


function allTypesFiles(state = [], action) {
    switch (action.type) {
        case RECEIVE_TYPESFILES:
            return action.typesFiles.result && action.typesFiles.result.typesFiles ? union(action.typesFiles.result.typesFiles, state) : (action.typesFiles.result ? action.typesFiles.result : state) ;
        case RECEIVE_TYPEFILE:
                return action.typeFile.result ? union([action.typeFile.result], state) : state;
        
        case SUCCESS_CREATE_TYPEFILE:
                   let datoTypeFileSCreate = action.typeFile.entities.typesFiles;
                   let idNuevoSCreate = null;
                   if (Object.values(datoTypeFileSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoTypeFileSCreate)[0] && Object.values(datoTypeFileSCreate)[0].id ? Object.values(datoTypeFileSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_TYPESFILES:
                   let typesFilesCreate = action.typesFiles.entities && action.typesFiles.entities.typesFiles ? action.typesFiles.entities.typesFiles : null;
                   return typesFilesCreate ?
                       union(state, Object.values(typesFilesCreate).map((typesFiles) => {
                           return typesFiles.id
                       })) : state;
        case RESET_TYPESFILES:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.typesFiles ? action.assets.entities.typesFiles : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.typesFiles ? action.asset.entities.typesFiles : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.typesFiles ? action.assets.entities.typesFiles : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalTypesFiles(state = null, action) {
    switch (action.type) {
        case RECEIVE_TYPESFILES:
            return action.typesFiles && action.typesFiles.result.total ? action.typesFiles.result.total : 0;
        case RESET_TYPESFILES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_TYPEFILE:
            let dato = action.typeFile.entities.typesFiles;
            let typeFile = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: typeFile ? typeFile : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_TYPEFILE:
            let idsUpdate = [];
            Object.values(action.typeFile).map((typeFileUpdate) => {
                if (typeFileUpdate && typeFileUpdate.id)
                    idsUpdate.push(typeFileUpdate.id);
            });
            return merge({}, state, {
                activo: action.typeFile,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_TYPEFILE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_TYPEFILE:
            let datoTypeFileActualizado = {};
            if (Object.values(action.typeFile.entities.typesFiles).length > 0)
                datoTypeFileActualizado = Object.values(action.typeFile.entities.typesFiles)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoTypeFileActualizado
            });
        case ERROR_UPDATE_TYPEFILE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_TYPESFILES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_TYPESFILES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_TYPESFILES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_TYPEFILE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_TYPEFILE:
            let datoTypeFileDelete = action.typeFile;
            let idsDelete = [];
           Object.values(action.typeFile).map((typeFileDelete) => {
               if (typeFileDelete && typeFileDelete.id)
                   idsDelete.push(typeFileDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoTypeFileDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_TYPEFILE:
                       let datoTypeFileDeleteUpdate = action.typeFile;
                       let idsDeleteUpdate = [];
                      Object.values(action.typeFile).map((typeFileDelete) => {
                          if (typeFileDelete && typeFileDelete.id)
                              idsDeleteUpdate.push(typeFileDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoTypeFileDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_TYPEFILE:
                    let datoTypeFileDeleted = {};
                    if (Object.values(action.typeFile.entities.typesFiles).length > 0)
                        datoTypeFileDeleted = Object.values(action.typeFile.entities.typesFiles)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoTypeFileDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_TYPEFILE:
             let idsCreate = [];
             Object.values(action.typeFile).map((typeFileCreate) => {
                 if (typeFileCreate && typeFileCreate.id)
                     idsCreate.push(typeFileCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.typeFile,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_TYPEFILE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_TYPEFILE:
            let datoTypeFileNuevo = {};
            if (Object.values(action.typeFile.entities.typesFiles).length > 0)
                datoTypeFileNuevo = Object.values(action.typeFile.entities.typesFiles)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoTypeFileNuevo,
                nuevos: []
            });
        case ERROR_CREATE_TYPEFILE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_TYPESFILES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_TYPESFILES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_TYPESFILES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_TYPEFILE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_TYPEFILE:
           let datoTypeFileDelete = action.typeFile;
           let idsDelete = [];
           Object.values(action.typeFile).map((typeFileDelete) => {
               if (typeFileDelete && typeFileDelete.id)
                   idsDelete.push(typeFileDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoTypeFileDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_TYPEFILE:
                  let datoTypeFileDeleteCreate = action.typeFile;
                  let idsDeleteCreate = [];
                  Object.values(action.typeFile).map((typeFileDelete) => {
                      if (typeFileDelete && typeFileDelete.id)
                          idsDeleteCreate.push(typeFileDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoTypeFileDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_TYPEFILE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.typeFile,
                error: null,
            });
        case REQUEST_DELETE_TYPEFILE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_TYPEFILE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_TYPEFILE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_TYPEFILE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_TYPEFILE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_TYPEFILE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_TYPEFILE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const typesFiles = combineReducers({
    byId: typesFilesById,
    allIds: allTypesFiles,
    update: update,
    create: create,
    totalTypesFiles: totalTypesFiles,
    delete: deleter,
    print: print
});

export default typesFiles;