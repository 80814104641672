import {resetPermissions, resetCreatePermission, resetUpdatePermission, resetDeletePermission} from "../actions/PermissionActions";


var resetPermission = {
    resetAll(dispatch) {
        dispatch(resetPermissions());
        dispatch(resetCreatePermission());
        dispatch(resetUpdatePermission());
        dispatch(resetDeletePermission());
       
    },
};

export default resetPermission;