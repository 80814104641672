import {normalize, schema, denormalize} from 'normalizr';
import attributeGroup from "./normalizeAttributesGroups";
import attributeLanguage from "./normalizeAttributesLanguages";

const attribute = new schema.Entity('attributes', {attribute_group: attributeGroup, attribute_language: [attributeLanguage]}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {attributes: [attribute]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = attribute;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = attribute;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default attribute;
