import {
    INVALIDATE_ATTRIBUTES,
    ERROR_ATTRIBUTES,
    RECEIVE_ATTRIBUTES,
    REQUEST_ATTRIBUTES,
    RESET_ATTRIBUTES,
    ERROR_ATTRIBUTE,
    RECEIVE_ATTRIBUTE,
    REQUEST_ATTRIBUTE,
    UPDATE_ATTRIBUTE,
    REQUEST_UPDATE_ATTRIBUTE,
    SUCCESS_UPDATE_ATTRIBUTE,
    ERROR_UPDATE_ATTRIBUTE,
    RESET_UPDATE_ATTRIBUTE,
    REQUEST_UPDATE_ATTRIBUTES,
    SUCCESS_UPDATE_ATTRIBUTES,
    ERROR_UPDATE_ATTRIBUTES,
    RESET_UPDATE_ATTRIBUTES,
    CREATE_ATTRIBUTE,
    ERROR_CREATE_ATTRIBUTE,
    REQUEST_CREATE_ATTRIBUTE,
    RESET_CREATE_ATTRIBUTE,
    SUCCESS_CREATE_ATTRIBUTE,
    REQUEST_CREATE_ATTRIBUTES,
    SUCCESS_CREATE_ATTRIBUTES,
    ERROR_CREATE_ATTRIBUTES,
    RESET_CREATE_ATTRIBUTES,
    DELETE_ATTRIBUTE,
    DELETE_CREATE_ATTRIBUTE,
    DELETE_UPDATE_ATTRIBUTE,
    REQUEST_DELETE_ATTRIBUTE,
    SUCCESS_DELETE_ATTRIBUTE,
    ERROR_DELETE_ATTRIBUTE,
    RESET_DELETE_ATTRIBUTE,
    REQUEST_PRINT_ATTRIBUTE,
    SUCCESS_PRINT_ATTRIBUTE,
    ERROR_PRINT_ATTRIBUTE,
    RESET_PRINT_ATTRIBUTE,
    RECEIVE_FILE_ATTRIBUTE
} from '../actions/AttributeActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        attributes: {},
        files: {},
    }
}

function attributesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ATTRIBUTES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ATTRIBUTES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ATTRIBUTES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ATTRIBUTES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                attributes: {}
            });
        case RECEIVE_ATTRIBUTES:
            let dato = action.attributes.entities.attributes;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                attributes: merge({}, state.attributes, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ATTRIBUTE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ATTRIBUTE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ATTRIBUTE:
            let datoAttribute = action.attribute.entities.attributes;
            return Object.assign({}, state, {
                attributes: merge({}, state.attributes, datoAttribute),
                isFetching: false,
            });
        case RECEIVE_FILE_ATTRIBUTE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ATTRIBUTE:
            let datoAttributeEliminado = action.attribute.entities.attributes;
            return Object.assign({}, state, {
                attributes: mergeWith(clone(datoAttributeEliminado), state.attributes, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTE:
            let datoAttributeCreado = action.attribute.entities.attributes;
            return Object.assign({}, state, {
                attributes: mergeWith(clone(datoAttributeCreado), state.attributes, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTES:
            let datosAttributeCreado = action.attributes.entities.attributes;
                return Object.assign({}, state, {
                    attributes: mergeWith(clone(datosAttributeCreado), state.attributes, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ATTRIBUTE:
            let datoAttributeActualizado = action.attribute.entities.attributes;
            return Object.assign({}, state, {
                attributes: mergeWith(clone(datoAttributeActualizado), state.attributes, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ATTRIBUTES:
            let datosAttributeActualizado = action.attributes.entities.attributes;
                return Object.assign({}, state, {
                    attributes: mergeWith(clone(datosAttributeActualizado), state.attributes, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : {};
    return Object.assign({}, state, {
        attributes: merge({}, state.attributes, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributes ? action.products.entities.attributes : {};
    return Object.assign({}, state, {
        attributes: merge({}, state.attributes, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : {};
    return Object.assign({}, state, {
        attributes: mergeWith(clone(datoproductEliminado), pickBy(state.attributes, function(attribute) {return attribute.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : {};
    return Object.assign({}, state, {
        attributes: mergeWith(clone(datoproductCreado), pickBy(state.attributes, function(attribute) {return attribute.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : {};
    return Object.assign({}, state, {
        attributes: mergeWith(clone(datoproductActualizado), pickBy(state.attributes, function(attribute) {return attribute.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.attributes ? action.products.entities.attributes : {} ;
        return Object.assign({}, state, {
            attributes: mergeWith(clone(datosproductActualizado), state.attributes, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                attributes: {}
            });
        default:
            return state
    }
}


function allAttributes(state = [], action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTES:
            return action.attributes.result && action.attributes.result.attributes ? union(action.attributes.result.attributes, state) : (action.attributes.result ? action.attributes.result : state) ;
        case RECEIVE_ATTRIBUTE:
                return action.attribute.result ? union([action.attribute.result], state) : state;
        
        case SUCCESS_CREATE_ATTRIBUTE:
                   let datoAttributeSCreate = action.attribute.entities.attributes;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAttributeSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAttributeSCreate)[0] && Object.values(datoAttributeSCreate)[0].id ? Object.values(datoAttributeSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ATTRIBUTES:
                   let attributesCreate = action.attributes.entities && action.attributes.entities.attributes ? action.attributes.entities.attributes : null;
                   return attributesCreate ?
                       union(state, Object.values(attributesCreate).map((attributes) => {
                           return attributes.id
                       })) : state;
        case RESET_ATTRIBUTES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributes ? action.products.entities.attributes : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.attributes ? action.product.entities.attributes : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.attributes ? action.products.entities.attributes : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAttributes(state = null, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTES:
            return action.attributes && action.attributes.result.total ? action.attributes.result.total : 0;
        case RESET_ATTRIBUTES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTE:
            let dato = action.attribute.entities.attributes;
            let attribute = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: attribute ? attribute : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ATTRIBUTE:
            let idsUpdate = [];
            Object.values(action.attribute).map((attributeUpdate) => {
                if (attributeUpdate && attributeUpdate.id)
                    idsUpdate.push(attributeUpdate.id);
            });
            return merge({}, state, {
                activo: action.attribute,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ATTRIBUTE:
            let datoAttributeActualizado = {};
            if (Object.values(action.attribute.entities.attributes).length > 0)
                datoAttributeActualizado = Object.values(action.attribute.entities.attributes)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAttributeActualizado
            });
        case ERROR_UPDATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ATTRIBUTES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ATTRIBUTES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ATTRIBUTES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ATTRIBUTE:
            let datoAttributeDelete = action.attribute;
            let idsDelete = [];
           Object.values(action.attribute).map((attributeDelete) => {
               if (attributeDelete && attributeDelete.id)
                   idsDelete.push(attributeDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAttributeDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ATTRIBUTE:
                       let datoAttributeDeleteUpdate = action.attribute;
                       let idsDeleteUpdate = [];
                      Object.values(action.attribute).map((attributeDelete) => {
                          if (attributeDelete && attributeDelete.id)
                              idsDeleteUpdate.push(attributeDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAttributeDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ATTRIBUTE:
                    let datoAttributeDeleted = {};
                    if (Object.values(action.attribute.entities.attributes).length > 0)
                        datoAttributeDeleted = Object.values(action.attribute.entities.attributes)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAttributeDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ATTRIBUTE:
             let idsCreate = [];
             Object.values(action.attribute).map((attributeCreate) => {
                 if (attributeCreate && attributeCreate.id)
                     idsCreate.push(attributeCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.attribute,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ATTRIBUTE:
            let datoAttributeNuevo = {};
            if (Object.values(action.attribute.entities.attributes).length > 0)
                datoAttributeNuevo = Object.values(action.attribute.entities.attributes)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAttributeNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ATTRIBUTES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ATTRIBUTES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ATTRIBUTE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ATTRIBUTE:
           let datoAttributeDelete = action.attribute;
           let idsDelete = [];
           Object.values(action.attribute).map((attributeDelete) => {
               if (attributeDelete && attributeDelete.id)
                   idsDelete.push(attributeDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ATTRIBUTE:
                  let datoAttributeDeleteCreate = action.attribute;
                  let idsDeleteCreate = [];
                  Object.values(action.attribute).map((attributeDelete) => {
                      if (attributeDelete && attributeDelete.id)
                          idsDeleteCreate.push(attributeDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ATTRIBUTE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.attribute,
                error: null,
            });
        case REQUEST_DELETE_ATTRIBUTE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ATTRIBUTE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ATTRIBUTE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ATTRIBUTE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ATTRIBUTE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ATTRIBUTE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ATTRIBUTE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const attributes = combineReducers({
    byId: attributesById,
    allIds: allAttributes,
    update: update,
    create: create,
    totalAttributes: totalAttributes,
    delete: deleter,
    print: print
});

export default attributes;