//api
import attributesLanguagesApi from "../api/attributesLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAttributesLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ATTRIBUTESLANGUAGES
export const REQUEST_ATTRIBUTESLANGUAGES = 'REQUEST_ATTRIBUTESLANGUAGES';
export const RECEIVE_ATTRIBUTESLANGUAGES = 'RECEIVE_ATTRIBUTESLANGUAGES';
export const INVALIDATE_ATTRIBUTESLANGUAGES = 'INVALIDATE_ATTRIBUTESLANGUAGES';
export const ERROR_ATTRIBUTESLANGUAGES = "ERROR_ATTRIBUTESLANGUAGES";
export const RESET_ATTRIBUTESLANGUAGES = "RESET_ATTRIBUTESLANGUAGES";


export function invalidateAttributesLanguages() {
    return {
        type: INVALIDATE_ATTRIBUTESLANGUAGES
    }
}

function requestAttributesLanguages() {
    return {
        type: REQUEST_ATTRIBUTESLANGUAGES,
    }
}

function receiveAttributesLanguages(json) {
    return {
        type: RECEIVE_ATTRIBUTESLANGUAGES,
    attributesLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAttributesLanguages(error) {
    return {
        type: ERROR_ATTRIBUTESLANGUAGES,
        error: error,
    }
}

export function resetAttributesLanguages() {
    return {
        type: RESET_ATTRIBUTESLANGUAGES
    }
}

export function fetchAttributesLanguages(filtros) {
    return dispatch => {
        dispatch(requestAttributesLanguages());
        return attributesLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttributesLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributesLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAttributesLanguages(state) {
    const attributesLanguages = state.attributesLanguages.byId;
    if (!attributesLanguages) {
        return true
    } else if (attributesLanguages.isFetching) {
        return false
    } else {
        return attributesLanguages.didInvalidate;
    }
}

export function fetchAttributesLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAttributesLanguages(getState())) {
            return dispatch(fetchAttributesLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_ATTRIBUTELANGUAGE = 'REQUEST_ATTRIBUTELANGUAGE';
export const RECEIVE_ATTRIBUTELANGUAGE = 'RECEIVE_ATTRIBUTELANGUAGE';
export const INVALIDATE_ATTRIBUTELANGUAGE = 'INVALIDATE_ATTRIBUTELANGUAGE';
export const ERROR_ATTRIBUTELANGUAGE = "ERROR_ATTRIBUTELANGUAGE";
export const RESET_ATTRIBUTELANGUAGE = "RESET_ATTRIBUTELANGUAGE";


export function invalidateAttributeLanguage() {
    return {
        type: INVALIDATE_ATTRIBUTELANGUAGE
    }
}

function requestAttributeLanguage() {
    return {
        type: REQUEST_ATTRIBUTELANGUAGE,
    }
}

export function receiveAttributeLanguage(json) {
    return {
        type: RECEIVE_ATTRIBUTELANGUAGE,
    attributeLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAttributeLanguage(error) {
    return {
        type: ERROR_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function fetchAttributeLanguage(idAttributeLanguage) {
    return dispatch => {
        dispatch(requestAttributeLanguage());
        return attributesLanguagesApi.getOne(idAttributeLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttributeLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributeLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ATTRIBUTELANGUAGE = 'RECEIVE_FILE_ATTRIBUTELANGUAGE';

function receiveFileAttributeLanguage(file) {
    return {
        type: RECEIVE_FILE_ATTRIBUTELANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAttributeLanguage(idAttributeLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return attributesLanguagesApi.getFile(idAttributeLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAttributeLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributeLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ATTRIBUTELANGUAGE = 'UPDATE_ATTRIBUTELANGUAGE';
export const REQUEST_UPDATE_ATTRIBUTELANGUAGE = "REQUEST_UPDATE_ATTRIBUTELANGUAGE";
export const SUCCESS_UPDATE_ATTRIBUTELANGUAGE = "SUCCESS_UPDATE_ATTRIBUTELANGUAGE";
export const ERROR_UPDATE_ATTRIBUTELANGUAGE = "ERROR_UPDATE_ATTRIBUTELANGUAGE";
export const RESET_UPDATE_ATTRIBUTELANGUAGE = "RESET_UPDATE_ATTRIBUTELANGUAGE";
export const DELETE_UPDATE_ATTRIBUTELANGUAGE = "DELETE_UPDATE_ATTRIBUTELANGUAGE";

function requestUpdateAttributeLanguage() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTELANGUAGE,
    }
}

function receiveUpdateAttributeLanguage(attributeLanguage) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTELANGUAGE,
        receivedAt: Date.now(),
        attributeLanguage: normalizeDato(attributeLanguage)
    }
}

function errorUpdateAttributeLanguage(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function resetUpdateAttributeLanguage() {
    return {
        type: RESET_UPDATE_ATTRIBUTELANGUAGE,
    }
}

export function updateAttributeLanguage(attributeLanguage) {
    return {
        type: UPDATE_ATTRIBUTELANGUAGE,
        attributeLanguage
    }
}

export function saveUpdateAttributeLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttributeLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributeLanguage = denormalizeDato(getState().attributesLanguages.update.activo, store);

        return attributesLanguagesApi.saveUpdate(attributeLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttributeLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAttributesLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAttributesLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAttributeLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAttributeLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAttributeLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAttributeLanguage(attributeLanguage) {
    return {
        type: DELETE_UPDATE_ATTRIBUTELANGUAGE,
        attributeLanguage
    }
}

//UPDATE ATTRIBUTESLANGUAGES
export const REQUEST_UPDATE_ATTRIBUTESLANGUAGES = "REQUEST_UPDATE_ATTRIBUTESLANGUAGES";
export const SUCCESS_UPDATE_ATTRIBUTESLANGUAGES = "SUCCESS_UPDATE_ATTRIBUTESLANGUAGES";
export const ERROR_UPDATE_ATTRIBUTESLANGUAGES = "ERROR_UPDATE_ATTRIBUTESLANGUAGES";
export const RESET_UPDATE_ATTRIBUTESLANGUAGES = "RESET_UPDATE_ATTRIBUTESLANGUAGES";

function requestUpdateAttributesLanguages() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTESLANGUAGES,
}
}

function receiveUpdateAttributesLanguages(attributesLanguages) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTESLANGUAGES,
    receivedAt: Date.now(),
        attributesLanguages: normalizeDatos(attributesLanguages)
}
}

function errorUpdateAttributesLanguages(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTESLANGUAGES,
    error: error,
}
}

export function resetUpdateAttributesLanguages() {
    return {
        type: RESET_UPDATE_ATTRIBUTESLANGUAGES,
}
}

export function saveUpdateAttributesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttributesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributesLanguages = getState().attributesLanguages.update.activos.map((idAttributeLanguage) => {
            return denormalizeDato(getState().attributesLanguages.update.activo[idAttributeLanguage], store);
        });

        return attributesLanguagesApi.saveUpdateAttributesLanguages(attributesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttributesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAttributesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAttributesLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAttributesLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAttributesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAttributesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ATTRIBUTELANGUAGE
export const CREATE_ATTRIBUTELANGUAGE = 'CREATE_ATTRIBUTELANGUAGE';
export const REQUEST_CREATE_ATTRIBUTELANGUAGE = "REQUEST_CREATE_ATTRIBUTELANGUAGE";
export const SUCCESS_CREATE_ATTRIBUTELANGUAGE = "SUCCESS_CREATE_ATTRIBUTELANGUAGE";
export const ERROR_CREATE_ATTRIBUTELANGUAGE = "ERROR_CREATE_ATTRIBUTELANGUAGE";
export const RESET_CREATE_ATTRIBUTELANGUAGE = "RESET_CREATE_ATTRIBUTELANGUAGE";
export const DELETE_CREATE_ATTRIBUTELANGUAGE = "DELETE_CREATE_ATTRIBUTELANGUAGE";


//ALTA ATTRIBUTELANGUAGE
function requestCreateAttributeLanguage() {
    return {
        type: REQUEST_CREATE_ATTRIBUTELANGUAGE,
    }
}

function receiveCreateAttributeLanguage(attributeLanguage) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTELANGUAGE,
        receivedAt: Date.now(),
        attributeLanguage: normalizeDato(attributeLanguage)
    }
}

export function errorCreateAttributeLanguage(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function resetCreateAttributeLanguage() {
    return {
        type: RESET_CREATE_ATTRIBUTELANGUAGE
    }
}

export function createAttributeLanguage(attributeLanguage) {
    return {
        type: CREATE_ATTRIBUTELANGUAGE,
        attributeLanguage
    }
}

export function deleteCreateAttributeLanguage(attributeLanguage) {
    return {
        type: DELETE_CREATE_ATTRIBUTELANGUAGE,
        attributeLanguage
    }
}

export function saveCreateAttributeLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttributeLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributeLanguage = denormalizeDato(getState().attributesLanguages.create.nuevo, store);

        return attributesLanguagesApi.saveCreate(attributeLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttributeLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAttributesLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAttributesLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAttributeLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAttributeLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAttributeLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ATTRIBUTESLANGUAGES
export const REQUEST_CREATE_ATTRIBUTESLANGUAGES = "REQUEST_CREATE_ATTRIBUTESLANGUAGES";
export const SUCCESS_CREATE_ATTRIBUTESLANGUAGES = "SUCCESS_CREATE_ATTRIBUTESLANGUAGES";
export const ERROR_CREATE_ATTRIBUTESLANGUAGES = "ERROR_CREATE_ATTRIBUTESLANGUAGES";
export const RESET_CREATE_ATTRIBUTESLANGUAGES = "RESET_CREATE_ATTRIBUTESLANGUAGES";

function requestCreateAttributesLanguages() {
    return {
        type: REQUEST_CREATE_ATTRIBUTESLANGUAGES,
}
}

function receiveCreateAttributesLanguages(attributesLanguages) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTESLANGUAGES,
    receivedAt: Date.now(),
        attributesLanguages: normalizeDatos(attributesLanguages)
}
}

function errorCreateAttributesLanguages(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTESLANGUAGES,
    error: error,
}
}

export function resetCreateAttributesLanguages() {
    return {
        type: RESET_CREATE_ATTRIBUTESLANGUAGES,
}
}

export function saveCreateAttributesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttributesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributesLanguages = getState().attributesLanguages.create.nuevos.map((idAttributeLanguage) => {
            return denormalizeDato(getState().attributesLanguages.create.nuevo[idAttributeLanguage], store);
        });

        return attributesLanguagesApi.saveCreateAttributesLanguages(attributesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttributesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAttributesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAttributesLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAttributesLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAttributesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAttributesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ATTRIBUTELANGUAGE
export const DELETE_ATTRIBUTELANGUAGE = 'DELETE_ATTRIBUTELANGUAGE';
export const REQUEST_DELETE_ATTRIBUTELANGUAGE = "REQUEST_DELETE_ATTRIBUTELANGUAGE";
export const SUCCESS_DELETE_ATTRIBUTELANGUAGE = "SUCCESS_DELETE_ATTRIBUTELANGUAGE";
export const ERROR_DELETE_ATTRIBUTELANGUAGE = "ERROR_DELETE_ATTRIBUTELANGUAGE";
export const RESET_DELETE_ATTRIBUTELANGUAGE = "RESET_DELETE_ATTRIBUTELANGUAGE";

function requestDeleteAttributeLanguage() {
    return {
        type: REQUEST_DELETE_ATTRIBUTELANGUAGE,
    }
}

function receiveDeleteAttributeLanguage(attributeLanguage) {
    return {
        type: SUCCESS_DELETE_ATTRIBUTELANGUAGE,
        receivedAt: Date.now(),
        attributeLanguage: normalizeDato(attributeLanguage)
    }
}

function errorDeleteAttributeLanguage(error) {
    return {
        type: ERROR_DELETE_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function resetDeleteAttributeLanguage(error) {
    return {
        type: RESET_DELETE_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function deleteAttributeLanguage(attributeLanguage) {
    return {
        type: DELETE_ATTRIBUTELANGUAGE,
        attributeLanguage
    }
}

export function saveDeleteAttributeLanguage(attributeLanguage) {
    return dispatch => {
        dispatch(requestDeleteAttributeLanguage());
        return attributesLanguagesApi.saveDelete(attributeLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAttributeLanguage());
                            dispatch(receiveDeleteAttributeLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAttributeLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAttributeLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAttributeLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAttributeLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ATTRIBUTELANGUAGE
export const REQUEST_PRINT_ATTRIBUTELANGUAGE = "REQUEST_PRINT_ATTRIBUTELANGUAGE";
export const SUCCESS_PRINT_ATTRIBUTELANGUAGE = "SUCCESS_PRINT_ATTRIBUTELANGUAGE";
export const ERROR_PRINT_ATTRIBUTELANGUAGE = "ERROR_PRINT_ATTRIBUTELANGUAGE";
export const RESET_PRINT_ATTRIBUTELANGUAGE = "RESET_PRINT_ATTRIBUTELANGUAGE";

function requestPrintAttributeLanguage() {
    return {
        type: REQUEST_PRINT_ATTRIBUTELANGUAGE,
    }
}

function receivePrintAttributeLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_ATTRIBUTELANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAttributeLanguage(error) {
    return {
        type: ERROR_PRINT_ATTRIBUTELANGUAGE,
        error: error,
    }
}

export function resetPrintAttributeLanguage() {
    return {
        type: RESET_PRINT_ATTRIBUTELANGUAGE,
    }
}

export function printAttributeLanguage(idAttributeLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAttributeLanguage());
        return attributesLanguagesApi.printAttributeLanguage(idAttributeLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAttributeLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAttributeLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAttributeLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAttributeLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAttributeLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}