import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";
import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";import {resetAssociatedProducts, resetCreateAssociatedProduct, resetUpdateAssociatedProduct, resetDeleteAssociatedProduct} from "../actions/AssociatedProductActions";import {resetProductsLanguages, resetCreateProductLanguage, resetUpdateProductLanguage, resetDeleteProductLanguage} from "../actions/ProductLanguageActions";import {resetProductsCountries, resetCreateProductCountry, resetUpdateProductCountry, resetDeleteProductCountry} from "../actions/ProductCountryActions";

var resetProduct = {
    resetAll(dispatch) {
        dispatch(resetProducts());
        dispatch(resetCreateProduct());
        dispatch(resetUpdateProduct());
        dispatch(resetDeleteProduct());
       dispatch(resetAssets());
dispatch(resetCreateAsset());
dispatch(resetUpdateAsset());
dispatch(resetDeleteAsset());dispatch(resetAssociatedProducts());
dispatch(resetCreateAssociatedProduct());
dispatch(resetUpdateAssociatedProduct());
dispatch(resetDeleteAssociatedProduct());dispatch(resetProductsLanguages());
dispatch(resetCreateProductLanguage());
dispatch(resetUpdateProductLanguage());
dispatch(resetDeleteProductLanguage());dispatch(resetProductsCountries());
dispatch(resetCreateProductCountry());
dispatch(resetUpdateProductCountry());
dispatch(resetDeleteProductCountry());
    },
};

export default resetProduct;