import {normalize, schema, denormalize} from 'normalizr';
import productCountry from "./normalizeProductsCountries";
import productLanguage from "./normalizeProductsLanguages";
import productAttributeValue from "./normalizeProductsAttributesValues";
import postCountry from "./normalizePostsCountries";
import postLanguage from "./normalizePostsLanguages";
import assetLanguage from "./normalizeAssetsLanguages";
import assetCountry from "./normalizeAssetsCountries";
import section from "./normalizeSections";
import typeFile from "./normalizeTypesFiles";

const asset = new schema.Entity('assets', {
    asset_language: [assetLanguage],
    asset_country: [assetCountry],
    section: section,
    type_file: typeFile,
}, {idAttribute: "id"});
const post = new schema.Entity('posts', {
    post_country: [postCountry],
    post_language: [postLanguage],
    asset: [asset],
}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {posts: [post]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = post;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = post;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default post;
