import {normalize, schema, denormalize} from 'normalizr';

const postCountry = new schema.Entity('postsCountries', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {postsCountries: [postCountry]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = postCountry;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = postCountry;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default postCountry;
