import {
    INVALIDATE_SECTIONS,
    ERROR_SECTIONS,
    RECEIVE_SECTIONS,
    REQUEST_SECTIONS,
    RESET_SECTIONS,
    ERROR_SECTION,
    RECEIVE_SECTION,
    REQUEST_SECTION,
    UPDATE_SECTION,
    REQUEST_UPDATE_SECTION,
    SUCCESS_UPDATE_SECTION,
    ERROR_UPDATE_SECTION,
    RESET_UPDATE_SECTION,
    REQUEST_UPDATE_SECTIONS,
    SUCCESS_UPDATE_SECTIONS,
    ERROR_UPDATE_SECTIONS,
    RESET_UPDATE_SECTIONS,
    CREATE_SECTION,
    ERROR_CREATE_SECTION,
    REQUEST_CREATE_SECTION,
    RESET_CREATE_SECTION,
    SUCCESS_CREATE_SECTION,
    REQUEST_CREATE_SECTIONS,
    SUCCESS_CREATE_SECTIONS,
    ERROR_CREATE_SECTIONS,
    RESET_CREATE_SECTIONS,
    DELETE_SECTION,
    DELETE_CREATE_SECTION,
    DELETE_UPDATE_SECTION,
    REQUEST_DELETE_SECTION,
    SUCCESS_DELETE_SECTION,
    ERROR_DELETE_SECTION,
    RESET_DELETE_SECTION,
    REQUEST_PRINT_SECTION,
    SUCCESS_PRINT_SECTION,
    ERROR_PRINT_SECTION,
    RESET_PRINT_SECTION,
    RECEIVE_FILE_SECTION
} from '../actions/SectionActions';

import {
    RECEIVE_PAGE,
    RECEIVE_PAGES,
    SUCCESS_DELETE_PAGE,
    SUCCESS_CREATE_PAGE,
    SUCCESS_UPDATE_PAGE,
    SUCCESS_UPDATE_PAGES

} from '../actions/PageActions';
import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        sections: {},
        files: {},
    }
}

function sectionsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_SECTIONS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_SECTIONS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_SECTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_SECTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                sections: {}
            });
        case RECEIVE_SECTIONS:
            let dato = action.sections.entities.sections;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                sections: merge({}, state.sections, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_SECTION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_SECTION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_SECTION:
            let datoSection = action.section.entities.sections;
            return Object.assign({}, state, {
                sections: merge({}, state.sections, datoSection),
                isFetching: false,
            });
        case RECEIVE_FILE_SECTION:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_SECTION:
            let datoSectionEliminado = action.section.entities.sections;
            return Object.assign({}, state, {
                sections: mergeWith(clone(datoSectionEliminado), state.sections, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_SECTION:
            let datoSectionCreado = action.section.entities.sections;
            return Object.assign({}, state, {
                sections: mergeWith(clone(datoSectionCreado), state.sections, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_SECTIONS:
            let datosSectionCreado = action.sections.entities.sections;
                return Object.assign({}, state, {
                    sections: mergeWith(clone(datosSectionCreado), state.sections, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_SECTION:
            let datoSectionActualizado = action.section.entities.sections;
            return Object.assign({}, state, {
                sections: mergeWith(clone(datoSectionActualizado), state.sections, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_SECTIONS:
            let datosSectionActualizado = action.sections.entities.sections;
                return Object.assign({}, state, {
                    sections: mergeWith(clone(datosSectionActualizado), state.sections, (objValue, srcValue) => {return objValue;})
                });

            //PAGE
case RECEIVE_PAGE:
    let page = action.page.entities && action.page.entities.sections ? action.page.entities.sections : {};
    return Object.assign({}, state, {
        sections: merge({}, state.sections, page),
    });
case RECEIVE_PAGES:
    let pages = action.pages.entities && action.pages.entities.sections ? action.pages.entities.sections : {};
    return Object.assign({}, state, {
        sections: merge({}, state.sections, pages),
    });
case SUCCESS_DELETE_PAGE:
    let datopageEliminado = action.page.entities && action.page.entities.sections ? action.page.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datopageEliminado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PAGE:
    let datopageCreado = action.page.entities && action.page.entities.sections ? action.page.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datopageCreado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PAGE:
    let datopageActualizado = action.page.entities && action.page.entities.sections ? action.page.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datopageActualizado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PAGES:
    let datospageActualizado = action.pages.entities && action.pages.entities.sections ? action.pages.entities.sections : {} ;
        return Object.assign({}, state, {
            sections: mergeWith(clone(datospageActualizado), state.sections, (objValue, srcValue) => {return objValue;})
        });
//ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : {};
    return Object.assign({}, state, {
        sections: merge({}, state.sections, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.sections ? action.assets.entities.sections : {};
    return Object.assign({}, state, {
        sections: merge({}, state.sections, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datoassetEliminado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datoassetCreado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : {};
    return Object.assign({}, state, {
        sections: mergeWith(clone(datoassetActualizado), pickBy(state.sections, function(section) {return section.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.sections ? action.assets.entities.sections : {} ;
        return Object.assign({}, state, {
            sections: mergeWith(clone(datosassetActualizado), state.sections, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                sections: {}
            });
        default:
            return state
    }
}


function allSections(state = [], action) {
    switch (action.type) {
        case RECEIVE_SECTIONS:
            return action.sections.result && action.sections.result.sections ? union(action.sections.result.sections, state) : (action.sections.result ? action.sections.result : state) ;
        case RECEIVE_SECTION:
                return action.section.result ? union([action.section.result], state) : state;
        
        case SUCCESS_CREATE_SECTION:
                   let datoSectionSCreate = action.section.entities.sections;
                   let idNuevoSCreate = null;
                   if (Object.values(datoSectionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoSectionSCreate)[0] && Object.values(datoSectionSCreate)[0].id ? Object.values(datoSectionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_SECTIONS:
                   let sectionsCreate = action.sections.entities && action.sections.entities.sections ? action.sections.entities.sections : null;
                   return sectionsCreate ?
                       union(state, Object.values(sectionsCreate).map((sections) => {
                           return sections.id
                       })) : state;
        case RESET_SECTIONS:
            return [];

            case RECEIVE_PAGE:
    let page = action.page.entities && action.page.entities.sections ? action.page.entities.sections : null ;
    return page ?
        union(state, Object.values(page).map((page) => {
            return page.id
        })) : state;
case RECEIVE_PAGES:
    let pages = action.pages.entities && action.pages.entities.sections ? action.pages.entities.sections : null;
    return pages ?
        union(state, Object.values(pages).map((pages) => {
            return pages.id
        })) : state;

case SUCCESS_DELETE_PAGE:
    let pageDelete = action.page.entities && action.page.entities.sections ? action.page.entities.sections : null ;
        return pageDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageDelete).map((page) => {
                return page.id
            })) : state;
case SUCCESS_CREATE_PAGE:
   let pageCreate = action.page.entities && action.page.entities.sections ? action.page.entities.sections : null ;
       return pageCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageCreate).map((page) => {
               return page.id
           })) : state;
case SUCCESS_UPDATE_PAGE:
    let pageUpdate = action.page.entities && action.page.entities.sections ? action.page.entities.sections : null ;
        return pageUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(pageUpdate).map((page) => {
                return page.id
            })) : state;
 case SUCCESS_UPDATE_PAGES:
     let pagesUpdate = action.pages.entities && action.pages.entities.sections ? action.pages.entities.sections : null;
        return pagesUpdate ?
            union(state, Object.values(pagesUpdate).map((pages) => {
                return pages.id
            })) : state;case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.sections ? action.assets.entities.sections : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.sections ? action.asset.entities.sections : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.sections ? action.assets.entities.sections : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalSections(state = null, action) {
    switch (action.type) {
        case RECEIVE_SECTIONS:
            return action.sections && action.sections.result.total ? action.sections.result.total : 0;
        case RESET_SECTIONS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_SECTION:
            let dato = action.section.entities.sections;
            let section = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: section ? section : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_SECTION:
            let idsUpdate = [];
            Object.values(action.section).map((sectionUpdate) => {
                if (sectionUpdate && sectionUpdate.id)
                    idsUpdate.push(sectionUpdate.id);
            });
            return merge({}, state, {
                activo: action.section,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_SECTION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_SECTION:
            let datoSectionActualizado = {};
            if (Object.values(action.section.entities.sections).length > 0)
                datoSectionActualizado = Object.values(action.section.entities.sections)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoSectionActualizado
            });
        case ERROR_UPDATE_SECTION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_SECTIONS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_SECTIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_SECTIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_SECTION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PAGE
//TODO ver si esta bien
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });//ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_SECTION:
            let datoSectionDelete = action.section;
            let idsDelete = [];
           Object.values(action.section).map((sectionDelete) => {
               if (sectionDelete && sectionDelete.id)
                   idsDelete.push(sectionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoSectionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_SECTION:
                       let datoSectionDeleteUpdate = action.section;
                       let idsDeleteUpdate = [];
                      Object.values(action.section).map((sectionDelete) => {
                          if (sectionDelete && sectionDelete.id)
                              idsDeleteUpdate.push(sectionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoSectionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_SECTION:
                    let datoSectionDeleted = {};
                    if (Object.values(action.section.entities.sections).length > 0)
                        datoSectionDeleted = Object.values(action.section.entities.sections)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoSectionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_SECTION:
             let idsCreate = [];
             Object.values(action.section).map((sectionCreate) => {
                 if (sectionCreate && sectionCreate.id)
                     idsCreate.push(sectionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.section,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_SECTION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_SECTION:
            let datoSectionNuevo = {};
            if (Object.values(action.section.entities.sections).length > 0)
                datoSectionNuevo = Object.values(action.section.entities.sections)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoSectionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_SECTION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_SECTIONS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_SECTIONS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_SECTIONS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_SECTION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PAGE
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_SECTION:
           let datoSectionDelete = action.section;
           let idsDelete = [];
           Object.values(action.section).map((sectionDelete) => {
               if (sectionDelete && sectionDelete.id)
                   idsDelete.push(sectionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoSectionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_SECTION:
                  let datoSectionDeleteCreate = action.section;
                  let idsDeleteCreate = [];
                  Object.values(action.section).map((sectionDelete) => {
                      if (sectionDelete && sectionDelete.id)
                          idsDeleteCreate.push(sectionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoSectionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_SECTION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.section,
                error: null,
            });
        case REQUEST_DELETE_SECTION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_SECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_SECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_SECTION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PAGE
 case SUCCESS_CREATE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PAGE:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PAGES:
        return Object.assign({}, state, {
            eliminado: {},
        });//ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_SECTION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_SECTION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_SECTION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const sections = combineReducers({
    byId: sectionsById,
    allIds: allSections,
    update: update,
    create: create,
    totalSections: totalSections,
    delete: deleter,
    print: print
});

export default sections;