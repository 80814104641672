import {normalize, schema, denormalize} from 'normalizr';
import product from "./normalizeProducts";

const associatedProduct = new schema.Entity('associatedProducts', {related_product: product}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {associatedProducts: [associatedProduct]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = associatedProduct;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = associatedProduct;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default associatedProduct;
