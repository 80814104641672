import {resetPosts, resetCreatePost, resetUpdatePost, resetDeletePost} from "../actions/PostActions";
import {resetAssets, resetCreateAsset, resetUpdateAsset, resetDeleteAsset} from "../actions/AssetActions";import {resetAssociatedProducts, resetCreateAssociatedProduct, resetUpdateAssociatedProduct, resetDeleteAssociatedProduct} from "../actions/AssociatedProductActions";import {resetPostsLanguages, resetCreatePostLanguage, resetUpdatePostLanguage, resetDeletePostLanguage} from "../actions/PostLanguageActions";import {resetPostsCountries, resetCreatePostCountry, resetUpdatePostCountry, resetDeletePostCountry} from "../actions/PostCountryActions";

var resetPost = {
    resetAll(dispatch) {
        dispatch(resetPosts());
        dispatch(resetCreatePost());
        dispatch(resetUpdatePost());
        dispatch(resetDeletePost());
       dispatch(resetAssets());
dispatch(resetCreateAsset());
dispatch(resetUpdateAsset());
dispatch(resetDeleteAsset());dispatch(resetAssociatedProducts());
dispatch(resetCreateAssociatedProduct());
dispatch(resetUpdateAssociatedProduct());
dispatch(resetDeleteAssociatedProduct());dispatch(resetPostsLanguages());
dispatch(resetCreatePostLanguage());
dispatch(resetUpdatePostLanguage());
dispatch(resetDeletePostLanguage());dispatch(resetPostsCountries());
dispatch(resetCreatePostCountry());
dispatch(resetUpdatePostCountry());
dispatch(resetDeletePostCountry());
    },
};

export default resetPost;