import {normalize, schema, denormalize} from 'normalizr';
import categoryLanguage from "./normalizeCategoriesLanguages";

const category = new schema.Entity('categories', {}, {idAttribute: "id"});

category.define({
    children_categories: [category],
    category_language: [categoryLanguage]
});

export function normalizeDatos(myData) {

    const mySchema = {categories: [category]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = category;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = category;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default category;
