import {
    INVALIDATE_PRODUCTSATTRIBUTESVALUES,
    ERROR_PRODUCTSATTRIBUTESVALUES,
    RECEIVE_PRODUCTSATTRIBUTESVALUES,
    REQUEST_PRODUCTSATTRIBUTESVALUES,
    RESET_PRODUCTSATTRIBUTESVALUES,
    ERROR_PRODUCTATTRIBUTEVALUE,
    RECEIVE_PRODUCTATTRIBUTEVALUE,
    REQUEST_PRODUCTATTRIBUTEVALUE,
    UPDATE_PRODUCTATTRIBUTEVALUE,
    REQUEST_UPDATE_PRODUCTATTRIBUTEVALUE,
    SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE,
    ERROR_UPDATE_PRODUCTATTRIBUTEVALUE,
    RESET_UPDATE_PRODUCTATTRIBUTEVALUE,
    REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUES,
    SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES,
    ERROR_UPDATE_PRODUCTSATTRIBUTESVALUES,
    RESET_UPDATE_PRODUCTSATTRIBUTESVALUES,
    CREATE_PRODUCTATTRIBUTEVALUE,
    ERROR_CREATE_PRODUCTATTRIBUTEVALUE,
    REQUEST_CREATE_PRODUCTATTRIBUTEVALUE,
    RESET_CREATE_PRODUCTATTRIBUTEVALUE,
    SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE,
    REQUEST_CREATE_PRODUCTSATTRIBUTESVALUES,
    SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES,
    ERROR_CREATE_PRODUCTSATTRIBUTESVALUES,
    RESET_CREATE_PRODUCTSATTRIBUTESVALUES,
    DELETE_PRODUCTATTRIBUTEVALUE,
    DELETE_CREATE_PRODUCTATTRIBUTEVALUE,
    DELETE_UPDATE_PRODUCTATTRIBUTEVALUE,
    REQUEST_DELETE_PRODUCTATTRIBUTEVALUE,
    SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE,
    ERROR_DELETE_PRODUCTATTRIBUTEVALUE,
    RESET_DELETE_PRODUCTATTRIBUTEVALUE,
    REQUEST_PRINT_PRODUCTATTRIBUTEVALUE,
    SUCCESS_PRINT_PRODUCTATTRIBUTEVALUE,
    ERROR_PRINT_PRODUCTATTRIBUTEVALUE,
    RESET_PRINT_PRODUCTATTRIBUTEVALUE,
    RECEIVE_FILE_PRODUCTATTRIBUTEVALUE
} from '../actions/ProductAttributeValueActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productsAttributesValues: {},
        files: {},
    }
}

function productsAttributesValuesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productsAttributesValues: {}
            });
        case RECEIVE_PRODUCTSATTRIBUTESVALUES:
            let dato = action.productsAttributesValues.entities.productsAttributesValues;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productsAttributesValues: merge({}, state.productsAttributesValues, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValue = action.productAttributeValue.entities.productsAttributesValues;
            return Object.assign({}, state, {
                productsAttributesValues: merge({}, state.productsAttributesValues, datoProductAttributeValue),
                isFetching: false,
            });
        case RECEIVE_FILE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueEliminado = action.productAttributeValue.entities.productsAttributesValues;
            return Object.assign({}, state, {
                productsAttributesValues: mergeWith(clone(datoProductAttributeValueEliminado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueCreado = action.productAttributeValue.entities.productsAttributesValues;
            return Object.assign({}, state, {
                productsAttributesValues: mergeWith(clone(datoProductAttributeValueCreado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES:
            let datosProductAttributeValueCreado = action.productsAttributesValues.entities.productsAttributesValues;
                return Object.assign({}, state, {
                    productsAttributesValues: mergeWith(clone(datosProductAttributeValueCreado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueActualizado = action.productAttributeValue.entities.productsAttributesValues;
            return Object.assign({}, state, {
                productsAttributesValues: mergeWith(clone(datoProductAttributeValueActualizado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES:
            let datosProductAttributeValueActualizado = action.productsAttributesValues.entities.productsAttributesValues;
                return Object.assign({}, state, {
                    productsAttributesValues: mergeWith(clone(datosProductAttributeValueActualizado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : {};
    return Object.assign({}, state, {
        productsAttributesValues: merge({}, state.productsAttributesValues, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsAttributesValues ? action.products.entities.productsAttributesValues : {};
    return Object.assign({}, state, {
        productsAttributesValues: merge({}, state.productsAttributesValues, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : {};
    return Object.assign({}, state, {
        productsAttributesValues: mergeWith(clone(datoproductEliminado), pickBy(state.productsAttributesValues, function(productAttributeValue) {return productAttributeValue.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : {};
    return Object.assign({}, state, {
        productsAttributesValues: mergeWith(clone(datoproductCreado), pickBy(state.productsAttributesValues, function(productAttributeValue) {return productAttributeValue.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : {};
    return Object.assign({}, state, {
        productsAttributesValues: mergeWith(clone(datoproductActualizado), pickBy(state.productsAttributesValues, function(productAttributeValue) {return productAttributeValue.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.productsAttributesValues ? action.products.entities.productsAttributesValues : {} ;
        return Object.assign({}, state, {
            productsAttributesValues: mergeWith(clone(datosproductActualizado), state.productsAttributesValues, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productsAttributesValues: {}
            });
        default:
            return state
    }
}


function allProductsAttributesValues(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSATTRIBUTESVALUES:
            return action.productsAttributesValues.result && action.productsAttributesValues.result.productsAttributesValues ? union(action.productsAttributesValues.result.productsAttributesValues, state) : (action.productsAttributesValues.result ? action.productsAttributesValues.result : state) ;
        case RECEIVE_PRODUCTATTRIBUTEVALUE:
                return action.productAttributeValue.result ? union([action.productAttributeValue.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE:
                   let datoProductAttributeValueSCreate = action.productAttributeValue.entities.productsAttributesValues;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductAttributeValueSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductAttributeValueSCreate)[0] && Object.values(datoProductAttributeValueSCreate)[0].id ? Object.values(datoProductAttributeValueSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES:
                   let productsAttributesValuesCreate = action.productsAttributesValues.entities && action.productsAttributesValues.entities.productsAttributesValues ? action.productsAttributesValues.entities.productsAttributesValues : null;
                   return productsAttributesValuesCreate ?
                       union(state, Object.values(productsAttributesValuesCreate).map((productsAttributesValues) => {
                           return productsAttributesValues.id
                       })) : state;
        case RESET_PRODUCTSATTRIBUTESVALUES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.productsAttributesValues ? action.products.entities.productsAttributesValues : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.productsAttributesValues ? action.product.entities.productsAttributesValues : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.productsAttributesValues ? action.products.entities.productsAttributesValues : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductsAttributesValues(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTSATTRIBUTESVALUES:
            return action.productsAttributesValues && action.productsAttributesValues.result.total ? action.productsAttributesValues.result.total : 0;
        case RESET_PRODUCTSATTRIBUTESVALUES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTATTRIBUTEVALUE:
            let dato = action.productAttributeValue.entities.productsAttributesValues;
            let productAttributeValue = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productAttributeValue ? productAttributeValue : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTATTRIBUTEVALUE:
            let idsUpdate = [];
            Object.values(action.productAttributeValue).map((productAttributeValueUpdate) => {
                if (productAttributeValueUpdate && productAttributeValueUpdate.id)
                    idsUpdate.push(productAttributeValueUpdate.id);
            });
            return merge({}, state, {
                activo: action.productAttributeValue,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueActualizado = {};
            if (Object.values(action.productAttributeValue.entities.productsAttributesValues).length > 0)
                datoProductAttributeValueActualizado = Object.values(action.productAttributeValue.entities.productsAttributesValues)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductAttributeValueActualizado
            });
        case ERROR_UPDATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTSATTRIBUTESVALUES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTSATTRIBUTESVALUES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTSATTRIBUTESVALUES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueDelete = action.productAttributeValue;
            let idsDelete = [];
           Object.values(action.productAttributeValue).map((productAttributeValueDelete) => {
               if (productAttributeValueDelete && productAttributeValueDelete.id)
                   idsDelete.push(productAttributeValueDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductAttributeValueDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTATTRIBUTEVALUE:
                       let datoProductAttributeValueDeleteUpdate = action.productAttributeValue;
                       let idsDeleteUpdate = [];
                      Object.values(action.productAttributeValue).map((productAttributeValueDelete) => {
                          if (productAttributeValueDelete && productAttributeValueDelete.id)
                              idsDeleteUpdate.push(productAttributeValueDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductAttributeValueDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE:
                    let datoProductAttributeValueDeleted = {};
                    if (Object.values(action.productAttributeValue.entities.productsAttributesValues).length > 0)
                        datoProductAttributeValueDeleted = Object.values(action.productAttributeValue.entities.productsAttributesValues)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductAttributeValueDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTATTRIBUTEVALUE:
             let idsCreate = [];
             Object.values(action.productAttributeValue).map((productAttributeValueCreate) => {
                 if (productAttributeValueCreate && productAttributeValueCreate.id)
                     idsCreate.push(productAttributeValueCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productAttributeValue,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTATTRIBUTEVALUE:
            let datoProductAttributeValueNuevo = {};
            if (Object.values(action.productAttributeValue.entities.productsAttributesValues).length > 0)
                datoProductAttributeValueNuevo = Object.values(action.productAttributeValue.entities.productsAttributesValues)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductAttributeValueNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTSATTRIBUTESVALUES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTATTRIBUTEVALUE:
           let datoProductAttributeValueDelete = action.productAttributeValue;
           let idsDelete = [];
           Object.values(action.productAttributeValue).map((productAttributeValueDelete) => {
               if (productAttributeValueDelete && productAttributeValueDelete.id)
                   idsDelete.push(productAttributeValueDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductAttributeValueDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTATTRIBUTEVALUE:
                  let datoProductAttributeValueDeleteCreate = action.productAttributeValue;
                  let idsDeleteCreate = [];
                  Object.values(action.productAttributeValue).map((productAttributeValueDelete) => {
                      if (productAttributeValueDelete && productAttributeValueDelete.id)
                          idsDeleteCreate.push(productAttributeValueDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductAttributeValueDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTATTRIBUTEVALUE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productAttributeValue,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTATTRIBUTEVALUE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productsAttributesValues = combineReducers({
    byId: productsAttributesValuesById,
    allIds: allProductsAttributesValues,
    update: update,
    create: create,
    totalProductsAttributesValues: totalProductsAttributesValues,
    delete: deleter,
    print: print
});

export default productsAttributesValues;