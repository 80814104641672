import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
//IMG
import logo from '../../../assets/img/wiener-logo.png';
import { Hidden } from "@material-ui/core";
//Actions
import { logout } from "../../../actions/AuthActions";
import {useHistory} from "react-router";


const useStyles = makeStyles(theme => ({
    grow: {
        position: 'fixed',
        width: '100%',
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            position: "relative"
        },
    },
    menuButton: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    sectionDesktop: {
        marginLeft: 'auto',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    hidden: {
        display: 'none'
    },
    appBar: {
        backgroundColor: theme.palette.type === "dark" ? "rgba(0,0,0,0.74)" : "rgba(255,255,255,0.58)",
        backdropFilter: "blur(6px)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    // appBarShift: {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // title: {
    //     flexGrow: 1,
    // },
}));

export default function NavSup(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const auth = useSelector(state => state.auth);


    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }

    function handleMenuClose() {
        setAnchorEl(null);
        handleMobileMenuClose();
    }

    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => {
                history.push("/users/" + auth.usuarioLogueado.idUser );
                handleMenuClose()
            }}>Mi cuenta</MenuItem>
            <MenuItem onClick={() => dispatch(logout())}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => {
                history.push("/users/" + auth.usuarioLogueado.idUser );
                handleMenuClose()
            }}>Mi cuenta</MenuItem>
            <MenuItem onClick={() => dispatch(logout())}>Cerrar sesión</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.open,
                })}
            >
                <Toolbar>
                    <Hidden smUp>
                        <Link to="/" className={classes.menuButton} onClick={() => props.handleDrawerOpen()}>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="primary"
                                aria-label="open drawer"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Link>
                    </Hidden>
                    <Link to="/" className={classes.menuButton}>
                        <img style={{width: "120px", marginRight: '10px'}} src={logo} alt=""/>
                    </Link>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="primary"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="primary"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}
