import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography} from '@material-ui/core';
import clsx from "clsx";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
//Icons
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clone from "lodash/clone";
import union from "lodash/union";
import {useDispatch} from "react-redux";
//Img


const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    containerFileInput: {
        position: "relative",
        border: 0,
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
        "& legend": {
            position: "absolute",
            bottom: "-22px",
            right: "5px",
        }
    },
    fileInput: {
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        },
    },
    file: {
        width: "100%",
        height: "56px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
        }
    },
    progress: {
        width: "30px !important",
        height: "30px !important",
        position: "absolute",
    }
}));


export default function FileType(props) {
    const classes = styles();
    const dispatch = useDispatch();
    let textFile = React.useRef();
    let inputfile = React.createRef();
    const {onChange, language, assetLanguage, files, setFiles, changeAssetLanguage, file, deleteAssetLanguage, downloadFileAssetLanguage, previewFileAssetLanguage, acceptTypes, deleteFile} = props;
    //Hooks
    //State
    const [loading, setLoading] = React.useState(false);
    const [filename, setFileName] = React.useState("Subir archivo");

    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['clean']
        ],
    };

    let formats = [
        'bold', 'italic', 'underline', 'strike',
    ];

    //Hago la preview de las fotos cargadas del backend
    useEffect(() => {
        console.log(file)
            if (file)
                previewFile(file);
            else
                setFileName("Subir archivo");
        }, [file]
    )

    const previewFile = (file, type) => {
        setFileName(file.name);
    }

    const handleChangeFileAssetLanguage = (e) => {
        e.preventDefault();
        //Preview
        let fotos = [];
        setLoading(true);
        for (let i = 0; i < inputfile.current.files.length; i++) {
            let filename = inputfile.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(inputfile.current.files[i], 'name', {
                writable: true,
                value: filename
            });
            fotos.push(inputfile.current.files[i]);
        }
        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        for (let i = 0; i < inputfile.current.files.length; i++) {
            let fileSubido = inputfile.current.files[i];

            let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                setLoading(false);
                setFileName(filename);
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
            let id = assetLanguage ? assetLanguage.id : Date.now() + Math.random() + "-";
            let cambio = assetLanguage ? clone(assetLanguage) : {};
            cambio.filename = filename;
            changeAssetLanguage(cambio, id, language.id);
        }
    };



    return (
        <Box component={"div"} className={classes.form}>
            <Typography
                variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <TextField
                id="text1"
                placeholder="Describa el contenido del archivo"
                variant="outlined"
                label={"Descripción"}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // disabled: disabled
                }}
                fullWidth
                style={{margin: "12px 0 8px 0"}}
                value={assetLanguage ? assetLanguage.text1 : ""}
                onChange={(e) => onChange(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                // onKeyDown={(e) => handleKeyDown(e)}
            />
            <Box component={"fieldset"} className={classes.containerFileInput}>
                <Box component={"div"} className={clsx([classes.fileInput, classes.file])}>
                    <Button className={classes.buttonUpload}>
                        <label htmlFor={"file" + language.id}
                               className={classes.labelButton}
                            // ref={instance => labelRef.current = instance}
                        >
                            <Box component={"div"} position={"relative"} display={"flex"} alignItems={"center"}
                                 justifyContent={"center"} margin={"0 6px"}>
                                <CircularProgress className={classes.progress}
                                                  style={{display: !loading ? "none" : "inherit"}}/>
                                <AttachFileIcon color={"primary"}/>
                            </Box>
                            <Typography variant={"caption"} color={"primary"}>
                                {filename}
                            </Typography>
                        </label>
                        {
                            !!file &&
                            <Box display={"flex"} flexDirection={"row"} width={'130px'} alignItems={"center"}>

                                <IconButton edge="end" aria-label="delete"
                                            disabled={assetLanguage && assetLanguage.status === 0}
                                            onClick={() => deleteAssetLanguage(assetLanguage)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                                <IconButton edge="end"
                                            aria-label="descargar"
                                            disabled={assetLanguage && assetLanguage.status === 0}
                                            onClick={() => downloadFileAssetLanguage(file)}
                                >
                                    <CloudDownloadIcon/>
                                </IconButton>
                                <IconButton edge="end"
                                            aria-label="visualizar"
                                            disabled={assetLanguage && assetLanguage.status === 0}
                                            onClick={() => previewFileAssetLanguage(file)}
                                >
                                    <VisibilityIcon/>
                                </IconButton>
                            </Box>
                        }
                        <input ref={inputfile} type="file" name="file" id={"file" + language.id} className="inputfile"
                               accept={acceptTypes}
                               onChange={(e) => handleChangeFileAssetLanguage(e)}
                        />
                    </Button>
                </Box>
                <legend>
                    <Typography variant={"caption"}>
                        <i>{props.legend}</i>
                    </Typography>
                </legend>
            </Box>
        </Box>
    );
}

FileType.propTypes = {
    legend: PropTypes.string,
}