import {resetAttributesLanguages, resetCreateAttributeLanguage, resetUpdateAttributeLanguage, resetDeleteAttributeLanguage} from "../actions/AttributeLanguageActions";
import {resetProducts, resetCreateProduct, resetUpdateProduct, resetDeleteProduct} from "../actions/ProductActions";

var resetAttributeLanguage = {
    resetAll(dispatch) {
        dispatch(resetAttributesLanguages());
        dispatch(resetCreateAttributeLanguage());
        dispatch(resetUpdateAttributeLanguage());
        dispatch(resetDeleteAttributeLanguage());
       dispatch(resetProducts());
dispatch(resetCreateProduct());
dispatch(resetUpdateProduct());
dispatch(resetDeleteProduct());
    },
};

export default resetAttributeLanguage;