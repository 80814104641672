import {normalize, schema, denormalize} from 'normalizr';

const productCountry = new schema.Entity('productsCountries', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {productsCountries: [productCountry]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = productCountry;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = productCountry;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default productCountry;
