import {
    INVALIDATE_PRODUCTS,
    ERROR_PRODUCTS,
    RECEIVE_PRODUCTS,
    REQUEST_PRODUCTS,
    RESET_PRODUCTS,
    ERROR_PRODUCT,
    RECEIVE_PRODUCT,
    REQUEST_PRODUCT,
    UPDATE_PRODUCT,
    REQUEST_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    ERROR_UPDATE_PRODUCT,
    RESET_UPDATE_PRODUCT,
    REQUEST_UPDATE_PRODUCTS,
    SUCCESS_UPDATE_PRODUCTS,
    ERROR_UPDATE_PRODUCTS,
    RESET_UPDATE_PRODUCTS,
    CREATE_PRODUCT,
    ERROR_CREATE_PRODUCT,
    REQUEST_CREATE_PRODUCT,
    RESET_CREATE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    REQUEST_CREATE_PRODUCTS,
    SUCCESS_CREATE_PRODUCTS,
    ERROR_CREATE_PRODUCTS,
    RESET_CREATE_PRODUCTS,
    DELETE_PRODUCT,
    DELETE_CREATE_PRODUCT,
    DELETE_UPDATE_PRODUCT,
    REQUEST_DELETE_PRODUCT,
    SUCCESS_DELETE_PRODUCT,
    ERROR_DELETE_PRODUCT,
    RESET_DELETE_PRODUCT,
    REQUEST_PRINT_PRODUCT,
    SUCCESS_PRINT_PRODUCT,
    ERROR_PRINT_PRODUCT,
    RESET_PRINT_PRODUCT,
    RECEIVE_FILE_PRODUCT
} from '../actions/ProductActions';
import {
    CREATE_PRODUCTLANGUAGE,
    UPDATE_PRODUCTLANGUAGE,
    DELETE_PRODUCTLANGUAGE
} from '../actions/ProductLanguageActions';
import {
    CREATE_PRODUCTCOUNTRY,
    UPDATE_PRODUCTCOUNTRY,
    DELETE_PRODUCTCOUNTRY
} from '../actions/ProductCountryActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';
import {
    RECEIVE_ASSOCIATEDPRODUCT,
    RECEIVE_ASSOCIATEDPRODUCTS,
    SUCCESS_DELETE_ASSOCIATEDPRODUCT,
    SUCCESS_CREATE_ASSOCIATEDPRODUCT,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCT,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCTS

} from '../actions/AssociatedProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        products: {},
        files: {},
    }
}

function productsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                products: {}
            });
        case RECEIVE_PRODUCTS:
            let dato = action.products.entities.products;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                products: merge({}, state.products, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCT:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCT:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCT:
            let datoProduct = action.product.entities.products;
            return Object.assign({}, state, {
                products: merge({}, state.products, datoProduct),
                isFetching: false,
            });
        case RECEIVE_FILE_PRODUCT:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_PRODUCT:
            let datoProductEliminado = action.product.entities.products;
            return Object.assign({}, state, {
                products: mergeWith(clone(datoProductEliminado), state.products, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCT:
            let datoProductCreado = action.product.entities.products;
            return Object.assign({}, state, {
                products: mergeWith(clone(datoProductCreado), state.products, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTS:
            let datosProductCreado = action.products.entities.products;
                return Object.assign({}, state, {
                    products: mergeWith(clone(datosProductCreado), state.products, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCT:
            let datoProductActualizado = action.product.entities.products;
            return Object.assign({}, state, {
                products: mergeWith(clone(datoProductActualizado), state.products, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTS:
            let datosProductActualizado = action.products.entities.products;
                return Object.assign({}, state, {
                    products: mergeWith(clone(datosProductActualizado), state.products, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : {};
    return Object.assign({}, state, {
        products: merge({}, state.products, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.products ? action.assets.entities.products : {};
    return Object.assign({}, state, {
        products: merge({}, state.products, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassetEliminado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassetCreado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassetActualizado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.products ? action.assets.entities.products : {} ;
        return Object.assign({}, state, {
            products: mergeWith(clone(datosassetActualizado), state.products, (objValue, srcValue) => {return objValue;})
        });
//ASSOCIATEDPRODUCT
case RECEIVE_ASSOCIATEDPRODUCT:
    let associatedProduct = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : {};
    return Object.assign({}, state, {
        products: merge({}, state.products, associatedProduct),
    });
case RECEIVE_ASSOCIATEDPRODUCTS:
    let associatedProducts = action.associatedProducts.entities && action.associatedProducts.entities.products ? action.associatedProducts.entities.products : {};
    return Object.assign({}, state, {
        products: merge({}, state.products, associatedProducts),
    });
case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
    let datoassociatedProductEliminado = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassociatedProductEliminado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
    let datoassociatedProductCreado = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassociatedProductCreado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
    let datoassociatedProductActualizado = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : {};
    return Object.assign({}, state, {
        products: mergeWith(clone(datoassociatedProductActualizado), pickBy(state.products, function(product) {return product.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
    let datosassociatedProductActualizado = action.associatedProducts.entities && action.associatedProducts.entities.products ? action.associatedProducts.entities.products : {} ;
        return Object.assign({}, state, {
            products: mergeWith(clone(datosassociatedProductActualizado), state.products, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                products: {}
            });
        default:
            return state
    }
}


function allProducts(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return action.products.result && action.products.result.products ? union(action.products.result.products, state) : (action.products.result ? action.products.result : state) ;
        case RECEIVE_PRODUCT:
                return action.product.result ? union([action.product.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCT:
                   let datoProductSCreate = action.product.entities.products;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductSCreate)[0] && Object.values(datoProductSCreate)[0].id ? Object.values(datoProductSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTS:
                   let productsCreate = action.products.entities && action.products.entities.products ? action.products.entities.products : null;
                   return productsCreate ?
                       union(state, Object.values(productsCreate).map((products) => {
                           return products.id
                       })) : state;
        case RESET_PRODUCTS:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.products ? action.assets.entities.products : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.products ? action.asset.entities.products : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.products ? action.assets.entities.products : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;case RECEIVE_ASSOCIATEDPRODUCT:
    let associatedProduct = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : null ;
    return associatedProduct ?
        union(state, Object.values(associatedProduct).map((associatedProduct) => {
            return associatedProduct.id
        })) : state;
case RECEIVE_ASSOCIATEDPRODUCTS:
    let associatedProducts = action.associatedProducts.entities && action.associatedProducts.entities.products ? action.associatedProducts.entities.products : null;
    return associatedProducts ?
        union(state, Object.values(associatedProducts).map((associatedProducts) => {
            return associatedProducts.id
        })) : state;

case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
    let associatedProductDelete = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : null ;
        return associatedProductDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductDelete).map((associatedProduct) => {
                return associatedProduct.id
            })) : state;
case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
   let associatedProductCreate = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : null ;
       return associatedProductCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductCreate).map((associatedProduct) => {
               return associatedProduct.id
           })) : state;
case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
    let associatedProductUpdate = action.associatedProduct.entities && action.associatedProduct.entities.products ? action.associatedProduct.entities.products : null ;
        return associatedProductUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductUpdate).map((associatedProduct) => {
                return associatedProduct.id
            })) : state;
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
     let associatedProductsUpdate = action.associatedProducts.entities && action.associatedProducts.entities.products ? action.associatedProducts.entities.products : null;
        return associatedProductsUpdate ?
            union(state, Object.values(associatedProductsUpdate).map((associatedProducts) => {
                return associatedProducts.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProducts(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return action.products && action.products.result.total ? action.products.result.total : 0;
        case RESET_PRODUCTS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCT:
            let dato = action.product.entities.products;
            let product = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: product ? product : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCT:
            let idsUpdate = [];
            Object.values(action.product).map((productUpdate) => {
                if (productUpdate && productUpdate.id)
                    idsUpdate.push(productUpdate.id);
            });
            return merge({}, state, {
                activo: action.product,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCT:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCT:
            let datoProductActualizado = {};
            if (Object.values(action.product.entities.products).length > 0)
                datoProductActualizado = Object.values(action.product.entities.products)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductActualizado
            });
        case ERROR_UPDATE_PRODUCT:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCT:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_PRODUCTLANGUAGE:
    let productproductLanguageCreateActivo = clone(state.activo);
    let productproductLanguageCreateActivos = clone(state.activos);
    Object.values(action.productLanguage).map((productLanguageCreate) => {
        if(productLanguageCreate && productLanguageCreate.idProduct && productproductLanguageCreateActivo[productLanguageCreate.idProduct]){
            if(productLanguageCreate.idProduct.toString().indexOf("-") === -1)
                productproductLanguageCreateActivo[productLanguageCreate.idProduct].product_language = union(productproductLanguageCreateActivo.product_language, [productLanguageCreate.id]);
        } else if (productLanguageCreate) {
            productproductLanguageCreateActivo.product_language = union(productproductLanguageCreateActivo.product_language ? productproductLanguageCreateActivo.product_language : [], [productLanguageCreate.id]);
        }
        if(productLanguageCreate && productLanguageCreate.idProduct && productLanguageCreate.idProduct.toString().indexOf("-") === -1)
            productproductLanguageCreateActivos = union(productproductLanguageCreateActivos, [productLanguageCreate.idProduct]);
    });
    return Object.assign({}, state, {
        activo: productproductLanguageCreateActivo,
        activos: productproductLanguageCreateActivos
    });
case UPDATE_PRODUCTLANGUAGE:
    let productproductLanguageUpdateActivo = clone(state.activo);
        let productproductLanguageUpdateActivos = clone(state.activos);
        Object.values(action.productLanguage).map((productLanguageUpdate) => {
            if(productLanguageUpdate && productLanguageUpdate.idProduct && productproductLanguageUpdateActivo[productLanguageUpdate.idProduct]){
                if(productLanguageUpdate.idProduct.toString().indexOf("-") === -1)
                    productproductLanguageUpdateActivo[productLanguageUpdate.idProduct].product_language = union(productproductLanguageUpdateActivo.product_language, [productLanguageUpdate.id]);
            } else if (productLanguageUpdate) {
                productproductLanguageUpdateActivo.product_language = union(productproductLanguageUpdateActivo.product_language ? productproductLanguageUpdateActivo.product_language : [], [productLanguageUpdate.id]);
            }
            if(productLanguageUpdate && productLanguageUpdate.idProduct && productLanguageUpdate.idProduct.toString().indexOf("-") === -1)
                productproductLanguageUpdateActivos = union(productproductLanguageUpdateActivos, [productLanguageUpdate.idProduct]);
        });
        return Object.assign({}, state, {
            activo: productproductLanguageUpdateActivo,
            activos: productproductLanguageUpdateActivos
        });
case DELETE_PRODUCTLANGUAGE:
    let productproductLanguageDeleteActivo = clone(state.activo);
    let productproductLanguageDeleteActivos = clone(state.activos);
    Object.values(action.productLanguage).map((productLanguageDelete) => {
        if(productLanguageDelete && productLanguageDelete.idProduct && productproductLanguageDeleteActivo[productLanguageDelete.idProduct]){
            if(productLanguageDelete.idProduct.toString().indexOf("-") === -1)
                productproductLanguageDeleteActivo[productLanguageDelete.idProduct].product_language = difference(productproductLanguageDeleteActivo.product_language, [productLanguageDelete.id]);
        } else if (productLanguageDelete) {
            productproductLanguageDeleteActivo.product_language = difference(productproductLanguageDeleteActivo.product_language ? productproductLanguageDeleteActivo.product_language : [], [productLanguageDelete.id]);
        }
        if(productLanguageDelete && productLanguageDelete.idProduct && productLanguageDelete.idProduct.toString().indexOf("-") === -1)
            productproductLanguageDeleteActivos = union(productproductLanguageDeleteActivos, [productLanguageDelete.idProduct]);
    });
    return Object.assign({}, state, {
        activo: productproductLanguageDeleteActivo,
        activos: productproductLanguageDeleteActivos
    });case CREATE_PRODUCTCOUNTRY:
    let productproductCountryCreateActivo = clone(state.activo);
    let productproductCountryCreateActivos = clone(state.activos);
    Object.values(action.productCountry).map((productCountryCreate) => {
        if(productCountryCreate && productCountryCreate.idProduct && productproductCountryCreateActivo[productCountryCreate.idProduct]){
            if(productCountryCreate.idProduct.toString().indexOf("-") === -1)
                productproductCountryCreateActivo[productCountryCreate.idProduct].product_country = union(productproductCountryCreateActivo.product_country, [productCountryCreate.id]);
        } else if (productCountryCreate) {
            productproductCountryCreateActivo.product_country = union(productproductCountryCreateActivo.product_country ? productproductCountryCreateActivo.product_country : [], [productCountryCreate.id]);
        }
        if(productCountryCreate && productCountryCreate.idProduct && productCountryCreate.idProduct.toString().indexOf("-") === -1)
            productproductCountryCreateActivos = union(productproductCountryCreateActivos, [productCountryCreate.idProduct]);
    });
    return Object.assign({}, state, {
        activo: productproductCountryCreateActivo,
        activos: productproductCountryCreateActivos
    });
case UPDATE_PRODUCTCOUNTRY:
    let productproductCountryUpdateActivo = clone(state.activo);
        let productproductCountryUpdateActivos = clone(state.activos);
        Object.values(action.productCountry).map((productCountryUpdate) => {
            if(productCountryUpdate && productCountryUpdate.idProduct && productproductCountryUpdateActivo[productCountryUpdate.idProduct]){
                if(productCountryUpdate.idProduct.toString().indexOf("-") === -1)
                    productproductCountryUpdateActivo[productCountryUpdate.idProduct].product_country = union(productproductCountryUpdateActivo.product_country, [productCountryUpdate.id]);
            } else if (productCountryUpdate) {
                productproductCountryUpdateActivo.product_country = union(productproductCountryUpdateActivo.product_country ? productproductCountryUpdateActivo.product_country : [], [productCountryUpdate.id]);
            }
            if(productCountryUpdate && productCountryUpdate.idProduct && productCountryUpdate.idProduct.toString().indexOf("-") === -1)
                productproductCountryUpdateActivos = union(productproductCountryUpdateActivos, [productCountryUpdate.idProduct]);
        });
        return Object.assign({}, state, {
            activo: productproductCountryUpdateActivo,
            activos: productproductCountryUpdateActivos
        });
case DELETE_PRODUCTCOUNTRY:
    let productproductCountryDeleteActivo = clone(state.activo);
    let productproductCountryDeleteActivos = clone(state.activos);
    Object.values(action.productCountry).map((productCountryDelete) => {
        if(productCountryDelete && productCountryDelete.idProduct && productproductCountryDeleteActivo[productCountryDelete.idProduct]){
            if(productCountryDelete.idProduct.toString().indexOf("-") === -1)
                productproductCountryDeleteActivo[productCountryDelete.idProduct].product_country = difference(productproductCountryDeleteActivo.product_country, [productCountryDelete.id]);
        } else if (productCountryDelete) {
            productproductCountryDeleteActivo.product_country = difference(productproductCountryDeleteActivo.product_country ? productproductCountryDeleteActivo.product_country : [], [productCountryDelete.id]);
        }
        if(productCountryDelete && productCountryDelete.idProduct && productCountryDelete.idProduct.toString().indexOf("-") === -1)
            productproductCountryDeleteActivos = union(productproductCountryDeleteActivos, [productCountryDelete.idProduct]);
    });
    return Object.assign({}, state, {
        activo: productproductCountryDeleteActivo,
        activos: productproductCountryDeleteActivos
    });

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });//ASSOCIATEDPRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_PRODUCT:
            let datoProductDelete = action.product;
            let idsDelete = [];
           Object.values(action.product).map((productDelete) => {
               if (productDelete && productDelete.id)
                   idsDelete.push(productDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCT:
                       let datoProductDeleteUpdate = action.product;
                       let idsDeleteUpdate = [];
                      Object.values(action.product).map((productDelete) => {
                          if (productDelete && productDelete.id)
                              idsDeleteUpdate.push(productDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCT:
                    let datoProductDeleted = {};
                    if (Object.values(action.product.entities.products).length > 0)
                        datoProductDeleted = Object.values(action.product.entities.products)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCT:
             let idsCreate = [];
             Object.values(action.product).map((productCreate) => {
                 if (productCreate && productCreate.id)
                     idsCreate.push(productCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.product,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCT:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCT:
            let datoProductNuevo = {};
            if (Object.values(action.product.entities.products).length > 0)
                datoProductNuevo = Object.values(action.product.entities.products)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCT:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCT:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE PRODUCTLANGUAGE
case CREATE_PRODUCTLANGUAGE:
    let productproductLanguageCreateActivo = clone(state.nuevo);
    Object.values(action.productLanguage).map((productLanguageCreate) => {
        if(productLanguageCreate && productLanguageCreate.idProduct && productproductLanguageCreateActivo[productLanguageCreate.idProduct]){
            if(productLanguageCreate.idProduct.toString().indexOf("-") !== -1)
                productproductLanguageCreateActivo[productLanguageCreate.idProduct].product_language = union(productproductLanguageCreateActivo.product_language, [productLanguageCreate.id]);
        } else if (productLanguageCreate) {
            productproductLanguageCreateActivo.product_language = union(productproductLanguageCreateActivo.product_language ? productproductLanguageCreateActivo.product_language : [], [productLanguageCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductLanguageCreateActivo,
        //nuevos: productLanguageCreate && productLanguageCreate.idProduct ? union(state.nuevos, [productLanguageCreate.idProduct]) : state.nuevos,
    });
case UPDATE_PRODUCTLANGUAGE:
    let productproductLanguageUpdateActivo = clone(state.nuevo);
    Object.values(action.productLanguage).map((productLanguageUpdate) => {
        if(productLanguageUpdate && productLanguageUpdate.idProduct && productproductLanguageUpdateActivo[productLanguageUpdate.idProduct]){
            if(productLanguageUpdate.idProduct.toString().indexOf("-") !== -1)
                productproductLanguageUpdateActivo[productLanguageUpdate.idProduct].product_language = union(productproductLanguageUpdateActivo.product_language, [productLanguageUpdate.id]);
        } else if(productLanguageUpdate){
            productproductLanguageUpdateActivo.product_language = union(productproductLanguageUpdateActivo.product_language ? productproductLanguageUpdateActivo.product_language : [], [productLanguageUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductLanguageUpdateActivo,
        //nuevos: productLanguageUpdate && productLanguageUpdate.idProduct ? union(state.nuevos, [productLanguageUpdate.idProduct]) : state.nuevos,
    });
case DELETE_PRODUCTLANGUAGE:
    let productproductLanguageDeleteActivo = clone(state.nuevo);
    Object.values(action.productLanguage).map((productLanguageDelete) => {
        if(productLanguageDelete && productLanguageDelete.idProduct && productLanguageDelete.idProduct && productproductLanguageDeleteActivo[productLanguageDelete.idProduct]){
            if(productLanguageDelete.idProduct.toString().indexOf("-") !== -1)
                productproductLanguageDeleteActivo[productLanguageDelete.idProduct].product_language = difference(productproductLanguageDeleteActivo.product_language, [productLanguageDelete.id]);
        } else if (productLanguageDelete) {
            productproductLanguageDeleteActivo.product_language = difference(productproductLanguageDeleteActivo.product_language ? productproductLanguageDeleteActivo.product_language : [], [productLanguageDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductLanguageDeleteActivo,
        //nuevos: productLanguageDelete && productLanguageDelete.idProduct ? union(state.nuevos, [productLanguageDelete.idProduct]) : state.nuevos,
    });//CREATE PRODUCTCOUNTRY
case CREATE_PRODUCTCOUNTRY:
    let productproductCountryCreateActivo = clone(state.nuevo);
    Object.values(action.productCountry).map((productCountryCreate) => {
        if(productCountryCreate && productCountryCreate.idProduct && productproductCountryCreateActivo[productCountryCreate.idProduct]){
            if(productCountryCreate.idProduct.toString().indexOf("-") !== -1)
                productproductCountryCreateActivo[productCountryCreate.idProduct].product_country = union(productproductCountryCreateActivo.product_country, [productCountryCreate.id]);
        } else if (productCountryCreate) {
            productproductCountryCreateActivo.product_country = union(productproductCountryCreateActivo.product_country ? productproductCountryCreateActivo.product_country : [], [productCountryCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductCountryCreateActivo,
        //nuevos: productCountryCreate && productCountryCreate.idProduct ? union(state.nuevos, [productCountryCreate.idProduct]) : state.nuevos,
    });
case UPDATE_PRODUCTCOUNTRY:
    let productproductCountryUpdateActivo = clone(state.nuevo);
    Object.values(action.productCountry).map((productCountryUpdate) => {
        if(productCountryUpdate && productCountryUpdate.idProduct && productproductCountryUpdateActivo[productCountryUpdate.idProduct]){
            if(productCountryUpdate.idProduct.toString().indexOf("-") !== -1)
                productproductCountryUpdateActivo[productCountryUpdate.idProduct].product_country = union(productproductCountryUpdateActivo.product_country, [productCountryUpdate.id]);
        } else if(productCountryUpdate){
            productproductCountryUpdateActivo.product_country = union(productproductCountryUpdateActivo.product_country ? productproductCountryUpdateActivo.product_country : [], [productCountryUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductCountryUpdateActivo,
        //nuevos: productCountryUpdate && productCountryUpdate.idProduct ? union(state.nuevos, [productCountryUpdate.idProduct]) : state.nuevos,
    });
case DELETE_PRODUCTCOUNTRY:
    let productproductCountryDeleteActivo = clone(state.nuevo);
    Object.values(action.productCountry).map((productCountryDelete) => {
        if(productCountryDelete && productCountryDelete.idProduct && productCountryDelete.idProduct && productproductCountryDeleteActivo[productCountryDelete.idProduct]){
            if(productCountryDelete.idProduct.toString().indexOf("-") !== -1)
                productproductCountryDeleteActivo[productCountryDelete.idProduct].product_country = difference(productproductCountryDeleteActivo.product_country, [productCountryDelete.id]);
        } else if (productCountryDelete) {
            productproductCountryDeleteActivo.product_country = difference(productproductCountryDeleteActivo.product_country ? productproductCountryDeleteActivo.product_country : [], [productCountryDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: productproductCountryDeleteActivo,
        //nuevos: productCountryDelete && productCountryDelete.idProduct ? union(state.nuevos, [productCountryDelete.idProduct]) : state.nuevos,
    });

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//ASSOCIATEDPRODUCT
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCT:
           let datoProductDelete = action.product;
           let idsDelete = [];
           Object.values(action.product).map((productDelete) => {
               if (productDelete && productDelete.id)
                   idsDelete.push(productDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCT:
                  let datoProductDeleteCreate = action.product;
                  let idsDeleteCreate = [];
                  Object.values(action.product).map((productDelete) => {
                      if (productDelete && productDelete.id)
                          idsDeleteCreate.push(productDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCT:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.product,
                error: null,
            });
        case REQUEST_DELETE_PRODUCT:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });//ASSOCIATEDPRODUCT
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCT:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCT:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCT:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const products = combineReducers({
    byId: productsById,
    allIds: allProducts,
    update: update,
    create: create,
    totalProducts: totalProducts,
    delete: deleter,
    print: print
});

export default products;