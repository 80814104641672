import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import YoutubePlayer from 'youtube-player';
import Player from '@vimeo/player';
//Components
import {Box, Button, IconButton, TextField, Typography} from '@material-ui/core';
//Icons
import DeleteIcon from '@material-ui/icons/Delete';
//Img


const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "100px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        width: "100%",
        maxWidth: "200px",
        height: "120px",
        paddingLeft: "6px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        "& svg circle": {
            strokeWidth: "1.2",
        }
    },
    videoContainer: {
        width: '394px',
        height: '240px',
        marginRight: "20px",
        marginBottom: "20px",
        "& iframe": {
            width: '100%',
            height: '100%',
        },
    },
    playerContainer: {
        width: '100%',
        height: '100%',
    },
    url: {
        "& button": {
            marginLeft: "10px",
            '@media (max-width: 960px)': {
                marginLeft: "0px",
            },
        }
    }
}));


export default function VideoType(props) {
    const classes = styles();
    const {
        onChange,
        language,
        assetLanguage,
        deleteAssetLanguage
    } = props;
    const player = useRef(null)
    const playerVimeo = useRef(null)
    const divplayer = useRef(null)
    const vimeoplayer = useRef(null)
    const [validate, setValidate] = React.useState(null);
    const mount = useRef(false);

    useEffect(() => {
        if (assetLanguage?.urlAction && !mount.current) {
            validateVideo();
            mount.current = true;
        }
    }, [assetLanguage])

    const validateVideo = () => {
        let link = assetLanguage.urlAction;
        playerVimeo.current?.unload();
        player.current?.stopVideo();
        setValidate(true);
        if (link && link.indexOf("youtu") !== -1) {
            if (link.indexOf("embed") === -1) {
                let array = link.split("https://youtu.be/");
                link = array[1];
            }
            //Inicializar reproductor
            if (!player.current)
                player.current = YoutubePlayer('player-' + language.id, {
                    playerVars: {
                        'autoplay': 0,
                        'controls': 1,
                        'autohide': 1,
                    },
                    videoId: link
                });
            player.current.loadVideoById(link);
            player.current?.stopVideo();
        } else if (link && link.indexOf("vimeo") !== -1) {
            if (link.indexOf("player.vimeo.com/video") === -1) {
                let array = link.split("vimeo.com/");
                link = array[array.length - 1];
            }
            if (!playerVimeo.current)
                playerVimeo.current = new Player('playervimeo-' + language.id, {
                    id: link,
                });
            playerVimeo.current.loadVideo(link);
        } else {
            setValidate(false);
        }
    }

    const handleChangeUrl = (e) => {
        setValidate(false);
        onChange(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)
    }

    return (
        <Box component={"div"} className={classes.form}>
            <Typography
                variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <TextField
                id="text1"
                placeholder="Descripción del link"
                variant="outlined"
                label={"Descripción del link"}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // disabled: disabled
                }}
                fullWidth
                style={{margin: "12px 0 8px 0"}}
                value={assetLanguage ? assetLanguage.text1 : ""}
                onChange={(e) => onChange(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                // onKeyDown={(e) => handleKeyDown(e)}
            />
            <Box component={"div"} position={'relative'} display={'flex'}>
                <TextField
                    id="urlAction"
                    placeholder="URL"
                    variant="outlined"
                    label={"URL"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <Button variant="outlined" color="secondary" onClick={validateVideo}>
                            Validar
                        </Button>,
                    }}
                    fullWidth
                    style={{margin: "12px 0 8px 0"}}
                    value={assetLanguage ? assetLanguage.urlAction : ""}
                    onChange={handleChangeUrl}
                    // onKeyDown={(e) => handleKeyDown(e)}
                    helperText={'Links de YouTube o Vimeo'}
                />

            </Box>
            <Box component={"div"} position={'relative'}
                 className={classes.videoContainer} display={validate ? 'inherit' : 'none'}>
                <Box ref={divplayer} display={assetLanguage?.urlAction?.indexOf("youtu") !== -1 ? 'inherit' : 'none'}
                     className={classes.playerContainer} id={"player-" + language.id}></Box>
                <Box ref={vimeoplayer} display={assetLanguage?.urlAction?.indexOf("vimeo") !== -1 ? 'inherit' : 'none'}
                     className={classes.playerContainer} id={"playervimeo-" + language.id}></Box>
                <Box position={"absolute"} right={'10px'} bottom={'10px'} display={"flex"}>
                    <IconButton size="small" aria-label="close"
                                onClick={() => deleteAssetLanguage(assetLanguage)}
                                color="primary" className={classes.btnImg}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

VideoType.propTypes = {
    legend: PropTypes.string,
}