import {normalize, schema, denormalize} from 'normalizr';
import attribute from "./normalizeAttributes";
import productAttributeValueLanguage from "./normalizeProductsAttributesValuesLanguages";

const productAttributeValue = new schema.Entity('productsAttributesValues', {attribute: attribute, product_attribute_value_language: [productAttributeValueLanguage]}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {productsAttributesValues: [productAttributeValue]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = productAttributeValue;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = productAttributeValue;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default productAttributeValue;
