import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import "../../assets/css/PostLanguage.css";
//Components
import {
    AppBar,
    Box,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slide,
    TextField,
    Typography,
    useTheme
} from '@material-ui/core';
import HTMLEditor from "../../library/HTMLEditor";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Icons
import TranslateIcon from '@material-ui/icons/Translate';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import clsx from "clsx";
import clone from "lodash/clone";
import {
    createPostLanguage,
    saveCreatePostsLanguages,
    saveUpdatePostsLanguages,
    updatePostLanguage
} from "../../actions/PostLanguageActions";
import {useDispatch, useSelector} from "react-redux";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ClearIcon from '@material-ui/icons/Clear';
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    btbLang: {
        minWidth: "180px",
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            color: theme.palette.primary.main,
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostLanguage(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = styles();
    const viewport = useRef();
    const {language, postLanguage, idPost, post} = props;
    //Hooks
    //State
    const [open, setOpen] = React.useState(false);
    const [openLanguagesModal, setOpenLanguagesModal] = React.useState(false);

    const postsLanguages = useSelector(state => state.postsLanguages);
    const languages = useSelector(state => state.languages);
    let disabled = props.disabled || postsLanguages.create.isCreating || postsLanguages.update.isUpdating;


    useEffect(() => {
        setTimeout(() => {
            if (viewport.current)
                viewport.current.addEventListener('scroll', handleScroll, false);
        }, 500);

        return function cleanup() {
            if (viewport.current)
                viewport.current.removeEventListener('scroll', handleScroll);
        }

    }, [open])

    const handleScroll = (e) => {
        var navbar = document.getElementsByClassName("ql-toolbar ql-snow");
        var sticky = 205;
        if (sticky && navbar[0])
            if (viewport.current.scrollTop >= sticky) {
                navbar[0].classList.add("sticky")
            } else {
                navbar[0].classList.remove("sticky");
            }
    }

    const handleClickOpenLanguagesModal = () => {
        setOpenLanguagesModal(true);
    };

    const handleCloseLanguagesModal = () => {
        setOpenLanguagesModal(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //Post language
    const handleChangePostLanguage = (e, idPostLanguage, idLanguage) => {
        let id = idPostLanguage ? idPostLanguage : Date.now() + Math.random() + "-";
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changePostLanguage(cambio, id, idLanguage);
    }

    const changePostLanguage = (cambio, idPostLanguage, idLanguage) => {
        //Verificar si ya esta siendo actualizado
        let postLanguage = idPostLanguage && postsLanguages.update.activos.indexOf(idPostLanguage) === -1 && postsLanguages.create.nuevos.indexOf(idPostLanguage) === -1 ? postsLanguages.byId.postsLanguages[idPostLanguage] : null;
        if (postLanguage) {
            let actualizar = postLanguage ? clone(postLanguage) : null;
            if (actualizar) {
                let cambioPostLanguage = {};
                cambioPostLanguage[actualizar.id] = actualizar;
                if (idPostLanguage.toString().indexOf("-") === -1)
                    dispatch(updatePostLanguage(cambioPostLanguage));
                else
                    dispatch(createPostLanguage(cambioPostLanguage));
            }
        }
        cambio.id = idPostLanguage;
        cambio.idPost = idPost;
        cambio.idLanguage = idLanguage;
        let cambioPostLanguage = {};
        cambioPostLanguage[idPostLanguage] = cambio;
        if (idPostLanguage.toString().indexOf("-") === -1)
            dispatch(updatePostLanguage(cambioPostLanguage));
        else
            dispatch(createPostLanguage(cambioPostLanguage));
    };

    const handleChangeTextEditorPostLanguage = (content, delta, source, editor, idField, idPost, idLanguage) => {
        if (source === 'user') {
            let id = idPost ? idPost : Date.now() + Math.random() + "-";
            let cambio = {};
            cambio[idField] = content;
            changePostLanguage(cambio, id, idLanguage);
        }
    }

    const translate = (id) => {
        setTimeout(() => {
        }, 1000);
        if (id.toString().indexOf("-") === -1)
            dispatch(saveUpdatePostsLanguages());
        else
            dispatch(saveCreatePostsLanguages());
    };

    const handleChangeStatusPostLanguage = () => {
        let cambio = {};
        cambio.status = postLanguage.status ? 0 : 1;
        changePostLanguage(cambio, postLanguage.id, language.id);
    }

    function handleChangeLanguageFrom(idLanguageFrom) {
        let postLanguageFrom = null;
        let postLanguageTo = null;
        if (post && post.post_language)
            post.post_language.some((idPostLanguage) => {
                let postLanguage = postsLanguages.byId.postsLanguages[idPostLanguage];
                if (postsLanguages.update.activos.indexOf(idPostLanguage) !== -1)
                    postLanguage = postsLanguages.update.activo[idPostLanguage];
                if (postsLanguages.create.nuevos.indexOf(idPostLanguage) !== -1)
                    postLanguage = postsLanguages.create.nuevo[idPostLanguage];
                if (postLanguage && postLanguage.idLanguage === idLanguageFrom)
                    postLanguageFrom = postLanguage;
                if (postLanguage && postLanguage.idLanguage === language.id)
                    postLanguageTo = postLanguage;
                return !!(postLanguageFrom && postLanguageTo);

            })
        let id = postLanguageTo && postLanguageTo.id ? postLanguageTo.id : Date.now() + Math.random() + "-";
        let cambio = {
            titlePost: postLanguageFrom.titlePost,
            subtitlePost: postLanguageFrom.subtitlePost,
            post: postLanguageFrom.post,
            traslate: 1,
            idLanguage: language.id,
            id: id,
            idLanguageFrom: idLanguageFrom
        };
        changePostLanguage(cambio, id, language.id)
        handleCloseLanguagesModal();
        translate(id);
    }

    return (
        <>
            <Box display={"flex"} alignItems={"center"} alignContent={"center"} style={{marginTop: "27px"}}
                 justifyContent={"space-between"}>
                <ButtonGroup aria-label="outlined primary button group" variant={"outlined"} color="primary">
                    <Button className={clsx(classes.btbLang, {[classes.selected]: !!postLanguage})}
                            onClick={handleClickOpen}
                            disabled={disabled}>
                        {language ? language.unicodeIcon + " " + language.desLanguage : ""}
                    </Button>
                    <Button onClick={handleClickOpenLanguagesModal} disabled={disabled || !post || !post.post_language || post.post_language.length === 0}>
                        <TranslateIcon/>
                    </Button>
                    {!!postLanguage &&
                    <Button color={postLanguage && postLanguage.status === 0 ? "default" : theme.palette.success.main}
                            onClick={handleChangeStatusPostLanguage}
                            startIcon={
                                postLanguage && postLanguage.status === 0 ?
                                    <ClearIcon/>
                                    :
                                    <SpellcheckIcon/>
                            } disabled={disabled}>
                        {
                            postLanguage && postLanguage.status === 0 ?
                                "Pendiente"
                                :
                                "Publicado"
                        }
                    </Button>
                    }
                </ButtonGroup>
                {
                    disabled &&
                    <CircularProgress color="secondary"/>
                }
            </Box>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}
                    className={"postLanguage"}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {language ? language.desLanguage + " " + language.unicodeIcon : ""}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Aceptar
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent id='dialogCon' ref={viewport}>
                    <Grid container style={{padding: "25px 25px 25px"}}>
                        <TextField
                            margin={"normal"}
                            id="titlePost"
                            placeholder="Título"
                            variant="outlined"
                            label={"Título"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disabled: disabled
                            }}
                            fullWidth
                            value={postLanguage ? postLanguage.titlePost : ""}
                            onChange={(e) => handleChangePostLanguage(e, postLanguage ? postLanguage.id : null, language ? language.id : null)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <TextField
                            margin={"normal"}
                            id="subtitlePost"
                            placeholder="Subtítulo"
                            variant="outlined"
                            label={"Subtítulo"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disabled: disabled
                            }}
                            fullWidth
                            value={postLanguage ? postLanguage.subtitlePost : ""}
                            onChange={(e) => handleChangePostLanguage(e, postLanguage ? postLanguage.id : null, language ? language.id : null)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <HTMLEditor textLabel={"Escribe el post"} disabled={disabled}
                                    onChange={(content, delta, source, editor) => handleChangeTextEditorPostLanguage(content, delta, source, editor, "post", postLanguage ? postLanguage.id : null, language ? language.id : null)}
                                    value={postLanguage ? postLanguage.post : ""} rows={"100%"}/>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog onClose={handleCloseLanguagesModal} aria-labelledby="simple-dialog-title" open={openLanguagesModal}>
                <DialogTitle id="simple-dialog-title">{`Traducir al ${language.desLanguage} desde:`}</DialogTitle>
                <List>
                    {
                        post && post.post_language && post.post_language.map((idPostLanguage, i) => {
                            let postLanguage = postsLanguages.byId.postsLanguages[idPostLanguage];
                            if (postsLanguages.update.activos.indexOf(idPostLanguage) !== -1)
                                postLanguage = postsLanguages.update.activo[idPostLanguage];
                            if (postsLanguages.create.nuevos.indexOf(idPostLanguage) !== -1)
                                postLanguage = postsLanguages.create.nuevo[idPostLanguage];
                            let languageDesde = null;
                            if (postLanguage)
                                languageDesde = languages.byId.languages[postLanguage.idLanguage];
                            if (language && languageDesde && language.id !== languageDesde.id)
                                return (<ListItem key={"lid-" + languageDesde.id} button
                                                  onClick={(e) => handleChangeLanguageFrom(languageDesde.id)}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            {languageDesde ? languageDesde.unicodeIcon : null}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={languageDesde ? languageDesde.desLanguage : ""}/>
                                </ListItem>)
                        })
                    }
                </List>
            </Dialog>
        </>
    )
        ;
}

PostLanguage.propTypes = {}