import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import clone from "lodash/clone";
import * as PropTypes from "prop-types";
//Components
import {Box, MenuItem, TextField, Typography} from '@material-ui/core';
import HTMLEditor from "../../library/HTMLEditor";
//Icons
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
}));


export default function ProductCategory(props) {
    const classes = styles();
    const {changeProduct, product} = props;
    //Hooks
    //State
    const [idsCategoriaSeleccionada, setIdsCategoriaSeleccionada] = useState([]);
    const products = useSelector(state => state.products);
    const categories = useSelector(state => state.categories);


    useEffect(() => {
        if (categories.allIds.length > 0 && ((product.idCategory && !idsCategoriaSeleccionada[1]) || (product.idCategory && idsCategoriaSeleccionada[idsCategoriaSeleccionada.length - 1] != product.idCategory))) {
            let categoria = categories.byId.categories[product.idCategory];
            let idsCategoriaSeleccionadaArray = [];
            idsCategoriaSeleccionadaArray.push(product.idCategory);
            while (categoria && categoria.idParentCategory) {
                idsCategoriaSeleccionadaArray.push(categoria.idParentCategory);
                categoria = categories.byId.categories[categoria.idParentCategory];
            }
            idsCategoriaSeleccionadaArray.push(null);
            idsCategoriaSeleccionadaArray.reverse();
            setIdsCategoriaSeleccionada(idsCategoriaSeleccionadaArray);
        }
    }, [product.id, categories.byId.isFetching]);

    const handleChangeCategoria = (e) => {
        let categoriaSeleccionada = categories.byId.categories[e.target.value];
        //let level = parseInt(e.target.name.substring(e.target.name.length - 1));
        let level = categoriaSeleccionada.level;
        let idsCategoriaSeleccionadaArray = clone(idsCategoriaSeleccionada);
        idsCategoriaSeleccionadaArray[level] = e.target.value;
        for (let i = idsCategoriaSeleccionada.length - 1; i > level; i--) {
            idsCategoriaSeleccionadaArray.splice(i, 1);
        }
        setIdsCategoriaSeleccionada(idsCategoriaSeleccionadaArray);
        if (categoriaSeleccionada.children_categories && categoriaSeleccionada.children_categories.length > 0) {
            let cambio = {};
            cambio.idCategory = null;
            changeProduct(cambio);
        } else {
            let cambio = {};
            cambio.idCategory = e.target.value;
            changeProduct(cambio);
        }
    };


    //Categorias
    let Categorias = [];
    let ultimo = false;
    categories.allIds.some((idCategory, i) => {
        let categoria = categories.byId.categories[idCategory];
        if (categoria.level > 1)
            return;
        if (!categoria.children_categories || categoria.children_categories.length == 0)
            ultimo = true;
        if (idsCategoriaSeleccionada && idsCategoriaSeleccionada[1]) {
            let categoriaSeleccionada = categories.byId.categories[idsCategoriaSeleccionada[1]];
            if (!categoriaSeleccionada || !categoriaSeleccionada.children_categories || categoriaSeleccionada.children_categories.length == 0)
                ultimo = true;
            else
                ultimo = false;
        }
        Categorias.push(
            <MenuItem key={categoria.id} value={categoria.id}>
                {categoria.nameCategory}
            </MenuItem>);
    });
    let CategoriaPrincipal = <TextField
        id={!ultimo ? "idCategory1" : "idCategory"}
        select
        label="Seleccione categoria"
        margin="normal"
        fullWidth
        value={!ultimo ? (idsCategoriaSeleccionada && idsCategoriaSeleccionada[1] ? idsCategoriaSeleccionada[1] : "") : (product && product.idCategory ? product.idCategory : "")}
        onChange={(e) => handleChangeCategoria(e)}
        InputProps={!ultimo ? {
            id: "idCategory1", name: "idCategory1"
        } : {
            id: "idCategory", name: "idCategory"
        }}
        // helperText="Please select your currency"
        variant="outlined"
        // onBlur={validar}
        // error={!validador.isValidById(!ultimo ? "idCategory1" : "idCategory")}
        // helperText={validador.getHelperTextById(!ultimo ? "idCategory1" : "idCategory")}
    >
        {Categorias}
    </TextField>;

    let CategoriasHijas = [];
    for (let i = 1; i < idsCategoriaSeleccionada.length; i++) {
        let idCategorySeleccionada = idsCategoriaSeleccionada[i];
        let categoriaSeleccionada = categories.byId.categories[idCategorySeleccionada];
        if (categoriaSeleccionada && categoriaSeleccionada.children_categories && categoriaSeleccionada.children_categories.length > 0) {
            let ultimo = false;
            let opcionesCategoria = categoriaSeleccionada.children_categories.map((idCategory, i) => {
                let categoria = categories.byId.categories[idCategory];
                if (idsCategoriaSeleccionada && idsCategoriaSeleccionada[categoriaSeleccionada.level + 1]) {
                    let categoriaOrden = categories.byId.categories[idsCategoriaSeleccionada[categoriaSeleccionada.level + 1]];
                    if (!categoriaOrden.children_categories || categoriaOrden.children_categories.length == 0)
                        ultimo = true;
                } else if (product.idCategory) {
                    let categoriaOrden = categories.byId.categories[product.idCategory];
                    if (!categoriaOrden.children_categories || categoriaOrden.children_categories.length == 0)
                        ultimo = true;
                }
                return (<MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.nameCategory}
                </MenuItem>);
            });
            if (ultimo)
                CategoriasHijas.push(
                    <TextField key="idCategory"
                               id={"idCategory"}
                               select
                               label="Seleccione sub-categoria"
                               margin="normal"
                               fullWidth
                               value={product && product.idCategory ? product.idCategory : ""}
                               onChange={(e) => handleChangeCategoria(e)}
                               InputProps={{
                                   id: "idCategory",
                                   name: "idCategory"
                               }}
                        // helperText="Please select your currency"
                               variant="outlined"
                        // onBlur={validar}
                        // error={!validador.isValidById("idCategory")}
                        // helperText={validador.getHelperTextById("idCategory")}
                    >
                        {opcionesCategoria}
                    </TextField>
                )
            else
                CategoriasHijas.push(
                    <TextField key={categoriaSeleccionada.level + 1 + "cat"}
                               id={"idCategory" + (categoriaSeleccionada.level + 1)}
                               select
                               label="Seleccione sub-categoria"
                               margin="normal"
                               fullWidth
                               value={idsCategoriaSeleccionada && idsCategoriaSeleccionada[categoriaSeleccionada.level + 1] ? idsCategoriaSeleccionada[categoriaSeleccionada.level + 1] : ""}
                               onChange={(e) => handleChangeCategoria(e)}
                               InputProps={{
                                   id: "idCategory" + (categoriaSeleccionada.level + 1),
                                   name: "idCategory" + (categoriaSeleccionada.level + 1)
                               }}
                        // helperText="Please select your currency"
                               variant="outlined"
                        // onBlur={validar}
                        // error={!validador.isValidById("idCategory" + (categoriaSeleccionada.level + 1))}
                        // helperText={validador.getHelperTextById("idCategory" + (categoriaSeleccionada.level + 1))}
                    >
                        {opcionesCategoria}
                    </TextField>
                )
        }

    }


    return (
        <Box component={"div"} className={classes.form}>
            {CategoriaPrincipal}
            {CategoriasHijas}
        </Box>
    );
}

ProductCategory.propTypes = {}