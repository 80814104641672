import {
    INVALIDATE_POSTS,
    ERROR_POSTS,
    RECEIVE_POSTS,
    REQUEST_POSTS,
    RESET_POSTS,
    ERROR_POST,
    RECEIVE_POST,
    REQUEST_POST,
    UPDATE_POST,
    REQUEST_UPDATE_POST,
    SUCCESS_UPDATE_POST,
    ERROR_UPDATE_POST,
    RESET_UPDATE_POST,
    REQUEST_UPDATE_POSTS,
    SUCCESS_UPDATE_POSTS,
    ERROR_UPDATE_POSTS,
    RESET_UPDATE_POSTS,
    CREATE_POST,
    ERROR_CREATE_POST,
    REQUEST_CREATE_POST,
    RESET_CREATE_POST,
    SUCCESS_CREATE_POST,
    REQUEST_CREATE_POSTS,
    SUCCESS_CREATE_POSTS,
    ERROR_CREATE_POSTS,
    RESET_CREATE_POSTS,
    DELETE_POST,
    DELETE_CREATE_POST,
    DELETE_UPDATE_POST,
    REQUEST_DELETE_POST,
    SUCCESS_DELETE_POST,
    ERROR_DELETE_POST,
    RESET_DELETE_POST,
    REQUEST_PRINT_POST,
    SUCCESS_PRINT_POST,
    ERROR_PRINT_POST,
    RESET_PRINT_POST,
    RECEIVE_FILE_POST
} from '../actions/PostActions';
import {
    CREATE_POSTLANGUAGE,
    UPDATE_POSTLANGUAGE,
    DELETE_POSTLANGUAGE
} from '../actions/PostLanguageActions';
import {
    CREATE_POSTCOUNTRY,
    UPDATE_POSTCOUNTRY,
    DELETE_POSTCOUNTRY
} from '../actions/PostCountryActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';
import {
    RECEIVE_ASSOCIATEDPRODUCT,
    RECEIVE_ASSOCIATEDPRODUCTS,
    SUCCESS_DELETE_ASSOCIATEDPRODUCT,
    SUCCESS_CREATE_ASSOCIATEDPRODUCT,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCT,
    SUCCESS_UPDATE_ASSOCIATEDPRODUCTS

} from '../actions/AssociatedProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        posts: {},
        files: {},
    }
}

function postsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_POSTS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_POSTS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_POSTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_POSTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                posts: {}
            });
        case RECEIVE_POSTS:
            let dato = action.posts.entities.posts;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                posts: merge({}, state.posts, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_POST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_POST:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_POST:
            let datoPost = action.post.entities.posts;
            return Object.assign({}, state, {
                posts: merge({}, state.posts, datoPost),
                isFetching: false,
            });
        case RECEIVE_FILE_POST:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_POST:
            let datoPostEliminado = action.post.entities.posts;
            return Object.assign({}, state, {
                posts: mergeWith(clone(datoPostEliminado), state.posts, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POST:
            let datoPostCreado = action.post.entities.posts;
            return Object.assign({}, state, {
                posts: mergeWith(clone(datoPostCreado), state.posts, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_POSTS:
            let datosPostCreado = action.posts.entities.posts;
                return Object.assign({}, state, {
                    posts: mergeWith(clone(datosPostCreado), state.posts, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_POST:
            let datoPostActualizado = action.post.entities.posts;
            return Object.assign({}, state, {
                posts: mergeWith(clone(datoPostActualizado), state.posts, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_POSTS:
            let datosPostActualizado = action.posts.entities.posts;
                return Object.assign({}, state, {
                    posts: mergeWith(clone(datosPostActualizado), state.posts, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : {};
    return Object.assign({}, state, {
        posts: merge({}, state.posts, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.posts ? action.assets.entities.posts : {};
    return Object.assign({}, state, {
        posts: merge({}, state.posts, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassetEliminado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassetCreado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassetActualizado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.posts ? action.assets.entities.posts : {} ;
        return Object.assign({}, state, {
            posts: mergeWith(clone(datosassetActualizado), state.posts, (objValue, srcValue) => {return objValue;})
        });
//ASSOCIATEDPRODUCT
case RECEIVE_ASSOCIATEDPRODUCT:
    let associatedProduct = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : {};
    return Object.assign({}, state, {
        posts: merge({}, state.posts, associatedProduct),
    });
case RECEIVE_ASSOCIATEDPRODUCTS:
    let associatedProducts = action.associatedProducts.entities && action.associatedProducts.entities.posts ? action.associatedProducts.entities.posts : {};
    return Object.assign({}, state, {
        posts: merge({}, state.posts, associatedProducts),
    });
case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
    let datoassociatedProductEliminado = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassociatedProductEliminado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
    let datoassociatedProductCreado = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassociatedProductCreado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
    let datoassociatedProductActualizado = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : {};
    return Object.assign({}, state, {
        posts: mergeWith(clone(datoassociatedProductActualizado), pickBy(state.posts, function(post) {return post.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
    let datosassociatedProductActualizado = action.associatedProducts.entities && action.associatedProducts.entities.posts ? action.associatedProducts.entities.posts : {} ;
        return Object.assign({}, state, {
            posts: mergeWith(clone(datosassociatedProductActualizado), state.posts, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                posts: {}
            });
        default:
            return state
    }
}


function allPosts(state = [], action) {
    switch (action.type) {
        case RECEIVE_POSTS:
            return action.posts.result && action.posts.result.posts ? union(action.posts.result.posts, state) : (action.posts.result ? action.posts.result : state) ;
        case RECEIVE_POST:
                return action.post.result ? union([action.post.result], state) : state;
        
        case SUCCESS_CREATE_POST:
                   let datoPostSCreate = action.post.entities.posts;
                   let idNuevoSCreate = null;
                   if (Object.values(datoPostSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoPostSCreate)[0] && Object.values(datoPostSCreate)[0].id ? Object.values(datoPostSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_POSTS:
                   let postsCreate = action.posts.entities && action.posts.entities.posts ? action.posts.entities.posts : null;
                   return postsCreate ?
                       union(state, Object.values(postsCreate).map((posts) => {
                           return posts.id
                       })) : state;
        case RESET_POSTS:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.posts ? action.assets.entities.posts : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.posts ? action.asset.entities.posts : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.posts ? action.assets.entities.posts : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;case RECEIVE_ASSOCIATEDPRODUCT:
    let associatedProduct = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : null ;
    return associatedProduct ?
        union(state, Object.values(associatedProduct).map((associatedProduct) => {
            return associatedProduct.id
        })) : state;
case RECEIVE_ASSOCIATEDPRODUCTS:
    let associatedProducts = action.associatedProducts.entities && action.associatedProducts.entities.posts ? action.associatedProducts.entities.posts : null;
    return associatedProducts ?
        union(state, Object.values(associatedProducts).map((associatedProducts) => {
            return associatedProducts.id
        })) : state;

case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
    let associatedProductDelete = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : null ;
        return associatedProductDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductDelete).map((associatedProduct) => {
                return associatedProduct.id
            })) : state;
case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
   let associatedProductCreate = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : null ;
       return associatedProductCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductCreate).map((associatedProduct) => {
               return associatedProduct.id
           })) : state;
case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
    let associatedProductUpdate = action.associatedProduct.entities && action.associatedProduct.entities.posts ? action.associatedProduct.entities.posts : null ;
        return associatedProductUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(associatedProductUpdate).map((associatedProduct) => {
                return associatedProduct.id
            })) : state;
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
     let associatedProductsUpdate = action.associatedProducts.entities && action.associatedProducts.entities.posts ? action.associatedProducts.entities.posts : null;
        return associatedProductsUpdate ?
            union(state, Object.values(associatedProductsUpdate).map((associatedProducts) => {
                return associatedProducts.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPosts(state = null, action) {
    switch (action.type) {
        case RECEIVE_POSTS:
            return action.posts && action.posts.result.total ? action.posts.result.total : 0;
        case RESET_POSTS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_POST:
            let dato = action.post.entities.posts;
            let post = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: post ? post : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_POST:
            let idsUpdate = [];
            Object.values(action.post).map((postUpdate) => {
                if (postUpdate && postUpdate.id)
                    idsUpdate.push(postUpdate.id);
            });
            return merge({}, state, {
                activo: action.post,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_POST:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_POST:
            let datoPostActualizado = {};
            if (Object.values(action.post.entities.posts).length > 0)
                datoPostActualizado = Object.values(action.post.entities.posts)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoPostActualizado
            });
        case ERROR_UPDATE_POST:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_POSTS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_POSTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_POSTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_POST:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_POSTLANGUAGE:
    let postpostLanguageCreateActivo = clone(state.activo);
    let postpostLanguageCreateActivos = clone(state.activos);
    Object.values(action.postLanguage).map((postLanguageCreate) => {
        if(postLanguageCreate && postLanguageCreate.idPost && postpostLanguageCreateActivo[postLanguageCreate.idPost]){
            if(postLanguageCreate.idPost.toString().indexOf("-") === -1)
                postpostLanguageCreateActivo[postLanguageCreate.idPost].post_language = union(postpostLanguageCreateActivo.post_language, [postLanguageCreate.id]);
        } else if (postLanguageCreate) {
            postpostLanguageCreateActivo.post_language = union(postpostLanguageCreateActivo.post_language ? postpostLanguageCreateActivo.post_language : [], [postLanguageCreate.id]);
        }
        if(postLanguageCreate && postLanguageCreate.idPost && postLanguageCreate.idPost.toString().indexOf("-") === -1)
            postpostLanguageCreateActivos = union(postpostLanguageCreateActivos, [postLanguageCreate.idPost]);
    });
    return Object.assign({}, state, {
        activo: postpostLanguageCreateActivo,
        activos: postpostLanguageCreateActivos
    });
case UPDATE_POSTLANGUAGE:
    let postpostLanguageUpdateActivo = clone(state.activo);
        let postpostLanguageUpdateActivos = clone(state.activos);
        Object.values(action.postLanguage).map((postLanguageUpdate) => {
            if(postLanguageUpdate && postLanguageUpdate.idPost && postpostLanguageUpdateActivo[postLanguageUpdate.idPost]){
                if(postLanguageUpdate.idPost.toString().indexOf("-") === -1)
                    postpostLanguageUpdateActivo[postLanguageUpdate.idPost].post_language = union(postpostLanguageUpdateActivo.post_language, [postLanguageUpdate.id]);
            } else if (postLanguageUpdate) {
                postpostLanguageUpdateActivo.post_language = union(postpostLanguageUpdateActivo.post_language ? postpostLanguageUpdateActivo.post_language : [], [postLanguageUpdate.id]);
            }
            if(postLanguageUpdate && postLanguageUpdate.idPost && postLanguageUpdate.idPost.toString().indexOf("-") === -1)
                postpostLanguageUpdateActivos = union(postpostLanguageUpdateActivos, [postLanguageUpdate.idPost]);
        });
        return Object.assign({}, state, {
            activo: postpostLanguageUpdateActivo,
            activos: postpostLanguageUpdateActivos
        });
case DELETE_POSTLANGUAGE:
    let postpostLanguageDeleteActivo = clone(state.activo);
    let postpostLanguageDeleteActivos = clone(state.activos);
    Object.values(action.postLanguage).map((postLanguageDelete) => {
        if(postLanguageDelete && postLanguageDelete.idPost && postpostLanguageDeleteActivo[postLanguageDelete.idPost]){
            if(postLanguageDelete.idPost.toString().indexOf("-") === -1)
                postpostLanguageDeleteActivo[postLanguageDelete.idPost].post_language = difference(postpostLanguageDeleteActivo.post_language, [postLanguageDelete.id]);
        } else if (postLanguageDelete) {
            postpostLanguageDeleteActivo.post_language = difference(postpostLanguageDeleteActivo.post_language ? postpostLanguageDeleteActivo.post_language : [], [postLanguageDelete.id]);
        }
        if(postLanguageDelete && postLanguageDelete.idPost && postLanguageDelete.idPost.toString().indexOf("-") === -1)
            postpostLanguageDeleteActivos = union(postpostLanguageDeleteActivos, [postLanguageDelete.idPost]);
    });
    return Object.assign({}, state, {
        activo: postpostLanguageDeleteActivo,
        activos: postpostLanguageDeleteActivos
    });case CREATE_POSTCOUNTRY:
    let postpostCountryCreateActivo = clone(state.activo);
    let postpostCountryCreateActivos = clone(state.activos);
    Object.values(action.postCountry).map((postCountryCreate) => {
        if(postCountryCreate && postCountryCreate.idPost && postpostCountryCreateActivo[postCountryCreate.idPost]){
            if(postCountryCreate.idPost.toString().indexOf("-") === -1)
                postpostCountryCreateActivo[postCountryCreate.idPost].post_country = union(postpostCountryCreateActivo.post_country, [postCountryCreate.id]);
        } else if (postCountryCreate) {
            postpostCountryCreateActivo.post_country = union(postpostCountryCreateActivo.post_country ? postpostCountryCreateActivo.post_country : [], [postCountryCreate.id]);
        }
        if(postCountryCreate && postCountryCreate.idPost && postCountryCreate.idPost.toString().indexOf("-") === -1)
            postpostCountryCreateActivos = union(postpostCountryCreateActivos, [postCountryCreate.idPost]);
    });
    return Object.assign({}, state, {
        activo: postpostCountryCreateActivo,
        activos: postpostCountryCreateActivos
    });
case UPDATE_POSTCOUNTRY:
    let postpostCountryUpdateActivo = clone(state.activo);
        let postpostCountryUpdateActivos = clone(state.activos);
        Object.values(action.postCountry).map((postCountryUpdate) => {
            if(postCountryUpdate && postCountryUpdate.idPost && postpostCountryUpdateActivo[postCountryUpdate.idPost]){
                if(postCountryUpdate.idPost.toString().indexOf("-") === -1)
                    postpostCountryUpdateActivo[postCountryUpdate.idPost].post_country = union(postpostCountryUpdateActivo.post_country, [postCountryUpdate.id]);
            } else if (postCountryUpdate) {
                postpostCountryUpdateActivo.post_country = union(postpostCountryUpdateActivo.post_country ? postpostCountryUpdateActivo.post_country : [], [postCountryUpdate.id]);
            }
            if(postCountryUpdate && postCountryUpdate.idPost && postCountryUpdate.idPost.toString().indexOf("-") === -1)
                postpostCountryUpdateActivos = union(postpostCountryUpdateActivos, [postCountryUpdate.idPost]);
        });
        return Object.assign({}, state, {
            activo: postpostCountryUpdateActivo,
            activos: postpostCountryUpdateActivos
        });
case DELETE_POSTCOUNTRY:
    let postpostCountryDeleteActivo = clone(state.activo);
    let postpostCountryDeleteActivos = clone(state.activos);
    Object.values(action.postCountry).map((postCountryDelete) => {
        if(postCountryDelete && postCountryDelete.idPost && postpostCountryDeleteActivo[postCountryDelete.idPost]){
            if(postCountryDelete.idPost.toString().indexOf("-") === -1)
                postpostCountryDeleteActivo[postCountryDelete.idPost].post_country = difference(postpostCountryDeleteActivo.post_country, [postCountryDelete.id]);
        } else if (postCountryDelete) {
            postpostCountryDeleteActivo.post_country = difference(postpostCountryDeleteActivo.post_country ? postpostCountryDeleteActivo.post_country : [], [postCountryDelete.id]);
        }
        if(postCountryDelete && postCountryDelete.idPost && postCountryDelete.idPost.toString().indexOf("-") === -1)
            postpostCountryDeleteActivos = union(postpostCountryDeleteActivos, [postCountryDelete.idPost]);
    });
    return Object.assign({}, state, {
        activo: postpostCountryDeleteActivo,
        activos: postpostCountryDeleteActivos
    });

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });//ASSOCIATEDPRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_POST:
            let datoPostDelete = action.post;
            let idsDelete = [];
           Object.values(action.post).map((postDelete) => {
               if (postDelete && postDelete.id)
                   idsDelete.push(postDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoPostDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_POST:
                       let datoPostDeleteUpdate = action.post;
                       let idsDeleteUpdate = [];
                      Object.values(action.post).map((postDelete) => {
                          if (postDelete && postDelete.id)
                              idsDeleteUpdate.push(postDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoPostDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_POST:
                    let datoPostDeleted = {};
                    if (Object.values(action.post.entities.posts).length > 0)
                        datoPostDeleted = Object.values(action.post.entities.posts)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoPostDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_POST:
             let idsCreate = [];
             Object.values(action.post).map((postCreate) => {
                 if (postCreate && postCreate.id)
                     idsCreate.push(postCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.post,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_POST:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_POST:
            let datoPostNuevo = {};
            if (Object.values(action.post.entities.posts).length > 0)
                datoPostNuevo = Object.values(action.post.entities.posts)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoPostNuevo,
                nuevos: []
            });
        case ERROR_CREATE_POST:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_POSTS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_POSTS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_POSTS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_POST:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE POSTLANGUAGE
case CREATE_POSTLANGUAGE:
    let postpostLanguageCreateActivo = clone(state.nuevo);
    Object.values(action.postLanguage).map((postLanguageCreate) => {
        if(postLanguageCreate && postLanguageCreate.idPost && postpostLanguageCreateActivo[postLanguageCreate.idPost]){
            if(postLanguageCreate.idPost.toString().indexOf("-") !== -1)
                postpostLanguageCreateActivo[postLanguageCreate.idPost].post_language = union(postpostLanguageCreateActivo.post_language, [postLanguageCreate.id]);
        } else if (postLanguageCreate) {
            postpostLanguageCreateActivo.post_language = union(postpostLanguageCreateActivo.post_language ? postpostLanguageCreateActivo.post_language : [], [postLanguageCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostLanguageCreateActivo,
        //nuevos: postLanguageCreate && postLanguageCreate.idPost ? union(state.nuevos, [postLanguageCreate.idPost]) : state.nuevos,
    });
case UPDATE_POSTLANGUAGE:
    let postpostLanguageUpdateActivo = clone(state.nuevo);
    Object.values(action.postLanguage).map((postLanguageUpdate) => {
        if(postLanguageUpdate && postLanguageUpdate.idPost && postpostLanguageUpdateActivo[postLanguageUpdate.idPost]){
            if(postLanguageUpdate.idPost.toString().indexOf("-") !== -1)
                postpostLanguageUpdateActivo[postLanguageUpdate.idPost].post_language = union(postpostLanguageUpdateActivo.post_language, [postLanguageUpdate.id]);
        } else if(postLanguageUpdate){
            postpostLanguageUpdateActivo.post_language = union(postpostLanguageUpdateActivo.post_language ? postpostLanguageUpdateActivo.post_language : [], [postLanguageUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostLanguageUpdateActivo,
        //nuevos: postLanguageUpdate && postLanguageUpdate.idPost ? union(state.nuevos, [postLanguageUpdate.idPost]) : state.nuevos,
    });
case DELETE_POSTLANGUAGE:
    let postpostLanguageDeleteActivo = clone(state.nuevo);
    Object.values(action.postLanguage).map((postLanguageDelete) => {
        if(postLanguageDelete && postLanguageDelete.idPost && postLanguageDelete.idPost && postpostLanguageDeleteActivo[postLanguageDelete.idPost]){
            if(postLanguageDelete.idPost.toString().indexOf("-") !== -1)
                postpostLanguageDeleteActivo[postLanguageDelete.idPost].post_language = difference(postpostLanguageDeleteActivo.post_language, [postLanguageDelete.id]);
        } else if (postLanguageDelete) {
            postpostLanguageDeleteActivo.post_language = difference(postpostLanguageDeleteActivo.post_language ? postpostLanguageDeleteActivo.post_language : [], [postLanguageDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostLanguageDeleteActivo,
        //nuevos: postLanguageDelete && postLanguageDelete.idPost ? union(state.nuevos, [postLanguageDelete.idPost]) : state.nuevos,
    });//CREATE POSTCOUNTRY
case CREATE_POSTCOUNTRY:
    let postpostCountryCreateActivo = clone(state.nuevo);
    Object.values(action.postCountry).map((postCountryCreate) => {
        if(postCountryCreate && postCountryCreate.idPost && postpostCountryCreateActivo[postCountryCreate.idPost]){
            if(postCountryCreate.idPost.toString().indexOf("-") !== -1)
                postpostCountryCreateActivo[postCountryCreate.idPost].post_country = union(postpostCountryCreateActivo.post_country, [postCountryCreate.id]);
        } else if (postCountryCreate) {
            postpostCountryCreateActivo.post_country = union(postpostCountryCreateActivo.post_country ? postpostCountryCreateActivo.post_country : [], [postCountryCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostCountryCreateActivo,
        //nuevos: postCountryCreate && postCountryCreate.idPost ? union(state.nuevos, [postCountryCreate.idPost]) : state.nuevos,
    });
case UPDATE_POSTCOUNTRY:
    let postpostCountryUpdateActivo = clone(state.nuevo);
    Object.values(action.postCountry).map((postCountryUpdate) => {
        if(postCountryUpdate && postCountryUpdate.idPost && postpostCountryUpdateActivo[postCountryUpdate.idPost]){
            if(postCountryUpdate.idPost.toString().indexOf("-") !== -1)
                postpostCountryUpdateActivo[postCountryUpdate.idPost].post_country = union(postpostCountryUpdateActivo.post_country, [postCountryUpdate.id]);
        } else if(postCountryUpdate){
            postpostCountryUpdateActivo.post_country = union(postpostCountryUpdateActivo.post_country ? postpostCountryUpdateActivo.post_country : [], [postCountryUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostCountryUpdateActivo,
        //nuevos: postCountryUpdate && postCountryUpdate.idPost ? union(state.nuevos, [postCountryUpdate.idPost]) : state.nuevos,
    });
case DELETE_POSTCOUNTRY:
    let postpostCountryDeleteActivo = clone(state.nuevo);
    Object.values(action.postCountry).map((postCountryDelete) => {
        if(postCountryDelete && postCountryDelete.idPost && postCountryDelete.idPost && postpostCountryDeleteActivo[postCountryDelete.idPost]){
            if(postCountryDelete.idPost.toString().indexOf("-") !== -1)
                postpostCountryDeleteActivo[postCountryDelete.idPost].post_country = difference(postpostCountryDeleteActivo.post_country, [postCountryDelete.id]);
        } else if (postCountryDelete) {
            postpostCountryDeleteActivo.post_country = difference(postpostCountryDeleteActivo.post_country ? postpostCountryDeleteActivo.post_country : [], [postCountryDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: postpostCountryDeleteActivo,
        //nuevos: postCountryDelete && postCountryDelete.idPost ? union(state.nuevos, [postCountryDelete.idPost]) : state.nuevos,
    });

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//ASSOCIATEDPRODUCT
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_POST:
           let datoPostDelete = action.post;
           let idsDelete = [];
           Object.values(action.post).map((postDelete) => {
               if (postDelete && postDelete.id)
                   idsDelete.push(postDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoPostDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_POST:
                  let datoPostDeleteCreate = action.post;
                  let idsDeleteCreate = [];
                  Object.values(action.post).map((postDelete) => {
                      if (postDelete && postDelete.id)
                          idsDeleteCreate.push(postDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoPostDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_POST:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.post,
                error: null,
            });
        case REQUEST_DELETE_POST:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_POST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_POST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_POST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });//ASSOCIATEDPRODUCT
 case SUCCESS_CREATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSOCIATEDPRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSOCIATEDPRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_POST:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_POST:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_POST:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const posts = combineReducers({
    byId: postsById,
    allIds: allPosts,
    update: update,
    create: create,
    totalPosts: totalPosts,
    delete: deleter,
    print: print
});

export default posts;