import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, TextField, Typography} from '@material-ui/core';
import HTMLEditor from "../../../library/HTMLEditor";
//Icons
//Img

const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
}));


function TextType(props) {
    const classes = styles();
    const {changeAssetLanguage, language, assetLanguage, handleChangeAssetLanguage} = props;
    //Hooks
    //State

    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    let formats = [
        'bold', 'italic', 'underline', 'strike', 'image'
    ];

    const handleChangeTextEditorAssetLanguage = (content, delta, source, editor, idField, idAssetLanguage, idLanguage) => {
        if (source === 'user') {
            let id = idAssetLanguage ? idAssetLanguage : Date.now() + Math.random() + "-";
            let cambio = {};
            cambio[idField] = content;
            changeAssetLanguage(cambio, id, idLanguage);
        }
    }


    return (
        <Box component={"div"} className={classes.form}>
            <Typography variant={"overline"}>{language ? language.desLanguage + " " + language.unicodeIcon : ""}</Typography>
            <Box component={"div"} marginTop={"10px"}>
                <HTMLEditor modules={modules} textLabel={"Texto 1"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text1", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text1 : ""}/>
                <HTMLEditor modules={modules} textLabel={"Texto 2"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text2", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text2 : ""}/>
                <HTMLEditor modules={modules} textLabel={"Texto 3"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text3", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text3 : ""}/>
                <HTMLEditor modules={modules} textLabel={"Texto 4"}
                            onChange={(content, delta, source, editor) => handleChangeTextEditorAssetLanguage(content, delta, source, editor, "text4", assetLanguage ? assetLanguage.id : null,language ? language.id : null)}
                            value={assetLanguage ? assetLanguage.text4 : ""}/>
                <TextField
                    id="textAction"
                    placeholder="Texto de botón de acción"
                    variant="outlined"
                    label={"Texto de botón de acción"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        // disabled: disabled
                    }}
                    margin={"normal"}
                    fullWidth
                    value={assetLanguage ? assetLanguage.textAction : ""}
                    onChange={(e) => handleChangeAssetLanguage(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                    // onKeyDown={(e) => handleKeyDown(e)}
                />
                <TextField
                    id="urlAction"
                    placeholder="Link de acción"
                    variant="outlined"
                    label={"Link de acción"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        // disabled: disabled
                    }}
                    margin={"normal"}
                    fullWidth
                    value={assetLanguage ? assetLanguage.urlAction : ""}
                    onChange={(e) => handleChangeAssetLanguage(e, assetLanguage ? assetLanguage.id : null, language ? language.id : null)}
                    // onKeyDown={(e) => handleKeyDown(e)}
                />
            </Box>
        </Box>
    );
}

TextType.propTypes = {}


function areEqual(prevProps, nextProps) {
    return prevProps.assetLanguage && nextProps.assetLanguage && prevProps.assetLanguage.id === nextProps.assetLanguage.id && prevProps.assetLanguage.text1 === nextProps.assetLanguage.text1 && prevProps.assetLanguage.text2 === nextProps.assetLanguage.text2 && prevProps.assetLanguage.text3 === nextProps.assetLanguage.text3 && prevProps.assetLanguage.text4 === nextProps.assetLanguage.text4 && prevProps.assetLanguage.textAction === nextProps.assetLanguage.textAction && prevProps.assetLanguage.textUrl === nextProps.assetLanguage.textUrl;
}

export default React.memo(TextType, areEqual);