import {
    INVALIDATE_ASSETS,
    ERROR_ASSETS,
    RECEIVE_ASSETS,
    REQUEST_ASSETS,
    RESET_ASSETS,
    ERROR_ASSET,
    RECEIVE_ASSET,
    REQUEST_ASSET,
    UPDATE_ASSET,
    REQUEST_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    ERROR_UPDATE_ASSET,
    RESET_UPDATE_ASSET,
    REQUEST_UPDATE_ASSETS,
    SUCCESS_UPDATE_ASSETS,
    ERROR_UPDATE_ASSETS,
    RESET_UPDATE_ASSETS,
    CREATE_ASSET,
    ERROR_CREATE_ASSET,
    REQUEST_CREATE_ASSET,
    RESET_CREATE_ASSET,
    SUCCESS_CREATE_ASSET,
    REQUEST_CREATE_ASSETS,
    SUCCESS_CREATE_ASSETS,
    ERROR_CREATE_ASSETS,
    RESET_CREATE_ASSETS,
    DELETE_ASSET,
    DELETE_CREATE_ASSET,
    DELETE_UPDATE_ASSET,
    REQUEST_DELETE_ASSET,
    SUCCESS_DELETE_ASSET,
    ERROR_DELETE_ASSET,
    RESET_DELETE_ASSET,
    REQUEST_PRINT_ASSET,
    SUCCESS_PRINT_ASSET,
    ERROR_PRINT_ASSET,
    RESET_PRINT_ASSET,
    RECEIVE_FILE_ASSET
} from '../actions/AssetActions';
import {
    CREATE_ASSETLANGUAGE,
    UPDATE_ASSETLANGUAGE,
    DELETE_ASSETLANGUAGE
} from '../actions/AssetLanguageActions';
import {
    CREATE_ASSETCOUNTRY,
    UPDATE_ASSETCOUNTRY,
    DELETE_ASSETCOUNTRY
} from '../actions/AssetCountryActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        assets: {},
        files: {},
    }
}

function assetsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ASSETS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ASSETS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ASSETS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ASSETS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                assets: {}
            });
        case RECEIVE_ASSETS:
            let dato = action.assets.entities.assets;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                assets: merge({}, state.assets, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ASSET:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ASSET:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ASSET:
            let datoAsset = action.asset.entities.assets;
            return Object.assign({}, state, {
                assets: merge({}, state.assets, datoAsset),
                isFetching: false,
            });
        case RECEIVE_FILE_ASSET:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ASSET:
            let datoAssetEliminado = action.asset.entities.assets;
            return Object.assign({}, state, {
                assets: mergeWith(clone(datoAssetEliminado), state.assets, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSET:
            let datoAssetCreado = action.asset.entities.assets;
            return Object.assign({}, state, {
                assets: mergeWith(clone(datoAssetCreado), state.assets, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSETS:
            let datosAssetCreado = action.assets.entities.assets;
                return Object.assign({}, state, {
                    assets: mergeWith(clone(datosAssetCreado), state.assets, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ASSET:
            let datoAssetActualizado = action.asset.entities.assets;
            return Object.assign({}, state, {
                assets: mergeWith(clone(datoAssetActualizado), state.assets, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ASSETS:
            let datosAssetActualizado = action.assets.entities.assets;
                return Object.assign({}, state, {
                    assets: mergeWith(clone(datosAssetActualizado), state.assets, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                assets: {}
            });
        default:
            return state
    }
}


function allAssets(state = [], action) {
    switch (action.type) {
        case RECEIVE_ASSETS:
            return action.assets.result && action.assets.result.assets ? union(action.assets.result.assets, state) : (action.assets.result ? action.assets.result : state) ;
        case RECEIVE_ASSET:
                return action.asset.result ? union([action.asset.result], state) : state;
        
        case SUCCESS_CREATE_ASSET:
                   let datoAssetSCreate = action.asset.entities.assets;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAssetSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAssetSCreate)[0] && Object.values(datoAssetSCreate)[0].id ? Object.values(datoAssetSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ASSETS:
                   let assetsCreate = action.assets.entities && action.assets.entities.assets ? action.assets.entities.assets : null;
                   return assetsCreate ?
                       union(state, Object.values(assetsCreate).map((assets) => {
                           return assets.id
                       })) : state;
        case RESET_ASSETS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAssets(state = null, action) {
    switch (action.type) {
        case RECEIVE_ASSETS:
            return action.assets && action.assets.result.total ? action.assets.result.total : 0;
        case RESET_ASSETS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ASSET:
            let dato = action.asset.entities.assets;
            let asset = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: asset ? asset : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ASSET:
            let idsUpdate = [];
            Object.values(action.asset).map((assetUpdate) => {
                if (assetUpdate && assetUpdate.id)
                    idsUpdate.push(assetUpdate.id);
            });
            return merge({}, state, {
                activo: action.asset,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ASSET:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ASSET:
            let datoAssetActualizado = {};
            if (Object.values(action.asset.entities.assets).length > 0)
                datoAssetActualizado = Object.values(action.asset.entities.assets)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAssetActualizado
            });
        case ERROR_UPDATE_ASSET:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ASSETS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ASSETS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ASSETS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ASSET:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_ASSETLANGUAGE:
    let assetassetLanguageCreateActivo = clone(state.activo);
    let assetassetLanguageCreateActivos = clone(state.activos);
    Object.values(action.assetLanguage).map((assetLanguageCreate) => {
        if(assetLanguageCreate && assetLanguageCreate.idAsset && assetassetLanguageCreateActivo[assetLanguageCreate.idAsset]){
            if(assetLanguageCreate.idAsset.toString().indexOf("-") === -1)
                assetassetLanguageCreateActivo[assetLanguageCreate.idAsset].asset_language = union(assetassetLanguageCreateActivo.asset_language, [assetLanguageCreate.id]);
        } else if (assetLanguageCreate) {
            assetassetLanguageCreateActivo.asset_language = union(assetassetLanguageCreateActivo.asset_language ? assetassetLanguageCreateActivo.asset_language : [], [assetLanguageCreate.id]);
        }
        if(assetLanguageCreate && assetLanguageCreate.idAsset && assetLanguageCreate.idAsset.toString().indexOf("-") === -1)
            assetassetLanguageCreateActivos = union(assetassetLanguageCreateActivos, [assetLanguageCreate.idAsset]);
    });
    return Object.assign({}, state, {
        activo: assetassetLanguageCreateActivo,
        activos: assetassetLanguageCreateActivos
    });
case UPDATE_ASSETLANGUAGE:
    let assetassetLanguageUpdateActivo = clone(state.activo);
        let assetassetLanguageUpdateActivos = clone(state.activos);
        Object.values(action.assetLanguage).map((assetLanguageUpdate) => {
            if(assetLanguageUpdate && assetLanguageUpdate.idAsset && assetassetLanguageUpdateActivo[assetLanguageUpdate.idAsset]){
                if(assetLanguageUpdate.idAsset.toString().indexOf("-") === -1)
                    assetassetLanguageUpdateActivo[assetLanguageUpdate.idAsset].asset_language = union(assetassetLanguageUpdateActivo.asset_language, [assetLanguageUpdate.id]);
            } else if (assetLanguageUpdate) {
                assetassetLanguageUpdateActivo.asset_language = union(assetassetLanguageUpdateActivo.asset_language ? assetassetLanguageUpdateActivo.asset_language : [], [assetLanguageUpdate.id]);
            }
            if(assetLanguageUpdate && assetLanguageUpdate.idAsset && assetLanguageUpdate.idAsset.toString().indexOf("-") === -1)
                assetassetLanguageUpdateActivos = union(assetassetLanguageUpdateActivos, [assetLanguageUpdate.idAsset]);
        });
        return Object.assign({}, state, {
            activo: assetassetLanguageUpdateActivo,
            activos: assetassetLanguageUpdateActivos
        });
case DELETE_ASSETLANGUAGE:
    let assetassetLanguageDeleteActivo = clone(state.activo);
    let assetassetLanguageDeleteActivos = clone(state.activos);
    Object.values(action.assetLanguage).map((assetLanguageDelete) => {
        if(assetLanguageDelete && assetLanguageDelete.idAsset && assetassetLanguageDeleteActivo[assetLanguageDelete.idAsset]){
            if(assetLanguageDelete.idAsset.toString().indexOf("-") === -1)
                assetassetLanguageDeleteActivo[assetLanguageDelete.idAsset].asset_language = difference(assetassetLanguageDeleteActivo.asset_language, [assetLanguageDelete.id]);
        } else if (assetLanguageDelete) {
            assetassetLanguageDeleteActivo.asset_language = difference(assetassetLanguageDeleteActivo.asset_language ? assetassetLanguageDeleteActivo.asset_language : [], [assetLanguageDelete.id]);
        }
        if(assetLanguageDelete && assetLanguageDelete.idAsset && assetLanguageDelete.idAsset.toString().indexOf("-") === -1)
            assetassetLanguageDeleteActivos = union(assetassetLanguageDeleteActivos, [assetLanguageDelete.idAsset]);
    });
    return Object.assign({}, state, {
        activo: assetassetLanguageDeleteActivo,
        activos: assetassetLanguageDeleteActivos
    });case CREATE_ASSETCOUNTRY:
    let assetassetCountryCreateActivo = clone(state.activo);
    let assetassetCountryCreateActivos = clone(state.activos);
    Object.values(action.assetCountry).map((assetCountryCreate) => {
        if(assetCountryCreate && assetCountryCreate.idAsset && assetassetCountryCreateActivo[assetCountryCreate.idAsset]){
            if(assetCountryCreate.idAsset.toString().indexOf("-") === -1)
                assetassetCountryCreateActivo[assetCountryCreate.idAsset].asset_country = union(assetassetCountryCreateActivo.asset_country, [assetCountryCreate.id]);
        } else if (assetCountryCreate) {
            assetassetCountryCreateActivo.asset_country = union(assetassetCountryCreateActivo.asset_country ? assetassetCountryCreateActivo.asset_country : [], [assetCountryCreate.id]);
        }
        if(assetCountryCreate && assetCountryCreate.idAsset && assetCountryCreate.idAsset.toString().indexOf("-") === -1)
            assetassetCountryCreateActivos = union(assetassetCountryCreateActivos, [assetCountryCreate.idAsset]);
    });
    return Object.assign({}, state, {
        activo: assetassetCountryCreateActivo,
        activos: assetassetCountryCreateActivos
    });
case UPDATE_ASSETCOUNTRY:
    let assetassetCountryUpdateActivo = clone(state.activo);
        let assetassetCountryUpdateActivos = clone(state.activos);
        Object.values(action.assetCountry).map((assetCountryUpdate) => {
            if(assetCountryUpdate && assetCountryUpdate.idAsset && assetassetCountryUpdateActivo[assetCountryUpdate.idAsset]){
                if(assetCountryUpdate.idAsset.toString().indexOf("-") === -1)
                    assetassetCountryUpdateActivo[assetCountryUpdate.idAsset].asset_country = union(assetassetCountryUpdateActivo.asset_country, [assetCountryUpdate.id]);
            } else if (assetCountryUpdate) {
                assetassetCountryUpdateActivo.asset_country = union(assetassetCountryUpdateActivo.asset_country ? assetassetCountryUpdateActivo.asset_country : [], [assetCountryUpdate.id]);
            }
            if(assetCountryUpdate && assetCountryUpdate.idAsset && assetCountryUpdate.idAsset.toString().indexOf("-") === -1)
                assetassetCountryUpdateActivos = union(assetassetCountryUpdateActivos, [assetCountryUpdate.idAsset]);
        });
        return Object.assign({}, state, {
            activo: assetassetCountryUpdateActivo,
            activos: assetassetCountryUpdateActivos
        });
case DELETE_ASSETCOUNTRY:
    let assetassetCountryDeleteActivo = clone(state.activo);
    let assetassetCountryDeleteActivos = clone(state.activos);
    Object.values(action.assetCountry).map((assetCountryDelete) => {
        if(assetCountryDelete && assetCountryDelete.idAsset && assetassetCountryDeleteActivo[assetCountryDelete.idAsset]){
            if(assetCountryDelete.idAsset.toString().indexOf("-") === -1)
                assetassetCountryDeleteActivo[assetCountryDelete.idAsset].asset_country = difference(assetassetCountryDeleteActivo.asset_country, [assetCountryDelete.id]);
        } else if (assetCountryDelete) {
            assetassetCountryDeleteActivo.asset_country = difference(assetassetCountryDeleteActivo.asset_country ? assetassetCountryDeleteActivo.asset_country : [], [assetCountryDelete.id]);
        }
        if(assetCountryDelete && assetCountryDelete.idAsset && assetCountryDelete.idAsset.toString().indexOf("-") === -1)
            assetassetCountryDeleteActivos = union(assetassetCountryDeleteActivos, [assetCountryDelete.idAsset]);
    });
    return Object.assign({}, state, {
        activo: assetassetCountryDeleteActivo,
        activos: assetassetCountryDeleteActivos
    });

           

        case DELETE_ASSET:
            let datoAssetDelete = action.asset;
            let idsDelete = [];
           Object.values(action.asset).map((assetDelete) => {
               if (assetDelete && assetDelete.id)
                   idsDelete.push(assetDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAssetDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ASSET:
                       let datoAssetDeleteUpdate = action.asset;
                       let idsDeleteUpdate = [];
                      Object.values(action.asset).map((assetDelete) => {
                          if (assetDelete && assetDelete.id)
                              idsDeleteUpdate.push(assetDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAssetDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ASSET:
                    let datoAssetDeleted = {};
                    if (Object.values(action.asset.entities.assets).length > 0)
                        datoAssetDeleted = Object.values(action.asset.entities.assets)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAssetDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ASSET:
             let idsCreate = [];
             Object.values(action.asset).map((assetCreate) => {
                 if (assetCreate && assetCreate.id)
                     idsCreate.push(assetCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.asset,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ASSET:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ASSET:
            let datoAssetNuevo = {};
            if (Object.values(action.asset.entities.assets).length > 0)
                datoAssetNuevo = Object.values(action.asset.entities.assets)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAssetNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ASSET:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ASSETS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ASSETS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ASSETS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ASSET:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE ASSETLANGUAGE
case CREATE_ASSETLANGUAGE:
    let assetassetLanguageCreateActivo = clone(state.nuevo);
    Object.values(action.assetLanguage).map((assetLanguageCreate) => {
        if(assetLanguageCreate && assetLanguageCreate.idAsset && assetassetLanguageCreateActivo[assetLanguageCreate.idAsset]){
            if(assetLanguageCreate.idAsset.toString().indexOf("-") !== -1)
                assetassetLanguageCreateActivo[assetLanguageCreate.idAsset].asset_language = union(assetassetLanguageCreateActivo.asset_language, [assetLanguageCreate.id]);
        } else if (assetLanguageCreate) {
            assetassetLanguageCreateActivo.asset_language = union(assetassetLanguageCreateActivo.asset_language ? assetassetLanguageCreateActivo.asset_language : [], [assetLanguageCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetLanguageCreateActivo,
        //nuevos: assetLanguageCreate && assetLanguageCreate.idAsset ? union(state.nuevos, [assetLanguageCreate.idAsset]) : state.nuevos,
    });
case UPDATE_ASSETLANGUAGE:
    let assetassetLanguageUpdateActivo = clone(state.nuevo);
    Object.values(action.assetLanguage).map((assetLanguageUpdate) => {
        if(assetLanguageUpdate && assetLanguageUpdate.idAsset && assetassetLanguageUpdateActivo[assetLanguageUpdate.idAsset]){
            if(assetLanguageUpdate.idAsset.toString().indexOf("-") !== -1)
                assetassetLanguageUpdateActivo[assetLanguageUpdate.idAsset].asset_language = union(assetassetLanguageUpdateActivo.asset_language, [assetLanguageUpdate.id]);
        } else if(assetLanguageUpdate){
            assetassetLanguageUpdateActivo.asset_language = union(assetassetLanguageUpdateActivo.asset_language ? assetassetLanguageUpdateActivo.asset_language : [], [assetLanguageUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetLanguageUpdateActivo,
        //nuevos: assetLanguageUpdate && assetLanguageUpdate.idAsset ? union(state.nuevos, [assetLanguageUpdate.idAsset]) : state.nuevos,
    });
case DELETE_ASSETLANGUAGE:
    let assetassetLanguageDeleteActivo = clone(state.nuevo);
    Object.values(action.assetLanguage).map((assetLanguageDelete) => {
        if(assetLanguageDelete && assetLanguageDelete.idAsset && assetLanguageDelete.idAsset && assetassetLanguageDeleteActivo[assetLanguageDelete.idAsset]){
            if(assetLanguageDelete.idAsset.toString().indexOf("-") !== -1)
                assetassetLanguageDeleteActivo[assetLanguageDelete.idAsset].asset_language = difference(assetassetLanguageDeleteActivo.asset_language, [assetLanguageDelete.id]);
        } else if (assetLanguageDelete) {
            assetassetLanguageDeleteActivo.asset_language = difference(assetassetLanguageDeleteActivo.asset_language ? assetassetLanguageDeleteActivo.asset_language : [], [assetLanguageDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetLanguageDeleteActivo,
        //nuevos: assetLanguageDelete && assetLanguageDelete.idAsset ? union(state.nuevos, [assetLanguageDelete.idAsset]) : state.nuevos,
    });//CREATE ASSETCOUNTRY
case CREATE_ASSETCOUNTRY:
    let assetassetCountryCreateActivo = clone(state.nuevo);
    Object.values(action.assetCountry).map((assetCountryCreate) => {
        if(assetCountryCreate && assetCountryCreate.idAsset && assetassetCountryCreateActivo[assetCountryCreate.idAsset]){
            if(assetCountryCreate.idAsset.toString().indexOf("-") !== -1)
                assetassetCountryCreateActivo[assetCountryCreate.idAsset].asset_country = union(assetassetCountryCreateActivo.asset_country, [assetCountryCreate.id]);
        } else if (assetCountryCreate) {
            assetassetCountryCreateActivo.asset_country = union(assetassetCountryCreateActivo.asset_country ? assetassetCountryCreateActivo.asset_country : [], [assetCountryCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetCountryCreateActivo,
        //nuevos: assetCountryCreate && assetCountryCreate.idAsset ? union(state.nuevos, [assetCountryCreate.idAsset]) : state.nuevos,
    });
case UPDATE_ASSETCOUNTRY:
    let assetassetCountryUpdateActivo = clone(state.nuevo);
    Object.values(action.assetCountry).map((assetCountryUpdate) => {
        if(assetCountryUpdate && assetCountryUpdate.idAsset && assetassetCountryUpdateActivo[assetCountryUpdate.idAsset]){
            if(assetCountryUpdate.idAsset.toString().indexOf("-") !== -1)
                assetassetCountryUpdateActivo[assetCountryUpdate.idAsset].asset_country = union(assetassetCountryUpdateActivo.asset_country, [assetCountryUpdate.id]);
        } else if(assetCountryUpdate){
            assetassetCountryUpdateActivo.asset_country = union(assetassetCountryUpdateActivo.asset_country ? assetassetCountryUpdateActivo.asset_country : [], [assetCountryUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetCountryUpdateActivo,
        //nuevos: assetCountryUpdate && assetCountryUpdate.idAsset ? union(state.nuevos, [assetCountryUpdate.idAsset]) : state.nuevos,
    });
case DELETE_ASSETCOUNTRY:
    let assetassetCountryDeleteActivo = clone(state.nuevo);
    Object.values(action.assetCountry).map((assetCountryDelete) => {
        if(assetCountryDelete && assetCountryDelete.idAsset && assetCountryDelete.idAsset && assetassetCountryDeleteActivo[assetCountryDelete.idAsset]){
            if(assetCountryDelete.idAsset.toString().indexOf("-") !== -1)
                assetassetCountryDeleteActivo[assetCountryDelete.idAsset].asset_country = difference(assetassetCountryDeleteActivo.asset_country, [assetCountryDelete.id]);
        } else if (assetCountryDelete) {
            assetassetCountryDeleteActivo.asset_country = difference(assetassetCountryDeleteActivo.asset_country ? assetassetCountryDeleteActivo.asset_country : [], [assetCountryDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: assetassetCountryDeleteActivo,
        //nuevos: assetCountryDelete && assetCountryDelete.idAsset ? union(state.nuevos, [assetCountryDelete.idAsset]) : state.nuevos,
    });

             
        case DELETE_ASSET:
           let datoAssetDelete = action.asset;
           let idsDelete = [];
           Object.values(action.asset).map((assetDelete) => {
               if (assetDelete && assetDelete.id)
                   idsDelete.push(assetDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAssetDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ASSET:
                  let datoAssetDeleteCreate = action.asset;
                  let idsDeleteCreate = [];
                  Object.values(action.asset).map((assetDelete) => {
                      if (assetDelete && assetDelete.id)
                          idsDeleteCreate.push(assetDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAssetDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ASSET:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.asset,
                error: null,
            });
        case REQUEST_DELETE_ASSET:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ASSET:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ASSET:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ASSET:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ASSET:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ASSET:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ASSET:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const assets = combineReducers({
    byId: assetsById,
    allIds: allAssets,
    update: update,
    create: create,
    totalAssets: totalAssets,
    delete: deleter,
    print: print
});

export default assets;