//api
import productsApi from "../api/productsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProducts";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTS
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const INVALIDATE_PRODUCTS = 'INVALIDATE_PRODUCTS';
export const ERROR_PRODUCTS = "ERROR_PRODUCTS";
export const RESET_PRODUCTS = "RESET_PRODUCTS";


export function invalidateProducts() {
    return {
        type: INVALIDATE_PRODUCTS
    }
}

function requestProducts() {
    return {
        type: REQUEST_PRODUCTS,
    }
}

function receiveProducts(json) {
    return {
        type: RECEIVE_PRODUCTS,
    products: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProducts(error) {
    return {
        type: ERROR_PRODUCTS,
        error: error,
    }
}

export function resetProducts() {
    return {
        type: RESET_PRODUCTS
    }
}

export function fetchProducts(filtros) {
    return dispatch => {
        dispatch(requestProducts());
        return productsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProducts(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProducts(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProducts(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProducts(state) {
    const products = state.products.byId;
    if (!products) {
        return true
    } else if (products.isFetching) {
        return false
    } else {
        return products.didInvalidate;
    }
}

export function fetchProductsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProducts(getState())) {
            return dispatch(fetchProducts(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const INVALIDATE_PRODUCT = 'INVALIDATE_PRODUCT';
export const ERROR_PRODUCT = "ERROR_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";


export function invalidateProduct() {
    return {
        type: INVALIDATE_PRODUCT
    }
}

function requestProduct() {
    return {
        type: REQUEST_PRODUCT,
    }
}

export function receiveProduct(json) {
    return {
        type: RECEIVE_PRODUCT,
    product: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProduct(error) {
    return {
        type: ERROR_PRODUCT,
        error: error,
    }
}

export function fetchProduct(idProduct) {
    return dispatch => {
        dispatch(requestProduct());
        return productsApi.getOne(idProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProduct(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProduct(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProduct(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_PRODUCT = 'RECEIVE_FILE_PRODUCT';

function receiveFileProduct(file) {
    return {
        type: RECEIVE_FILE_PRODUCT,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileProduct(idProduct, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return productsApi.getFile(idProduct, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileProduct(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProduct(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProduct(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REQUEST_UPDATE_PRODUCT = "REQUEST_UPDATE_PRODUCT";
export const SUCCESS_UPDATE_PRODUCT = "SUCCESS_UPDATE_PRODUCT";
export const ERROR_UPDATE_PRODUCT = "ERROR_UPDATE_PRODUCT";
export const RESET_UPDATE_PRODUCT = "RESET_UPDATE_PRODUCT";
export const DELETE_UPDATE_PRODUCT = "DELETE_UPDATE_PRODUCT";

function requestUpdateProduct() {
    return {
        type: REQUEST_UPDATE_PRODUCT,
    }
}

function receiveUpdateProduct(product) {
    return {
        type: SUCCESS_UPDATE_PRODUCT,
        receivedAt: Date.now(),
        product: normalizeDato(product)
    }
}

function errorUpdateProduct(error) {
    return {
        type: ERROR_UPDATE_PRODUCT,
        error: error,
    }
}

export function resetUpdateProduct() {
    return {
        type: RESET_UPDATE_PRODUCT,
    }
}

export function updateProduct(product) {
    return {
        type: UPDATE_PRODUCT,
        product
    }
}

export function saveUpdateProduct(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateProduct());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let product = denormalizeDato(getState().products.update.activo, store);

        return productsApi.saveUpdate(product, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveUpdateProduct(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProducts(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProducts(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProduct(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                    dispatch(receiveUpdateProduct(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProduct(product) {
    return {
        type: DELETE_UPDATE_PRODUCT,
        product
    }
}

//UPDATE PRODUCTS
export const REQUEST_UPDATE_PRODUCTS = "REQUEST_UPDATE_PRODUCTS";
export const SUCCESS_UPDATE_PRODUCTS = "SUCCESS_UPDATE_PRODUCTS";
export const ERROR_UPDATE_PRODUCTS = "ERROR_UPDATE_PRODUCTS";
export const RESET_UPDATE_PRODUCTS = "RESET_UPDATE_PRODUCTS";

function requestUpdateProducts() {
    return {
        type: REQUEST_UPDATE_PRODUCTS,
}
}

function receiveUpdateProducts(products) {
    return {
        type: SUCCESS_UPDATE_PRODUCTS,
    receivedAt: Date.now(),
        products: normalizeDatos(products)
}
}

function errorUpdateProducts(error) {
    return {
        type: ERROR_UPDATE_PRODUCTS,
    error: error,
}
}

export function resetUpdateProducts() {
    return {
        type: RESET_UPDATE_PRODUCTS,
}
}

export function saveUpdateProducts() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProducts());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let products = getState().products.update.activos.map((idProduct) => {
            return denormalizeDato(getState().products.update.activo[idProduct], store);
        });

        return productsApi.saveUpdateProducts(products)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProducts(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProducts(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProducts(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProducts(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProducts(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProducts(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCT
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const REQUEST_CREATE_PRODUCT = "REQUEST_CREATE_PRODUCT";
export const SUCCESS_CREATE_PRODUCT = "SUCCESS_CREATE_PRODUCT";
export const ERROR_CREATE_PRODUCT = "ERROR_CREATE_PRODUCT";
export const RESET_CREATE_PRODUCT = "RESET_CREATE_PRODUCT";
export const DELETE_CREATE_PRODUCT = "DELETE_CREATE_PRODUCT";


//ALTA PRODUCT
function requestCreateProduct() {
    return {
        type: REQUEST_CREATE_PRODUCT,
    }
}

function receiveCreateProduct(product) {
    return {
        type: SUCCESS_CREATE_PRODUCT,
        receivedAt: Date.now(),
        product: normalizeDato(product)
    }
}

export function errorCreateProduct(error) {
    return {
        type: ERROR_CREATE_PRODUCT,
        error: error,
    }
}

export function resetCreateProduct() {
    return {
        type: RESET_CREATE_PRODUCT
    }
}

export function createProduct(product) {
    return {
        type: CREATE_PRODUCT,
        product
    }
}

export function deleteCreateProduct(product) {
    return {
        type: DELETE_CREATE_PRODUCT,
        product
    }
}

export function saveCreateProduct(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateProduct());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let product = denormalizeDato(getState().products.create.nuevo, store);

        return productsApi.saveCreate(product, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateProduct(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProducts(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProducts(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProduct(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProduct(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTS
export const REQUEST_CREATE_PRODUCTS = "REQUEST_CREATE_PRODUCTS";
export const SUCCESS_CREATE_PRODUCTS = "SUCCESS_CREATE_PRODUCTS";
export const ERROR_CREATE_PRODUCTS = "ERROR_CREATE_PRODUCTS";
export const RESET_CREATE_PRODUCTS = "RESET_CREATE_PRODUCTS";

function requestCreateProducts() {
    return {
        type: REQUEST_CREATE_PRODUCTS,
}
}

function receiveCreateProducts(products) {
    return {
        type: SUCCESS_CREATE_PRODUCTS,
    receivedAt: Date.now(),
        products: normalizeDatos(products)
}
}

function errorCreateProducts(error) {
    return {
        type: ERROR_CREATE_PRODUCTS,
    error: error,
}
}

export function resetCreateProducts() {
    return {
        type: RESET_CREATE_PRODUCTS,
}
}

export function saveCreateProducts() {
    return (dispatch, getState) => {
        dispatch(requestCreateProducts());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let products = getState().products.create.nuevos.map((idProduct) => {
            return denormalizeDato(getState().products.create.nuevo[idProduct], store);
        });

        return productsApi.saveCreateProducts(products)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProducts(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProducts(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProducts(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProducts(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProducts(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProducts(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCT
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REQUEST_DELETE_PRODUCT = "REQUEST_DELETE_PRODUCT";
export const SUCCESS_DELETE_PRODUCT = "SUCCESS_DELETE_PRODUCT";
export const ERROR_DELETE_PRODUCT = "ERROR_DELETE_PRODUCT";
export const RESET_DELETE_PRODUCT = "RESET_DELETE_PRODUCT";

function requestDeleteProduct() {
    return {
        type: REQUEST_DELETE_PRODUCT,
    }
}

function receiveDeleteProduct(product) {
    return {
        type: SUCCESS_DELETE_PRODUCT,
        receivedAt: Date.now(),
        product: normalizeDato(product)
    }
}

function errorDeleteProduct(error) {
    return {
        type: ERROR_DELETE_PRODUCT,
        error: error,
    }
}

export function resetDeleteProduct(error) {
    return {
        type: RESET_DELETE_PRODUCT,
        error: error,
    }
}

export function deleteProduct(product) {
    return {
        type: DELETE_PRODUCT,
        product
    }
}

export function saveDeleteProduct(product) {
    return dispatch => {
        dispatch(requestDeleteProduct());
        return productsApi.saveDelete(product)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProduct());
                            dispatch(receiveDeleteProduct(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProduct(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProduct(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProduct(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProduct(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProduct(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCT
export const REQUEST_PRINT_PRODUCT = "REQUEST_PRINT_PRODUCT";
export const SUCCESS_PRINT_PRODUCT = "SUCCESS_PRINT_PRODUCT";
export const ERROR_PRINT_PRODUCT = "ERROR_PRINT_PRODUCT";
export const RESET_PRINT_PRODUCT = "RESET_PRINT_PRODUCT";

function requestPrintProduct() {
    return {
        type: REQUEST_PRINT_PRODUCT,
    }
}

function receivePrintProduct(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCT,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProduct(error) {
    return {
        type: ERROR_PRINT_PRODUCT,
        error: error,
    }
}

export function resetPrintProduct() {
    return {
        type: RESET_PRINT_PRODUCT,
    }
}

export function printProduct(idProduct) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProduct());
        return productsApi.printProduct(idProduct)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProduct(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProduct(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProduct(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProduct(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProduct(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}