//api
import attributesApi from "../api/attributesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAttributes";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ATTRIBUTES
export const REQUEST_ATTRIBUTES = 'REQUEST_ATTRIBUTES';
export const RECEIVE_ATTRIBUTES = 'RECEIVE_ATTRIBUTES';
export const INVALIDATE_ATTRIBUTES = 'INVALIDATE_ATTRIBUTES';
export const ERROR_ATTRIBUTES = "ERROR_ATTRIBUTES";
export const RESET_ATTRIBUTES = "RESET_ATTRIBUTES";


export function invalidateAttributes() {
    return {
        type: INVALIDATE_ATTRIBUTES
    }
}

function requestAttributes() {
    return {
        type: REQUEST_ATTRIBUTES,
    }
}

function receiveAttributes(json) {
    return {
        type: RECEIVE_ATTRIBUTES,
    attributes: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAttributes(error) {
    return {
        type: ERROR_ATTRIBUTES,
        error: error,
    }
}

export function resetAttributes() {
    return {
        type: RESET_ATTRIBUTES
    }
}

export function fetchAttributes(filtros) {
    return dispatch => {
        dispatch(requestAttributes());
        return attributesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttributes(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttributes(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttributes(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAttributes(state) {
    const attributes = state.attributes.byId;
    if (!attributes) {
        return true
    } else if (attributes.isFetching) {
        return false
    } else {
        return attributes.didInvalidate;
    }
}

export function fetchAttributesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAttributes(getState())) {
            return dispatch(fetchAttributes(filtros))
        }
    }
}


//MODEL
export const REQUEST_ATTRIBUTE = 'REQUEST_ATTRIBUTE';
export const RECEIVE_ATTRIBUTE = 'RECEIVE_ATTRIBUTE';
export const INVALIDATE_ATTRIBUTE = 'INVALIDATE_ATTRIBUTE';
export const ERROR_ATTRIBUTE = "ERROR_ATTRIBUTE";
export const RESET_ATTRIBUTE = "RESET_ATTRIBUTE";


export function invalidateAttribute() {
    return {
        type: INVALIDATE_ATTRIBUTE
    }
}

function requestAttribute() {
    return {
        type: REQUEST_ATTRIBUTE,
    }
}

export function receiveAttribute(json) {
    return {
        type: RECEIVE_ATTRIBUTE,
    attribute: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAttribute(error) {
    return {
        type: ERROR_ATTRIBUTE,
        error: error,
    }
}

export function fetchAttribute(idAttribute) {
    return dispatch => {
        dispatch(requestAttribute());
        return attributesApi.getOne(idAttribute)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAttribute(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttribute(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_ATTRIBUTE = 'RECEIVE_FILE_ATTRIBUTE';

function receiveFileAttribute(file) {
    return {
        type: RECEIVE_FILE_ATTRIBUTE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileAttribute(idAttribute, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return attributesApi.getFile(idAttribute, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileAttribute(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAttribute(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const REQUEST_UPDATE_ATTRIBUTE = "REQUEST_UPDATE_ATTRIBUTE";
export const SUCCESS_UPDATE_ATTRIBUTE = "SUCCESS_UPDATE_ATTRIBUTE";
export const ERROR_UPDATE_ATTRIBUTE = "ERROR_UPDATE_ATTRIBUTE";
export const RESET_UPDATE_ATTRIBUTE = "RESET_UPDATE_ATTRIBUTE";
export const DELETE_UPDATE_ATTRIBUTE = "DELETE_UPDATE_ATTRIBUTE";

function requestUpdateAttribute() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTE,
    }
}

function receiveUpdateAttribute(attribute) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTE,
        receivedAt: Date.now(),
        attribute: normalizeDato(attribute)
    }
}

function errorUpdateAttribute(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTE,
        error: error,
    }
}

export function resetUpdateAttribute() {
    return {
        type: RESET_UPDATE_ATTRIBUTE,
    }
}

export function updateAttribute(attribute) {
    return {
        type: UPDATE_ATTRIBUTE,
        attribute
    }
}

export function saveUpdateAttribute() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttribute());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attribute = denormalizeDato(getState().attributes.update.activo, store);

        return attributesApi.saveUpdate(attribute)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttribute(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateAttributes(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateAttributes(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAttribute(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateAttribute(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateAttribute(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateAttribute(attribute) {
    return {
        type: DELETE_UPDATE_ATTRIBUTE,
        attribute
    }
}

//UPDATE ATTRIBUTES
export const REQUEST_UPDATE_ATTRIBUTES = "REQUEST_UPDATE_ATTRIBUTES";
export const SUCCESS_UPDATE_ATTRIBUTES = "SUCCESS_UPDATE_ATTRIBUTES";
export const ERROR_UPDATE_ATTRIBUTES = "ERROR_UPDATE_ATTRIBUTES";
export const RESET_UPDATE_ATTRIBUTES = "RESET_UPDATE_ATTRIBUTES";

function requestUpdateAttributes() {
    return {
        type: REQUEST_UPDATE_ATTRIBUTES,
}
}

function receiveUpdateAttributes(attributes) {
    return {
        type: SUCCESS_UPDATE_ATTRIBUTES,
    receivedAt: Date.now(),
        attributes: normalizeDatos(attributes)
}
}

function errorUpdateAttributes(error) {
    return {
        type: ERROR_UPDATE_ATTRIBUTES,
    error: error,
}
}

export function resetUpdateAttributes() {
    return {
        type: RESET_UPDATE_ATTRIBUTES,
}
}

export function saveUpdateAttributes() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAttributes());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributes = getState().attributes.update.activos.map((idAttribute) => {
            return denormalizeDato(getState().attributes.update.activo[idAttribute], store);
        });

        return attributesApi.saveUpdateAttributes(attributes)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAttributes(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAttributes(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateAttributes(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateAttributes(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateAttributes(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateAttributes(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ATTRIBUTE
export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE';
export const REQUEST_CREATE_ATTRIBUTE = "REQUEST_CREATE_ATTRIBUTE";
export const SUCCESS_CREATE_ATTRIBUTE = "SUCCESS_CREATE_ATTRIBUTE";
export const ERROR_CREATE_ATTRIBUTE = "ERROR_CREATE_ATTRIBUTE";
export const RESET_CREATE_ATTRIBUTE = "RESET_CREATE_ATTRIBUTE";
export const DELETE_CREATE_ATTRIBUTE = "DELETE_CREATE_ATTRIBUTE";


//ALTA ATTRIBUTE
function requestCreateAttribute() {
    return {
        type: REQUEST_CREATE_ATTRIBUTE,
    }
}

function receiveCreateAttribute(attribute) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTE,
        receivedAt: Date.now(),
        attribute: normalizeDato(attribute)
    }
}

export function errorCreateAttribute(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTE,
        error: error,
    }
}

export function resetCreateAttribute() {
    return {
        type: RESET_CREATE_ATTRIBUTE
    }
}

export function createAttribute(attribute) {
    return {
        type: CREATE_ATTRIBUTE,
        attribute
    }
}

export function deleteCreateAttribute(attribute) {
    return {
        type: DELETE_CREATE_ATTRIBUTE,
        attribute
    }
}

export function saveCreateAttribute() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttribute());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attribute = denormalizeDato(getState().attributes.create.nuevo, store);

        return attributesApi.saveCreate(attribute)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttribute(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateAttributes(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateAttributes(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateAttribute(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateAttribute(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateAttribute(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ATTRIBUTES
export const REQUEST_CREATE_ATTRIBUTES = "REQUEST_CREATE_ATTRIBUTES";
export const SUCCESS_CREATE_ATTRIBUTES = "SUCCESS_CREATE_ATTRIBUTES";
export const ERROR_CREATE_ATTRIBUTES = "ERROR_CREATE_ATTRIBUTES";
export const RESET_CREATE_ATTRIBUTES = "RESET_CREATE_ATTRIBUTES";

function requestCreateAttributes() {
    return {
        type: REQUEST_CREATE_ATTRIBUTES,
}
}

function receiveCreateAttributes(attributes) {
    return {
        type: SUCCESS_CREATE_ATTRIBUTES,
    receivedAt: Date.now(),
        attributes: normalizeDatos(attributes)
}
}

function errorCreateAttributes(error) {
    return {
        type: ERROR_CREATE_ATTRIBUTES,
    error: error,
}
}

export function resetCreateAttributes() {
    return {
        type: RESET_CREATE_ATTRIBUTES,
}
}

export function saveCreateAttributes() {
    return (dispatch, getState) => {
        dispatch(requestCreateAttributes());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let attributes = getState().attributes.create.nuevos.map((idAttribute) => {
            return denormalizeDato(getState().attributes.create.nuevo[idAttribute], store);
        });

        return attributesApi.saveCreateAttributes(attributes)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAttributes(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateAttributes(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateAttributes(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateAttributes(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateAttributes(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateAttributes(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ATTRIBUTE
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const REQUEST_DELETE_ATTRIBUTE = "REQUEST_DELETE_ATTRIBUTE";
export const SUCCESS_DELETE_ATTRIBUTE = "SUCCESS_DELETE_ATTRIBUTE";
export const ERROR_DELETE_ATTRIBUTE = "ERROR_DELETE_ATTRIBUTE";
export const RESET_DELETE_ATTRIBUTE = "RESET_DELETE_ATTRIBUTE";

function requestDeleteAttribute() {
    return {
        type: REQUEST_DELETE_ATTRIBUTE,
    }
}

function receiveDeleteAttribute(attribute) {
    return {
        type: SUCCESS_DELETE_ATTRIBUTE,
        receivedAt: Date.now(),
        attribute: normalizeDato(attribute)
    }
}

function errorDeleteAttribute(error) {
    return {
        type: ERROR_DELETE_ATTRIBUTE,
        error: error,
    }
}

export function resetDeleteAttribute(error) {
    return {
        type: RESET_DELETE_ATTRIBUTE,
        error: error,
    }
}

export function deleteAttribute(attribute) {
    return {
        type: DELETE_ATTRIBUTE,
        attribute
    }
}

export function saveDeleteAttribute(attribute) {
    return dispatch => {
        dispatch(requestDeleteAttribute());
        return attributesApi.saveDelete(attribute)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAttribute());
                            dispatch(receiveDeleteAttribute(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteAttribute(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteAttribute(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAttribute(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteAttribute(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ATTRIBUTE
export const REQUEST_PRINT_ATTRIBUTE = "REQUEST_PRINT_ATTRIBUTE";
export const SUCCESS_PRINT_ATTRIBUTE = "SUCCESS_PRINT_ATTRIBUTE";
export const ERROR_PRINT_ATTRIBUTE = "ERROR_PRINT_ATTRIBUTE";
export const RESET_PRINT_ATTRIBUTE = "RESET_PRINT_ATTRIBUTE";

function requestPrintAttribute() {
    return {
        type: REQUEST_PRINT_ATTRIBUTE,
    }
}

function receivePrintAttribute(turnos) {
    return {
        type: SUCCESS_PRINT_ATTRIBUTE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintAttribute(error) {
    return {
        type: ERROR_PRINT_ATTRIBUTE,
        error: error,
    }
}

export function resetPrintAttribute() {
    return {
        type: RESET_PRINT_ATTRIBUTE,
    }
}

export function printAttribute(idAttribute) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintAttribute());
        return attributesApi.printAttribute(idAttribute)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintAttribute(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintAttribute(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintAttribute(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintAttribute(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintAttribute(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}