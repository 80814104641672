import {resetLogs, resetCreateLog, resetUpdateLog, resetDeleteLog} from "../actions/LogActions";


var resetLog = {
    resetAll(dispatch) {
        dispatch(resetLogs());
        dispatch(resetCreateLog());
        dispatch(resetUpdateLog());
        dispatch(resetDeleteLog());
       
    },
};

export default resetLog;