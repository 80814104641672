import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from "react-redux";

import "../../assets/css/ListaCategorias.css";
//Actions
import {
    createCategory,
    deleteCategory,
    fetchCategoriesIfNeeded, fetchFileCategory,
    resetCreateCategory,
    resetUpdateCategory,
    saveCreateCategory,
    saveDeleteCategory,
    saveUpdateCategory,
    updateCategory
} from "../../actions/CategoryActions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
//Img
import clone from "lodash/clone";
import max from "lodash/max";
import Skeleton from '@material-ui/lab/Skeleton';
import {useValidador} from "../../utils/validador";
import MenuItem from "@material-ui/core/MenuItem";
import {
    createCategoryLanguage, resetCreateCategoryLanguage,
    resetUpdateCategoryLanguage,
    updateCategoryLanguage
} from "../../actions/CategoryLanguageActions";
import CategoryLanguage from "./CategoryLanguage";
import {fetchLanguagesIfNeeded} from "../../actions/LanguageActions";
import FileCategory from "./FileCategory";
import {CircularProgress, withStyles} from "@material-ui/core";
import {fetchPermissionsIfNeeded, invalidatePermissions, resetPermissions} from "../../actions/PermissionActions";
import usePermisos from "../Layout/CustomHooks/usePermisos";
import permissionReset from "../../reset/permissionReset";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles(theme => ({
        withoutPadding: {
            paddingLeft: 0,
            paddingRight: 0
        },
        listas: {
            display: "flex",
            overflow: "auto",
            paddingLeft: "15px",
        },
        botonesMando: {
            paddingLeft: "85px",
        },
        containerNivel: {
            minWidth: "350px",
        },
        encabezadoNivel: {
            display: "flex",
            "& button": {
                marginLeft: "10px"
            }
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.light,
            },
        },
        buttonProgress: {
            color: theme.palette.success.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }

    }))
;

export default function CategoriesList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mounted = useRef();
    let permisos = usePermisos("categories");

    //State
    const [open, setOpen] = React.useState(false);
    const [idCategory, setIdCategory] = React.useState(false);
    const [idsCategorySeleccionada, setIdsCategorySeleccionada] = useState([]);
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);

    //Store
    const categories = useSelector(state => state.categories);
    const categoria = idCategory ? categories.update.activo : categories.create.nuevo;
    const languages = useSelector(state => state.languages);
    const categoriesLanguages = useSelector(state => state.categoriesLanguages);

    const guardandoLoading = idCategory ? categories.update.isUpdating : categories.create.isCreating;
    const disabled = (idCategory ? categories.update.isUpdating : categories.create.isCreating) || categories.byId.isFetching || !permisos.get("update") || !permisos.get("create");


    //Hooks
    let reglas = {
        level: ["required"],
    };
    const [validador, validar] = useValidador(reglas);

    //Effects
    useEffect(() => {
        //let params = new URLSearchParams(props.location.search);
        window.scrollTo(0, 0);

        //dispatch(invalidateCategories());
        dispatch(fetchCategoriesIfNeeded({idParentCategory: null, with: ['childrenCategories', "categoryLanguage"]}));
        dispatch(fetchLanguagesIfNeeded());

    }, []);

    //Effects
    useEffect(() => {
        dispatch(invalidatePermissions());
        dispatch(fetchPermissionsIfNeeded({show: "Mis permisos", module: "categories"}));
        return function cleanup() {
            permissionReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if ((!categories.create.isCreating && !categories.create.error)
                && (!categories.update.isUpdating && !categories.update.error)) {
                dispatch(resetCreateCategory());
                dispatch(resetUpdateCategory());
                setOpen(false);
                setFiles([]);
            }
        }
    }, [categories.create.isCreating || categories.update.isUpdating]);

    const handleChangeCategory = (e, idCategory) => {
        let categoriaSeleccionada = categories.byId.categories[idCategory];
        //let level = parseInt(e.target.name.substring(e.target.name.length - 1));
        let level = categoriaSeleccionada.level;
        let idsCategorySeleccionadaArray = clone(idsCategorySeleccionada);
        idsCategorySeleccionadaArray[level] = idCategory;
        for (let i = idsCategorySeleccionada.length - 1; i > level; i--) {
            idsCategorySeleccionadaArray.splice(i, 1);
        }
        setIdsCategorySeleccionada(idsCategorySeleccionadaArray);
    };

    const handleClickOpen = (level, idCategory) => {
        //Crea nueva categoria
        setIdCategory(null);
        setOpen(true);


        //Seteo estado de los seleccionados
        if (idCategory)
            handleChangeCategory(null, idCategory);

        //Orden
        let ev = {};
        ev.target = {};
        ev.target.id = "level";
        ev.target.value = level;
        handleChangeCategoryDatos(ev, null);


        //idParentCategory
        if (level > 1) {
            let ev = {};
            ev.target = {};
            ev.target.id = "idParentCategory";
            if (idCategory)
                ev.target.value = idCategory;
            else
                ev.target.value = idsCategorySeleccionada[level - 1];
            handleChangeCategoryDatos(ev, null);
        } else {
            let ev = {};
            ev.target = {};
            ev.target.id = "idParentCategory";
            ev.target.value = null;
            handleChangeCategoryDatos(ev, null);
        }
        //Codigo
        ev.target = {};
        ev.target.id = "codCategory";
        //let codCategoryInicial = "";
        let codCategoryFinal = "";
        if (level !== 1) {
            let categoria = {};
            if (idCategory)
                categoria = categories.byId.categories[idCategory];
            else
                categoria = categories.byId.categories[idsCategorySeleccionada[level - 1]];
            //codCategoryInicial = categoria.codCategory;
            let idUltimaNivel = max(categoria.children_categories);
            let ultimaNivel = categories.byId.categories[idUltimaNivel];
            if (ultimaNivel)
                codCategoryFinal = parseInt(ultimaNivel.codCategory) + 1;
            else
                codCategoryFinal = categoria.codCategory + "01";
        } else {
            let idUltimaNivel = max(categories.allIds);
            let ultimaNivel = categories.byId.categories[idUltimaNivel];
            if (ultimaNivel)
                codCategoryFinal = parseInt(ultimaNivel.codCategory) + 1;
        }
        ev.target.value = codCategoryFinal;
        handleChangeCategoryDatos(ev, null);
    };

    const handleClickOpenUpdate = (level, idCategory) => {
        setOpen(true);
        setIdCategory(idCategory);
        dispatch(fetchFileCategory(idCategory, {}));
        let categoria = clone(categories.byId.categories[idCategory]);
        dispatch(updateCategory(categoria));
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(resetUpdateCategory());
        dispatch(resetCreateCategory());
        dispatch(resetUpdateCategoryLanguage());
        dispatch(resetCreateCategoryLanguage());
        setFiles([]);
    };

    const handleChangeCategoryDatos = (e, _idCategory) => {
        _idCategory = _idCategory !== undefined ? _idCategory : idCategory;
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        if (_idCategory)
            dispatch(updateCategory(cambio));
        else
            dispatch(createCategory(cambio));

        //Actualizar código
        if (e.target.name === "idParentCategory" && !_idCategory) {
            //Codigo
            let ev = {};
            ev.target = {};
            ev.target.id = "codCategory";
            //let codCategoryInicial = "";
            let codCategoryFinal = "";
            if (categoria.level !== 1) {
                let categoriaPadre = {};
                if (e.target.value)
                    categoriaPadre = categories.byId.categories[e.target.value];
                else
                    categoriaPadre = categories.byId.categories[idsCategorySeleccionada[categoria.level - 1]];
                //codCategoryInicial = categoria.codCategory;
                let idUltimaNivel = max(categoriaPadre.children_categories);
                let ultimaNivel = categories.byId.categories[idUltimaNivel];
                if (ultimaNivel)
                    codCategoryFinal = parseInt(ultimaNivel.codCategory) + 1;
                else
                    codCategoryFinal = categoriaPadre.codCategory + "01";
            } else {
                let idUltimaNivel = max(categories.allIds);
                let ultimaNivel = categories.byId.categories[idUltimaNivel];
                codCategoryFinal = parseInt(ultimaNivel.codCategory) + 1;
            }
            ev.target.value = codCategoryFinal;
            handleChangeCategoryDatos(ev);
        }
    }

    const changeCategory = (cambio) => {
        if (idCategory)
            dispatch(updateCategory(cambio));
        else
            dispatch(createCategory(cambio));
    };

    const handleChangeEstado = (e, categoria) => {
        if (!open) {
            let cambio = categoria;
            cambio.destacado = e.target.checked;
            dispatch(updateCategory(cambio));
            dispatch(saveUpdateCategory());
        } else {
            let ev = {};
            ev.target = {};
            ev.target.id = "destacado";
            ev.target.value = e.target.checked;
            handleChangeCategoryDatos(ev);
        }
    }

    const handleDeleteCategory = (categoria) => {
        dispatch(deleteCategory(categoria));
        dispatch(saveDeleteCategory(categoria.id));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validador.isValid()) {
            if (idCategory)
                dispatch(saveUpdateCategory(files));
            else
                dispatch(saveCreateCategory(files));
        }
    };

    //Category language
    const handleChangeCategoryLanguage = (e, idCategoryLanguage, idLanguage) => {
        let id = idCategoryLanguage ? idCategoryLanguage : Date.now() + Math.random() + "-";
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeCategoryLanguage(cambio, id, idLanguage);
    }

    const changeCategoryLanguage = (cambio, idCategoryLanguage, idLanguage) => {
        //Verificar si ya esta siendo actualizado
        let categoryLanguage = idCategoryLanguage && categoriesLanguages.update.activos.indexOf(idCategoryLanguage) === -1 && categoriesLanguages.create.nuevos.indexOf(idCategoryLanguage) === -1 ? categoriesLanguages.byId.categoriesLanguages[idCategoryLanguage] : null;
        if (categoryLanguage) {
            let actualizar = categoryLanguage ? clone(categoryLanguage) : null;
            if (actualizar) {
                let cambioCategoryLanguage = {};
                cambioCategoryLanguage[actualizar.id] = actualizar;
                if (idCategoryLanguage.toString().indexOf("-") === -1)
                    dispatch(updateCategoryLanguage(cambioCategoryLanguage));
                else
                    dispatch(createCategoryLanguage(cambioCategoryLanguage));
            }
        }
        cambio.id = idCategoryLanguage;
        cambio.idCategory = idCategory;
        cambio.idLanguage = idLanguage;
        let cambioCategoryLanguage = {};
        cambioCategoryLanguage[idCategoryLanguage] = cambio;
        if (idCategoryLanguage.toString().indexOf("-") === -1)
            dispatch(updateCategoryLanguage(cambioCategoryLanguage));
        else
            dispatch(createCategoryLanguage(cambioCategoryLanguage));
    };

    let OrdenesCategories = [];
    let Categories = {};

    if (categories.byId.isFetching) {
        OrdenesCategories = [...Array(5).keys()].map(() => {
            return (<Skeleton width="100%" variant="rect" height="100vh"/>);
        });
    }

    if (!categories.byId.isFetching) {
        categories.allIds.forEach(idCategory => {
            let categoria = categories.byId.categories[idCategory];
            if (!Categories[categoria.level])
                Categories[categoria.level] = [];
            Categories[categoria.level].push(
                <ListItem key={categoria.id} onClick={(e) => handleChangeCategory(e, categoria.id)}
                          selected={idsCategorySeleccionada[categoria.level] === categoria.id}>
                    <ListItemText
                        style={{cursor: "pointer"}}
                        primary={categoria.nameCategory}
                    />
                    <ListItemSecondaryAction className={classes.botonesMando}>
                        <div>
                            {/*<Checkbox icon={<FavoriteBorder/>} checkedIcon={<Favorite/>} name="destacado"*/}
                            {/*          id="destacado"*/}
                            {/*          checked={!!categoria.destacado}*/}
                            {/*          onChange={(e) => handleChangeEstado(e, categoria)}/>*/}
                            <IconButton edge="end" aria-label="delete" disabled={disabled}
                                        onClick={() => handleClickOpen(categoria.level + 1, idCategory)}>
                                <ControlPoint/>
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" disabled={disabled}
                                        onClick={() => handleClickOpenUpdate(categoria.level + 1, idCategory)}>
                                <EditIcon/>
                            </IconButton>
                            {
                                !categoria.externalId &&
                                <IconButton edge="end" aria-label="delete" disabled={disabled}
                                            onClick={() => handleDeleteCategory(categoria)}>
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        </div>
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });

        for (let i = 0; i < idsCategorySeleccionada.length; i++) {
            let idCategorySeleccionada = idsCategorySeleccionada[i];
            let categoriaSeleccionada = categories.byId.categories[idCategorySeleccionada];

            if (categoriaSeleccionada && categoriaSeleccionada.children_categories) {
                Categories[categoriaSeleccionada.level + 1] = categoriaSeleccionada.children_categories.map((idCategory, i) => {
                    let categoria = categories.byId.categories[idCategory];
                    return (
                        <ListItem onClick={(e) => handleChangeCategory(e, categoria.id)}
                                  selected={idsCategorySeleccionada[categoria.level] === categoria.id}
                                  style={{cursor: "pointer"}}>
                            <ListItemText
                                primary={categoria.nameCategory}
                            />
                            <ListItemSecondaryAction className={classes.botonesMando}>
                                <div>
                                    {/*<Checkbox icon={<FavoriteBorder/>} checkedIcon={<Favorite/>} name="destacado"*/}
                                    {/*          id="destacado"*/}
                                    {/*          checked={!!categoria.destacado}*/}
                                    {/*          onChange={(e) => handleChangeEstado(e, categoria)}/>*/}
                                    <IconButton edge="end" aria-label="delete" disabled={disabled}
                                                onClick={() => handleClickOpen(categoria.level + 1, idCategory)}>
                                        <ControlPoint/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" disabled={disabled}
                                                onClick={() => handleClickOpenUpdate(categoria.level + 1, idCategory)}>
                                        <EditIcon/>
                                    </IconButton>
                                    {
                                        !categoria.externalId &&
                                        <IconButton edge="end" aria-label="delete" disabled={disabled}
                                                    onClick={() => handleDeleteCategory(categoria)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    }
                                </div>
                            </ListItemSecondaryAction>

                        </ListItem>
                    )
                })
            }
        }

        if (Object.keys(Categories).length === 0)
            Categories[1] = [];
        OrdenesCategories = Object.keys(Categories).map((level) => {
            return (
                <div className={classes.containerNivel}>
                    <div className={classes.encabezadoNivel}>
                        <h3>{`Nivel ${level}`}</h3>
                        <Button variant="outlined" size="small" color="secondary" disabled={disabled}
                                onClick={() => handleClickOpen(parseInt(level))}>
                            Agregar categoria
                        </Button>
                    </div>
                    <List dense={false}>
                        {Categories[level]}
                    </List>
                </div>
            )
        });
    }

    return (
        <Container maxWidth={"xg"} className={classes.withoutPadding}>
            <Grid item xs={12}>
                <div className={classes.listas}>
                    {OrdenesCategories}
                </div>
            </Grid>
            <Dialog open={open} fullWidth={"lg"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Categoria
                </DialogTitle>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="level"
                            label="Nivel de categoria"
                            type="number"
                            fullWidth
                            placeholder="Nivel de categoria"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            InputProps={{disabled: true}}
                            value={categoria && categoria.level ? categoria.level : ""}
                            onChange={handleChangeCategoryDatos}
                            onBlur={validar}
                            error={!validador.isValidById("level")}
                            helperText={validador.getHelperTextById("level")}
                        />
                        {
                            categoria.level === 2 &&
                            <TextField
                                id={"idParentCategory"}
                                select
                                label="Category padre"
                                margin="normal"
                                fullWidth
                                value={categoria && categoria.idParentCategory ? categoria.idParentCategory : ""}
                                onChange={(e) => handleChangeCategoryDatos(e)}
                                InputProps={{
                                    id: "idParentCategory", name: "idParentCategory"
                                }}
                                // helperText="Please select your currency"
                                variant="outlined"
                                onBlur={validar}
                                error={!validador.isValidById("idParentCategory")}
                                helperText={validador.getHelperTextById("idParentCategory")}
                            >
                                {
                                    categories.allIds.map((idCategory) => {
                                        let categoriaHija = categories.byId.categories[idCategory];
                                        return <MenuItem key={categoriaHija.id} value={categoriaHija.id}>
                                            {categoriaHija.nameCategory}
                                        </MenuItem>
                                    })
                                }
                            </TextField>
                        }
                        {
                            categoria.level > 2 && idsCategorySeleccionada[categoria.level - 2] && categories.byId.categories[idsCategorySeleccionada[categoria.level - 2]] &&
                            <TextField
                                id={"idParentCategory"}
                                select
                                label="Category padre"
                                margin="normal"
                                fullWidth
                                value={categoria && categoria.idParentCategory ? categoria.idParentCategory : ""}
                                onChange={(e) => handleChangeCategoryDatos(e)}
                                InputProps={{
                                    id: "idParentCategory", name: "idParentCategory"
                                }}
                                // helperText="Please select your currency"
                                variant="outlined"
                                onBlur={validar}
                                error={!validador.isValidById("idParentCategory")}
                                helperText={validador.getHelperTextById("idParentCategory")}
                            >
                                {
                                    categories.byId.categories[idsCategorySeleccionada[categoria.level - 2]].children_categories.map((idCategory) => {
                                        let categoriaHija = categories.byId.categories[idCategory];
                                        return <MenuItem key={categoriaHija.id} value={categoriaHija.id}>
                                            {categoriaHija.nameCategory}
                                        </MenuItem>
                                    })
                                }
                            </TextField>
                        }
                        <TextField
                            autoFocus
                            margin="normal"
                            id="codCategory"
                            label="Código"
                            type="text"
                            fullWidth
                            placeholder="Código"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={categoria && categoria.codCategory ? categoria.codCategory : ""}
                            onChange={handleChangeCategoryDatos}
                            onBlur={validar}
                            error={!validador.isValidById("codCategory")}
                            helperText={validador.getHelperTextById("codCategory")}
                        />
                        <TextField
                            autoFocus
                            margin="normal"
                            id="priority"
                            label="Prioridad"
                            type="text"
                            fullWidth
                            placeholder="Prioridad"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                type: "number",
                            }}
                            variant="outlined"
                            value={categoria && categoria.priority ? categoria.priority : ""}
                            onChange={handleChangeCategoryDatos}
                            onBlur={validar}
                            error={!validador.isValidById("priority")}
                            helperText={validador.getHelperTextById("priority")}
                        />
                        <FileCategory files={files} setFiles={setFiles} changeCategory={changeCategory}
                                      category={categoria} acceptTypes={"image/svg"}/>
                        {
                            languages.allIds && languages.allIds.map((idLanguage, i) => {
                                let language = languages.byId.languages[idLanguage];
                                let category = idCategory ? categories.update.activo : categories.create.nuevo;
                                let categoryLanguageFound = null;
                                if (category && category.category_language)
                                    category.category_language.some((idCategoryLanguage) => {
                                        let categoryLanguage = categoriesLanguages.byId.categoriesLanguages[idCategoryLanguage];
                                        if (categoriesLanguages.update.activos.indexOf(idCategoryLanguage) !== -1)
                                            categoryLanguage = categoriesLanguages.update.activo[idCategoryLanguage];
                                        if (categoriesLanguages.create.nuevos.indexOf(idCategoryLanguage) !== -1)
                                            categoryLanguage = categoriesLanguages.create.nuevo[idCategoryLanguage];
                                        if (categoryLanguage && categoryLanguage.idLanguage === idLanguage && (categoryLanguage.status === undefined || categoryLanguage.status === 1)) {
                                            categoryLanguageFound = categoryLanguage;
                                            return true;
                                        }
                                        return false;
                                    })
                                return <CategoryLanguage language={language} categoryLanguage={categoryLanguageFound}
                                                         changeCategoryLanguage={changeCategoryLanguage}
                                                         handleChangeCategoryLanguage={handleChangeCategoryLanguage}/>;

                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={guardandoLoading}>
                            Cancelar
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={guardandoLoading}
                            >
                                Guardar
                            </Button>
                            {guardandoLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </Container>
    );
}
