import {normalize, schema, denormalize} from 'normalizr';

const assetLanguage = new schema.Entity('assetsLanguages', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {assetsLanguages: [assetLanguage]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = assetLanguage;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = assetLanguage;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default assetLanguage;
