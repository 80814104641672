import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//Components
import {
  Breadcrumbs,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import logReset from "../../reset/logReset";
import { fetchLogs, invalidateLogs, resetLogs } from "../../actions/LogActions";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import CropFreeIcon from "@material-ui/icons/Search";
import Grow from "../../library/Grow";
import PaperFooter from "../../library/CustomPaper/PaperFooter";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import PhoneIcon from "@material-ui/icons/Phone";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import AppsIcon from "@material-ui/icons/Apps";
import PublicIcon from "@material-ui/icons/Public";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LinkIcon from "@material-ui/icons/Link";
import moment from "moment";
import c from "../../constants/Constants";
import { useHistory } from "react-router";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import * as roles from "../../constants/Roles";
//Icons
//Img

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
    backgroundSize: "contain",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function LogsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [idLog, setIdLog] = React.useState(null);

  //Store
  const auth = useSelector((state) => state.auth);
  const logs = useSelector((state) => state.logs);
  let log = idLog ? logs.byId.logs[idLog] : null;

  //State
  const [search, setSearch] = useState("");

  const params = new URLSearchParams(props.location.search);
  const order = params.get("order") ? params.get("order") : "id";
  const direction = params.get("direction") ? params.get("direction") : "desc";
  const page = params.get("page") ? params.get("page") : 1;
  const searchPhase = params.get("sp") ? params.get("sp") : "";
  const type = params.get("type") ? params.get("type") : "";
  const idCategorySelected = params.get("idCategory")
    ? params.get("idCategory")
    : "";

  const disabled = logs.byId.isFetching || logs.delete.isDeleting;

  //Hooks
  const timer = useRef();
  const mounted = useRef();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchPhase != search) {
      setSearch(searchPhase);
    }

    return function cleanup() {
      logReset.resetAll(dispatch);
    };
  }, []);

  useEffect(() => {
    let filtros = {
      searchPhase: search ? search : "",
      order: order,
      direction: direction,
      page: page,
      idCategory: idCategorySelected,
      with: ["logLanguage", "logCountry"],
      type: type,
    };

    dispatch(resetLogs());
    dispatch(invalidateLogs());
    dispatch(fetchLogs(filtros));
  }, [props.location.search]);

  const changeOrder = (or) => {
    params.set("order", or ? or : "");
    let direction = params.get("direction");
    params.set("direction", direction === "asc" ? "desc" : "asc");
    props.history.push("/logs?" + params.toString());
  };
  const WAIT_INTERVAL = 500;

  const changeSearch = (e) => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = (searchPhase) => {
    if ((!searchPhase && !search) || searchPhase === "") {
      params.delete("sp");
      props.history.push("/logs?" + params.toString());
    } else {
      params.delete("page");
      params.set("sp", searchPhase ? searchPhase : search);
      props.history.push("/logs?" + params.toString());
    }
  };

  const handleChangePage = (e, page) => {
    let pagina = page;
    let paramsPagina = new URLSearchParams(props.location.search);
    paramsPagina.set("page", pagina ? pagina : "1");
    props.history.push("/logs?" + paramsPagina.toString());
  };

  const handleChangeType = (e, type) => {
    let paramsType = new URLSearchParams(props.location.search);
    paramsType.set("type", type ? type : "");
    props.history.push("/logs?" + paramsType.toString());
  };

  const handleOpenData = (idLog) => {
    setIdLog(idLog);
    setOpen(true);
  };

  const getIconLog = (icon) => {
    switch (icon) {
      case "enterprise":
        return <HomeIcon />;
      case "name":
        return <EmojiPeopleIcon />;
      case "lastname":
        return <EmojiPeopleIcon />;
      case "email":
        return <MailOutlineIcon />;
      case "contact_description":
        return <InsertCommentIcon />;
      case "job_post_name":
        return <AppsIcon />;
      case "mobilephone":
        return <PhoneIcon />;
      case "contact_type":
        return <NewReleasesIcon />;
      case "originalFilename":
        return <CloudDownloadIcon />;
      case "idPost":
        return <LinkIcon />;
      case "linkedin":
        return <LinkedInIcon />;
      case "birthday":
        return <CalendarTodayIcon />;
      default:
        return <PublicIcon />;
    }
  };

  const getDesLog = (des) => {
    switch (des) {
      case "enterprise":
        return "Empresa";
      case "name":
        return "Nombre";
      case "lastname":
        return "Apellido";
      case "email":
        return "Email";
      case "contact_description":
        return "Mensaje";
      case "job_post_name":
        return "Asunto";
      case "mobilephone":
        return "Teléfono";
      case "contact_type":
        return "Tipo consulta";
      case "maritalStatus":
        return "Estado civil";
      case "notiwiener_subscribe":
        return "Subscripción a Notiwiener";
      case "nationality":
        return "Nacionalidad";
      case "idPost":
        return "Ref. empleo";
      case "lan":
        return "Idioma";
      case "birthday":
        return "Fecha nacimiento";
      case "linkedin":
        return "LinkedIn";
      case "secNationality":
        return "Segunda nacionalidad";
      case "originalFilename":
        return "Nombre archivo";
      case "address":
        return "Dirección";
      case "paper_edition":
        return "Edición impresa";
      case "state":
        return "Provincia";
      case "city":
        return "Ciudad";
      case "country":
        return "País";
      case "locale":
        return "Localización";
      case "language":
        return "Idioma";
      case "ip":
        return "IP";
      default:
        return des.toUpperCase();
    }
  };

  const getCodLog = (des) => {
    switch (des) {
      case "Empresa":
        return "enterprise";
      case "Nombre":
        return "name";
      case "Apellido":
        return "lastname";
      case "Email":
        return "email";
      case "Mensaje":
        return "contact_description";
      case "Asunto":
        return "job_post_name";
      case "Teléfono":
        return "mobilephone";
      case "Tipo consulta":
        return "contact_type";
      case "Estado civil":
        return "maritalStatus";
      case "Subscripción a Notiwiener":
        return "notiwiener_subscribe";
      case "Nacionalidad":
        return "nationality";
      case "Ref. empleo":
        return "idPost";
      case "Idioma":
        return "language";
      case "Nombre archivo":
        return "originalFilename";
      case "Fecha nacimiento":
        return "birthday";
      case "LinkedIn":
        return "linkedin";
      case "Segunda nacionalidad":
        return "secNationality";
      case "Dirección":
        return "address";
      case "Edición impresa":
        return "paper_edition";
      case "Provincia":
        return "state";
      case "Ciudad":
        return "city";
      case "País":
        return "country";
      case "Localización":
        return "locale";
      case "IP":
        return "ip";
      default:
        return des;
    }
  };

  const previewFile = (url) => {
    // let fileSubido = file;
    // let reader = new FileReader();
    // reader.onloadend = function () {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" +
        c.API_HOST +
        url +
        "'></iframe>"
    );
    // };
    // if (fileSubido) {
    //     reader.readAsDataURL(fileSubido);
    // }
  };

  let Logs = [];

  if (logs.byId.isFetching) {
    Logs = [...Array(5).keys()].map((i) => {
      return (
        <TableRow key={"inv" + i}>
          {[...Array(6).keys()].map((i) => (
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  if (!logs.byId.isFetching)
    Logs = logs.allIds.map((idLog) => {
      let log = logs.byId.logs[idLog];
      return (
        <TableRow key={log.id}>
          <TableCell>{log ? log.id : ""}</TableCell>
          <TableCell>{log ? log.desOp : ""}</TableCell>
          <TableCell>{log ? log.userLastMdf : ""}</TableCell>
          <TableCell>{log ? log.userLastMdf + " " + log.desLog : ""}</TableCell>
          <TableCell>
            {log
              ? moment(log.created_at, "YYYY-MM-DD HH:mm").format(
                  "DD-MM-YYYY HH:mm"
                )
              : ""}
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenData(idLog)}
            >
              Ver
            </Button>
          </TableCell>
        </TableRow>
      );
    });

  if (Logs.length === 0)
    Logs.push(
      <TableRow key={0}>
        <TableCell align="center" colSpan={11}>
          No se encontraron registros
        </TableCell>
      </TableRow>
    );

  const totalPaginas = Math.ceil(logs.totalLogs ? logs.totalLogs / 50 : 0);

  let unordered = {};
  log &&
    log.data &&
    Object.keys(JSON.parse(log.data)).forEach((key) => {
      unordered[getDesLog(key)] = JSON.parse(log.data)[key];
    });
  console.log(unordered);
  const ordered =
    unordered &&
    Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[getCodLog(key)] = unordered[key];
        return obj;
      }, {});
  return (
    <Container maxWidth={"xg"}>
      <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
        <Link color="inherit" to="/">
          Home
        </Link>
      </Breadcrumbs>
      <Typography component={"h1"} variant={"h1"}>
        Registro
      </Typography>
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              ),
              // disabled: disabled
            }}
            value={search ? search : ""}
            onChange={(e) => changeSearch(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <Grow />
          {auth &&
            auth.usuarioLogueado &&
            auth.usuarioLogueado.idRole !== roles.CAPITAL_HUMANO_ID && (
              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleChangeType}
                aria-label="text alignment"
              >
                <ToggleButton value="todos" aria-label="left aligned">
                  Todos
                </ToggleButton>
                <ToggleButton value="empleo" aria-label="centered">
                  Empleo
                </ToggleButton>
                <ToggleButton value="contacto" aria-label="right aligned">
                  Contacto
                </ToggleButton>
                <ToggleButton value="notiwiener" aria-label="justified">
                  Subscripción al Notiwiener
                </ToggleButton>
                <ToggleButton value="consulta_producto" aria-label="justified">
                  Consulta producto
                </ToggleButton>
                <ToggleButton value="descarga_demo" aria-label="justified">
                  Demo
                </ToggleButton>
              </ToggleButtonGroup>
            )}
        </PaperHeader>
        <TableContainer>
          <Table aria-label="Tabla de contenido">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order === "id"}
                    direction={direction}
                    onClick={() => changeOrder("id")}
                  >
                    <b>ID</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === "type"}
                    direction={direction}
                    onClick={() => changeOrder("type")}
                  >
                    <b>Tipo</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === "idUserLastMdf"}
                    direction={direction}
                    onClick={() => changeOrder("idUserLastMdf")}
                  >
                    <b>Usuario</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === "genericName"}
                    direction={direction}
                    onClick={() => changeOrder("genericName")}
                  >
                    <b>Descripción</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === "created_at"}
                    direction={direction}
                    onClick={() => changeOrder("created_at")}
                  >
                    <b>Fecha</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <b>Datos</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{Logs}</TableBody>
          </Table>
        </TableContainer>
        <PaperFooter style={{ justifyContent: "center" }}>
          {!!totalPaginas && totalPaginas > 1 && (
            <Pagination
              count={totalPaginas}
              page={page ? parseInt(page) : 1}
              color="primary"
              onChange={handleChangePage}
              size={"small"}
            />
          )}
        </PaperFooter>
      </CustomPaper>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {log ? log.desOp : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Los datos de la solicitud</DialogContentText>
          <p>{log ? (log ? log.userLastMdf + " " + log.desLog : "") : ""}</p>
          <List component="nav" aria-label="main mailbox folders">
            {ordered &&
              Object.keys(ordered).map((key) => {
                let data = JSON.parse(log.data);
                if (data[key] && data[key] !== "null")
                  if (key === "originalFilename")
                    return (
                      <ListItem button onClick={() => previewFile(log.path)}>
                        <ListItemIcon>{getIconLog(key)}</ListItemIcon>
                        <ListItemText
                          primary={getDesLog(key) + ": " + data[key]}
                        />
                      </ListItem>
                    );
                if (key === "idPost")
                  return (
                    <ListItem
                      button
                      onClick={() => history.push("/posts/" + data[key])}
                    >
                      <ListItemIcon>{getIconLog(key)}</ListItemIcon>
                      <ListItemText
                        primary={getDesLog(key) + ": " + data[key]}
                      />
                    </ListItem>
                  );
                return (
                  <ListItem>
                    <ListItemIcon>{getIconLog(key)}</ListItemIcon>
                    <ListItemText primary={getDesLog(key) + ": " + data[key]} />
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

LogsList.propTypes = {};

export default LogsList;
