import {
    INVALIDATE_ATTRIBUTESLANGUAGES,
    ERROR_ATTRIBUTESLANGUAGES,
    RECEIVE_ATTRIBUTESLANGUAGES,
    REQUEST_ATTRIBUTESLANGUAGES,
    RESET_ATTRIBUTESLANGUAGES,
    ERROR_ATTRIBUTELANGUAGE,
    RECEIVE_ATTRIBUTELANGUAGE,
    REQUEST_ATTRIBUTELANGUAGE,
    UPDATE_ATTRIBUTELANGUAGE,
    REQUEST_UPDATE_ATTRIBUTELANGUAGE,
    SUCCESS_UPDATE_ATTRIBUTELANGUAGE,
    ERROR_UPDATE_ATTRIBUTELANGUAGE,
    RESET_UPDATE_ATTRIBUTELANGUAGE,
    REQUEST_UPDATE_ATTRIBUTESLANGUAGES,
    SUCCESS_UPDATE_ATTRIBUTESLANGUAGES,
    ERROR_UPDATE_ATTRIBUTESLANGUAGES,
    RESET_UPDATE_ATTRIBUTESLANGUAGES,
    CREATE_ATTRIBUTELANGUAGE,
    ERROR_CREATE_ATTRIBUTELANGUAGE,
    REQUEST_CREATE_ATTRIBUTELANGUAGE,
    RESET_CREATE_ATTRIBUTELANGUAGE,
    SUCCESS_CREATE_ATTRIBUTELANGUAGE,
    REQUEST_CREATE_ATTRIBUTESLANGUAGES,
    SUCCESS_CREATE_ATTRIBUTESLANGUAGES,
    ERROR_CREATE_ATTRIBUTESLANGUAGES,
    RESET_CREATE_ATTRIBUTESLANGUAGES,
    DELETE_ATTRIBUTELANGUAGE,
    DELETE_CREATE_ATTRIBUTELANGUAGE,
    DELETE_UPDATE_ATTRIBUTELANGUAGE,
    REQUEST_DELETE_ATTRIBUTELANGUAGE,
    SUCCESS_DELETE_ATTRIBUTELANGUAGE,
    ERROR_DELETE_ATTRIBUTELANGUAGE,
    RESET_DELETE_ATTRIBUTELANGUAGE,
    REQUEST_PRINT_ATTRIBUTELANGUAGE,
    SUCCESS_PRINT_ATTRIBUTELANGUAGE,
    ERROR_PRINT_ATTRIBUTELANGUAGE,
    RESET_PRINT_ATTRIBUTELANGUAGE,
    RECEIVE_FILE_ATTRIBUTELANGUAGE
} from '../actions/AttributeLanguageActions';

import {
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS,
    SUCCESS_DELETE_PRODUCT,
    SUCCESS_CREATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCT,
    SUCCESS_UPDATE_PRODUCTS

} from '../actions/ProductActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        attributesLanguages: {},
        files: {},
    }
}

function attributesLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                attributesLanguages: {}
            });
        case RECEIVE_ATTRIBUTESLANGUAGES:
            let dato = action.attributesLanguages.entities.attributesLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                attributesLanguages: merge({}, state.attributesLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguage = action.attributeLanguage.entities.attributesLanguages;
            return Object.assign({}, state, {
                attributesLanguages: merge({}, state.attributesLanguages, datoAttributeLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageEliminado = action.attributeLanguage.entities.attributesLanguages;
            return Object.assign({}, state, {
                attributesLanguages: mergeWith(clone(datoAttributeLanguageEliminado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageCreado = action.attributeLanguage.entities.attributesLanguages;
            return Object.assign({}, state, {
                attributesLanguages: mergeWith(clone(datoAttributeLanguageCreado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ATTRIBUTESLANGUAGES:
            let datosAttributeLanguageCreado = action.attributesLanguages.entities.attributesLanguages;
                return Object.assign({}, state, {
                    attributesLanguages: mergeWith(clone(datosAttributeLanguageCreado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageActualizado = action.attributeLanguage.entities.attributesLanguages;
            return Object.assign({}, state, {
                attributesLanguages: mergeWith(clone(datoAttributeLanguageActualizado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ATTRIBUTESLANGUAGES:
            let datosAttributeLanguageActualizado = action.attributesLanguages.entities.attributesLanguages;
                return Object.assign({}, state, {
                    attributesLanguages: mergeWith(clone(datosAttributeLanguageActualizado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
                });

            //PRODUCT
case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : {};
    return Object.assign({}, state, {
        attributesLanguages: merge({}, state.attributesLanguages, product),
    });
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributesLanguages ? action.products.entities.attributesLanguages : {};
    return Object.assign({}, state, {
        attributesLanguages: merge({}, state.attributesLanguages, products),
    });
case SUCCESS_DELETE_PRODUCT:
    let datoproductEliminado = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : {};
    return Object.assign({}, state, {
        attributesLanguages: mergeWith(clone(datoproductEliminado), pickBy(state.attributesLanguages, function(attributeLanguage) {return attributeLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCT:
    let datoproductCreado = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : {};
    return Object.assign({}, state, {
        attributesLanguages: mergeWith(clone(datoproductCreado), pickBy(state.attributesLanguages, function(attributeLanguage) {return attributeLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCT:
    let datoproductActualizado = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : {};
    return Object.assign({}, state, {
        attributesLanguages: mergeWith(clone(datoproductActualizado), pickBy(state.attributesLanguages, function(attributeLanguage) {return attributeLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTS:
    let datosproductActualizado = action.products.entities && action.products.entities.attributesLanguages ? action.products.entities.attributesLanguages : {} ;
        return Object.assign({}, state, {
            attributesLanguages: mergeWith(clone(datosproductActualizado), state.attributesLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                attributesLanguages: {}
            });
        default:
            return state
    }
}


function allAttributesLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTESLANGUAGES:
            return action.attributesLanguages.result && action.attributesLanguages.result.attributesLanguages ? union(action.attributesLanguages.result.attributesLanguages, state) : (action.attributesLanguages.result ? action.attributesLanguages.result : state) ;
        case RECEIVE_ATTRIBUTELANGUAGE:
                return action.attributeLanguage.result ? union([action.attributeLanguage.result], state) : state;
        
        case SUCCESS_CREATE_ATTRIBUTELANGUAGE:
                   let datoAttributeLanguageSCreate = action.attributeLanguage.entities.attributesLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAttributeLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAttributeLanguageSCreate)[0] && Object.values(datoAttributeLanguageSCreate)[0].id ? Object.values(datoAttributeLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ATTRIBUTESLANGUAGES:
                   let attributesLanguagesCreate = action.attributesLanguages.entities && action.attributesLanguages.entities.attributesLanguages ? action.attributesLanguages.entities.attributesLanguages : null;
                   return attributesLanguagesCreate ?
                       union(state, Object.values(attributesLanguagesCreate).map((attributesLanguages) => {
                           return attributesLanguages.id
                       })) : state;
        case RESET_ATTRIBUTESLANGUAGES:
            return [];

            case RECEIVE_PRODUCT:
    let product = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : null ;
    return product ?
        union(state, Object.values(product).map((product) => {
            return product.id
        })) : state;
case RECEIVE_PRODUCTS:
    let products = action.products.entities && action.products.entities.attributesLanguages ? action.products.entities.attributesLanguages : null;
    return products ?
        union(state, Object.values(products).map((products) => {
            return products.id
        })) : state;

case SUCCESS_DELETE_PRODUCT:
    let productDelete = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : null ;
        return productDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productDelete).map((product) => {
                return product.id
            })) : state;
case SUCCESS_CREATE_PRODUCT:
   let productCreate = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : null ;
       return productCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productCreate).map((product) => {
               return product.id
           })) : state;
case SUCCESS_UPDATE_PRODUCT:
    let productUpdate = action.product.entities && action.product.entities.attributesLanguages ? action.product.entities.attributesLanguages : null ;
        return productUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productUpdate).map((product) => {
                return product.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTS:
     let productsUpdate = action.products.entities && action.products.entities.attributesLanguages ? action.products.entities.attributesLanguages : null;
        return productsUpdate ?
            union(state, Object.values(productsUpdate).map((products) => {
                return products.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAttributesLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTESLANGUAGES:
            return action.attributesLanguages && action.attributesLanguages.result.total ? action.attributesLanguages.result.total : 0;
        case RESET_ATTRIBUTESLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ATTRIBUTELANGUAGE:
            let dato = action.attributeLanguage.entities.attributesLanguages;
            let attributeLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: attributeLanguage ? attributeLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ATTRIBUTELANGUAGE:
            let idsUpdate = [];
            Object.values(action.attributeLanguage).map((attributeLanguageUpdate) => {
                if (attributeLanguageUpdate && attributeLanguageUpdate.id)
                    idsUpdate.push(attributeLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.attributeLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageActualizado = {};
            if (Object.values(action.attributeLanguage.entities.attributesLanguages).length > 0)
                datoAttributeLanguageActualizado = Object.values(action.attributeLanguage.entities.attributesLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAttributeLanguageActualizado
            });
        case ERROR_UPDATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ATTRIBUTESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ATTRIBUTESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ATTRIBUTESLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PRODUCT
//TODO ver si esta bien
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageDelete = action.attributeLanguage;
            let idsDelete = [];
           Object.values(action.attributeLanguage).map((attributeLanguageDelete) => {
               if (attributeLanguageDelete && attributeLanguageDelete.id)
                   idsDelete.push(attributeLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAttributeLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ATTRIBUTELANGUAGE:
                       let datoAttributeLanguageDeleteUpdate = action.attributeLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.attributeLanguage).map((attributeLanguageDelete) => {
                          if (attributeLanguageDelete && attributeLanguageDelete.id)
                              idsDeleteUpdate.push(attributeLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAttributeLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ATTRIBUTELANGUAGE:
                    let datoAttributeLanguageDeleted = {};
                    if (Object.values(action.attributeLanguage.entities.attributesLanguages).length > 0)
                        datoAttributeLanguageDeleted = Object.values(action.attributeLanguage.entities.attributesLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAttributeLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ATTRIBUTELANGUAGE:
             let idsCreate = [];
             Object.values(action.attributeLanguage).map((attributeLanguageCreate) => {
                 if (attributeLanguageCreate && attributeLanguageCreate.id)
                     idsCreate.push(attributeLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.attributeLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ATTRIBUTELANGUAGE:
            let datoAttributeLanguageNuevo = {};
            if (Object.values(action.attributeLanguage.entities.attributesLanguages).length > 0)
                datoAttributeLanguageNuevo = Object.values(action.attributeLanguage.entities.attributesLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAttributeLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ATTRIBUTESLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ATTRIBUTELANGUAGE:
           let datoAttributeLanguageDelete = action.attributeLanguage;
           let idsDelete = [];
           Object.values(action.attributeLanguage).map((attributeLanguageDelete) => {
               if (attributeLanguageDelete && attributeLanguageDelete.id)
                   idsDelete.push(attributeLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ATTRIBUTELANGUAGE:
                  let datoAttributeLanguageDeleteCreate = action.attributeLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.attributeLanguage).map((attributeLanguageDelete) => {
                      if (attributeLanguageDelete && attributeLanguageDelete.id)
                          idsDeleteCreate.push(attributeLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAttributeLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ATTRIBUTELANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.attributeLanguage,
                error: null,
            });
        case REQUEST_DELETE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PRODUCT
 case SUCCESS_CREATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCT:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ATTRIBUTELANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const attributesLanguages = combineReducers({
    byId: attributesLanguagesById,
    allIds: allAttributesLanguages,
    update: update,
    create: create,
    totalAttributesLanguages: totalAttributesLanguages,
    delete: deleter,
    print: print
});

export default attributesLanguages;