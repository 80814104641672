//api
import categoriesLanguagesApi from "../api/categoriesLanguagesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeCategoriesLanguages";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//CATEGORIESLANGUAGES
export const REQUEST_CATEGORIESLANGUAGES = 'REQUEST_CATEGORIESLANGUAGES';
export const RECEIVE_CATEGORIESLANGUAGES = 'RECEIVE_CATEGORIESLANGUAGES';
export const INVALIDATE_CATEGORIESLANGUAGES = 'INVALIDATE_CATEGORIESLANGUAGES';
export const ERROR_CATEGORIESLANGUAGES = "ERROR_CATEGORIESLANGUAGES";
export const RESET_CATEGORIESLANGUAGES = "RESET_CATEGORIESLANGUAGES";


export function invalidateCategoriesLanguages() {
    return {
        type: INVALIDATE_CATEGORIESLANGUAGES
    }
}

function requestCategoriesLanguages() {
    return {
        type: REQUEST_CATEGORIESLANGUAGES,
    }
}

function receiveCategoriesLanguages(json) {
    return {
        type: RECEIVE_CATEGORIESLANGUAGES,
    categoriesLanguages: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorCategoriesLanguages(error) {
    return {
        type: ERROR_CATEGORIESLANGUAGES,
        error: error,
    }
}

export function resetCategoriesLanguages() {
    return {
        type: RESET_CATEGORIESLANGUAGES
    }
}

export function fetchCategoriesLanguages(filtros) {
    return dispatch => {
        dispatch(requestCategoriesLanguages());
        return categoriesLanguagesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategoriesLanguages(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategoriesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategoriesLanguages(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategoriesLanguages(state) {
    const categoriesLanguages = state.categoriesLanguages.byId;
    if (!categoriesLanguages) {
        return true
    } else if (categoriesLanguages.isFetching) {
        return false
    } else {
        return categoriesLanguages.didInvalidate;
    }
}

export function fetchCategoriesLanguagesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCategoriesLanguages(getState())) {
            return dispatch(fetchCategoriesLanguages(filtros))
        }
    }
}


//MODEL
export const REQUEST_CATEGORYLANGUAGE = 'REQUEST_CATEGORYLANGUAGE';
export const RECEIVE_CATEGORYLANGUAGE = 'RECEIVE_CATEGORYLANGUAGE';
export const INVALIDATE_CATEGORYLANGUAGE = 'INVALIDATE_CATEGORYLANGUAGE';
export const ERROR_CATEGORYLANGUAGE = "ERROR_CATEGORYLANGUAGE";
export const RESET_CATEGORYLANGUAGE = "RESET_CATEGORYLANGUAGE";


export function invalidateCategoryLanguage() {
    return {
        type: INVALIDATE_CATEGORYLANGUAGE
    }
}

function requestCategoryLanguage() {
    return {
        type: REQUEST_CATEGORYLANGUAGE,
    }
}

export function receiveCategoryLanguage(json) {
    return {
        type: RECEIVE_CATEGORYLANGUAGE,
    categoryLanguage: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorCategoryLanguage(error) {
    return {
        type: ERROR_CATEGORYLANGUAGE,
        error: error,
    }
}

export function fetchCategoryLanguage(idCategoryLanguage) {
    return dispatch => {
        dispatch(requestCategoryLanguage());
        return categoriesLanguagesApi.getOne(idCategoryLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategoryLanguage(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategoryLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_CATEGORYLANGUAGE = 'RECEIVE_FILE_CATEGORYLANGUAGE';

function receiveFileCategoryLanguage(file) {
    return {
        type: RECEIVE_FILE_CATEGORYLANGUAGE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileCategoryLanguage(idCategoryLanguage, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return categoriesLanguagesApi.getFile(idCategoryLanguage, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileCategoryLanguage(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategoryLanguage(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_CATEGORYLANGUAGE = 'UPDATE_CATEGORYLANGUAGE';
export const REQUEST_UPDATE_CATEGORYLANGUAGE = "REQUEST_UPDATE_CATEGORYLANGUAGE";
export const SUCCESS_UPDATE_CATEGORYLANGUAGE = "SUCCESS_UPDATE_CATEGORYLANGUAGE";
export const ERROR_UPDATE_CATEGORYLANGUAGE = "ERROR_UPDATE_CATEGORYLANGUAGE";
export const RESET_UPDATE_CATEGORYLANGUAGE = "RESET_UPDATE_CATEGORYLANGUAGE";
export const DELETE_UPDATE_CATEGORYLANGUAGE = "DELETE_UPDATE_CATEGORYLANGUAGE";

function requestUpdateCategoryLanguage() {
    return {
        type: REQUEST_UPDATE_CATEGORYLANGUAGE,
    }
}

function receiveUpdateCategoryLanguage(categoryLanguage) {
    return {
        type: SUCCESS_UPDATE_CATEGORYLANGUAGE,
        receivedAt: Date.now(),
        categoryLanguage: normalizeDato(categoryLanguage)
    }
}

function errorUpdateCategoryLanguage(error) {
    return {
        type: ERROR_UPDATE_CATEGORYLANGUAGE,
        error: error,
    }
}

export function resetUpdateCategoryLanguage() {
    return {
        type: RESET_UPDATE_CATEGORYLANGUAGE,
    }
}

export function updateCategoryLanguage(categoryLanguage) {
    return {
        type: UPDATE_CATEGORYLANGUAGE,
        categoryLanguage
    }
}

export function saveUpdateCategoryLanguage() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategoryLanguage());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoryLanguage = denormalizeDato(getState().categoriesLanguages.update.activo, store);

        return categoriesLanguagesApi.saveUpdate(categoryLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCategoryLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateCategoriesLanguages(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateCategoriesLanguages(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateCategoryLanguage(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateCategoryLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateCategoryLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateCategoryLanguage(categoryLanguage) {
    return {
        type: DELETE_UPDATE_CATEGORYLANGUAGE,
        categoryLanguage
    }
}

//UPDATE CATEGORIESLANGUAGES
export const REQUEST_UPDATE_CATEGORIESLANGUAGES = "REQUEST_UPDATE_CATEGORIESLANGUAGES";
export const SUCCESS_UPDATE_CATEGORIESLANGUAGES = "SUCCESS_UPDATE_CATEGORIESLANGUAGES";
export const ERROR_UPDATE_CATEGORIESLANGUAGES = "ERROR_UPDATE_CATEGORIESLANGUAGES";
export const RESET_UPDATE_CATEGORIESLANGUAGES = "RESET_UPDATE_CATEGORIESLANGUAGES";

function requestUpdateCategoriesLanguages() {
    return {
        type: REQUEST_UPDATE_CATEGORIESLANGUAGES,
}
}

function receiveUpdateCategoriesLanguages(categoriesLanguages) {
    return {
        type: SUCCESS_UPDATE_CATEGORIESLANGUAGES,
    receivedAt: Date.now(),
        categoriesLanguages: normalizeDatos(categoriesLanguages)
}
}

function errorUpdateCategoriesLanguages(error) {
    return {
        type: ERROR_UPDATE_CATEGORIESLANGUAGES,
    error: error,
}
}

export function resetUpdateCategoriesLanguages() {
    return {
        type: RESET_UPDATE_CATEGORIESLANGUAGES,
}
}

export function saveUpdateCategoriesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategoriesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoriesLanguages = getState().categoriesLanguages.update.activos.map((idCategoryLanguage) => {
            return denormalizeDato(getState().categoriesLanguages.update.activo[idCategoryLanguage], store);
        });

        return categoriesLanguagesApi.saveUpdateCategoriesLanguages(categoriesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCategoriesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCategoriesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateCategoriesLanguages(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateCategoriesLanguages(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateCategoriesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateCategoriesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA CATEGORYLANGUAGE
export const CREATE_CATEGORYLANGUAGE = 'CREATE_CATEGORYLANGUAGE';
export const REQUEST_CREATE_CATEGORYLANGUAGE = "REQUEST_CREATE_CATEGORYLANGUAGE";
export const SUCCESS_CREATE_CATEGORYLANGUAGE = "SUCCESS_CREATE_CATEGORYLANGUAGE";
export const ERROR_CREATE_CATEGORYLANGUAGE = "ERROR_CREATE_CATEGORYLANGUAGE";
export const RESET_CREATE_CATEGORYLANGUAGE = "RESET_CREATE_CATEGORYLANGUAGE";
export const DELETE_CREATE_CATEGORYLANGUAGE = "DELETE_CREATE_CATEGORYLANGUAGE";


//ALTA CATEGORYLANGUAGE
function requestCreateCategoryLanguage() {
    return {
        type: REQUEST_CREATE_CATEGORYLANGUAGE,
    }
}

function receiveCreateCategoryLanguage(categoryLanguage) {
    return {
        type: SUCCESS_CREATE_CATEGORYLANGUAGE,
        receivedAt: Date.now(),
        categoryLanguage: normalizeDato(categoryLanguage)
    }
}

export function errorCreateCategoryLanguage(error) {
    return {
        type: ERROR_CREATE_CATEGORYLANGUAGE,
        error: error,
    }
}

export function resetCreateCategoryLanguage() {
    return {
        type: RESET_CREATE_CATEGORYLANGUAGE
    }
}

export function createCategoryLanguage(categoryLanguage) {
    return {
        type: CREATE_CATEGORYLANGUAGE,
        categoryLanguage
    }
}

export function deleteCreateCategoryLanguage(categoryLanguage) {
    return {
        type: DELETE_CREATE_CATEGORYLANGUAGE,
        categoryLanguage
    }
}

export function saveCreateCategoryLanguage() {
    return (dispatch, getState) => {
        dispatch(requestCreateCategoryLanguage());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoryLanguage = denormalizeDato(getState().categoriesLanguages.create.nuevo, store);

        return categoriesLanguagesApi.saveCreate(categoryLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCategoryLanguage(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateCategoriesLanguages(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateCategoriesLanguages(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateCategoryLanguage(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateCategoryLanguage(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateCategoryLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE CATEGORIESLANGUAGES
export const REQUEST_CREATE_CATEGORIESLANGUAGES = "REQUEST_CREATE_CATEGORIESLANGUAGES";
export const SUCCESS_CREATE_CATEGORIESLANGUAGES = "SUCCESS_CREATE_CATEGORIESLANGUAGES";
export const ERROR_CREATE_CATEGORIESLANGUAGES = "ERROR_CREATE_CATEGORIESLANGUAGES";
export const RESET_CREATE_CATEGORIESLANGUAGES = "RESET_CREATE_CATEGORIESLANGUAGES";

function requestCreateCategoriesLanguages() {
    return {
        type: REQUEST_CREATE_CATEGORIESLANGUAGES,
}
}

function receiveCreateCategoriesLanguages(categoriesLanguages) {
    return {
        type: SUCCESS_CREATE_CATEGORIESLANGUAGES,
    receivedAt: Date.now(),
        categoriesLanguages: normalizeDatos(categoriesLanguages)
}
}

function errorCreateCategoriesLanguages(error) {
    return {
        type: ERROR_CREATE_CATEGORIESLANGUAGES,
    error: error,
}
}

export function resetCreateCategoriesLanguages() {
    return {
        type: RESET_CREATE_CATEGORIESLANGUAGES,
}
}

export function saveCreateCategoriesLanguages() {
    return (dispatch, getState) => {
        dispatch(requestCreateCategoriesLanguages());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoriesLanguages = getState().categoriesLanguages.create.nuevos.map((idCategoryLanguage) => {
            return denormalizeDato(getState().categoriesLanguages.create.nuevo[idCategoryLanguage], store);
        });

        return categoriesLanguagesApi.saveCreateCategoriesLanguages(categoriesLanguages)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCategoriesLanguages(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCategoriesLanguages(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateCategoriesLanguages(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateCategoriesLanguages(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateCategoriesLanguages(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateCategoriesLanguages(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE CATEGORYLANGUAGE
export const DELETE_CATEGORYLANGUAGE = 'DELETE_CATEGORYLANGUAGE';
export const REQUEST_DELETE_CATEGORYLANGUAGE = "REQUEST_DELETE_CATEGORYLANGUAGE";
export const SUCCESS_DELETE_CATEGORYLANGUAGE = "SUCCESS_DELETE_CATEGORYLANGUAGE";
export const ERROR_DELETE_CATEGORYLANGUAGE = "ERROR_DELETE_CATEGORYLANGUAGE";
export const RESET_DELETE_CATEGORYLANGUAGE = "RESET_DELETE_CATEGORYLANGUAGE";

function requestDeleteCategoryLanguage() {
    return {
        type: REQUEST_DELETE_CATEGORYLANGUAGE,
    }
}

function receiveDeleteCategoryLanguage(categoryLanguage) {
    return {
        type: SUCCESS_DELETE_CATEGORYLANGUAGE,
        receivedAt: Date.now(),
        categoryLanguage: normalizeDato(categoryLanguage)
    }
}

function errorDeleteCategoryLanguage(error) {
    return {
        type: ERROR_DELETE_CATEGORYLANGUAGE,
        error: error,
    }
}

export function resetDeleteCategoryLanguage(error) {
    return {
        type: RESET_DELETE_CATEGORYLANGUAGE,
        error: error,
    }
}

export function deleteCategoryLanguage(categoryLanguage) {
    return {
        type: DELETE_CATEGORYLANGUAGE,
        categoryLanguage
    }
}

export function saveDeleteCategoryLanguage(categoryLanguage) {
    return dispatch => {
        dispatch(requestDeleteCategoryLanguage());
        return categoriesLanguagesApi.saveDelete(categoryLanguage)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteCategoryLanguage());
                            dispatch(receiveDeleteCategoryLanguage(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteCategoryLanguage(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteCategoryLanguage(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteCategoryLanguage(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteCategoryLanguage(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT CATEGORYLANGUAGE
export const REQUEST_PRINT_CATEGORYLANGUAGE = "REQUEST_PRINT_CATEGORYLANGUAGE";
export const SUCCESS_PRINT_CATEGORYLANGUAGE = "SUCCESS_PRINT_CATEGORYLANGUAGE";
export const ERROR_PRINT_CATEGORYLANGUAGE = "ERROR_PRINT_CATEGORYLANGUAGE";
export const RESET_PRINT_CATEGORYLANGUAGE = "RESET_PRINT_CATEGORYLANGUAGE";

function requestPrintCategoryLanguage() {
    return {
        type: REQUEST_PRINT_CATEGORYLANGUAGE,
    }
}

function receivePrintCategoryLanguage(turnos) {
    return {
        type: SUCCESS_PRINT_CATEGORYLANGUAGE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintCategoryLanguage(error) {
    return {
        type: ERROR_PRINT_CATEGORYLANGUAGE,
        error: error,
    }
}

export function resetPrintCategoryLanguage() {
    return {
        type: RESET_PRINT_CATEGORYLANGUAGE,
    }
}

export function printCategoryLanguage(idCategoryLanguage) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintCategoryLanguage());
        return categoriesLanguagesApi.printCategoryLanguage(idCategoryLanguage)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintCategoryLanguage(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintCategoryLanguage(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintCategoryLanguage(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintCategoryLanguage(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintCategoryLanguage(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}