import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
//Components
import {Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography} from '@material-ui/core';
import clsx from "clsx";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
//Icons
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clone from "lodash/clone";
import union from "lodash/union";
import {useDispatch, useSelector} from "react-redux";
//Img


const styles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    fileInput: {
        marginBottom: "12px",
        "& label": {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            borderRadius: "8px",
            backgroundColor: "rgba(241, 10, 77, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    mobile: {
        width: "100%",
        maxWidth: "100px",
        height: "120px",
        paddingRight: "6px",
    },
    desktop: {
        width: "100%",
        maxWidth: "200px",
        height: "120px",
        paddingLeft: "6px",
    },
    buttonUpload: {
        padding: 0,
        borderRadius: "8px",
        textTransform: "none",
        width: "100%",
        height: "100%",
        "& .MuiButton-label": {
            width: "100%",
            height: "100%",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            padding: "3px"
        },
    },
    labelButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "& img": {
            position: "absolute",
            maxWidth: "100%",
            backgroundColor: "white",
        }
    },
    progress: {
        width: "80px !important",
        height: "80px !important",
        position: "absolute",
        "& svg circle": {
            strokeWidth: "1.2",
        }
    }
}));


export default function FileCategory(props) {
    const classes = styles();
    const dispatch = useDispatch();
    let textFile = React.useRef();
    let inputfile = React.createRef();
    let img = React.useRef();
    const {files, setFiles, changeCategory, category, acceptTypes} = props;
    //Hooks
    //State
    const [loading, setLoading] = React.useState(false);
    const [filename, setFileName] = React.useState("Subir archivo");

    //Store
    const categories = useSelector(state => state.categories);

    let file = category ? categories.byId.files[category.filename] : null;

    //Hago la preview de las fotos cargadas del backend
    useEffect(() => {

            if (file)
                previewFile(file);
            else
                setFileName("Subir archivo");
        }, [file]
    )

    const previewFile = (file, type) => {
        let reader = new FileReader();
        reader.onloadend = (e) => {
            img.current.src = reader.result;
            setLoading(false);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleChangeFileCategory = (e) => {
        e.preventDefault();
        //Preview
        let fotos = [];
        setLoading(true);
        for (let i = 0; i < inputfile.current.files.length; i++) {
            let filename = inputfile.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(inputfile.current.files[i], 'name', {
                writable: true,
                value: filename
            });
            fotos.push(inputfile.current.files[i]);
        }
        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        for (let i = 0; i < inputfile.current.files.length; i++) {
            let fileSubido = inputfile.current.files[i];

            let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                setLoading(false);
                setFileName(filename);
                img.current.src = reader.result;
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
            let cambio = {};
            cambio.filename = filename;
            changeCategory(cambio);
        }
    };



    return (
        <Box display={"flex"} component={"div"} className={classes.containerFileInput} justifyContent={"flex-end"}>
            <Box component={"div"} className={clsx([classes.fileInput, classes.desktop])}>
                <Button className={classes.buttonUpload}>
                    <label htmlFor={"file"}
                           className={classes.labelButton}
                        // ref={instance => labelRef.current = instance}
                    >
                        <AddPhotoAlternateIcon color={"primary"}/>
                        <Typography variant={"caption"} color={"primary"}>
                            Subir imagen
                        </Typography>
                        <img ref={img} src={""} alt=""/>
                        <CircularProgress className={classes.progress}
                                          style={{display: !loading ? "none" : "inherit"}}/>
                    </label>
                    <input ref={inputfile} type="file" name="file" id={"file"}
                           className="inputfile"
                           accept={acceptTypes}
                           onChange={(e) => handleChangeFileCategory(e)}
                    />
                </Button>
            </Box>
        </Box>
    );
}

FileCategory.propTypes = {
    legend: PropTypes.string,
}