import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';

//Components
import {
    Avatar,
    Checkbox,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

//Icons
import CropFreeIcon from "@material-ui/icons/Search";
//Actions
//constants
//Hooks
import countryReset from "../../../reset/countryReset";

import {fetchCountriesIfNeeded, invalidateCountries} from "../../../actions/CountryActions";


let moment = require('moment');

const useStyles = makeStyles(theme => ({
    tarjeta: {
        padding: "10px 20px 20px 20px",
        marginBottom: "17px",
    },
    rootLista: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        height: "300px",
        overflowY: "auto",
    },
    listaOperadores: {
        "&.checked": {
            borderLeft:"3px solid",
            borderLeftColor: theme.palette.primary[theme.palette.type],
        }
    },
    containerAreaOp: {
        display: "flex",
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.2),
        },
    },
    mensaje:{
        display: "none",
        color: theme.palette.error.main,
        "&.visible": {
            display: "inherit",
        }
    }
}));

export default function EmbeddedCountriesList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {disabled, refresh} = props;

    //State
    const [checkboxSelectAllCountries, setCheckboxSelectAllCountries] = useState(false);
    const [searchPhase, setSearchPhase] = useState("");

    //Store
    const countries = useSelector(state => state.countries);


    //Effects
    useEffect(() => {
        dispatch(invalidateCountries());
        dispatch(fetchCountriesIfNeeded());

        return function cleanup() {
            countryReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        setCheckboxSelectAllCountries(false);
    }, [refresh]);

    const onSelectAll = (e) => {
        props.msjCountries.current.classList.remove("visible");

        //Checkbox
        let checked = !checkboxSelectAllCountries;
        setCheckboxSelectAllCountries(checked);

        countries.allIds.forEach((idCountry) => {
                let persona = countries.byId.countries[idCountry];
                let esCountry = props.isSelected(idCountry);
                if ((checked && !esCountry) || (!checked && esCountry))
                    onSelect(idCountry, checked);
            }
        )
    }

    const onSelect = (idCountry, selectAll) => {
        props.msjCountries.current.classList.remove("visible");

        setCheckboxSelectAllCountries(!!selectAll);

        props.onSelect(idCountry);
    }

    return (
        <Paper elevation={1} className={classes.tarjeta}>
            <Grid container spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                    <TextField
                        id="search"
                        placeholder="Buscar ..."
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // variant="filled"
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    {
                                        searchPhase !== "" &&
                                        <IconButton onClick={() => setSearchPhase("")}>
                                            <CloseIcon/>
                                        </IconButton>
                                    }
                                    <Icon>
                                        <CropFreeIcon/>
                                    </Icon>
                                </InputAdornment>,
                        }}
                        value={searchPhase ? searchPhase : ""}
                        onChange={(e) => setSearchPhase(e.target.value)}
                    />
                </Grid>
            </Grid>
            <hr style={{opacity: ".25"}}/>
            <Grid container spacing={1} >
                <Grid item md={12} sm={12} xs={12}>
                    <List dense className={classes.rootLista} subheader={<li/>}>
                        <ListSubheader
                            button
                            style={{padding: "10px 1px"}}
                        >
                            <ListItemText primary={"Seleccionar todos"} disabled={disabled} onClick={!disabled ? () => onSelectAll() : ""}/>
                            <ListItemSecondaryAction>
                                <Checkbox
                                    checked={checkboxSelectAllCountries}
                                    edge="end"
                                    onChange={() => onSelectAll()}
                                    disabled={disabled}
                                />
                            </ListItemSecondaryAction>
                        </ListSubheader>
                        {countries.allIds.map((idCountry) => {
                            let country = countries.byId.countries[idCountry];
                            const labelId = `checkbox-list-secondary-label-${idCountry}`;
                            if (searchPhase === "" || (country.desCountry).toLowerCase().indexOf(searchPhase.toLowerCase()) !== -1)
                                return (
                                    <ListItem key={idCountry}
                                              button
                                              onClick={() => onSelect(idCountry)}
                                              disabled={disabled}
                                              className={`${classes.listaOperadores} ${props.isSelected(idCountry) ? "checked" : ""}`}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>{country && country.desCountry ? country.desCountry.charAt(0) : ""}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={labelId}
                                                      primary={`${country ? country.desCountry + " " + country.unicodeIcon : ""}`}
                                                      secondary={<React.Fragment>
                                                          <Typography
                                                              component="span"
                                                              variant="body2"
                                                              className={classes.inline}
                                                              color="textPrimary"
                                                          >
                                                              {/*{country ? country.unicodeIcon : ""}*/}
                                                          </Typography>
                                                      </React.Fragment>}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={() => onSelect(idCountry)}
                                                checked={props.isSelected(idCountry)}
                                                inputProps={{'aria-labelledby': labelId}}
                                                color={"primary"}
                                                disabled={disabled}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                        })}
                    </List>
                    <p className={classes.mensaje} ref={props.msjCountries}>Debe seleccionar por lo menos un país</p>
                </Grid>
            </Grid>
        </Paper>

    );
}
