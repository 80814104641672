import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
//Components
import LogsList from "./LogsList";

export default function Logs() {

    const dispatch = useDispatch();
    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);

    return (
        <Switch>
            <Route path="/logs" component={LogsList}/>
        </Switch>

    );
}

