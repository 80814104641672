//api
import sectionsApi from "../api/sectionsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeSections";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//SECTIONS
export const REQUEST_SECTIONS = 'REQUEST_SECTIONS';
export const RECEIVE_SECTIONS = 'RECEIVE_SECTIONS';
export const INVALIDATE_SECTIONS = 'INVALIDATE_SECTIONS';
export const ERROR_SECTIONS = "ERROR_SECTIONS";
export const RESET_SECTIONS = "RESET_SECTIONS";


export function invalidateSections() {
    return {
        type: INVALIDATE_SECTIONS
    }
}

function requestSections() {
    return {
        type: REQUEST_SECTIONS,
    }
}

function receiveSections(json) {
    return {
        type: RECEIVE_SECTIONS,
    sections: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorSections(error) {
    return {
        type: ERROR_SECTIONS,
        error: error,
    }
}

export function resetSections() {
    return {
        type: RESET_SECTIONS
    }
}

export function fetchSections(filtros) {
    return dispatch => {
        dispatch(requestSections());
        return sectionsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveSections(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorSections(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorSections(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchSections(state) {
    const sections = state.sections.byId;
    if (!sections) {
        return true
    } else if (sections.isFetching) {
        return false
    } else {
        return sections.didInvalidate;
    }
}

export function fetchSectionsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchSections(getState())) {
            return dispatch(fetchSections(filtros))
        }
    }
}


//MODEL
export const REQUEST_SECTION = 'REQUEST_SECTION';
export const RECEIVE_SECTION = 'RECEIVE_SECTION';
export const INVALIDATE_SECTION = 'INVALIDATE_SECTION';
export const ERROR_SECTION = "ERROR_SECTION";
export const RESET_SECTION = "RESET_SECTION";


export function invalidateSection() {
    return {
        type: INVALIDATE_SECTION
    }
}

function requestSection() {
    return {
        type: REQUEST_SECTION,
    }
}

export function receiveSection(json) {
    return {
        type: RECEIVE_SECTION,
    section: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorSection(error) {
    return {
        type: ERROR_SECTION,
        error: error,
    }
}

export function fetchSection(idSection) {
    return dispatch => {
        dispatch(requestSection());
        return sectionsApi.getOne(idSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveSection(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorSection(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorSection(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_SECTION = 'RECEIVE_FILE_SECTION';

function receiveFileSection(file) {
    return {
        type: RECEIVE_FILE_SECTION,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileSection(idSection, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return sectionsApi.getFile(idSection, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileSection(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorSection(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorSection(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const REQUEST_UPDATE_SECTION = "REQUEST_UPDATE_SECTION";
export const SUCCESS_UPDATE_SECTION = "SUCCESS_UPDATE_SECTION";
export const ERROR_UPDATE_SECTION = "ERROR_UPDATE_SECTION";
export const RESET_UPDATE_SECTION = "RESET_UPDATE_SECTION";
export const DELETE_UPDATE_SECTION = "DELETE_UPDATE_SECTION";

function requestUpdateSection() {
    return {
        type: REQUEST_UPDATE_SECTION,
    }
}

function receiveUpdateSection(section) {
    return {
        type: SUCCESS_UPDATE_SECTION,
        receivedAt: Date.now(),
        section: normalizeDato(section)
    }
}

function errorUpdateSection(error) {
    return {
        type: ERROR_UPDATE_SECTION,
        error: error,
    }
}

export function resetUpdateSection() {
    return {
        type: RESET_UPDATE_SECTION,
    }
}

export function updateSection(section) {
    return {
        type: UPDATE_SECTION,
        section
    }
}

export function saveUpdateSection() {
    return (dispatch, getState) => {
        dispatch(requestUpdateSection());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let section = denormalizeDato(getState().sections.update.activo, store);

        return sectionsApi.saveUpdate(section)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateSection(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateSections(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateSections(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateSection(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateSection(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateSection(section) {
    return {
        type: DELETE_UPDATE_SECTION,
        section
    }
}

//UPDATE SECTIONS
export const REQUEST_UPDATE_SECTIONS = "REQUEST_UPDATE_SECTIONS";
export const SUCCESS_UPDATE_SECTIONS = "SUCCESS_UPDATE_SECTIONS";
export const ERROR_UPDATE_SECTIONS = "ERROR_UPDATE_SECTIONS";
export const RESET_UPDATE_SECTIONS = "RESET_UPDATE_SECTIONS";

function requestUpdateSections() {
    return {
        type: REQUEST_UPDATE_SECTIONS,
}
}

function receiveUpdateSections(sections) {
    return {
        type: SUCCESS_UPDATE_SECTIONS,
    receivedAt: Date.now(),
        sections: normalizeDatos(sections)
}
}

function errorUpdateSections(error) {
    return {
        type: ERROR_UPDATE_SECTIONS,
    error: error,
}
}

export function resetUpdateSections() {
    return {
        type: RESET_UPDATE_SECTIONS,
}
}

export function saveUpdateSections() {
    return (dispatch, getState) => {
        dispatch(requestUpdateSections());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let sections = getState().sections.update.activos.map((idSection) => {
            return denormalizeDato(getState().sections.update.activo[idSection], store);
        });

        return sectionsApi.saveUpdateSections(sections)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateSections(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateSections(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateSections(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateSections(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateSections(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateSections(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA SECTION
export const CREATE_SECTION = 'CREATE_SECTION';
export const REQUEST_CREATE_SECTION = "REQUEST_CREATE_SECTION";
export const SUCCESS_CREATE_SECTION = "SUCCESS_CREATE_SECTION";
export const ERROR_CREATE_SECTION = "ERROR_CREATE_SECTION";
export const RESET_CREATE_SECTION = "RESET_CREATE_SECTION";
export const DELETE_CREATE_SECTION = "DELETE_CREATE_SECTION";


//ALTA SECTION
function requestCreateSection() {
    return {
        type: REQUEST_CREATE_SECTION,
    }
}

function receiveCreateSection(section) {
    return {
        type: SUCCESS_CREATE_SECTION,
        receivedAt: Date.now(),
        section: normalizeDato(section)
    }
}

export function errorCreateSection(error) {
    return {
        type: ERROR_CREATE_SECTION,
        error: error,
    }
}

export function resetCreateSection() {
    return {
        type: RESET_CREATE_SECTION
    }
}

export function createSection(section) {
    return {
        type: CREATE_SECTION,
        section
    }
}

export function deleteCreateSection(section) {
    return {
        type: DELETE_CREATE_SECTION,
        section
    }
}

export function saveCreateSection() {
    return (dispatch, getState) => {
        dispatch(requestCreateSection());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let section = denormalizeDato(getState().sections.create.nuevo, store);

        return sectionsApi.saveCreate(section)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateSection(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateSections(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateSections(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateSection(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateSection(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE SECTIONS
export const REQUEST_CREATE_SECTIONS = "REQUEST_CREATE_SECTIONS";
export const SUCCESS_CREATE_SECTIONS = "SUCCESS_CREATE_SECTIONS";
export const ERROR_CREATE_SECTIONS = "ERROR_CREATE_SECTIONS";
export const RESET_CREATE_SECTIONS = "RESET_CREATE_SECTIONS";

function requestCreateSections() {
    return {
        type: REQUEST_CREATE_SECTIONS,
}
}

function receiveCreateSections(sections) {
    return {
        type: SUCCESS_CREATE_SECTIONS,
    receivedAt: Date.now(),
        sections: normalizeDatos(sections)
}
}

function errorCreateSections(error) {
    return {
        type: ERROR_CREATE_SECTIONS,
    error: error,
}
}

export function resetCreateSections() {
    return {
        type: RESET_CREATE_SECTIONS,
}
}

export function saveCreateSections() {
    return (dispatch, getState) => {
        dispatch(requestCreateSections());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let sections = getState().sections.create.nuevos.map((idSection) => {
            return denormalizeDato(getState().sections.create.nuevo[idSection], store);
        });

        return sectionsApi.saveCreateSections(sections)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateSections(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateSections(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateSections(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateSections(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateSections(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateSections(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE SECTION
export const DELETE_SECTION = 'DELETE_SECTION';
export const REQUEST_DELETE_SECTION = "REQUEST_DELETE_SECTION";
export const SUCCESS_DELETE_SECTION = "SUCCESS_DELETE_SECTION";
export const ERROR_DELETE_SECTION = "ERROR_DELETE_SECTION";
export const RESET_DELETE_SECTION = "RESET_DELETE_SECTION";

function requestDeleteSection() {
    return {
        type: REQUEST_DELETE_SECTION,
    }
}

function receiveDeleteSection(section) {
    return {
        type: SUCCESS_DELETE_SECTION,
        receivedAt: Date.now(),
        section: normalizeDato(section)
    }
}

function errorDeleteSection(error) {
    return {
        type: ERROR_DELETE_SECTION,
        error: error,
    }
}

export function resetDeleteSection(error) {
    return {
        type: RESET_DELETE_SECTION,
        error: error,
    }
}

export function deleteSection(section) {
    return {
        type: DELETE_SECTION,
        section
    }
}

export function saveDeleteSection(section) {
    return dispatch => {
        dispatch(requestDeleteSection());
        return sectionsApi.saveDelete(section)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteSection());
                            dispatch(receiveDeleteSection(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteSection(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteSection(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteSection(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteSection(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteSection(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT SECTION
export const REQUEST_PRINT_SECTION = "REQUEST_PRINT_SECTION";
export const SUCCESS_PRINT_SECTION = "SUCCESS_PRINT_SECTION";
export const ERROR_PRINT_SECTION = "ERROR_PRINT_SECTION";
export const RESET_PRINT_SECTION = "RESET_PRINT_SECTION";

function requestPrintSection() {
    return {
        type: REQUEST_PRINT_SECTION,
    }
}

function receivePrintSection(turnos) {
    return {
        type: SUCCESS_PRINT_SECTION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintSection(error) {
    return {
        type: ERROR_PRINT_SECTION,
        error: error,
    }
}

export function resetPrintSection() {
    return {
        type: RESET_PRINT_SECTION,
    }
}

export function printSection(idSection) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintSection());
        return sectionsApi.printSection(idSection)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintSection(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintSection(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintSection(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintSection(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintSection(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}