/**
 * Created by joelg on 6/9/17.
 */

//localhost
if (process.env.NODE_ENV === "development" && !process.env.REACT_APP_ENVI) {
  // var BASE_URL = "http://localhost:8000/api";
  // var API_HOST = "http://localhost:8000";
  var BASE_URL = "https://webapi.wiener-lab.com/api";
  var API_HOST = "https://webapi.wiener-lab.com";
}

if (process.env.NODE_ENV === "production") {
  var BASE_URL = "https://webapi.wiener-lab.com/api";
  var API_HOST = "https://webapi.wiener-lab.com";
}

export default {
  BASE_URL: BASE_URL,
  API_HOST: API_HOST,
};
