import {
    INVALIDATE_ASSETSLANGUAGES,
    ERROR_ASSETSLANGUAGES,
    RECEIVE_ASSETSLANGUAGES,
    REQUEST_ASSETSLANGUAGES,
    RESET_ASSETSLANGUAGES,
    ERROR_ASSETLANGUAGE,
    RECEIVE_ASSETLANGUAGE,
    REQUEST_ASSETLANGUAGE,
    UPDATE_ASSETLANGUAGE,
    REQUEST_UPDATE_ASSETLANGUAGE,
    SUCCESS_UPDATE_ASSETLANGUAGE,
    ERROR_UPDATE_ASSETLANGUAGE,
    RESET_UPDATE_ASSETLANGUAGE,
    REQUEST_UPDATE_ASSETSLANGUAGES,
    SUCCESS_UPDATE_ASSETSLANGUAGES,
    ERROR_UPDATE_ASSETSLANGUAGES,
    RESET_UPDATE_ASSETSLANGUAGES,
    CREATE_ASSETLANGUAGE,
    ERROR_CREATE_ASSETLANGUAGE,
    REQUEST_CREATE_ASSETLANGUAGE,
    RESET_CREATE_ASSETLANGUAGE,
    SUCCESS_CREATE_ASSETLANGUAGE,
    REQUEST_CREATE_ASSETSLANGUAGES,
    SUCCESS_CREATE_ASSETSLANGUAGES,
    ERROR_CREATE_ASSETSLANGUAGES,
    RESET_CREATE_ASSETSLANGUAGES,
    DELETE_ASSETLANGUAGE,
    DELETE_CREATE_ASSETLANGUAGE,
    DELETE_UPDATE_ASSETLANGUAGE,
    REQUEST_DELETE_ASSETLANGUAGE,
    SUCCESS_DELETE_ASSETLANGUAGE,
    ERROR_DELETE_ASSETLANGUAGE,
    RESET_DELETE_ASSETLANGUAGE,
    REQUEST_PRINT_ASSETLANGUAGE,
    SUCCESS_PRINT_ASSETLANGUAGE,
    ERROR_PRINT_ASSETLANGUAGE,
    RESET_PRINT_ASSETLANGUAGE,
    RECEIVE_FILE_ASSETLANGUAGE
} from '../actions/AssetLanguageActions';

import {
    RECEIVE_ASSET,
    RECEIVE_ASSETS,
    SUCCESS_DELETE_ASSET,
    SUCCESS_CREATE_ASSET,
    SUCCESS_UPDATE_ASSET,
    SUCCESS_UPDATE_ASSETS

} from '../actions/AssetActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        assetsLanguages: {},
        files: {},
    }
}

function assetsLanguagesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                assetsLanguages: {}
            });
        case RECEIVE_ASSETSLANGUAGES:
            let dato = action.assetsLanguages.entities.assetsLanguages;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                assetsLanguages: merge({}, state.assetsLanguages, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ASSETLANGUAGE:
            let datoAssetLanguage = action.assetLanguage.entities.assetsLanguages;
            return Object.assign({}, state, {
                assetsLanguages: merge({}, state.assetsLanguages, datoAssetLanguage),
                isFetching: false,
            });
        case RECEIVE_FILE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_ASSETLANGUAGE:
            let datoAssetLanguageEliminado = action.assetLanguage.entities.assetsLanguages;
            return Object.assign({}, state, {
                assetsLanguages: mergeWith(clone(datoAssetLanguageEliminado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSETLANGUAGE:
            let datoAssetLanguageCreado = action.assetLanguage.entities.assetsLanguages;
            return Object.assign({}, state, {
                assetsLanguages: mergeWith(clone(datoAssetLanguageCreado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ASSETSLANGUAGES:
            let datosAssetLanguageCreado = action.assetsLanguages.entities.assetsLanguages;
                return Object.assign({}, state, {
                    assetsLanguages: mergeWith(clone(datosAssetLanguageCreado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ASSETLANGUAGE:
            let datoAssetLanguageActualizado = action.assetLanguage.entities.assetsLanguages;
            return Object.assign({}, state, {
                assetsLanguages: mergeWith(clone(datoAssetLanguageActualizado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ASSETSLANGUAGES:
            let datosAssetLanguageActualizado = action.assetsLanguages.entities.assetsLanguages;
                return Object.assign({}, state, {
                    assetsLanguages: mergeWith(clone(datosAssetLanguageActualizado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
                });

            //ASSET
case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : {};
    return Object.assign({}, state, {
        assetsLanguages: merge({}, state.assetsLanguages, asset),
    });
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.assetsLanguages ? action.assets.entities.assetsLanguages : {};
    return Object.assign({}, state, {
        assetsLanguages: merge({}, state.assetsLanguages, assets),
    });
case SUCCESS_DELETE_ASSET:
    let datoassetEliminado = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : {};
    return Object.assign({}, state, {
        assetsLanguages: mergeWith(clone(datoassetEliminado), pickBy(state.assetsLanguages, function(assetLanguage) {return assetLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_ASSET:
    let datoassetCreado = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : {};
    return Object.assign({}, state, {
        assetsLanguages: mergeWith(clone(datoassetCreado), pickBy(state.assetsLanguages, function(assetLanguage) {return assetLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_ASSET:
    let datoassetActualizado = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : {};
    return Object.assign({}, state, {
        assetsLanguages: mergeWith(clone(datoassetActualizado), pickBy(state.assetsLanguages, function(assetLanguage) {return assetLanguage.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_ASSETS:
    let datosassetActualizado = action.assets.entities && action.assets.entities.assetsLanguages ? action.assets.entities.assetsLanguages : {} ;
        return Object.assign({}, state, {
            assetsLanguages: mergeWith(clone(datosassetActualizado), state.assetsLanguages, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                assetsLanguages: {}
            });
        default:
            return state
    }
}


function allAssetsLanguages(state = [], action) {
    switch (action.type) {
        case RECEIVE_ASSETSLANGUAGES:
            return action.assetsLanguages.result && action.assetsLanguages.result.assetsLanguages ? union(action.assetsLanguages.result.assetsLanguages, state) : (action.assetsLanguages.result ? action.assetsLanguages.result : state) ;
        case RECEIVE_ASSETLANGUAGE:
                return action.assetLanguage.result ? union([action.assetLanguage.result], state) : state;
        
        case SUCCESS_CREATE_ASSETLANGUAGE:
                   let datoAssetLanguageSCreate = action.assetLanguage.entities.assetsLanguages;
                   let idNuevoSCreate = null;
                   if (Object.values(datoAssetLanguageSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoAssetLanguageSCreate)[0] && Object.values(datoAssetLanguageSCreate)[0].id ? Object.values(datoAssetLanguageSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ASSETSLANGUAGES:
                   let assetsLanguagesCreate = action.assetsLanguages.entities && action.assetsLanguages.entities.assetsLanguages ? action.assetsLanguages.entities.assetsLanguages : null;
                   return assetsLanguagesCreate ?
                       union(state, Object.values(assetsLanguagesCreate).map((assetsLanguages) => {
                           return assetsLanguages.id
                       })) : state;
        case RESET_ASSETSLANGUAGES:
            return [];

            case RECEIVE_ASSET:
    let asset = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : null ;
    return asset ?
        union(state, Object.values(asset).map((asset) => {
            return asset.id
        })) : state;
case RECEIVE_ASSETS:
    let assets = action.assets.entities && action.assets.entities.assetsLanguages ? action.assets.entities.assetsLanguages : null;
    return assets ?
        union(state, Object.values(assets).map((assets) => {
            return assets.id
        })) : state;

case SUCCESS_DELETE_ASSET:
    let assetDelete = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : null ;
        return assetDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetDelete).map((asset) => {
                return asset.id
            })) : state;
case SUCCESS_CREATE_ASSET:
   let assetCreate = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : null ;
       return assetCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetCreate).map((asset) => {
               return asset.id
           })) : state;
case SUCCESS_UPDATE_ASSET:
    let assetUpdate = action.asset.entities && action.asset.entities.assetsLanguages ? action.asset.entities.assetsLanguages : null ;
        return assetUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(assetUpdate).map((asset) => {
                return asset.id
            })) : state;
 case SUCCESS_UPDATE_ASSETS:
     let assetsUpdate = action.assets.entities && action.assets.entities.assetsLanguages ? action.assets.entities.assetsLanguages : null;
        return assetsUpdate ?
            union(state, Object.values(assetsUpdate).map((assets) => {
                return assets.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAssetsLanguages(state = null, action) {
    switch (action.type) {
        case RECEIVE_ASSETSLANGUAGES:
            return action.assetsLanguages && action.assetsLanguages.result.total ? action.assetsLanguages.result.total : 0;
        case RESET_ASSETSLANGUAGES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ASSETLANGUAGE:
            let dato = action.assetLanguage.entities.assetsLanguages;
            let assetLanguage = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: assetLanguage ? assetLanguage : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ASSETLANGUAGE:
            let idsUpdate = [];
            Object.values(action.assetLanguage).map((assetLanguageUpdate) => {
                if (assetLanguageUpdate && assetLanguageUpdate.id)
                    idsUpdate.push(assetLanguageUpdate.id);
            });
            return merge({}, state, {
                activo: action.assetLanguage,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ASSETLANGUAGE:
            let datoAssetLanguageActualizado = {};
            if (Object.values(action.assetLanguage.entities.assetsLanguages).length > 0)
                datoAssetLanguageActualizado = Object.values(action.assetLanguage.entities.assetsLanguages)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAssetLanguageActualizado
            });
        case ERROR_UPDATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ASSETSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ASSETSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ASSETSLANGUAGES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //ASSET
//TODO ver si esta bien
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_ASSETLANGUAGE:
            let datoAssetLanguageDelete = action.assetLanguage;
            let idsDelete = [];
           Object.values(action.assetLanguage).map((assetLanguageDelete) => {
               if (assetLanguageDelete && assetLanguageDelete.id)
                   idsDelete.push(assetLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAssetLanguageDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ASSETLANGUAGE:
                       let datoAssetLanguageDeleteUpdate = action.assetLanguage;
                       let idsDeleteUpdate = [];
                      Object.values(action.assetLanguage).map((assetLanguageDelete) => {
                          if (assetLanguageDelete && assetLanguageDelete.id)
                              idsDeleteUpdate.push(assetLanguageDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoAssetLanguageDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ASSETLANGUAGE:
                    let datoAssetLanguageDeleted = {};
                    if (Object.values(action.assetLanguage.entities.assetsLanguages).length > 0)
                        datoAssetLanguageDeleted = Object.values(action.assetLanguage.entities.assetsLanguages)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoAssetLanguageDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ASSETLANGUAGE:
             let idsCreate = [];
             Object.values(action.assetLanguage).map((assetLanguageCreate) => {
                 if (assetLanguageCreate && assetLanguageCreate.id)
                     idsCreate.push(assetLanguageCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.assetLanguage,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ASSETLANGUAGE:
            let datoAssetLanguageNuevo = {};
            if (Object.values(action.assetLanguage.entities.assetsLanguages).length > 0)
                datoAssetLanguageNuevo = Object.values(action.assetLanguage.entities.assetsLanguages)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAssetLanguageNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ASSETSLANGUAGES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_ASSETLANGUAGE:
           let datoAssetLanguageDelete = action.assetLanguage;
           let idsDelete = [];
           Object.values(action.assetLanguage).map((assetLanguageDelete) => {
               if (assetLanguageDelete && assetLanguageDelete.id)
                   idsDelete.push(assetLanguageDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAssetLanguageDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ASSETLANGUAGE:
                  let datoAssetLanguageDeleteCreate = action.assetLanguage;
                  let idsDeleteCreate = [];
                  Object.values(action.assetLanguage).map((assetLanguageDelete) => {
                      if (assetLanguageDelete && assetLanguageDelete.id)
                          idsDeleteCreate.push(assetLanguageDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoAssetLanguageDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ASSETLANGUAGE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.assetLanguage,
                error: null,
            });
        case REQUEST_DELETE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //ASSET
 case SUCCESS_CREATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_ASSET:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_ASSETS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ASSETLANGUAGE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const assetsLanguages = combineReducers({
    byId: assetsLanguagesById,
    allIds: allAssetsLanguages,
    update: update,
    create: create,
    totalAssetsLanguages: totalAssetsLanguages,
    delete: deleter,
    print: print
});

export default assetsLanguages;