import {resetCategories, resetCreateCategory, resetUpdateCategory, resetDeleteCategory} from "../actions/CategoryActions";
import {resetCategoriesLanguages, resetCreateCategoryLanguage, resetUpdateCategoryLanguage, resetDeleteCategoryLanguage} from "../actions/CategoryLanguageActions";

var resetCategory = {
    resetAll(dispatch) {
        dispatch(resetCategories());
        dispatch(resetCreateCategory());
        dispatch(resetUpdateCategory());
        dispatch(resetDeleteCategory());
       dispatch(resetCategoriesLanguages());
dispatch(resetCreateCategoryLanguage());
dispatch(resetUpdateCategoryLanguage());
dispatch(resetDeleteCategoryLanguage());
    },
};

export default resetCategory;