//api
import postsCountriesApi from "../api/postsCountriesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizePostsCountries";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//POSTSCOUNTRIES
export const REQUEST_POSTSCOUNTRIES = 'REQUEST_POSTSCOUNTRIES';
export const RECEIVE_POSTSCOUNTRIES = 'RECEIVE_POSTSCOUNTRIES';
export const INVALIDATE_POSTSCOUNTRIES = 'INVALIDATE_POSTSCOUNTRIES';
export const ERROR_POSTSCOUNTRIES = "ERROR_POSTSCOUNTRIES";
export const RESET_POSTSCOUNTRIES = "RESET_POSTSCOUNTRIES";


export function invalidatePostsCountries() {
    return {
        type: INVALIDATE_POSTSCOUNTRIES
    }
}

function requestPostsCountries() {
    return {
        type: REQUEST_POSTSCOUNTRIES,
    }
}

function receivePostsCountries(json) {
    return {
        type: RECEIVE_POSTSCOUNTRIES,
    postsCountries: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPostsCountries(error) {
    return {
        type: ERROR_POSTSCOUNTRIES,
        error: error,
    }
}

export function resetPostsCountries() {
    return {
        type: RESET_POSTSCOUNTRIES
    }
}

export function fetchPostsCountries(filtros) {
    return dispatch => {
        dispatch(requestPostsCountries());
        return postsCountriesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePostsCountries(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPostsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostsCountries(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPostsCountries(state) {
    const postsCountries = state.postsCountries.byId;
    if (!postsCountries) {
        return true
    } else if (postsCountries.isFetching) {
        return false
    } else {
        return postsCountries.didInvalidate;
    }
}

export function fetchPostsCountriesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPostsCountries(getState())) {
            return dispatch(fetchPostsCountries(filtros))
        }
    }
}


//MODEL
export const REQUEST_POSTCOUNTRY = 'REQUEST_POSTCOUNTRY';
export const RECEIVE_POSTCOUNTRY = 'RECEIVE_POSTCOUNTRY';
export const INVALIDATE_POSTCOUNTRY = 'INVALIDATE_POSTCOUNTRY';
export const ERROR_POSTCOUNTRY = "ERROR_POSTCOUNTRY";
export const RESET_POSTCOUNTRY = "RESET_POSTCOUNTRY";


export function invalidatePostCountry() {
    return {
        type: INVALIDATE_POSTCOUNTRY
    }
}

function requestPostCountry() {
    return {
        type: REQUEST_POSTCOUNTRY,
    }
}

export function receivePostCountry(json) {
    return {
        type: RECEIVE_POSTCOUNTRY,
    postCountry: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPostCountry(error) {
    return {
        type: ERROR_POSTCOUNTRY,
        error: error,
    }
}

export function fetchPostCountry(idPostCountry) {
    return dispatch => {
        dispatch(requestPostCountry());
        return postsCountriesApi.getOne(idPostCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePostCountry(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_POSTCOUNTRY = 'RECEIVE_FILE_POSTCOUNTRY';

function receiveFilePostCountry(file) {
    return {
        type: RECEIVE_FILE_POSTCOUNTRY,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFilePostCountry(idPostCountry, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return postsCountriesApi.getFile(idPostCountry, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFilePostCountry(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPostCountry(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_POSTCOUNTRY = 'UPDATE_POSTCOUNTRY';
export const REQUEST_UPDATE_POSTCOUNTRY = "REQUEST_UPDATE_POSTCOUNTRY";
export const SUCCESS_UPDATE_POSTCOUNTRY = "SUCCESS_UPDATE_POSTCOUNTRY";
export const ERROR_UPDATE_POSTCOUNTRY = "ERROR_UPDATE_POSTCOUNTRY";
export const RESET_UPDATE_POSTCOUNTRY = "RESET_UPDATE_POSTCOUNTRY";
export const DELETE_UPDATE_POSTCOUNTRY = "DELETE_UPDATE_POSTCOUNTRY";

function requestUpdatePostCountry() {
    return {
        type: REQUEST_UPDATE_POSTCOUNTRY,
    }
}

function receiveUpdatePostCountry(postCountry) {
    return {
        type: SUCCESS_UPDATE_POSTCOUNTRY,
        receivedAt: Date.now(),
        postCountry: normalizeDato(postCountry)
    }
}

function errorUpdatePostCountry(error) {
    return {
        type: ERROR_UPDATE_POSTCOUNTRY,
        error: error,
    }
}

export function resetUpdatePostCountry() {
    return {
        type: RESET_UPDATE_POSTCOUNTRY,
    }
}

export function updatePostCountry(postCountry) {
    return {
        type: UPDATE_POSTCOUNTRY,
        postCountry
    }
}

export function saveUpdatePostCountry() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePostCountry());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postCountry = denormalizeDato(getState().postsCountries.update.activo, store);

        return postsCountriesApi.saveUpdate(postCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePostCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdatePostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdatePostsCountries(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdatePostsCountries(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdatePostCountry(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdatePostCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdatePostCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdatePostCountry(postCountry) {
    return {
        type: DELETE_UPDATE_POSTCOUNTRY,
        postCountry
    }
}

//UPDATE POSTSCOUNTRIES
export const REQUEST_UPDATE_POSTSCOUNTRIES = "REQUEST_UPDATE_POSTSCOUNTRIES";
export const SUCCESS_UPDATE_POSTSCOUNTRIES = "SUCCESS_UPDATE_POSTSCOUNTRIES";
export const ERROR_UPDATE_POSTSCOUNTRIES = "ERROR_UPDATE_POSTSCOUNTRIES";
export const RESET_UPDATE_POSTSCOUNTRIES = "RESET_UPDATE_POSTSCOUNTRIES";

function requestUpdatePostsCountries() {
    return {
        type: REQUEST_UPDATE_POSTSCOUNTRIES,
}
}

function receiveUpdatePostsCountries(postsCountries) {
    return {
        type: SUCCESS_UPDATE_POSTSCOUNTRIES,
    receivedAt: Date.now(),
        postsCountries: normalizeDatos(postsCountries)
}
}

function errorUpdatePostsCountries(error) {
    return {
        type: ERROR_UPDATE_POSTSCOUNTRIES,
    error: error,
}
}

export function resetUpdatePostsCountries() {
    return {
        type: RESET_UPDATE_POSTSCOUNTRIES,
}
}

export function saveUpdatePostsCountries() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePostsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postsCountries = getState().postsCountries.update.activos.map((idPostCountry) => {
            return denormalizeDato(getState().postsCountries.update.activo[idPostCountry], store);
        });

        return postsCountriesApi.saveUpdatePostsCountries(postsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePostsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePostsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdatePostsCountries(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdatePostsCountries(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdatePostsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdatePostsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA POSTCOUNTRY
export const CREATE_POSTCOUNTRY = 'CREATE_POSTCOUNTRY';
export const REQUEST_CREATE_POSTCOUNTRY = "REQUEST_CREATE_POSTCOUNTRY";
export const SUCCESS_CREATE_POSTCOUNTRY = "SUCCESS_CREATE_POSTCOUNTRY";
export const ERROR_CREATE_POSTCOUNTRY = "ERROR_CREATE_POSTCOUNTRY";
export const RESET_CREATE_POSTCOUNTRY = "RESET_CREATE_POSTCOUNTRY";
export const DELETE_CREATE_POSTCOUNTRY = "DELETE_CREATE_POSTCOUNTRY";


//ALTA POSTCOUNTRY
function requestCreatePostCountry() {
    return {
        type: REQUEST_CREATE_POSTCOUNTRY,
    }
}

function receiveCreatePostCountry(postCountry) {
    return {
        type: SUCCESS_CREATE_POSTCOUNTRY,
        receivedAt: Date.now(),
        postCountry: normalizeDato(postCountry)
    }
}

export function errorCreatePostCountry(error) {
    return {
        type: ERROR_CREATE_POSTCOUNTRY,
        error: error,
    }
}

export function resetCreatePostCountry() {
    return {
        type: RESET_CREATE_POSTCOUNTRY
    }
}

export function createPostCountry(postCountry) {
    return {
        type: CREATE_POSTCOUNTRY,
        postCountry
    }
}

export function deleteCreatePostCountry(postCountry) {
    return {
        type: DELETE_CREATE_POSTCOUNTRY,
        postCountry
    }
}

export function saveCreatePostCountry() {
    return (dispatch, getState) => {
        dispatch(requestCreatePostCountry());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postCountry = denormalizeDato(getState().postsCountries.create.nuevo, store);

        return postsCountriesApi.saveCreate(postCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePostCountry(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreatePostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreatePostsCountries(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreatePostsCountries(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreatePostCountry(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreatePostCountry(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreatePostCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE POSTSCOUNTRIES
export const REQUEST_CREATE_POSTSCOUNTRIES = "REQUEST_CREATE_POSTSCOUNTRIES";
export const SUCCESS_CREATE_POSTSCOUNTRIES = "SUCCESS_CREATE_POSTSCOUNTRIES";
export const ERROR_CREATE_POSTSCOUNTRIES = "ERROR_CREATE_POSTSCOUNTRIES";
export const RESET_CREATE_POSTSCOUNTRIES = "RESET_CREATE_POSTSCOUNTRIES";

function requestCreatePostsCountries() {
    return {
        type: REQUEST_CREATE_POSTSCOUNTRIES,
}
}

function receiveCreatePostsCountries(postsCountries) {
    return {
        type: SUCCESS_CREATE_POSTSCOUNTRIES,
    receivedAt: Date.now(),
        postsCountries: normalizeDatos(postsCountries)
}
}

function errorCreatePostsCountries(error) {
    return {
        type: ERROR_CREATE_POSTSCOUNTRIES,
    error: error,
}
}

export function resetCreatePostsCountries() {
    return {
        type: RESET_CREATE_POSTSCOUNTRIES,
}
}

export function saveCreatePostsCountries() {
    return (dispatch, getState) => {
        dispatch(requestCreatePostsCountries());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let postsCountries = getState().postsCountries.create.nuevos.map((idPostCountry) => {
            return denormalizeDato(getState().postsCountries.create.nuevo[idPostCountry], store);
        });

        return postsCountriesApi.saveCreatePostsCountries(postsCountries)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePostsCountries(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePostsCountries(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreatePostsCountries(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreatePostsCountries(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreatePostsCountries(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreatePostsCountries(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE POSTCOUNTRY
export const DELETE_POSTCOUNTRY = 'DELETE_POSTCOUNTRY';
export const REQUEST_DELETE_POSTCOUNTRY = "REQUEST_DELETE_POSTCOUNTRY";
export const SUCCESS_DELETE_POSTCOUNTRY = "SUCCESS_DELETE_POSTCOUNTRY";
export const ERROR_DELETE_POSTCOUNTRY = "ERROR_DELETE_POSTCOUNTRY";
export const RESET_DELETE_POSTCOUNTRY = "RESET_DELETE_POSTCOUNTRY";

function requestDeletePostCountry() {
    return {
        type: REQUEST_DELETE_POSTCOUNTRY,
    }
}

function receiveDeletePostCountry(postCountry) {
    return {
        type: SUCCESS_DELETE_POSTCOUNTRY,
        receivedAt: Date.now(),
        postCountry: normalizeDato(postCountry)
    }
}

function errorDeletePostCountry(error) {
    return {
        type: ERROR_DELETE_POSTCOUNTRY,
        error: error,
    }
}

export function resetDeletePostCountry(error) {
    return {
        type: RESET_DELETE_POSTCOUNTRY,
        error: error,
    }
}

export function deletePostCountry(postCountry) {
    return {
        type: DELETE_POSTCOUNTRY,
        postCountry
    }
}

export function saveDeletePostCountry(postCountry) {
    return dispatch => {
        dispatch(requestDeletePostCountry());
        return postsCountriesApi.saveDelete(postCountry)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeletePostCountry());
                            dispatch(receiveDeletePostCountry(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeletePostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeletePostCountry(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeletePostCountry(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeletePostCountry(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeletePostCountry(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT POSTCOUNTRY
export const REQUEST_PRINT_POSTCOUNTRY = "REQUEST_PRINT_POSTCOUNTRY";
export const SUCCESS_PRINT_POSTCOUNTRY = "SUCCESS_PRINT_POSTCOUNTRY";
export const ERROR_PRINT_POSTCOUNTRY = "ERROR_PRINT_POSTCOUNTRY";
export const RESET_PRINT_POSTCOUNTRY = "RESET_PRINT_POSTCOUNTRY";

function requestPrintPostCountry() {
    return {
        type: REQUEST_PRINT_POSTCOUNTRY,
    }
}

function receivePrintPostCountry(turnos) {
    return {
        type: SUCCESS_PRINT_POSTCOUNTRY,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintPostCountry(error) {
    return {
        type: ERROR_PRINT_POSTCOUNTRY,
        error: error,
    }
}

export function resetPrintPostCountry() {
    return {
        type: RESET_PRINT_POSTCOUNTRY,
    }
}

export function printPostCountry(idPostCountry) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintPostCountry());
        return postsCountriesApi.printPostCountry(idPostCountry)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintPostCountry(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintPostCountry(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintPostCountry(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintPostCountry(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintPostCountry(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}