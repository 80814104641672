import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
//Components
import {
    Breadcrumbs,
    Chip,
    Container,
    IconButton,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import AssetCard from "../../library/CustomCard/AssetCard";
import SectionCard from "../../library/CustomCard/SectionCard";
import Pagination from "@material-ui/lab/Pagination";
import Grow from "../../library/Grow";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import PaperFooter from "../../library/CustomPaper/PaperFooter";
import ContainerBtn from "../../library/Buttons/ContainerBtn";
import Box from "@material-ui/core/Box";
import {Skeleton, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import BannerPreviewType from "./PreviewsTypesElements/BannerPreviewType";
import HTMLPreviewType from "./PreviewsTypesElements/HTMLPreviewType";
import FilePreviewType from "./PreviewsTypesElements/FilePreviewType";
import ProductPreviewType from "./PreviewsTypesElements/ProductPreviewType";

import Spacing from "../../library/Spacing";
//Img
import CropFreeIcon from "@material-ui/icons/Search";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import PhoneIcon from "@material-ui/icons/Phone";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import DoneIcon from "@material-ui/icons/Done";
import WebAssetIcon from '@material-ui/icons/WebAsset';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AdminPanelSettingsIcon from '@material-ui/icons/Security';
//reset
import assetReset from "../../reset/assetReset";
//Actions
import {fetchAssets, invalidateAssets, resetAssets, saveDeleteAsset} from "../../actions/AssetActions";
import {fetchLanguagesIfNeeded} from "../../actions/LanguageActions";
import {fetchCountriesIfNeeded} from "../../actions/CountryActions";
import {fetchPages} from "../../actions/PageActions";
import {fetchFileAssetLanguage} from "../../actions/AssetLanguageActions";
import languageReset from "../../reset/languageReset";
import countryReset from "../../reset/countryReset";
import pageReset from "../../reset/pageReset";

let moment = require('moment');

const useStyles = makeStyles(theme => ({
    typographyLanguage: {
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "flex-start",
            flexWrap: "nowrap",
        },
    }
}));

export default function AssetsList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    //Store
    const assets = useSelector(state => state.assets);
    const assetsLanguages = useSelector(state => state.assetsLanguages);
    const assetsCountries = useSelector(state => state.assetsCountries);
    const pages = useSelector(state => state.pages);
    const sections = useSelector(state => state.sections);
    const languages = useSelector(state => state.languages);
    const countries = useSelector(state => state.countries);
    const typesFiles = useSelector(state => state.typesFiles);
    const typesFilesSections = useSelector(state => state.typesFilesSections);
    const products = useSelector(state => state.products);

    //State
    const [search, setSearch] = useState("");
    const [idPageSelected, setIdPageSelected] = React.useState(null);

    const params = new URLSearchParams(props.location.search);
    const order = params.get("order") ? params.get("order") : "id";
    const direction = params.get("direction") ? params.get("direction") : "asc";
    const page = params.get("page") ? params.get("page") : 1;
    const searchPhase = params.get("sp") ? params.get("sp") : "";
    const idSectionSelected = params.get("idSection") ? params.get("idSection") : "";
    const show = params.get("show") ? params.get("show") : "actives";

    const pageSelected = idPageSelected ? pages.byId.pages[idPageSelected] : null;
    const sectionSelected = idSectionSelected ? sections.byId.sections[idSectionSelected] : null;
    const disabled = assets.byId.isFetching || assets.delete.isDeleting;

    //Hooks
    const timer = useRef();
    const mounted = useRef();

    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);

        if (searchPhase != search) {
            setSearch(searchPhase);
        }
        if (idSectionSelected !== "") {
            setSearch(searchPhase);
        }
        dispatch(fetchLanguagesIfNeeded());
        dispatch(fetchCountriesIfNeeded());
        dispatch(fetchPages({with: ["section.typeFileSection"]}))

        return function cleanup() {
            assetReset.resetAll(dispatch);
            languageReset.resetAll(dispatch);
            countryReset.resetAll(dispatch);
            pageReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: params.get("sp") ? params.get("sp") : "",
            order: order,
            direction: direction,
            page: page,
            idSection: idSectionSelected,
            show: show,
            with: ["section.page", "section.typeFileSection", "assetLanguage", "assetCountry", "typeFile", "product"]
        };

        dispatch(resetAssets());
        dispatch(invalidateAssets());
        if (idSectionSelected)
            dispatch(fetchAssets(filtros));
    }, [props.location.search]);

    useEffect(() => {
        if (pages.byId.isFetching === false && idSectionSelected !== "") {
            let section = sections.byId.sections[idSectionSelected];
            if (section)
                setIdPageSelected(section.idPage);
        }
    }, [pages.byId.isFetching]);

    useEffect(() => {
        if (assets.byId.isFetching === false) {
            fetchFiles();
        }
    }, [assets.byId.isFetching]);

    const fetchFiles = () => {
        let countFiles = 0;
        assets.allIds.forEach((idAsset) => {
            let asset = assets.byId.assets[idAsset];
            let typeFile = asset ? typesFiles.byId.typesFiles[asset.idTypeFile] : null;
            if (asset && asset.asset_language && typeFile && (typeFile.codType === 'BANNER' || typeFile.codType === 'IMAGE')) {
                asset.asset_language.forEach((idAssetLanguage) => {
                    let assetLanguage = assetsLanguages.byId.assetsLanguages[idAssetLanguage];
                    if (assetLanguage.filenameDesktop)
                        countFiles++;
                    if (assetLanguage.filenameMobile)
                        countFiles++;
                })
                if (asset.asset_language && (assetsLanguages.byId.files.size !== countFiles)) {
                    asset.asset_language.forEach((idDocumentoAtencion, i) => {
                        if (i === 0)
                            if (typeFile.codType === 'BANNER') {
                                dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "mobile"}));
                                dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "desktop"}));
                            } else {
                                dispatch(fetchFileAssetLanguage(idDocumentoAtencion, {type: "file"}));
                            }
                    })
                }
            }
        })
    }

    const changeOrder = (or) => {
        //let params = new URLSearchParams(props.location.search);
        params.set("order", or ? or : "");
        let direction = params.get("direction");
        params.set("direction", direction === "asc" ? "desc" : "asc");
        props.history.push("/assets?" + params.toString());
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        if ((!searchPhase && !search) || (searchPhase === "")) {
            params.delete("sp");
            props.history.push("/assets?" + params.toString());
        } else {
            params.delete("page");
            params.set("sp", searchPhase ? searchPhase : search);
            props.history.push("/assets?" + params.toString());
        }
    };

    const handleChangePage = (e, page) => {
        let pagina = page;
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("page", pagina ? pagina : "1");
        props.history.push("/assets?" + paramsPagina.toString());
    };

    const handleChangeEstado = (idAsset) => {
        let asset = assets.byId.assets[idAsset];
        dispatch(saveDeleteAsset(asset));
    }

    const handleChangeMostrar = (e, value) => {
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("show", value ? value : "actives");
        props.history.push("/assets?" + paramsPagina.toString());
    };

    const handleChangeSection = (id) => {
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("idSection", id ? id : "");
        props.history.push("/assets?" + paramsPagina.toString());
    };

    const handleSelectPage = (idPage) => {
        setIdPageSelected(idPage);
        params.delete("idSection");
        props.history.push("/assets?" + params.toString());
    }

    const getIconPage = (icon) => {
        switch (icon) {
            case "HomeIcon":
                return <HomeIcon/>;
            case "EmojiPeopleIcon":
                return <EmojiPeopleIcon/>;
            case "RoomServiceIcon":
                return <RoomServiceIcon/>;
            case "TrendingUpIcon":
                return <TrendingUpIcon/>;
            case "PhoneIcon":
                return <PhoneIcon/>;
            case "NewReleasesIcon" :
                return <NewReleasesIcon/>;
            case "BubbleChartIcon" :
                return <BubbleChartIcon/>;
            case "WebAssetIcon" :
                return <WebAssetIcon/>;
            case "SupervisorAccountIcon" :
                return <SupervisorAccountIcon/>;
            case "DashboardIcon":
                return <DashboardIcon/>;
            case "AdminPanelSettingsIcon":
                return <AdminPanelSettingsIcon/>;
            default:
                return <HomeIcon/>;
        }
    }

    //Estado del botón agregar
    const addStatus = sectionSelected && sectionSelected.type_file_section && sectionSelected.type_file_section.some((idTypeFileSection) => {
        let typeFileSection = typesFilesSections.byId.typesFilesSections[idTypeFileSection];
        if (typeFileSection && !typeFileSection.count)
            return true
        return false;
    })

    let Assets = [];

    if (assets.byId.isFetching) {
        Assets = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(7).keys()].map((i) =>
                    <TableCell><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    if (!assets.byId.isFetching)
        Assets = assets.allIds.map(idAsset => {
            let asset = assets.byId.assets[idAsset];
            let section = asset ? sections.byId.sections[asset.idSection] : null;
            let page = section ? pages.byId.pages[section.idPage] : null;
            let typeFile = asset ? typesFiles.byId.typesFiles[asset.idTypeFile] : null;
            let product = asset ? products.byId.products[asset.idProduct] : null;
            let assetLanguages = [];
            if (typeFile.codType === 'PRODUCT')
                assetLanguages.push(<ProductPreviewType product={product}/>)
            if (typeFile.codType === 'BANNER' && asset.asset_language.length > 0) {
                let assetLanguage = assetsLanguages.byId.assetsLanguages[asset.asset_language[0]];
                let language = assetLanguage ? languages.byId.languages[assetLanguage.idLanguage] : null;
                if (!language)
                    language = {id: null, desLanguage: "Multi-idioma", unicodeIcon: "🏳️‍🌈"};
                let fileDesktop = assetLanguage ? assetsLanguages.byId.files[assetLanguage.filenameDesktop] : null;
                let fileMobile = assetLanguage ? assetsLanguages.byId.files[assetLanguage.filenameMobile] : null;
                assetLanguages.push(<BannerPreviewType fileDesktop={fileDesktop} fileMobile={fileMobile}
                                                       language={language}
                                                       assetLanguage={assetLanguage} showImages={true}/>);
            }
            assetLanguages.push(asset.asset_language.map((idAssetLanguage, i) => {
                let assetLanguage = assetsLanguages.byId.assetsLanguages[idAssetLanguage];
                let language = assetLanguage ? languages.byId.languages[assetLanguage.idLanguage] : null;
                if (!language)
                    language = {id: null, desLanguage: "Multi-idioma", unicodeIcon: "🏳️‍🌈"};
                if (assetLanguage && assetLanguage.status === 1 && typeFile)

                    switch (typeFile.codType) {
                        case "BANNER":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "HTML":
                            return <HTMLPreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "PDF":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "IMAGE":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "CSV":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "OFFICE":
                            return <FilePreviewType language={language} assetLanguage={assetLanguage}/>;
                        case "PRODUCT":
                            return <HTMLPreviewType language={language} assetLanguage={assetLanguage}/>;
                    }

            }))
            let assetCountries = asset.asset_country.map((idAssetCountry) => {
                let assetCountry = assetsCountries.byId.assetsCountries[idAssetCountry];
                let country = assetCountry ? countries.byId.countries[assetCountry.idCountry] : null;
                if (country)
                    return (
                        country.unicodeIcon
                    )
            })
            return (
                <TableRow key={asset.id}>
                    <TableCell>{asset ? asset.id : ""}</TableCell>
                    <TableCell>{asset ? asset.nameAsset : ""}</TableCell>
                    <TableCell>{asset ? asset.order : ""}</TableCell>
                    <TableCell>{typeFile ? typeFile.nameType : ""}</TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                            {
                                assetLanguages
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box component={"div"} display={"flex"} flexDirection={"column"} flexWrap={"wrap"}>
                            {
                                assetCountries
                            }
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            {asset ? (moment(asset.dateFrom).format("DD-MM-YYYY") + (asset.dateTo ? "/" + moment(asset.dateTo).format("DD-MM-YYYY") : "")) : ""}
                            {
                                ((!asset.dateTo && moment(asset.dateFrom).isSameOrBefore(moment())) || moment().isBetween(moment(asset.dateFrom), moment(asset.dateTo)))
                                &&
                                <Chip
                                    icon={<DoneIcon/>}
                                    label="Vigente"
                                    color="primary"
                                    variant="outlined"
                                    style={{marginBottom: "20px"}}
                                    size={"small"}
                                />
                            }
                            {
                                !((!asset.dateTo && moment(asset.dateFrom).isSameOrBefore(moment())) || moment().isBetween(moment(asset.dateFrom), moment(asset.dateTo)))
                                &&
                                <Chip
                                    icon={<DoneIcon/>}
                                    label="Fuera de fecha"
                                    color="secondary"
                                    variant="outlined"
                                    style={{marginBottom: "20px"}}
                                    size={"small"}
                                />
                            }
                        </Box></TableCell>
                    <TableCell>
                        <Switch
                            disabled={disabled}
                            checked={!!asset.status}
                            onChange={() => handleChangeEstado(asset.id)}
                            name={"estado"}
                            color={"primary"}
                            size={"small"}
                        />
                    </TableCell>
                    <TableCell>
                        <Link to={"/assets/" + asset.id}
                        >
                            <Tooltip title="Editar">
                                <IconButton aria-label="editar" size={"small"} disabled={disabled}>
                                    <EditIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </TableCell>
                </TableRow>
            )
        });

    if (Assets.length === 0)
        Assets.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={11}>No se encontraron contenidos</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(assets.totalAssets ? assets.totalAssets / 50 : 0);


    return (
        <Container maxWidth={"xg"}>
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
                Contenidos
            </Typography>
            <CustomPaper>
                <PaperHeader>
                    <Typography variant={"overline"} component={"span"}>
                        PÁGINAS
                    </Typography>
                </PaperHeader>
                <Box component={"div"} className={classes.cardContainer}>
                    {
                        pages.allIds.map((idPage) => {
                            let page = pages.byId.pages[idPage];
                            if (page)
                                return <AssetCard primaryText={page.codPage} icon={getIconPage(page.icon)}
                                                  selected={idPageSelected === idPage} onClick={handleSelectPage}
                                                  id={idPage} disabled={disabled}/>
                        })
                    }
                </Box>
                {
                    !!idPageSelected &&
                    <PaperHeader>
                        <Typography variant={"overline"} component={"span"}>
                            SECCIÓN
                        </Typography>
                    </PaperHeader>
                }
                <Box component={"div"} className={classes.cardContainer}>
                    {
                        pageSelected && pageSelected.section &&
                        pageSelected.section.map((idSection) => {
                            let section = sections.byId.sections[idSection];
                            if (section)
                                return <SectionCard primaryText={section.nameSection} onClick={handleChangeSection}
                                                    selected={parseInt(idSectionSelected) === idSection}
                                                    id={idSection} disabled={disabled}/>
                        })
                    }
                </Box>
            </CustomPaper>
            {
                !!idSectionSelected && idSectionSelected !== "" &&
                <CustomPaper>
                    <PaperHeader>
                        <TextField
                            id="search"
                            placeholder="Buscar ..."
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <CropFreeIcon/>
                                        </IconButton>
                                    </InputAdornment>,
                                // disabled: disabled
                            }}
                            value={search ? search : ""}
                            onChange={(e) => changeSearch(e)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <Spacing/>
                        <ToggleButtonGroup
                            value={show}
                            exclusive
                            onChange={handleChangeMostrar}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="actives" aria-label="vigentes">
                                Activos
                            </ToggleButton>
                            <ToggleButton value="todos" aria-label="todos">
                                Todos
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Grow/>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<AddIcon/>}
                            onClick={() => props.history.push("/assets/new" + (idSectionSelected ? "/" + idSectionSelected : ""))}
                            disabled={disabled || !addStatus}
                        >
                            Agregar
                        </Button>
                    </PaperHeader>
                    <Box component={"div"} width={"100%"} padding={"0 24px 5px 24px"}>

                    </Box>
                    <TableContainer>
                        <Table aria-label="Tabla de contenido">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={order === "id"}
                                            direction={direction}
                                            onClick={() => changeOrder("id")}
                                        >
                                            <b>ID</b>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={order === "nameAsset"}
                                            direction={direction}
                                            onClick={() => changeOrder("nameAsset")}
                                        >
                                            <b>Nombre</b>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell width={"50px"}>
                                        <b>Orden</b>
                                    </TableCell>
                                    <TableCell width={"100px"}>
                                        <b>Tipo</b>
                                    </TableCell>
                                    <TableCell width={"500px"}>
                                        <b>Idiomas</b>
                                    </TableCell>
                                    <TableCell width={"100px"}>
                                        <b>Países</b>
                                    </TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell width={"10px"}/>
                                    <TableCell width={"10px"}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Assets
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaperFooter style={{justifyContent: "center"}}>
                        {
                            !!totalPaginas && totalPaginas > 1 &&
                            <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                        onChange={handleChangePage} size={"small"}/>
                        }
                    </PaperFooter>
                </CustomPaper>
            }
        </Container>
    );
}
